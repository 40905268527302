(function (angular, PlatformJS, appInfo, Storage) {

    var trackedOffers     = [],
        requestsList      = [];

    angular.module('olbg-web-app')

    // Tracker
    .factory('Tracker', ['AppSettings',
                         '$q',
                         '$http',
                         'Utils',
                         'deviceDetector',
                         'Environment',
                         '$state',
                         '$injector',
                         '$timeout',
                         'GeneralSettings',
                         'Features',
                         'Lang',
                         'NewFeatures',
                         '$filter',
                         'olbgDialog',
        function TrackerService(AppSettings, $q, $http, Utils, deviceDetector, Environment, $state, $injector, $timeout, GeneralSettings, Features, Lang, NewFeatures, $filter, olbgDialog) {

        // private variables
        var sessions      = 0,
            init          = $q.defer(),
            userIdPromise = $q.defer(),
            codePromise   = $q.defer(),
            trackDB   = Utils.dbFacade({
                dbName: 'track'
            }),
            notificationsDB      = 'notifications2',
            quietNotificationsDB = 'quiet_notifications2',
            service   = {},
            trackInfo = {
                appId:               Environment.keys.appId,
                appIdCode:           Environment.keys.appId,
                isHuman:             true,
                usingTestId:         false,
                developmentMode:     false,
                notifications:       $q.defer(),
                notificationsInited: false
            },
            $analytics,
            preventLogFeatures = AppSettings.tracking.preventLogs,
            currentToken,
            countersDB,
            counters = {},
            countersPromise = $q.defer(),

            FEAT_GA = 'GA',

            // PWA
            sw,
            isPWA      = appInfo.pwa,
            messaging  = appInfo.messaging,
            pwaAppName = appInfo.pwaAppName,
            swPromise  = appInfo.swPromise;

        service.init = function () {

            /**
             * Tracker.init
             *
             * It generates the current session's information
             * which includes:
             *     -> appId (appId)
             *     -> unique user id (userId)
             *     -> browser name and version (browser.name, browser.version)
             *     -> resolution (res)
             *     -> os (os)
             */

            var platformInfo,
                browserInfo,
                promises             = [],
                appsFlyerSettings    = [];

            function useRandomKey() {

                var id = appInfo.DEVICE_ID ? appInfo.DEVICE_ID : Utils.generateKey();

                // use random key
                trackInfo.userId = id;
                userIdPromise.resolve(trackInfo.userId);
                storeUserId(trackInfo.userId);
            }

            function storeUserId(key) {
                // attempt to persist it
                trackDB.put({
                    _id:  'userId',
                    name: key
                }).then(function () {
                    console.log('Tracker: Key successfully stored!');
                });
            }

            function gotAdvertiserId(key) {

                if (!key || key === '00000000-0000-0000-0000-000000000000') {
                    useRandomKey();
                    return;
                }

                trackInfo.userId = key;
                userIdPromise.resolve(trackInfo.userId);
                storeUserId(trackInfo.userId);
                console.log('Tracking [iOS / Android]: using the following IDFA ', trackInfo.userId);

                // if (trackInfo.userId === '38067617-B476-4745-97C4-07908794CB31') { // Adrian
                // // if (trackInfo.userId === '805A7D65-C6E0-4DB9-B521-A576B9943D16') { // Alvaro
                //     service.remoteLog();
                // }
            }

            // 1. GET / GENERATE: USER ID
            GeneralSettings.get(AppSettings.labels.fakeUserIdDB)
                .then(function (doc) {
                    trackInfo.userId          = doc && doc.name ? doc.name : '';
                    trackInfo.developmentMode = doc && doc.name;
                })
                .finally(function () {

                    if (!trackInfo.userId) {

                        // for mobile devices: rely on ADVERTISER ID we get at app startup
                        if (Utils.olbgMainApi.isHybrid && !appInfo.inBrowser && appInfo.DEVICE_ID) {
                            gotAdvertiserId(appInfo.DEVICE_ID);
                        } else {

                            // for m.olbg.com
                            trackDB.get(AppSettings.labels.userIdDB)
                                .then(function (doc) {
                                    // found user ID
                                    trackInfo.userId = doc.name;
                                    // trackInfo.userId = '322C0CFB-29C8-464E-83DC-ABE11AA65987'; // DEBUG Queenci
                                    // trackInfo.userId = '18C62050-E35D-4460-9144-5B3E5BDE3468'; // DEBUG Antony
                                    // trackInfo.userId = 'AC6EA6B9-A3F9-4C06-B362-712348589391'; // DEBUG Antony

                                    if (appInfo.debugAntony) {
                                        trackInfo.userId = 'CCCDD3A9-03D1-4399-BEBA-F05C558D731B'; // DEBUG debugAntony
                                    }

                                    // trackInfo.userId = 'ceb52abc-fa99-4858-85e9-8d1db12b4383'; // DEBUG Antony Android
                                    // trackInfo.userId = '38067617-B476-4745-97C4-07908794CB31'; // DEBUG Adrian
                                    // trackInfo.userId = '805A7D65-C6E0-4DB9-B521-A576B9943D16'; // DEBUG Alvaro
                                    // trackInfo.userId = '82c48bea-0f11-4900-8bed-547a16e1627f'; // DEBUG Sam Darkens
                                    userIdPromise.resolve(trackInfo.userId);
                                })
                                .catch(function () {
                                    // couldn't find user ID. either doesn't exist
                                    // or can't access local storage
                                    useRandomKey();
                                });
                        }
                    } else {
                        trackInfo.usingTestId = true;
                        userIdPromise.resolve();
                    }
                });
            promises.push(userIdPromise.promise);

            // 3. GET PLATFORM AND BROWSER INFO
            // get browser
            trackInfo.browser = {
                name:     deviceDetector.browser,
                version:  deviceDetector.browser_version
            };

            // get os
            trackInfo.os = PlatformJS.os;

            // 4. GET SCREEN RESOLUTION
            trackInfo.res = Utils.getScreenResolution();

            // 5. GET USER IP
            trackInfo.userIp = angular.isDefined(window.USER_IP_NUMBER) && window.USER_IP_NUMBER ? window.USER_IP_NUMBER : '0.0.0.0';

            // trackInfo.userIp = '31.222.140.247'; // DEBUG UK

            if (Utils.olbgMainApi.isHybrid && appInfo.inBrowser) {
                trackInfo.userIp = '31.222.140.247'; // DEBUG UK
                // trackInfo.userIp = '195.206.107.214'; // DEBUG ES
                // trackInfo.userIp = '188.26.253.9'; // DEBUG RO
            }

            /**
             * 6. IF HYBRID:
             * (when served in browser for development this won't run)
             */
            if (Utils.olbgMainApi.isHybrid && !Utils.olbgMainApi.inBrowser) {

                // 1) pass application name and version
                if (angular.isDefined(cordova)) {
                    appVersionPromise = cordova.getAppVersion.getVersionNumber().then(function (version) {

                        trackInfo.appVersion = version;
                        trackInfo.completeVersion = angular.isDefined(appInfo.patchVersion) ? (trackInfo.appVersion.replace(/(\d*.\d*.)\d*/, '$1' + appInfo.patchVersion)) : trackInfo.appVersion;
                        trackInfo.appId += trackInfo.completeVersion; // append app version to app id

                        // set user property
                        service.setUserProperty({
                            property: AppSettings.tracking.ga.userProperties.appVersion.name,
                            value: trackInfo.completeVersion
                        });

                        cordova.getAppVersion.getAppName().then(function (appName) {
                            trackInfo.appName = appName;
                            // appVersionPromise = cordova.getAppVersion.getVersionCode().then(function (code) {
                            //     // trackInfo.appCode = code;
                            // });
                        });
                    });
                    promises.push(appVersionPromise);
                }

                // 2) if deferred deeplinking: go to page
                if (appInfo.appsFlyer && appInfo.appsFlyer.initing && appInfo.appsFlyer.deferred) {

                    appInfo.appsFlyer.deferred.promise
                        .then(function () {

                            var appsFlyerResponse = angular.fromJson(appInfo.appsFlyer.response),
                                justInstalled     = true,
                                obj               = {};

                            // DEBUG: just for me
                            userIdPromise.promise.then(function () {
                                if (trackInfo.userId === '805A7D65-C6E0-4DB9-B521-A576B9943D16') {
                                    service.OLBGLog({ deferredDeeplink: appsFlyerResponse.data.af_dp});
                                }
                            });

                            GeneralSettings.get(AppSettings.labels.justInstalledDB)
                                .then(function (doc) {
                                    justInstalled = doc && angular.isDefined(doc.name) ? doc.name : true;
                                })
                                .finally(function () {

                                    // navigate to deferred deeplink
                                    if (appsFlyerResponse.data.af_dp && justInstalled) {
                                        console.log('AppsFlyer: Has been initialized before. Checking for deferred link.', appsFlyerResponse);
                                        Deeplinking = $injector.get('Deeplinking');
                                        Deeplinking.navigateToPage({ path: appsFlyerResponse.data.af_dp.replace(Environment.appsURLScheme, '/') });

                                        obj[AppSettings.labels.justInstalledDB] = false;

                                        // set just installed to false:
                                        GeneralSettings.store(obj);
                                    }
                                });
                        });
                }

                // 3) Setup campfire and registered push notifications (if enabled)
                document.addEventListener('deviceready', function () {

                    window.FirebasePlugin = typeof window.FirebasePlugin !== 'undefined' ? window.FirebasePlugin : '';

                    if (window.FirebasePlugin) {

                        userIdPromise.promise.then(function(id) {
                            window.FirebasePlugin.setUserId(trackInfo.userId);
                            window.FirebasePlugin.setCrashlyticsUserId(trackInfo.userId);
                        });

                        service.updateToken(); // send token if notifications are enabled
                    }
                });

                // 4) set counters
                service.setCounters();

                $timeout(function () {

                    // 3) setup AppsFlyer
                    document.addEventListener('deviceready', function () {

                        function init() {

                            var appsFlyerOpts,
                                Deeplinking;

                            // init appsflyer
                            appsFlyerOpts = {
                                devKey: Environment.keys.appsFlyer,
                                onInstallConversionDataListener: true
                            };

                            if (Utils.olbgMainApi.isiOS) { appsFlyerOpts.appId = Environment.keys.iOSAppId; }

                            userIdPromise.promise.finally(function () {

                                // NOW: init the SDK
                                window.plugins.appsFlyer.initSdk(appsFlyerOpts, function (response) {

                                    response = angular.fromJson(response);

                                    // navigate to deferred deeplink
                                    if (response.data.af_dp) {
                                        Deeplinking = $injector.get('Deeplinking');
                                        Deeplinking.navigateToPage({ path: response.data.af_dp.replace(Environment.appsURLScheme, '/') });
                                    }

                                    console.log('AppsFlyer: initialization success', response);
                                }, function (reason) { console.log('AppsFlyer: initialization failed', reason); });

                            });
                        }

                        if (window.plugins.appsFlyer) {

                            if (appInfo.appsFlyer && angular.isDefined(appInfo.appsFlyer.deferred)) {
                                appInfo.appsFlyer.deferred.promise.catch(init); // first attempt failed: try again
                            } else {
                                // no appsFlyer
                                init(); // initialize
                            }

                            // set currency
                            window.plugins.appsFlyer.setCurrencyCode(AppSettings.tracking.appsFlyer.currency); // set up currency

                            console.log('Tracker: kickstarting AppsFlyer');
                        }
                    }, false);

                    // 4) for iOS > get a reference to App ID
                    if (Utils.olbgMainApi.isiOS) { trackInfo.iOSAppId = Environment.keys.iOSAppId; }

                    // 6) Init Google App Tracking: only iOS for now
                    if (window.GappTrack && Utils.olbgMainApi.isiOS) { window.GappTrack.track(Environment.keys.gappTrack.conversionId, Environment.keys.gappTrack.label, Environment.keys.gappTrack.value, false); }

                    // 8) Track number of sessions
                    service.trackSessions();

                    // 9) Set user default properties
                    userIdPromise.promise.then(function (id) {
                        service.setUserProperty({property: AppSettings.tracking.ga.userProperties.code.name, value: appInfo.code});
                        service.setUserProperty({property: AppSettings.tracking.ga.userProperties.lang.name, value: appInfo.lang});
                        service.setUserProperty({property: AppSettings.tracking.ga.userProperties.res.name,  value: trackInfo.res});

                        $timeout(function () {
                            service.sendEvent(AppSettings.tracking.ga.events.setUserProperties);
                        }, 60000);
                    });
                }, AppSettings.delays.tracking);
            }

            /**
             * 7. IF GOOGLE PLAY
             * track non geolocated users
             */

            if (Utils.olbgMainApi.isGooglePlay && Utils.olbgMainApi.noGeo) {

                var platformSettings   = $injector.get('PlatformSettings'),
                    noGeolocationDelay = platformSettings.googlePlay.noGeoDelay;

                $timeout(function () {
                    service.trackData({
                        url:      Environment.APIurls.noGeoTracking,
                        postData: {
                            device:       device ? device : '',
                            errorMessage: appInfo.noGeoError ? appInfo.noGeoError : ''
                        }
                    });
                }, noGeolocationDelay);
            }

            /**
             * 8. IF PWA
             * push notifications / setup analytics / etc
             */
            if (isPWA && appInfo.swPromise) {

                appInfo.swPromise.then(function () {

                    // attempt to grab messaging again
                    messaging = messaging ? messaging : appInfo.messaging;

                    if (messaging) {
                        service.promptedNotifications()
                            .then(function (status) {
                                if (status) {
                                    service.updateToken(); // only if prompted before
                                }
                            });

                        sw = appInfo.sw; // get a reference to the service worker

                        // Track installation
                        window.onappinstalled = function (e) {
                            service.sendEvent(AppSettings.tracking.ga.events.pwaInstalled);
                            // disable installabiltiy features: app is installed
                            NewFeatures.disable(AppSettings.labels.features.PWAInstallability);
                            Utils.olbgMainApi.dispatch({ name: 'installedPWA' });
                        };
                    }
                });
            }

            $q.all(promises).finally(function () {
                init.resolve();
                console.log('Tracker: initialized', trackInfo);
            });
        };

        service.get = function () {
            var getPromise = $q.defer();
            init.promise.then(function () {
                getPromise.resolve(trackInfo);
            });
            return getPromise.promise;
        };

        service.getTrackingData = function () { return trackInfo; };

        service.getUserPromise = function () {
            return userIdPromise.promise;
        };

        service.getInitPromise = function () {
            return init.promise;
        };

        service.getTrackDB = function () {
            return trackDB;
        };

        service.appendTI = function (url) {

            //customize URL for localizations
            // if (Utils.olbgMainApi.isWebApp && Utils.isOlbgXX()) {
            //     url = service.appendLocalization(url);
            // }

            // append query character & or ? depending on URL
            url += (url.indexOf('?') > 0 ? '&' : '?');

            // if (Utils.olbgMainApi.isWebApp && angular.isDefined(window.ti_param)) {

            //     url += ('ti=' + window.ti_param);
            //     return url;
            // }

            url += AppSettings.tracking.TIParameter;

            // render the template
            return service.prepareTrackingURL(url);
        };

        service.appendGeo = function (url) {

            if (appInfo.geoISOCode || appInfo.isGooglePlay) {
                // append GEO ISO code
                url += '&checkGeo=' + appInfo.geoISOCode;
            }
            return url;
        };

        service.appendLocalization = function (url) {
                    url += (url.indexOf('?') > 0 ? '&' : '?');
                    url += 'source=.' + OLBG_APP_META.code.toLowerCase();
            return url;
        };

        service.prepareTrackingURL = function (url) {

            /**
             * prepareTrackingURL
             * @url {string} the url to use
             *
             * it simply renders the URL template for tracking requests
             * with the information available.
             */

            url = url.replace('{app-identifier}', trackInfo.appId)
                     .replace('{unique-user-identifier}', trackInfo.userId)
                     .replace('{browser-info}', trackInfo.browser.name + trackInfo.browser.version)
                     .replace('{resolution}', trackInfo.res)
                     .replace('{os}', encodeURI(trackInfo.os))
                     .replace('{ip}', encodeURI(trackInfo.userIp));

            return url;
        };

        service.trackData = function (opts) {

            var deferred = $q.defer();

            opts = angular.extend({
                type: 'post'
            }, opts);

            if (!opts.url) {
                throw "Tracker: a URL is required!";
            }

            function success(response) { deferred.resolve(); console.log('Tracking', opts); }

            init.promise.then(function () {

                var trackingURL = service.appendTI(opts.url);

                if (opts.params) {
                    angular.forEach(opts.params, function (value, param) {
                        trackingURL += ('&' + param + '=' + value);
                    });
                }

                if (opts.type === 'post' && opts.postData) {
                    $http.post(trackingURL, angular.toJson(opts.postData))
                    .success(function (response) { success(response); })
                    .error(deferred.reject);
                } else {
                    if (opts.type === 'get') {
                        // can't use request service here
                        $http.get(trackingURL)
                        .success(function (response) { success(response); })
                        .error(deferred.reject);
                    }
                }
            });

            return deferred.promise;
        };

        // My interests
        service.trackMySports = function (data) {

            var sports     = data.list,
                aliases    = data.aliases,
                from       = data.from;

                // eventsInfo = angular.copy(AppSettings.tracking.ga.events.setSports);

            // extend actions and labels for event
            // eventsInfo.action += from ? ' - via ' + from : '';
            // eventsInfo.label  = service.generateSportsList(aliases);

            service.trackData({
                url: Environment.APIurls.userSportsTracking,
                postData: {
                    sports: sports
                }
            });

            service.appsFlyerTrackEvent(AppSettings.tracking.appsFlyer.events.mySports, {
                af_sports_list: sports
            });

            // 4. send event for my sports
            // service.sendEvent(eventsInfo);
            if (sports && !!sports.length) { service.setUserProperty({ property: AppSettings.tracking.ga.userProperties.setSports.name, value: true }); }
        };

        /*----------  Tracking Methods  ----------*/


        service.userBookiesTracking = function (bookies, email, competition) {

            /**
             * userBookiesTracking - send tracking data when changing bookies accounts selected by user
             *
             * @param  {array}   bookies        an array of active bookies IDs
             * @param  {[bool]}  email          send email in parameter or not
             * @param  {[bool]}  competition    if should enter competition or not
             * @return {object}  promise        a promise object to be resolved when the $http.post completes
             */

            var userBookiesPromise = $q.defer();

            init.promise.then(function () {

                var trackingURL = service.appendTI(Environment.APIurls.userBookiesTracking);

                var dataToSend = {
                    active_bookies: bookies
                };

                $http.post(trackingURL + (email ? '&email=' + email : '') + (competition ? '&enter_competition=' + true : ''), angular.toJson(dataToSend))
                .success(function (response) {
                    console.log('Tracking: User bookies logged. Tracking ->', response);
                    userBookiesPromise.resolve(response);
                })
                .error(function (data, status) {
                    console.log('userBookiesTracking failed', status, data);
                    userBookiesPromise.reject(status);
                });
            });

            // send appsFlyer event
            service.appsFlyerTrackEvent(AppSettings.tracking.appsFlyer.events.completedBookies, {
                af_bookies: bookies
            });

            return userBookiesPromise.promise;
        };

        service.trackOffers = function (info) {

            /**
             * trackOffers - track offers
             *
             * @info  {[object]}
             *
             * info conforms to the following requirements:
             *
             * If Bookies Selector:
             *   + what ranking was that bookie
             *   + what was the offer
             *   + how much better was the odds of the new bookie than the best bookie they had an account with
             *   + were they logged in to any bookies
             *
             * If offers tab:
             *   + ???
             */

            init.promise.then(function () {
                $http.post(service.appendTI(Environment.APIurls.offersTracking), {
                    trackingInfo: info
                })
                .success(function (response) {
                    console.log('Tracking: Selected Offer', response);
                });
            });
        };

        service.trackBetslipURL = function (outcomes, id, betslipUrl) {

            betslipUrl = angular.isDefined(betslipUrl) ? encodeURIComponent(betslipUrl) : '';

            $http.post(service.appendTI(Environment.APIurls.betslipUrlTracking), {
                bets: outcomes,
                bookieId: id,
                betslipUrl: betslipUrl
            }).success(function (response) {
                console.log('Betslip URL Tracking: tracked the following object:', response);
            });
        };

        service.trackLogin = function (bookieName) {

            var trackingObj = {
                'bookie_login' : {
                    bookie: bookieName.toLowerCase()
                }
            };

            service.getInitPromise().then(function () {
                $http.post(service.appendTI(Environment.APIurls.loginTracking), trackingObj).success(function (response) {
                    console.log('Tracking: User logged. Tracking ->', trackingObj);
                });
            });

            // service.sendEvent(AppSettings.tracking.ga.events.loggedInBet365);
        };

        service.trackPlacedBets = function (bookieId, sentModel, betslipModel, APIresponse) {

            /**
             * tracks Placed bets
             */

            var jsonObj,
                requestUrl,
                trackingInfo,
                appsFlyerData = {},
                modelToSend = {
                    bookie_id: bookieId,
                    selections: []
                };

            service.get().then(function (response) {

                // var eventsInfo = angular.copy(AppSettings.tracking.ga.events.placedBet);

                // // extend with sports
                // eventsInfo.label = service.generateSportsList(betslipModel.selections);

                trackerInfo = response;

                // add selections
                angular.forEach(betslipModel.selections, function (selection) {
                    if (!selection.isAd) {
                        var bet = bets[selection.outcomeId + selection.sport];
                        selection = angular.extend({}, selection);
                        // if (angular.isDefined(bet.vc_outcome_id)) {
                        //     selection.vc_outcome_id = bet.vc_outcome_id;
                        // }
                        // if (angular.isDefined(bet.vc_event_id)) {
                        //     selection.vc_event_id = bet.vc_event_id;
                        // }
                        // add to model
                        modelToSend.selections.push(selection);
                    }
                });

                modelToSend.selections = encodeURI(angular.toJson(modelToSend.selections));

                // add multiples
                if (angular.isDefined(sentModel.multiples)) {
                    modelToSend.multiples = encodeURI(angular.toJson(angular.extend([], sentModel.multiples)));
                }

                // add bets
                modelToSend.bet = encodeURI(angular.toJson(APIresponse));

                // convert to JSON
                jsonObj = angular.toJson(modelToSend);

                // create URL
                requestUrl = service.appendTI(Environment.APIurls.placedBetsTracking);

                $http.post(requestUrl, jsonObj).success(function (response) {
                    console.log('Tracking: Placed bets tracked!', modelToSend);
                });

                // send Appsflyer tracking event
                service.appsFlyerTrackEvent(AppSettings.tracking.appsFlyer.events.bet365placedEvent, {
                    af_bookie_id:  modelToSend.bookie_id,
                    af_selections: modelToSend.selections,
                    af_placed_bet: modelToSend.bet
                });

                // GA event
                // service.sendEvent(eventsInfo);
            });
        };

        service.trackSessions = function () {

            service.get().then(function (trackInfo) {
                var version  = trackInfo.completeVersion,
                    db       = AppSettings.labels.sessionsDB + version,
                    doc      = {};

                GeneralSettings.get(db)
                    .then(function (data) {
                        sessions = data && data.name ? parseInt(data.name) : 0;
                    })
                    .finally(function () {

                        // increase session
                        sessions++;

                        service.appsFlyerTrackEvent(AppSettings.tracking.appsFlyer.events.newSession, {
                            af_session_number: sessions,
                            af_app_version: trackInfo.appId
                        });

                        doc[db] = sessions;

                        // store session number
                        GeneralSettings.store(doc);

                        console.log('AppsFlyer: Sessions for version ' + version, sessions);
                    });
            });
        };

        /*----------  Appsflyer events  ----------*/
        service.appsFlyerTrackEvent = function (id, data) {
            if (!id || !window.plugins ||!window.plugins.appsFlyer || !Utils.olbgMainApi.isHybrid) { return; }

            data = data ? data : {};

            document.addEventListener('deviceready', function () {
                window.plugins.appsFlyer.trackEvent(id, data);
                console.log('AppsFlyer: Tracking event created.', id, data);
            });
        };

        service.trackOfferAppsflyer = function (id, url, section) {
            var label = AppSettings.tracking.appsFlyer.events.clickedOffer + '_' + section;
            if (trackedOffers.indexOf(id) >= 0) { return; }
            trackedOffers.push(id);
            service.appsFlyerTrackEvent(label, {
                af_ad_url: url
            });
            console.log('Tracked!', label);
        };


        /*----------  Firebase events  ----------*/

        /**
         * user shares something in the app
         * @param  {string} via         the platform used
         * @param  {Object} shareObject the share data
         * @return {promise}
         */
        service.sendShareEvent = function (via, shareObject) {
            // var eventInfo = angular.copy(AppSettings.tracking.ga.events.share);
            // eventInfo.action += shareObject.id;
            // eventInfo.label  += via;
            // return service.sendEvent(eventInfo);
        };

        /**
         * a wrapper for sending events to GA
         * @param  {Object} eventInfo all the required event information
         * @return {void}
         */
        service.sendEvent = function (eventInfo, gaEventInfo) {

            var defer = $q.defer();

            if (!eventInfo) {
                console.log('Event Tracking: Make sure to pass event information.');
                defer.reject();
                return defer.promise;
            }

            if (window.FirebasePlugin && eventInfo.name) {
                console.log('Firebase: tracking event', eventInfo);
                window.FirebasePlugin.logEvent(eventInfo.name, eventInfo.params);
            }

            // attempt to send GA event
            if ((navigator && navigator.analytics) || (isPWA && gtag)) {

                // if no GA event info is passed: attempt to use default in eventInfo parent object
                gaEventInfo = gaEventInfo ? gaEventInfo : eventInfo.ga;

                if (gaEventInfo) {
                    if (isPWA) {

                        var pwaDetails = { event_category: gaEventInfo.category };

                        if (gaEventInfo.label) { pwaDetails.event_label = gaEventInfo.label; }
                        if (gaEventInfo.value) { pwaDetails.value = gaEventInfo.value; }

                        gtag('event', gaEventInfo.action, pwaDetails);
                    } else {
                        navigator.analytics.sendEvent(gaEventInfo.category, gaEventInfo.action, gaEventInfo.label ? gaEventInfo.label : '', gaEventInfo.value ? gaEventInfo.value : 0, function () {
                            service.customLog(FEAT_GA, 'sent event', gaEventInfo);
                        }, function (err) {});
                    }
                }
            }

            defer.resolve();

            return defer.promise;
        };

        service.setUserProperty = function (opts) {

            function sendUserProperty(property, value) {

                if (isPWA && gtag) {

                    var eventInfo       = angular.copy(AppSettings.tracking.ga.events.userProperty);
                    eventInfo.ga.action = property;

                    /**
                     * if it's a number is value
                     * otherwise use label
                     */

                    if (!isNaN(value)) {
                        eventInfo.ga.value = value;
                    } else {
                        eventInfo.ga.label = value;
                    }

                    service.sendEvent(eventInfo);

                } else if (window.FirebasePlugin) {
                    window.FirebasePlugin.setUserProperty(property, value);
                }
            }

            if (!opts.property) { return; }

            if (window.FirebasePlugin || (isPWA && gtag)) {

                opts = angular.extend({
                    property: '',
                    value: '',
                    increase: true
                }, opts);

                var property, count, props = AppSettings.tracking.ga.userProperties;

                Object.keys(props).forEach(function (k) {
                    if (props[k].name === opts.property) {
                        count = props[k].count;
                    }
                });

                if (count && opts.increase) {
                    // increase and get counter first
                    service.increaseCounter(opts.property)
                        .then(function (num) {
                            opts.value = num + '';
                            sendUserProperty(opts.property, opts.value);
                        });
                } else {
                    if (count) { opts.value = counters[opts.property] ? counters[opts.property] : '0'; }
                    opts.value += '';
                    sendUserProperty(opts.property, opts.value);
                }
            }
        };

        // service.sendImpression = function (data) {

        //     // DEPRECATED

        //     if (!data) {
        //         throw 'Impressions Tracking: Make sure to pass event information.';
        //     }

        //     // no longer using this
        //     var deferred = $q.defer();
        //     deferred.reject();
        //     return deferred;
        // };

        /**
         * joins an array with a given form
         * @param  {array} arr
         * @return {String}
         */
        service.joinGALabels = function (arr) {
            return arr && arr.length ? arr.join(', ') : '';
        };

        /**
         * generates a list of sports out from selections
         * @param  {array} selections
         * @param  {Object} opts
         * @return {String}            string of sports
         */

        service.generateSportsList = function (selections, opts) {

            var output = '';

            opts = angular.extend({
                label: 'sportAlias'
            }, opts);

            try {
                output = Utils.uniqueArray(selections.map(function (s) { return $filter('capitalize')(s[opts.label]); })).sort().join(', ');
            } catch(e) {}
            return output;
        };

        /**
         * count stuff in the app
         * for tracking
         */
        service.setCounters = function () {
            init.promise.then(function () {
                countersDB = AppSettings.labels.userPropertiesCounterDB;
                // get counters
                GeneralSettings.get(countersDB)
                    .then(function (data) {
                        counters = data && data.name ? angular.fromJson(data.name) : {};
                    })
                    .finally(function () {
                        var props = AppSettings.tracking.ga.userProperties;
                        Object.keys(props).forEach(function (k) {
                            if (props[k].count) {
                                service.setUserProperty({ property: props[k].name, increase: false });
                            }
                        });
                        countersPromise.resolve();
                    });
            });
        };

        service.increaseCounter = function (name) {
            var d = $q.defer();
            countersPromise.promise.then(function () {
                counters[name] = angular.isDefined(counters[name]) ? counters[name] : 0;

                // increase
                counters[name]++;
                d.resolve(counters[name]); // pass it along

                // store it
                var obj = {};
                obj[countersDB] = angular.toJson(counters);
                GeneralSettings.store(obj);

            });
            return d.promise;
        };

        service.sendSeenEvent = function (state) {

            // PWA
            if (isPWA && gtag) {

                gtag('event', 'screen_view', {
                    'app_name'    : pwaAppName,
                    'screen_name' : state,
                });

            } else {

                // Hybrid: Firebase
                if (window.FirebasePlugin) {
                    window.FirebasePlugin.setScreenName(state); // log current state
                }

                // Hybrid: Google Events
                if (navigator && navigator.analytics) {
                    navigator.analytics.sendAppView(state, function () {}, function (err) {});
                }
            }

            // also: if the state seen is special: track it
            var page   = AppSettings.tracking.ga.seenScreens.filter(function (el) { return el.state === state; }),
                name   = page[0] ? page[0].name   : '',
                gaName = page[0] ? page[0].gaName : '';

            if (name) {
                service.sendEvent({ name: name }, { category: 'SCREENS', action: gaName + ' / Viewed'});
            }
        };

        /*----------  Reports  ----------*/

        service.apiReport = function (opts) {

            // opts = angular.extend({url: ''}, opts);

            // if (navigator.connection.type !== Connection.NONE && opts.url) {

            //     // it's an API issue

            //     /**
            //      * IDEALLY:
            //      * define reporting request here
            //      */
            // }
        };

        /*----------  Custom Logs  ----------*/

        service.customLog = function (feature) {

            if (!feature || preventLogFeatures.indexOf(feature.toLowerCase()) >= 0) {
                return;
            }

            var args = Array.prototype.slice.call(arguments),
                primaryMsg = args.splice(1,args.length);

            // http://console.re/
            // if (console.re) { console.re.log(feature, primaryMsg); }

            // log to console >
            console.log(feature, primaryMsg);
        };

        /**
         * send data to OLBG for debugging purposes
         * @param {Object} obj data
         */
        service.OLBGLog = function (obj) {
            $http.post(Environment.APIurls.olbgDebug, angular.toJson(obj))
                .success(function (response) {
                    console.log('OLBGLogged: ', response);
                });
        };

        service.remoteLog = function () {

            // https://console.re/8393-c560-2c75
            consolere.ready(function () {
                window.onerror = function (message, source, lineno, colno, error) {
                    console.re.error('JS ERROR', message, source, lineno, colno, error);
                };
            });
            // $('head').append($('<script src="//console.re/connector.js" data-channel="olbg" id="consolerescript"></script>'));

            // // using https://jsconsole.com/
            // // :listen 2e09fe6f-b2ca-4966-8c8f-bdde388ec4f6
            // $('head').append($('<script src="https://jsconsole.com/js/remote.js?2e09fe6f-b2ca-4966-8c8f-bdde388ec4f6"></script>'));
        };

        // simply adds an HTTP request debug data to requestsList array
        service.recordRequest = function (data) {
            var url = angular.copy(data.url);
            if (url) {
                // extend data with TI
                data.ti  = service.prepareTrackingURL(AppSettings.tracking.TIParameter);
                data.tmp = Date.now();
                data.payload = data.payload ? angular.fromJson(data.payload) : {};
                // delete data.url;
                // render the template
                requestsList.push(data);
            }
        };

        service.getLoggedRequests = function () {
            return JSON.stringify(requestsList);
        };

        /*----------  Notifications  ----------*/
        service.showNotificationsNotSupported = function() {

            if (Utils.olbgMainApi.alertsAreBlocked()) {
                // try to request this a bit later...
                $timeout(service.showNotificationsNotSupported, 1000);
                return;
            }

            service.promptedNotifications()
                .then(function(s) {
                    if (!s) {
                        Utils.olbgMainApi.setBlockAlerts(true);
                        olbgDialog.open({
                            contents: {
                                title:       Lang.stream.notSupported.title,
                                message:     Lang.stream.notSupported.msg,
                                actions:     [Lang.stream.notSupported.cta],
                                illustration: '<i class="olbg-alert__illustration__icon olbg-alert__illustration__icon--big"><span style="position: relative; top: 12px; text-align: center; margin: 0 auto; display: block; font-size: 55px; color: #d95946;" class="olbg-alert__illustration__icon__the-icon ionicon ion-android-warning"></span></i>',
                                buttonTypes: ['positive'],
                                centered: true
                            },
                            closeByEscape: false,
                            closeByDocument: false,
                            closeWhenConfirm: [true],
                            actions: [function () {
                                window.open('https://www.google.com/chrome/', '_system');
                                Utils.olbgMainApi.setBlockAlerts(false);
                            }]
                        });
                        service.hasPrompted(); // user allows to prompt
                    }
                })
        };

        service.requestNotificationsInit = function (opts) {
            messaging = messaging ? messaging : appInfo.messaging;

            opts = angular.extend({
                title: Lang.stream.followDialog.title,
                bypassNotice: false
            }, opts);

            if ((Utils.olbgMainApi.isHybrid && !window.FirebasePlugin) ||
                (isPWA && appInfo.messagingNotSupported)) {
                    // if PWA let user know about Chrome
                    if (isPWA && appInfo.messagingNotSupported) {
                        service.showNotificationsNotSupported();
                    }
                return;
            }

            var latestPrompt = Storage.getItem('deniedNotifications');

            // make sure to init only once
            if (!trackInfo.notificationsInited) {

                // ask if prompted for notifications
                service.promptedNotifications()
                    .then(function (status) {

                        function prompt() {
                            service.sendPushToken();       // prompt user!
                            service.hasPrompted(); // user allows to prompt
                            Storage.setItem('deniedNotifications', '');
                            Utils.olbgMainApi.setBlockAlerts(false);
                        }

                        function showDialog() {

                            if (Utils.olbgMainApi.alertsAreBlocked()) {
                                // try to request this a bit later...
                                $timeout(showDialog, 1000);
                                return;
                            }

                            Utils.olbgMainApi.setBlockAlerts(true);

                            var message =  Lang.stream.followDialog.msg + (shouldRequest ? '</p><p>' + Lang.stream.followDialog.msgiOS + '!' + '</p>' : '');
                            message += Utils.olbgMainApi.isPWA ? '<p><b>Desktop User?</b><br>In order to receive notifications also make sure you <b>keep open</b> either the browser or the installed application.</p>' : '';

                            olbgDialog.open({
                                contents: {
                                    title:       Lang.stream.followDialog.title,
                                    message:     message,
                                    actions:     shouldRequest  ? [Lang.generic.dialog.notNow, Lang.generic.dialog.sure] : [Lang.generic.dialog.gotit],
                                    buttonTypes: shouldRequest  ? ['neutral', 'positive'] : ['positive'],
                                    footer:      !shouldRequest ? Lang.stream.followDialog.footer : '',
                                    illustration: '<i class="olbg-alert__illustration__icon olbg-alert__illustration__icon--big"><span style="position: relative; top: 12px; text-align: center; margin: 0 auto; display: block; font-size: 55px; color: #4b9eca;" class="olbg-alert__illustration__icon__the-icon ion-android-notifications-none"></span></i>',
                                    centered: true
                                },
                                closeByEscape: false,
                                closeByDocument: false,
                                closeWhenConfirm: [true, true],
                                actions: [function () {
                                    if (!shouldRequest) {
                                        service.sendPushToken();  // initialize for android
                                        service.hasPrompted();    // user allows to prompt
                                    } else {
                                        if (Storage) { Storage.setItem('deniedNotifications', Date.now()); }
                                    }

                                    Utils.olbgMainApi.setBlockAlerts(false);
                                }, prompt]
                            });
                        }

                        if (!status) {

                            var shouldRequest = !Utils.olbgMainApi.isAndroid; // android doesn't need permissions

                            if (!latestPrompt || (Date.now() - latestPrompt) > AppSettings.notifications.tryAgainEvery) {

                                if (!opts.bypassNotice) {
                                    showDialog();
                                } else {
                                    // prompt directly
                                    prompt();
                                }
                            }
                        } else { service.sendPushToken(); } // just init
                    });
            }
        };

        service.areNotificationsEnabled = function () {

            var deferred = $q.defer();

            if (!messaging && !window.FirebasePlugin) {
                // only enable this for debugging purpuses in
                var enabled = Utils.olbgMainApi.isCore && !Utils.olbgMainApi.isPWA;
                deferred.resolve(enabled);
                return deferred.promise;
            }

            if (messaging) {
                messaging.requestPermission()
                    .then(function () {
                        deferred.resolve(true);
                    })
                    .catch(function () {
                        deferred.resolve(true);
                    });
            } else {
                window.FirebasePlugin.hasPermission(function (data) {
                    deferred.resolve(angular.isDefined(data.isEnabled) ? data.isEnabled : data);
                });
            }

            return deferred.promise;
        };

        service.promptedNotifications = function () {
            var deferred = $q.defer();
            GeneralSettings.get(notificationsDB)
            .then(function (response) {
                var obj = response && response.name ? angular.fromJson(response.name) : {};
                deferred.resolve(obj.askedForPermission);
            })
            .catch(function () {
                deferred.resolve(false);
            });
            return deferred.promise;
        };

        service.hasPrompted = function () {

            var storeObj = {};
            storeObj[notificationsDB] = {
                askedForPermission: true
            };

            GeneralSettings.store(storeObj);
        };

        service.sendPushToken = function () {
            function send() {
                init.promise.then(function () {

                    /**
                     * wait until we get all the tracking information
                     * for now just sending iOS
                     */

                    if (trackInfo.notificationsInited) {
                        trackInfo.notifications.reject();
                        return;
                    }

                    function listenForTokens() {

                        if (token) {
                            // should register right away...
                            service.registerToken({token: token});
                        }

                        // still listen for a new token always...
                        if (messaging) {
                            // PWA
                            messaging.onTokenRefresh(function () {
                                messaging.getToken().then(function (newToken) {
                                    if (newToken && newToken !== currentToken) {
                                        service.registerToken({token: newToken});
                                    }
                                });
                            });
                        } else {
                            // hybrid
                            window.FirebasePlugin.onTokenRefresh(function (newToken) {
                                if (newToken && newToken !== currentToken) {
                                    service.registerToken({token: newToken});
                                }
                            });
                        }
                    }

                    var token,
                        obj  = {};

                    trackInfo.notificationsInited = true;    // notifications have been initialized

                    // request permission
                    if (messaging) {
                        messaging.requestPermission()
                            .then(function () {
                                messaging.getToken()
                                    .then(function (t) {
                                        token = t;
                                        listenForTokens();
                                    });
                            });
                    } else {

                        // firebasePlugin code

                        window.FirebasePlugin.grantPermission(function () {}); // grant permission

                        // attempt to get token
                        window.FirebasePlugin.getToken(function (newToken) {
                            token = newToken ? newToken : ''; // update the token
                            listenForTokens();
                        }, function(error) {
                            listenForTokens();
                        });
                    }

                    obj[notificationsDB] = { askedForPermission: true }; // asked for permission at least once
                    GeneralSettings.store(obj);

                    trackInfo.notifications.resolve(messaging);
                });
            }

            var notificationsEnabled = NewFeatures.isAvailable('tipsterFollowing');

            if (!notificationsEnabled) {
                trackInfo.notifications.reject();
                return;
            }

            // if it's hybrid: wait for device
            if (Utils.olbgMainApi.isHybrid) {
                document.addEventListener('deviceready', send);
            } else { send(); }
        };

        service.registerToken = function (opts) {

            opts = angular.extend({
                notificationsDB: notificationsDB,
                provisional: false
            }, opts);

            var newToken    = opts.token,
                provisional = opts.provisional;

            if (!newToken) { return; }

            // get permissions
            service.areNotificationsEnabled().then(function (status) {

                var obj,
                    o         = {},
                    allows    = status,
                    allowsReg = false;

                // set user property: allows notifications yes / no
                service.setUserProperty({
                    property: AppSettings.tracking.ga.userProperties.allowsNotifications.name,
                    value: allows
                });

                GeneralSettings.get(opts.notificationsDB)
                .then(function (response) {
                    obj       = response && response.name ? angular.fromJson(response.name) : {};
                    allowsReg = obj.allowsReg;
                })
                .finally(function () {

                    var url = Environment.APIurls.pushNotifications + (provisional ? '&quiet=1' : '');

                    // if there's a new token > register it
                    if (newToken) {
                        currentToken = newToken; // store current store
                        // can't use Request service here
                        $http.get(service.appendTI(url.replace('{token}', newToken)))
                        .success(function (response) {
                            console.log('Tracking: ' + (provisional ? 'Quiet ' : '') + 'Push Notifications', response, newToken);
                        });
                    }

                    // allows notification only once
                    if (!provisional && !allowsReg && allows) {

                        if (!obj) { obj = { askedForPermission: true, allowsReg: true }; }

                        obj.allowsReg = true;
                        o[opts.notificationsDB] = obj;
                        GeneralSettings.store(o);
                    }
                });
            });
        };

        service.updateToken = function () {

            /**
             * get token if notifications enabled
             */
            service.areNotificationsEnabled()
                .then(function (status) {
                    if (status) {
                        service.sendPushToken();
                    } else {
                        service.setUserProperty({
                            property: AppSettings.tracking.ga.userProperties.allowsNotifications.name,
                            value: false
                        });

                        service.requestProvisional();
                    }
                });
        };

        /**
         * requests provisional permissions
         * @return void
         */
        service.requestProvisional = function () {
            if (window.FirebasePlugin && window.FirebasePlugin.hasPermissionProvisional) {
                window.FirebasePlugin.hasPermissionProvisional(function (hasPermission) {
                    if (hasPermission) {
                        service.sendProvisitionalToken();
                    } else {
                        window.FirebasePlugin.grantPermissionProvisional(function (hasPermission) {
                            if (hasPermission) {
                                service.sendProvisitionalToken();
                            }
                        });
                    }
                });
            }
        };

        service.sendProvisitionalToken = function () {
            window.FirebasePlugin.getToken(function (newToken) {
                // register a silent token
                service.registerToken({
                    token: newToken,
                    provisional: true,
                    notificationsDB: quietNotificationsDB
                });

                var notificationsEnabled = NewFeatures.isAvailable('tipsterFollowing');

                // important: resolve notifications so we start to listen for them
                if (notificationsEnabled) { trackInfo.notifications.resolve(); }
            }, function(error) {
                console.error('Provisional tokens are not supported');
            });
        };


        /*----------  Consent (GDPR)  ----------*/
        service.getCookiesConsent = function() {
            var consent = false,
                obj = {},
                deferred = $q.defer();

            // [CONSENT]
            if (Utils.olbgMainApi.isHybrid && !Utils.olbgMainApi.inBrowser) {

                GeneralSettings.get(AppSettings.labels.consentDB)
                    .then(function(data) {
                        consent = data && data.name ? data.name : false;
                    })
                    .finally(function () {

                        if (!consent) {

                            // request consent
                            // service.customLog('CONSENT', consent);

                            // prompt user
                            document.addEventListener('deviceready', function() {
                                if (navigator.notification) {
                                    navigator.notification.confirm(Lang.consent.msg, function () {
                                        obj[AppSettings.labels.consentDB] = true;
                                        GeneralSettings.store(obj);
                                        deferred.resolve();
                                    }, Lang.consent.title, [Lang.generic.buttons.ok.toUpperCase()]);
                                } else {
                                    showFallbackDialog();
                                }
                            }, false);
                        } else {
                            deferred.resolve();
                        }
                    });
            } else {
                deferred.resolve();
            }

            return deferred.promise;
        };

        // make it public
        return service;
    }]);

})(angular, platform, window.OLBG_APP_META, window.localStorage);