angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBet', ['$rootScope', 'AppSettings', function olbgBetDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        require: '^olbgBetslip',
        scope: {
            currency: '=',
            currentBookie: '=',
            betModel: '=bet',
            type: '@',
            containsEw: '=',
            justOdds: '=',
            isiPad: '=isIpad'
        },
        link: function (scope, element, attrs, olbgBetslipCtrl) {

            var oddsFormat,
                once = true;

            // expose the parent controller to the Bet Controller
            scope.olbgBetslipCtrl = olbgBetslipCtrl;

            // if an input gets blurred: make the betslip dirty
            element.on('blur', 'input', function () {
                if (once) {
                    olbgBetslipCtrl.isDirty();
                    once = false;
                }
            });

        },
        controller: 'betCtrl',
        templateUrl: AppSettings.templates.bet,
        replace: true
    };
}]);