angular.module('olbg-web-app')

.controller('olbgTipsByMarketMenuCtrl', [

   '$rootScope',
   '$scope',
   'Betslip',
   'AppSettings',
   'Tips',
   'Utils',
   'Sports',
   '$stateParams',
   'DateService',
   'matchmedia',
   '$timeout',
   'Navigation',
   '$state',
   'Lang',

   function olbgTipsByMarketMenuCtrl($rootScope, $scope, Betslip, AppSettings, Tips, Utils, Sports, $stateParams, DateService, matchmedia, $timeout, Navigation, $state, Lang) {

    function resolveTemplate(el) {

        var dateInfo  = DateService.getDateInfo(),
            months    = DateService.getMonths(),
            eventDate = el.metadata.eventDate;

        $scope.tipsByMarketMenu.tips = el.tips;

        // prepare header

        $scope.eventName    = Utils.NORMAfy(el.metadata.menu_event);
        $scope.cssSport     = Utils.URLify(el.metadata.sport);
        $scope.eventDate    = Utils.formatDate(el.metadata.eventDate, dateInfo, months, true);
        $scope.expiredLabel = Utils.sanitizeHTML(Lang.generic.expiredEvents.label + '!');
        $scope.expired      = false;

        if (eventDate) {
            Tips.eventHasExpired({
                // expiration: (new Date().getTime() / 1000) + 5, // [EXPIRED EVENTS DEBUG]
                expiration: eventDate,
                scope: $scope
            }).then(function () {

                /**
                 * [EXPIRED EVENTS]
                 * event has expired: update UI accordingly
                 */

                 $scope.expired = true;
            });
        }

        $scope.tipsByMarketMenu.show = true;
    }

    function showTips() {

        var url,
            extendendUrl,
            tips,
            tipsByMarketModel;

        // add executed class
        $scope.tipsByMarketMenu.executed = true;

        $scope.tipsByMarketMenu.show = false;
        $scope.tipsByMarketMenu.toggleStatus = true;
        $scope.tipsByMarketMenu.allowAnimation = false;

        // get tip by market model
        tipsByMarketModel = Tips.getTipsByMarket();

        tips = tipsByMarketModel.tips;

        // generate URL
        url = '/sports/' + Utils.APIfy($stateParams.sportId) + '/' + Utils.APIfy($stateParams.catId) + '/' + Utils.APIfy($stateParams.leagueId) + '/' + Utils.APIfy($stateParams.eventName) + '/menu_tips?vc_event_id=' + $stateParams.eventId;

        if (!tips.length || !Tips.getAllPagesLoaded()) {

            // extend url!
            extendedUrl = Tips.appendRequiredFields(url, 'tipsByMarket');

            // get the tips
            Sports.getCategories(extendedUrl, function (data) {

                // check we are still in Tips By Market Screen
                if ($scope.olbgMain.isCurrentState('sports.categories.leagues.events.tips.tip') && angular.isDefined(data.tips)) {
                    // use the tips service
                    if (data.tips.length) {
                        Tips.prepareAndSaveTipsByMarket(data.tips, url, data.metadata).then(function () {
                            Tips.setAllPagesLoaded();
                            resolveTemplate(Tips.getTipsByMarket());
                        });
                    } else {
                        $scope.tipsByMarketMenu.executed = false;
                    }
                }
            });
        } else {

            $timeout(function () {

                // there might be pages to be inserted
                if (Tips.getTbmPagesToInsert().length) {
                    // First: insert all pages!
                    // console.log('Tips By Market in Side Menu: Inserting available pages!');
                    Tips.insertNewPageTipsByMarket(url, {all: true});
                }

                resolveTemplate(tipsByMarketModel);
            }, 1000);
        }
    }

    // controller namespace
    $scope.tipsByMarketMenu = {};
    $scope.betslip = Betslip.getOutcomes();

    // hide view
    $scope.tipsByMarketMenu.toggle   = false;
    $scope.tipsByMarketMenu.show     = false;
    $scope.tipsByMarketMenu.executed = false;

    // the navigate to Tip function
    $scope.navigateToTip = function (element) {

        // figure out next state
        var stateObj;

        stateObj = element.navigation_object;

        // store navigation element
        Navigation.store(stateObj.nextState, element, 'tip');

        // Make sure to just replace the current page
        Navigation.setTransition({
            resetNavigator: false,
            replacePage: true
        });

        $state.go(stateObj.nextState, stateObj.params);
    };

    // navigate to the event
    $scope.navigateToEvent = function () {
        $state.go('sports.categories.leagues.events.tips', $stateParams);
    };

    // event listeners
    $rootScope.$on('$stateChangeSuccess', function () {

        // hide the menu
        $scope.tipsByMarketMenu.show     = false;
        $scope.tipsByMarketMenu.executed = false;

        if ($scope.olbgMain.isCurrentState('sports.categories.leagues.events.tips.tip') && matchmedia.is(AppSettings.labels.mq.ipad)) {
            Betslip.sync().then(showTips);
        } else {
            if (!matchmedia.is(AppSettings.labels.mq.ipad)) {
                $scope.tipsByMarketMenu.executed = false;
            }
        }
    });

    // show / hide tips by market menu
    $scope.tipsByMarketMenu.toggle = function () {
        $scope.tipsByMarketMenu.allowAnimation = true;
        $scope.tipsByMarketMenu.toggleStatus = !$scope.tipsByMarketMenu.toggleStatus;
    };
}]);