(function (olbgMeta, OddsJS, FractionJS, MomentJS, trackId) {

var translatedMonths = {};

angular.module('olbg-web-app')

// Utils
.factory('Utils', function ($filter, $sce, AppSettings, Environment, $q, pouchDB, $cookieStore, Lang, $rootScope) {

    function storageAvailable(type) {
        try {
            var storage = window[type],
            x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }
        catch(e) {
            return false;
        }
    }

    var utilsObj,
        storagePromise,
        testingStorage = false,
        getterPromises = {};

    translatedMonths = {
        'jan' : Lang.labels.months[0],
        'feb' : Lang.labels.months[1],
        'mar' : Lang.labels.months[2],
        'apr' : Lang.labels.months[3],
        'may' : Lang.labels.months[4],
        'jun' : Lang.labels.months[5],
        'jul' : Lang.labels.months[6],
        'aug' : Lang.labels.months[7],
        'sep' : Lang.labels.months[8],
        'oct' : Lang.labels.months[9],
        'nov' : Lang.labels.months[10],
        'dec' : Lang.labels.months[11]
    };

    utilsObj = {

        /**
         * Checks if the app is running in an mobile olbg.xx website
         * @return {Boolean} true if the app is in mobile olbg.xx mode
         */
        isOlbgXX: function () {
            if ((olbgMeta.type === 'web-app') && (olbgMeta.code !== 'UK')) {
                return true;
            }else{
                return false;
            }
        },

        /**
         * passes a string through $sce.trustAsHtml()
         * @param  {string} string the string
         * @return {object}        sanitized string
         */
        sanitizeHTML: function (string) {
            return $sce.trustAsHtml(string);
        },

        /**
         * takes a string and turns it URL friendly
         * @param  {string} string
         * @return {string} URLified string
         */
        URLify: function (string) {
            return string
                    .toLowerCase()
                    .replace(/\s/g, '-')
                    .replace(/_/g, '-');
        },

        /**
         * takes a string and turns it API friendly
         * @param  {string} string
         * @return {string} APIfied string
         */
        APIfy: function (string) {

            var strArray = string.split('-');

            // capitalize strings
            for (var i = 0, len = strArray.length; i < len; i++) {
                strArray[i] = $filter('capitalize')(strArray[i]);
            }

            // construct string!
            string = strArray.join('_');

            return string;
        },

        /**
         * returns a normal string to be used in the app
         * @param  {string} string
         * @return {string} normalized string
         */
        NORMAfy: function (string) {
            return $filter('capitalize')(string.replace(/-|_/g, ' '));
        },

        capitalize: function (string) {
            return $filter('capitalize')(string);
        },

        /**
         * groups elements in an array
         * by certain criteria
         * @param  {Array} array the list of elements to be grouped
         * @param  {Function} f  a method that returns an array of different criteria
         * @return {[Array]}     an array of arrays
         */
        groupBy: function (array, f) {

            var groups = {};

            array.forEach(function (o) {
                var group = JSON.stringify( f(o) );
                groups[group] = groups[group] || [];
                groups[group].push( o );
            });

            return Object.keys(groups).map( function( group ) {
                return groups[group];
            });
        },

        /**
         * This function formats date in the following format:
         * [Today | Tomorrow | Month Number] at [Time]
         * if with addTime is set to true: time is added to the string.
         *
         * @param  {string} dateString unix timestamp
         * @param  {obj}    dateInfo   current time information
         * @param  {array}  months     an array with formatted months
         * @param  {[bool]} addTime
         * @param  {[bool]} addYear
         * @return {[type]}            [description]
         */
        formatDate: function (dateString, dateInfo, months, addTime, addYear) {

            addTime = angular.isDefined(addTime) ? addTime : false;
            addYear = angular.isDefined(addYear) ? addYear : false;

            // process date
            var day,
                time,
                date         = new Date(dateString * 1000),
                dateDay      = date.getDate(),
                dateMonth    = date.getMonth(),
                dateYear     = date.getYear(),
                dateFullYear = date.getFullYear(),
                currentMonth = dateInfo.month,
                currentYear  = dateInfo.year,
                currentDay   = dateInfo.day;

            if ((currentMonth === dateMonth) && (currentYear === dateYear)) {

                if (currentDay === dateDay) {
                    day = Lang.labels.days.today;
                } else {
                    if (dateDay-currentDay === 1) {
                        day = Lang.labels.days.tomorrow;
                    } else { day = months[dateMonth] + ' ' + dateDay; }
                }
            } else { day = months[dateMonth] + ' ' + dateDay; }

            if (addYear) {
                day += ', ' + dateFullYear;
            }

            if (addTime) {
                time = utilsObj.formatTime(date);
                return day + ' ' + Lang.labels.days.at + ' ' + time;
            }

            return day;
        },

        /**
         * This function takes a URL and figures out the state to go.
         * @param  {string} navUrl navigation URL
         * @return {obj}    the navigation object
         */
        getStateFromUrl: function (navUrl) {

            var pos,
                navArray,
                eventId,
                eventName,
                eventInfo,
                leagueName,
                navObject = {};

            navObject.params = {};

            navArray = navUrl.split('/').splice(1);

            /**
             * strips event ID from URL
             * @param  {int}   pos      position of where the event ID is
             * @param  {array} navArray array of URL parts
             * @return {obj}            composed object with event name and event id
             */
            function getEventInfo(pos, navArray) {
                eventId = navArray[pos].split('?')[1];
                if (eventId) { eventId = eventId.split('=')[1]; }
                eventName = navArray[pos].split('?')[0];

                return {
                    eventId: eventId,
                    eventName: eventName
                };
            }

            // if last element in array is empty: remove it
            // due to some inconsistencies in the API
            if (!navArray[navArray.length - 1]) {
                navArray.splice(navArray.length - 1, 1);
            }

            switch (navArray.length) {

                // one level navigation: sports
                case 1:
                    navObject.nextState = 'sports';
                    break;

                // two level navigation: categories
                case 2:
                    navObject.nextState      = 'sports.categories';
                    navObject.params.sportId = navArray[1];
                    break;

                // third level navigation: leagues
                case 3:
                    navObject.nextState      = 'sports.categories.leagues';
                    navObject.params.sportId = navArray[1];
                    navObject.params.catId   = navArray[2];
                    break;

                // forth level navigation: events
                case 4:
                    navObject.nextState       = 'sports.categories.leagues.events';
                    navObject.params.sportId  = navArray[1];
                    navObject.params.catId    = navArray[2];
                    navObject.params.leagueId = navArray[3];
                    navObject.params.league   = navArray[3];
                    break;

                // fifth level navigation: tips
                case 5:
                case 6:
                    eventInfo                   = getEventInfo(4, navArray);
                    navObject.nextState         = 'sports.categories.leagues.events.tips';
                    navObject.params.sportId    = navArray[1];
                    navObject.params.catId      = navArray[2];
                    navObject.params.leagueId   = navArray[3];
                    navObject.params.league     = navArray[3];
                    navObject.params.eventName  = utilsObj.URLify(eventInfo.eventName);
                    navObject.params.eventId    = eventInfo.eventId;
                    break;

                // final navigation element
                case 7:
                    eventInfo                   = getEventInfo(6, navArray);
                    navObject.nextState         = 'sports.categories.leagues.events.tips.tip';
                    navObject.params.sportId    = navArray[1];
                    navObject.params.catId      = navArray[2];
                    navObject.params.leagueId   = navArray[3];
                    navObject.params.league     = navArray[3];
                    navObject.params.eventName  = navArray[4];
                    navObject.params.eventId    = eventInfo.eventId;
                    navObject.params.tipId      = navArray[6].split('?')[0];
                    break;
            }

            return navObject;
        },

        /**
         * format times considering locale
         * @param  {Date} date  date instance
         * @return {string}     the formatted date
         */
        formatTime: function (date) {

            try {
                return MomentJS(date).format(utilsObj.getTimeFormat());
            }
            catch (e) {
                console.error(e);
            }

            // function toLocaleTimeStringSupportsLocales() {
            //     try {
            //         new Date().toLocaleTimeString('i');
            //     } catch (e) {
            //         return e.name === 'RangeError';
            //     }
            //     return false;
            // }

            // var formattedTime,
            //     localString,
            //     timeflag = false,
            //     dateHours    = date.getHours(),
            //     dateMinutes  = date.getMinutes() < 9 ? '0' + date.getMinutes() : date.getMinutes();

            // if (toLocaleTimeStringSupportsLocales) {

            //     // get local time format
            //     localString = date.toLocaleTimeString();

            //     // if it contains PM or AM: convert time
            //     timeFlag = /(AM|PM)/i.exec(localString);

            //     if (timeFlag) {
            //         // there's a time flag
            //         timeFlag = timeFlag[0].toUpperCase();
            //     }

            //     // local time string is available
            //     if (timeFlag === 'PM') {
            //         // convert hours to AM
            //         dateHours = dateHours - 12;

            //         if (!dateHours) {
            //             dateHours = 12;
            //         }
            //     } else {
            //         if (timeFlag === 'AM' && !dateHours) {
            //             dateHours = 12;
            //         }
            //     }
            // }

            // // finally set the formatted time
            // if (!timeFlag) {
            //     dateHours = dateHours < 9 ? '0' + dateHours : dateHours;
            // }

            // formattedTime = dateHours + ':' + dateMinutes;
            // formattedTime += timeFlag ? ' ' + timeFlag : '';

            // return formattedTime;
        },

        getTimeFormat: function (opts) {
            opts = angular.extend({
                showSecs: false
            }, opts);
            return utilsObj.olbgMainApi.hasTranslations ? 'kk:mm' + (opts.showSecs ? ':ss' : '') : 'h:mm' + (opts.showSecs ? ':ss' : '') + ' A';
        },

        /**
         * detects OS
         * @return {string} the OS
         */
        getOS: function () {

            function getOsString() {

                if (navigator.userAgent.match(/Android/i)) {
                    return 'android';
                }

                if ((navigator.userAgent.match(/BlackBerry/i)) || (navigator.userAgent.match(/RIM Tablet OS/i)) || (navigator.userAgent.match(/BB10/i))) {
                    return 'blackberry';
                }

                if (navigator.userAgent.match(/(iPhone|iPad||iPod touch);.*CPU.*OS 7_\d/i)) {
                    return 'ios7';
                }

                if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
                    return 'ios';
                }

                if (navigator.userAgent.match(/IEMobile/i)) {
                    return 'windows';
                }

                return 'unknown';
            }

            return getOsString();
        },

        getScreenResolution: function () {
            return window.screen.width + 'x' + window.screen.height + 'px';
        },

        /**
         * strips hashtags and returns a comma separated list with tags
         * for Twitter
         * @param  {string} el
         * @return {obj}
         */
        processHashTags: function (el) {

            /**
            * processHashTags
            *
            * el [{string}]
            *
            * This function takes a Twitter string,
            * strips hashtags and returns a comma separated list with
            * the tags.
            */

            var tag,
                obj = {},
                tags = '',
                regex = /(?:\s|^)(?:#(?!\d+(?:\s|$)))(\w+)(?=\s|$)/ig;

            // capture hashtags
            tag = regex.exec(el);

            while (tag) {
                tags += !tags ? tag[1] : ',' + tag[1];
                tag = regex.exec(el);
            }

            // remove hashtags from Twitter String
            el = el.replace(regex, '').trim();

            return {
                tags: tags.trim(),
                modifiedString: el
            };
        },

        /**
         * attempts to generate a unique key
         * as per Broofa's and Briguy37's anwsers in:
         * http://stackoverflow.com/questions/105034/create-guid-uuid-in-javascript
         * @return {[type]} [description]
         */
        generateKey: function () {
            var d = new Date().getTime();
            var uuid = 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
                var r = (d + Math.random()*16)%16 | 0;
                d = Math.floor(d/16);
                return (c=='x' ? r : (r&0x3|0x8)).toString(16);
            });
            return uuid;
        },

        /**
         * formats odds
         * It uses the Bookmaker Library by Sielski
         * https://github.com/sielay
         * https://github.com/sielay/bookmaker
         *
         * @param  {string | int} odds
         * @param  {[type]}       finalFormat the expected format
         * @return {string | int} final format
         */
        formatOdds: function (odds, finalFormat) {

            var tempFraction,
                fractionArray,
                top,
                bottom,
                tempOdds,
                formattedOdds,
                finalOdds     = '',
                initialFormat = '';

                if (odds === 'SP') {
                    return odds;
                }

                // 1. first decide which is the initial format
                if (/\//.test(odds)) {
                    initialFormat = AppSettings.labels.fractionalOdds;
                } else {
                    initialFormat = AppSettings.labels.decimalOdds;
                }

                // console.log('Odds: Converting from ' + initialFormat + ' to ' + finalFormat);

                // 2. get decimal values
                switch (initialFormat) {

                    case AppSettings.labels.fractionalOdds:

                        fractionArray = odds.split('/');
                        top     = parseInt(fractionArray[0]);
                        bottom  = parseInt(fractionArray[1]);

                        switch (finalFormat) {
                            case AppSettings.labels.decimalOdds:
                                formattedOdds = Odds.fractionToDecimal(top, bottom);
                                formattedOdds = formattedOdds.toFixed(2);
                                break;
                            default:
                                formattedOdds = odds;
                            }

                        break;

                    default:
                        switch (finalFormat) {
                            case AppSettings.labels.fractionalOdds:
                                tempFraction = new Fraction(odds - 1.00);
                                formattedOdds = tempFraction.numerator + '/' + tempFraction.denominator;
                                break;
                            default:
                                return odds;
                        }
                }

                return formattedOdds;
        },

        /**
         * parses format labels for API interaction
         * @param  {string} formatChoice the chosen format
         * @return {string}              the correct API label
         */
        figureOutOdds: function (formatChoice) {

            var oddsFormat;
            switch (formatChoice) {
                case 'decimal':
                    oddsFormat = AppSettings.labels.decimalOdds;
                    break;
                case 'fractional':
                    oddsFormat = AppSettings.labels.fractionalOdds;
                    break;
                case 'american':
                case 'american_odds':
                    oddsFormat = AppSettings.labels.americanOdds;
                    break;
                default:
                    oddsFormat = AppSettings.labels.decimalOdds;
            }
            return oddsFormat;
        },

        /**
         * find greatest common divisor of a and b
         * @param {float} a
         * @param {float} b
         * @return int
         */
        gcd: function (a, b) {

            if (a === 0) {
                return b;
            }

            while (b !== 0) {
                if (a > b) {
                    a = a - b;
                } else {
                    b = b - a;
                }
            }

            return a;

            // if (a === 0 || b === 0) {
            //     return Math.abs(Math.max(Math.abs(a), Math.abs(b)));
            // }

            // var r = a % b;

            // return (r !== 0) ?
            //     utilsObj.gcd(b, r) :
            //     Math.abs(b);
        },

        /**
         * parses decimal to american
         * @param  {float}  num
         * @return {string} american odds
         */
        oddsDecimalToAmerican: function (num) {

            num = parseFloat(num);

            if (!isNaN(num)) {

                if (! --num) {
                    return '-';
                }

                if (num <= 0) {
                    return '-';
                }

                if (num < 1) {
                    return ('-' + Math.round(100 / num));
                } else {
                    return ('+' + Math.round(num * 100));
                }
            } else {
                return num;
            }
        },

        /**
         * parses decimal to fractional
         * @param  {float}  num
         * @return {string} fractional odds
         */
        oddsDecimalToFractional: function (num) {

            var gcd;

            num = parseFloat(num);

            if (!isNaN(num)) {

                // remove the stake
                num -= 1;

                // keep just 2 decimal places
                num = parseFloat(num.toFixed(2));

                // make num a whole number
                num = Math.round(num * 100);

                // find Greatest Common Divisor of num and 100
                gcd = utilsObj.gcd(num, 100);

                // return fraction
                return ((num / gcd) + '/' + (100 / gcd));

            } else {
                return num;
            }
        },

        /**
         * parses decimal to fractional for aggregated odds
         * @param  {float}  num
         * @return {string} fractional odds
         */
        combinedOddsDecimalToFraction: function (num) {

            num = parseFloat(num);

            if (!isNaN(num)) {

                // remove the stake
                num -= 1;

                // keep just 2 decimal places
                num = num.toFixed(2);

                // return fraction
                return (num + '/1' );

            } else {
                return num;
            }
        },

        /**
         * removes events from $rootScope
         * when the passed scope is destroyed
         * @param  {$scope} scope
         * @param  {array} events [description]
         * @return void
         */
        deregisterEvents: function (scope, events) {
            scope.$on('$destroy', function () {
                // console.log('Bookies Selector: scope destroyed. Removing all events listeners from $rootScope');
                for(var i = 0, len = events.length; i < len; i++) {
                    events[i]();
                }
            });
        },

        /**
         * prepares link for Bookies and Acca
         * @param  {object} opts
         * @return {string}
         */
        prepareBookiesLink: function (opts) {

           /**
            * options are:
            * 1) url: the url we try to access
            * 2) pageRef: for olbg.info -> the page that is using this link
            * 3) noParams: if true we remove all the parameters from the URL (useful for using DELETE /acca)
            */

            var url;

            opts = angular.extend({
                    pageRef:  0,
                    noParams: false,
                    memberId: undefined
            }, opts);

            if (opts.noParams) {
                url = opts.url
                        .replace('/:lid/:sid/:pid/:eid/:mid/:urlType/:showAll/:order', '');
            } else {
                url = opts.url
                        .replace(':lid', Environment.keys.locationBet ? Environment.keys.locationBet : '0')
                        .replace(':sid', Environment.keys.sid)
                        .replace(':pid', angular.isDefined(opts.pageRef) ? opts.pageRef : '0')
                        .replace(':eid', 0)
                        .replace(':mid', opts.memberId)
                        .replace(':urlType', this.olbgMainApi.isPWA ? 'desktop' : 'mobile')
                        .replace(':showAll', 'all')
                        .replace(':order',   'null');
            }

            // add external referrer if there is one ->
            if (document.referrer) {
              url += ('?externalRef=' + encodeURIComponent(document.referrer));
            }

            return url;
        },

        /**
         * prepare offers url
         * @param  {obj} opts
         * @return {string}
         */
        prepareOffersUrl: function (opts) {

            /**
             * options are:
             * 1) url: the url we try to access
             * 2) pageRef: for olbg.info -> the page that is using this link
             * 3) offerType: possible values: "customer_promotions", "new_accounts", "both". default value: "both"
             * 4) useSVG: a flag to use SVG or PNGs depending on browser support
             */

            var url;

            opts = angular.extend({
                pageRef: 0,
                offerType: 'both',
                useSVG: true,
                memberId: undefined
            }, opts);

            url = opts.url
                    .replace(':lid', '')
                    .replace(':sid', Environment.keys.sid)
                    .replace(':pid', opts.pageRef)
                    .replace(':rid', 0)
                    .replace(':eid', trackId ? ('ref-' + trackId) : 0)
                    .replace(':mid', opts.memberId)
                    .replace(':offerType', opts.offerType);

            url += ('&useSVG=' + opts.useSVG);

            return url;
        },

        /**
         * tests to see what storage the app should use
         * @return {[type]} [description]
         */
        testLocalStorage: function () {

            if (testingStorage) {
                return storagePromise.promise;
            }

            // quick test to see if Local Storage is available
            storagePromise = $q.defer();
            testingStorage = true;

            var testDB  = pouchDB('Test'),
                testObj = {
                    _id: 'test',
                    title: 'works?'
                };

            testDB.get('test') // 1. SEE IF TEST EXISTS
                .then(function (response) {
                    testObj._rev = response._rev; // 2. IF EXISTS -> ADD REVISION
                })
                .finally(function () {

                    // 3. FINAL STORAGE TEST
                    testDB.put(testObj)
                        .then(function () {
                            utilsObj.olbgMainApi.settingsAvailable = true;
                            storagePromise.resolve();
                        })
                        .catch(function (err) {

                            // if (storageAvailable('localStorage')) {
                            //     console.log('STORAGE: Default to using LocalStorage.');
                            // } else {
                            //     console.log('STORAGE: Default to using Cookies.');
                            // }

                            // pouchDB doesn't work
                            utilsObj.olbgMainApi.settingsAvailable = false;
                            storagePromise.reject();
                        })
                        .finally(function () {
                            testingStorage = false;
                        });
                });

            return storagePromise.promise;
        },

        /**
         * a facade for storing. provides localStorage / Cookies
         * @param  {[type]} opts [description]
         * @return {[type]}      [description]
         */
        dbFacade: function (opts) {

            /**
            * dbFacade
            * @opts {object} an object with the following options:
            *                   opts.dbName / required
            *
            *  this is a wrapper function for using Pouch DB
            *  with a fallback for Private Mode (using local storage or cookies)
            */

            if (!opts || !angular.isDefined(opts.dbName)) {
                throw 'dbFacade requires a database name.';
            }

            var db = pouchDB(opts.dbName),
                dbRepresentation = {};

            function _notFound(deferred) {
                deferred.reject({
                    status: 404
                });
                return deferred.promise;
            }

            facade = {
                allDocs: function (option) {

                    var cookiedb,
                        deferred = $q.defer();

                    function fallback() {

                        var obj = {
                            total_rows: 0,
                            offset: 0,
                            rows: []
                        };

                        cookiedb = storageAvailable('localStorage') ? angular.fromJson(window.localStorage.getItem(opts.dbName)) : $cookieStore.get(opts.dbName);

                        if (!angular.isDefined(cookiedb) || !cookiedb) {
                            deferred.resolve({total_rows: 0, offset: 0, rows: []});
                        }

                        angular.forEach(cookiedb, function (el, prop) {

                            obj.rows.push({
                                id: prop,
                                key: prop,
                                doc: {
                                    _id: prop,
                                    name: el.name
                                }
                            });

                            obj.total_rows++;
                        });

                        deferred.resolve(obj);
                    }

                    if (utilsObj.olbgMainApi.settingsAvailable) {
                        return db.allDocs(option);
                    } else {
                        if (!angular.isDefined(utilsObj.olbgMainApi.settingsAvailable)) {
                            utilsObj.testLocalStorage()
                                .then(function () {
                                    db.allDocs(option)
                                        .then(function (data) {
                                            deferred.resolve(data);
                                        })
                                        .catch(function (err) {
                                            deferred.reject(err);
                                        });
                                })
                                .catch(fallback);
                        } else {
                            fallback();
                        }
                    }

                    return deferred.promise;
                },

                get: function (docName) {

                    var cookiedb,
                        deferred = $q.defer();

                    function fallback() {

                        // fallback ->
                        cookiedb = storageAvailable('localStorage') ? angular.fromJson(window.localStorage.getItem(opts.dbName)) : $cookieStore.get(opts.dbName);

                        if (!angular.isDefined(cookiedb) || !cookiedb || !angular.isDefined(cookiedb[docName])) {
                            // not such database or doc
                            return _notFound(deferred);
                        }

                        deferred.resolve(cookiedb[docName]);
                    }

                    if (angular.isDefined(utilsObj.olbgMainApi) &&
                        utilsObj.olbgMainApi.settingsAvailable) {
                        return db.get(docName);
                    } else {
                        if (!angular.isDefined(utilsObj.olbgMainApi) ||
                            !angular.isDefined(utilsObj.olbgMainApi.settingsAvailable)) {
                            utilsObj.testLocalStorage()
                                .then(function () {
                                    db.get(docName)
                                        .then(function (data) {
                                            deferred.resolve(data);
                                        })
                                        .catch(function (err) {
                                            deferred.reject(err);
                                        });
                                })
                                .catch(fallback);
                        } else {
                            fallback();
                        }
                    }
                    return deferred.promise;
                },

                put: function (obj) {
                    var cookiedb,
                    deferred = $q.defer();

                    function fallback() {

                        // fallback ->
                        cookiedb = storageAvailable('localStorage') ? angular.fromJson(window.localStorage.getItem(opts.dbName)) : $cookieStore.get(opts.dbName);
                        cookiedb = cookiedb || {};

                        cookiedb[obj._id] = obj;

                        if (storageAvailable('localStorage')) {
                            window.localStorage.setItem(opts.dbName, angular.toJson(cookiedb));
                        } else {
                            $cookieStore.put(opts.dbName, cookiedb);
                        }

                        deferred.resolve(obj);
                    }

                    if (utilsObj.olbgMainApi.settingsAvailable) {
                        return db.put(obj);
                    } else {
                        if (!angular.isDefined(utilsObj.olbgMainApi.settingsAvailable)) {
                            utilsObj.testLocalStorage()
                                .then(function () {
                                    db.put(obj)
                                    .then(function (data) {
                                        deferred.resolve(data);
                                    })
                                    .catch(function (err) {
                                        deferred.reject(data);
                                    });
                                })
                                .catch(fallback);
                        } else {
                            fallback();
                        }
                    }

                    return deferred.promise;
                },

                remove: function (doc) {

                    var deferred = $q.defer();

                        if (utilsObj.olbgMainApi.settingsAvailable) {
                            return  db.remove(doc);
                        } else {

                            db = storageAvailable('localStorage') ? angular.fromJson(window.localStorage.getItem(opts.dbName)) : $cookieStore.get(opts.dbName);

                            if (db && db[doc._id]) {
                                delete db[doc._id];
                            }

                            if (storageAvailable('localStorage')) {
                                window.localStorage.setItem(opts.dbName, angular.toJson(db));
                            } else {
                                $cookieStore.put(opts.dbName, db);
                            }

                            deferred.resolve();
                        }

                    return deferred.promise;
                }
            };

            return facade;
        },

        /**
         * sorts sports by popular using AppSettings
         * @param  {array}  sports
         * @param  {string} label  the label to compare against
         * @return {array}  sports the sorted array
         */
        sortSportsByPopular: function (sports, label) {
            return sports.sort(function (value) {
                var popular;
                angular.forEach(AppSettings.sports, function (v) {
                    if (v.name === utilsObj.URLify(value[label])) {
                        popular = v.popular;
                    }
                });
                return !popular ? 1 : -1;
            });
        },

        /**
         * used in settings after saving
         * @param {Object} opts options
         * @return {void}
         */
        settingsSaved: function (opts) {

            opts = angular.extend({
                msg: Lang.generic.settings.saved,
                goBack: true
            }, opts);

            if (opts.goBack) {
                if (!utilsObj.olbgMainApi.isiPad) {
                    utilsObj.olbgMainApi.navigateToParent();
                } else {
                    // if iPad >
                    utilsObj.olbgMainApi.currentSelectedItem = '';
                    utilsObj.olbgMainApi.closeContentPane();
                }
            }

            // broadcast
            if (opts.broadcast) { $rootScope.$broadcast(opts.broadcast); }

            utilsObj.olbgMainApi.displayMessage(opts.msg, 'success');
        },

        /**
         * returns a current http promises
         * @param {String} name the name of the http process
         * @return {Promise}
         */
        getPromise: function (name) {
            return getterPromises[name];
        },

        /**
         * sets an http promise
         * @param {String} name the name of the http process
         */
        setGetterPromise: function (name) {
            getterPromises[name] = $q.defer();
            return getterPromises[name];
        },

        /**
         * removes an http promise
         * @param {String} name the name of the http process
         */
        removeGetterPromise: function (name, error) {

            var deferred = getterPromises[name];

            if (deferred) {
                deferred.reject(error);
                delete getterPromises[name];
            }
        },

        /**
         * removes an http promises
         * @param  {String} name the name of the promise
         * @param  {Obj} obj  an object to pass once it's resolved
         * @return {void}
         */
        resolveGetterPromise: function (name, obj) {
            var deferred = getterPromises[name];
            if (deferred) {
                deferred.resolve(obj ? obj : '');
                delete getterPromises[name];
            }
        },

        /**
         * parse HTML content returned from API
         * @param  {String} template the string containing HTML
         * @return {$SCE object}          ng-bind-html ready object
         */
        parseHTMLtemplate: function (template) {
            if (!template) { return; }
            return utilsObj.sanitizeHTML(decodeURIComponent(template.replace(/\+/g, '%20')));
        },

        /**
         * add currency symbol
         * @param  {Int} num
         * @return {String}
         */
        monetize: function (num) {
            var currency = Lang.labels.currency;
                return angular.isDefined(num) ? (num < 0 ? '-' : '') + currency + Math.abs(num) : '-';
        },

        /**
         * generate default behavior for olbg-tabs
         * @param  {Obj} opts
         * @return void
         */
        setupTabs: function (opts) {
            if (!opts.controller || !opts.tabs.length) { return; }

            var controller = opts.controller;

            controller.tabs   = [];
            controller.tabIds = [];
            angular.forEach(opts.tabs, function (tab) {
                controller.tabs.push({
                    label: tab.label,
                    tabId: tab.id
                });
                controller.tabIds.push(tab.id);
            });
            // set default active tab
            controller.activeTab = opts.defaultTab ? opts.defaultTab : opts.tabs[0].id;
            controller.isActive = function (id) { return controller.activeTab === id; };
        },

        /**
         * set the page title (browser)
         * @param {String} title
         */
        setPageTitle: function (title) {
            $rootScope.pageTitle = title + ' - ' + AppSettings.defaultTitle;
        },

        /**
         * returns an array with unique elements
         * @param  {array} list
         * @return {arraty}
         */
        uniqueArray: function (list) {
            if (!list || !list.length) { return []; }
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }

            // usage example:
            return list.filter(onlyUnique);
        },

        /**
         * a method to translate months
         * @param  {string} month
         * @return {string}
         */
        translateMonths: function (str) {
            return str.replace(/(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)/gi, function (m) {
                return translatedMonths[m.toLowerCase()];
            });
        },

        debounce: function (func, wait, immediate) {
			var timeout;
			return function() {
				var context = this, args = arguments;
				var later = function() {
					timeout = null;
					if (!immediate) { func.apply(context, args); }
				};
				var callNow = immediate && !timeout;
				clearTimeout(timeout);
				timeout = setTimeout(later, wait);
				if (callNow) { func.apply(context, args); }
			};
		}
    };

    /**
    * Expose libraries through Utils sevice
    */

    utilsObj.libs = {
        moment: MomentJS
    };

    return utilsObj;
});

})(window.OLBG_APP_META, Odds, Fraction, moment, typeof TRACK_ID !== 'undefined' ? TRACK_ID : '');