angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgPagination', function olbgPaginationDirective(AppSettings) {
    return {
        restrict: 'AE',
        scope: {
            isLoading: '=',
            endOfList: '=',
            error:     '=',
            messages:  '=',
            small:     '@'
        },
        link: function (scope, element, attrs) {

            var loadingClass = 'olbg-pagination--loading';

            element.addClass('olbg-pagination');
            if (scope.small !== 'no') { element.addClass('olbg-pagination--small ' + loadingClass); }

            scope.$watch('isLoading', function (value) {
                element.toggleClass(loadingClass);
            });
        },
        controller: ['$scope', 'Utils', function ($scope, Utils) {

            $scope.messages = angular.extend({}, $scope.messages);

            $scope.messages.error = '<ons-icon icon="ion-alert-circled"></ons-icon>' + $scope.messages.error;

            angular.forEach($scope.messages, function (value, name) {
                if (typeof value === 'string') {
                    $scope.messages[name] = Utils.sanitizeHTML(value);
                }
            });

        }],
        templateUrl: AppSettings.templates['olbg-pagination']
    };
});