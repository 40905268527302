(function (appInfo) {

    if (angular.isUndefined(appInfo)) {
        throw 'OLBG_APP_META is required';
    }

    var environment = angular.isDefined(appInfo.environment) ? appInfo.environment : 'staging';

    // create the angular module
    angular.module('olbg-web-app')

    // put settings in a factory
    .factory('Environment', ['EnvironmentVariables', 'EnvironmentExtension', 'EnvironmentExtensionLocal', function (EnvironmentVariables, EnvironmentExtension, EnvironmentExtensionLocal) {

            /**
             * Returns the olbg environment settings.
             */

        var TERRITORY = EnvironmentVariables.territory ? ('&source=' + EnvironmentVariables.territory) : '',
            BEST_ODDS  = '&bookie_name=_best_';

        var ts = new Date().getTime(),
            extendedSettings = {},
            settings = {

                // OLBGDesktopDomain:  (environment === 'staging') ? 'http://dev.olbg.com' : 'http://www.olbg.com',
                OLBGDesktopDomain: 'https://www.olbg.com',
                OLBGdomain:         EnvironmentVariables.olbgDomain,
                OLBGMobileDomain:  '://m.olbg.com/betting-tips/',
                silksProxy:        'https://shared.olbg.info/desktopsites/shared/caching_silks.php?url=[silks_url]',
                appsURLScheme:     appInfo.appsURLScheme ? appInfo.appsURLScheme : 'olbgtips://',
                appShareLink:      'https://olbg.mobi',
                olbgCDN:           'https://olbg-shared.scdn1.secure.raxcdn.com/',

                // translations
                earlyAccessEmail: 'mailto:earlyaccess@olbg.com',

                info: {
                    type:        environment,
                    appType:     'core',
                    basePath:    '/'
                },

                // set OpenGraph information for sharing
                og: {
                    title:       'Share OLBG.com, The Sports Betting Community',
                    type:        'article',
                    image:       EnvironmentVariables.olbgDomain + '/images/facebook-share.png',
                    url:         EnvironmentVariables.olbgDomain,
                    description: 'Join experienced sports bettors to learn, share daily tips, knowledge and fun. Exclusive free bets, free tipster competitions and much more.',
                    caption:     'Free tips at OLBG.com'
                },

                keys : {
                    facebook:     (environment === 'staging') ? '521366484670180' : '1606230062961326',
                    urlShortener: 'AIzaSyAGabuwHDuv2AP571Tci1hmpvQB3CMc21k',
                    sid:          appInfo.pwa ? 'D' : 'M',
                    appId:        appInfo.pwa ? 'pwa__' : 'ohios_gbie',
                    locationBet:  'L30',
                    redirectKey:  'JRX8NRxHlp',
                    appsFlyer:    appInfo.appsFlyer && appInfo.appsFlyer.options ? appInfo.appsFlyer.options.devKey : 'cNdBpbiokYzsMntCMZgUU4', // get it from flags.js
                    apiKey:       EnvironmentVariables.apiKey,
                    bookiesAppId: appInfo.pwa ? 'G3vfssnPnQegC9Ajxdx4JvMR5BbS5S2K' : 'cOOKVGak9i5eS0YTGoNzti6kgFwdEdXN',
                    territory:    TERRITORY,
                },

                APIurls : {

                    general:             EnvironmentVariables.tipsAPI.slice(0, -1),

                    // NOTIFICATIONS API
                    pushNotifications:   EnvironmentVariables.notifications + 'token' + (appInfo.pwa ? '/pwa' : '') + '?token={token}'   + '&territory=.' + EnvironmentVariables.territory,
                    stream:              EnvironmentVariables.notifications + 'events'                                                   + '?territory=.' + EnvironmentVariables.territory,
                    getUnreadStream:     EnvironmentVariables.notifications + 'events/count'                                             + '?territory=.' + EnvironmentVariables.territory,
                    readStream:          EnvironmentVariables.notifications + 'events/read/:id'                                          + '?territory=.' + EnvironmentVariables.territory,
                    streamSettings:      EnvironmentVariables.notifications + 'settings'                                                 + '?territory=.' + EnvironmentVariables.territory,
                    streamSubscribe:     EnvironmentVariables.notifications + 'subscribe'                                                + '?territory=.' + EnvironmentVariables.territory,
                    muteStatus:          EnvironmentVariables.notifications + 'notifications/status'                                     + '?territory=.' + EnvironmentVariables.territory,
                    unmute:              EnvironmentVariables.notifications + 'notifications/unmute'                                     + '?territory=.' + EnvironmentVariables.territory,
                    subscriptions:       EnvironmentVariables.tipstersAPI   + 'topics/{:interactions}'                                   + '?territory=.' + EnvironmentVariables.territory + '{:parameters}',


                    // ADS API
                    ads:                 EnvironmentVariables.adsAPI      + 'a?type={type}&path={path}&ip={ip}&page_ref={:pid}',
                    interactiveAds:      EnvironmentVariables.adsAPI      + 'interactiveads.php',
                    offers:              EnvironmentVariables.adsAPI      + 'offers?type=in-offers-tab&location_id=:lid&site_type=:sid&page_ref=:pid&link_ref=:rid&external_ref=:eid&member_id=:mid&offer_type=:offerType',
                    seenOffers:          EnvironmentVariables.adsAPI      + 'user/offers/seen',
                    // bookiesWithAds:      EnvironmentVariables.adsAPI      + 'bookiesWithAds',
                    bookiesWithAds:      EnvironmentVariables.adsAPI      + 'bookies',
                    preferences:         EnvironmentVariables.adsAPI      + 'user/preferences',

                    // SAVE AND SYNC
                    snsSendEmail:        EnvironmentVariables.snsAPI      + 'user/email/link?device_name={:device_name}&email={:user_email}',
                    snsUnlink:           EnvironmentVariables.snsAPI      + 'user/email/unlink',
                    snsType:             EnvironmentVariables.snsAPI      + 'user/email/sync/{:sync_type}',

                    // TRACKING API
                    adsTracking:         EnvironmentVariables.trackingAPI + 'tracking/'      + 'a/impressions?type={type}&ip={ip}',
                    placedBetsTracking:  EnvironmentVariables.trackingAPI + 'tracking/'      + 'bets/placed',
                    loginTracking:       EnvironmentVariables.trackingAPI + 'tracking/'      + 'bookie/login',
                    userBookiesTracking: EnvironmentVariables.trackingAPI + 'tracking/'      + 'bookie/user_account',
                    userSportsTracking:  EnvironmentVariables.trackingAPI + 'tracking/'      + 'user/preferences/sport',
                    offersTracking:      EnvironmentVariables.trackingAPI + 'tracking/'      + 'offers',
                    betslipUrlTracking:  EnvironmentVariables.trackingAPI + 'tracking/'      + 'betslip/urls',
                    impressions:         EnvironmentVariables.trackingAPI + 'tracking/'      + 'screens/impressions',
                    streamTracking:      EnvironmentVariables.trackingAPI + 'notifications/' + 'click',
                    olbgDebug:           EnvironmentVariables.trackingAPI + 'tracking/request_errors',

                    // BETTING API
                    betslip:             EnvironmentVariables.bettingAPI  + 'v1/betslip',
                    acca:                EnvironmentVariables.bettingAPI  + 'v2/acca/null/:lid/:sid/:pid/:eid/:mid/:urlType/:showAll/:order',
                    login:               EnvironmentVariables.bettingAPI  + 'v1/isAuthenticated/:bookieId',
                    authorize:           EnvironmentVariables.bettingAPI  + 'v1/authorize',
                    authenticate:        EnvironmentVariables.bettingAPI  + 'v1/authenticate/:bookieId?jsCall=true&returnUrl=:uri',
                    getBookies:          EnvironmentVariables.bettingAPI  + 'v2/bookies',

                    // BOOKIES API
                    getAllBookies:       EnvironmentVariables.bookiesAPI  + 'getBookiesByCountry?deviceType={:DEVICE}&country={:CODE}',

                    bookiesSelector:     EnvironmentVariables.bettingAPI  + 'v2/bookieSelector/:lid/:sid/:pid/:eid/:mid/:urlType/:showAll/:order',
                    bet:                 EnvironmentVariables.bettingAPI  + 'v1/bet',
                    multiples:           EnvironmentVariables.bettingAPI  + 'v1/bet/get/multiples/:bookieId',
                    balance:             EnvironmentVariables.bettingAPI  + 'v1/balance/:bookieId',
                    bettingHistory:      EnvironmentVariables.bettingAPI  + 'v1/bets/:bookieId/:betType',
                    deposit:             EnvironmentVariables.bettingAPI  + 'v1/deposit/:bookieId[?returnUrl=:returnUrl]',
                    validateBet:         EnvironmentVariables.bettingAPI  + 'v1/bet/validate/:bookieId',
                    placeBet:            EnvironmentVariables.bettingAPI  + 'v1/bet/:bookieId',
                    convertOdds:         EnvironmentVariables.bettingAPI  + 'v2/odds/convert',

                    // TIPS API
                    tip:                 EnvironmentVariables.tipsAPI     + 'tips/[tip-id]/odds?api_key='                            + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS + '&include_expired=1',
                    tips:                EnvironmentVariables.tipsAPI     + 'tips/hot?api_key='                                      + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS + ((environment === 'staging') ? '' : ('&ts=' + ts)),
                    tipsByMarket:        EnvironmentVariables.tipsAPI     + 'tips/{:event_id}/{:market_id}?api_key='                 + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS + ((environment === 'staging') ? '' : ('&ts=' + ts)),
                    sports:              EnvironmentVariables.tipsAPI     + 'sports/categories_with_tips?api_key='                   + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    subsports:           EnvironmentVariables.tipsAPI     + 'sports/[put-sport-here]/leagues_with_tips?api_key='     + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    wizardSports:        EnvironmentVariables.tipsAPI     + 'sports/wizard_categs?api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    leagues:             EnvironmentVariables.tipsAPI     + 'leagues/football?api_key='                              + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    markets:             EnvironmentVariables.tipsAPI     + 'tips/hot/markets?sport_alias=[put-sport-here]&api_key=' + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    allSports:           EnvironmentVariables.tipsAPI     + 'sports/all?api_key='                                    + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    categories:          EnvironmentVariables.tipsAPI     + '[cat-path]?api_key='                                    + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS + '&include_expired=1',
                    comments:            EnvironmentVariables.tipsAPI     + 'tips/[tip-hash]/comments?page=[page-num]&api_key='      + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    share:               EnvironmentVariables.tipsAPI     + 'settings?tag=sharing&api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    support:             EnvironmentVariables.tipsAPI     + 'settings?tag=support&api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    appSettings:         EnvironmentVariables.tipsAPI     + 'settings?tag=app_settings&api_key='                     + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    wizardsStrings:      EnvironmentVariables.tipsAPI     + 'settings?tag=wizards&api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    contact:             EnvironmentVariables.tipsAPI     + 'contact?api_key='                                       + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    terms:               EnvironmentVariables.tipsAPI     + 'docs/privacy.html?api_key='                             + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    byTipsters:          EnvironmentVariables.tipsAPI     + 'tips/by_tipsters?api_key='                              + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    suggestedTipsters:   EnvironmentVariables.tipsAPI     + 'tipsters/suggested/sport/{:sportID}?api_key='           + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    marketsDetails:      EnvironmentVariables.tipsAPI     + 'markets/list?api_key='                                  + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    tipsterInfo:         EnvironmentVariables.tipsAPI     + 'tipsters?api_key='                                      + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    getFavorites:        EnvironmentVariables.tipsAPI     + 'favourites?api_key='                                    + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    addFavorite:         EnvironmentVariables.tipsAPI     + 'favourites/add?api_key='                                + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    removeFavorite:      EnvironmentVariables.tipsAPI     + 'favourites/remove?api_key='                             + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    favoriteEvents:      EnvironmentVariables.tipsAPI     + 'favourites/events?api_key='                             + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    myOLBGWizardSwitch:  EnvironmentVariables.tipsAPI     + 'settings?tag=wizards&api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    tipstersSports:      EnvironmentVariables.tipsAPI     + 'tipsters/sports?api_key='                               + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    tipstersBySport:     EnvironmentVariables.tipsAPI     + 'tipsters/hot/sport/{vc_sport_id}?api_key='              + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    searchTipster:       EnvironmentVariables.tipsAPI     + 'tipsters/search?filter={:term}&order=followers&api_key='+ EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    tipsByTipster:       EnvironmentVariables.tipsAPI     + 'tipsters/{tipster_id}/sport/{vc_sport_id}?api_key='     + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    yourAcca:            EnvironmentVariables.tipsAPI     + 'acca?api_key='                                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS + '&interval_start={interval_start}&interval_end={interval_end}&max_selections={max_selections}&vc_sport_ids={vc_sport_ids}&time_padding=10',
                    dailyAccas:          EnvironmentVariables.tipsAPI     + 'daily_accas/settings?api_key='                          + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    dailyAccasList:      EnvironmentVariables.tipsAPI     + 'daily_accas?api_key='                                   + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    archivedAcca:        EnvironmentVariables.tipsAPI     + 'daily_accas/archive?api_key='                           + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    hasTips:             EnvironmentVariables.tipsAPI     + 'favourites/havetips?api_key='                           + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    getAPITranslations:  EnvironmentVariables.tipsAPI     + 'translations?api_key='                                  + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    commentTranslate:    EnvironmentVariables.tipsAPI     + 'comments/translate/{:comment_id}?api_key='              + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,
                    commentTranslationsAvailability:  EnvironmentVariables.tipsAPI + 'comments/translate/has_budget?api_key='        + EnvironmentVariables.apiKey + TERRITORY + BEST_ODDS,

                    // TIPSTERS API
                    followedTipsters:    EnvironmentVariables.tipstersAPI + 'getFollowed',
                    followTipster:       EnvironmentVariables.tipstersAPI + 'follow',
                    unfollowTipster:     EnvironmentVariables.tipstersAPI + 'unfollow',
                    followMultiple:      EnvironmentVariables.tipstersAPI + 'followMulti',
                    unfollowMultiple:    EnvironmentVariables.tipstersAPI + 'unfollowMulti',

                    // INFO API
                    bookiesRegister:     EnvironmentVariables.infoAPI     + 'bookie_id/location_id/site_type/page_ref/',

                    // VERIFY INSTALLATION
                    verifyInstallation:  EnvironmentVariables.members     + 'validate-install'
                }
            };

            // EXTEND THINGS

            // extend settings with Platform extensions and local (if building a localized app)
            extendedSettings = angular.extend({}, settings, EnvironmentExtension, EnvironmentExtensionLocal);

            // extend groups
            extendedSettings.info   =  angular.extend({}, settings.info,    EnvironmentExtension.info,    EnvironmentExtensionLocal.info    ? EnvironmentExtensionLocal.info    : {});
            extendedSettings.keys   =  angular.extend({}, settings.keys,    EnvironmentExtension.keys,    EnvironmentExtensionLocal.keys    ? EnvironmentExtensionLocal.keys    : {});
            extendedSettings.APIurls = angular.extend({}, settings.APIurls, EnvironmentExtension.APIurls, EnvironmentExtensionLocal.APIurls ? EnvironmentExtensionLocal.APIurls : {});

            return extendedSettings;
        }]);

})(OLBG_APP_META);
