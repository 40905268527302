(function (angular) {

    // // the controller
    // function olbgEmptyMessageCtrl(settings, compile, templateCache) {       
    // }

    // the directive definition
    var olbgEmptyMessageDirective = function ()  {

        var settings = angular.element(document).injector().get('AppSettings');

        return {
            restrict: 'E',
            // controller: ['AppSettings', '$compile', '$templateCache', olbgEmptyMessageCtrl],
            // controllerAs: 'vm',
            link: function (scope, element, attrs) {
            },
            templateUrl: settings.templates['olbg-empty-message']
        };
    };

    // register directive ->
    angular.module('olbg-web-app').directive('olbgEmptyMessage', olbgEmptyMessageDirective);

})(angular);