angular.module('olbg-web-app')

.controller('betCtrl', ['$scope', 'AppSettings', 'Betslip', '$state', 'Navigation', 'Utils', '$q', 'Lang', function betCtrl($scope, AppSettings, Betslip, $state, Navigation, Utils, $q, Lang) {

    var id,
        bets,
        oldStake,
        previousValidStake = 0,
        expired = false;

    function calculateReturns(stake) {

        var fraction   = [],
            ewFraction = isSingle() && $scope.bet.model.bookiesModel[$scope.currentBookie].ewFraction ? $scope.bet.model.bookiesModel[$scope.currentBookie].ewFraction : '',
            id         = isSingle() ? $scope.bet.model.outcomeId + $scope.bet.model.eventTime : $scope.bet.model.name,
            odds       = isSingle() ? parseFloat($scope.bet.model.bookiesModel[$scope.currentBookie].oddsDecimalValue) : parseFloat($scope.bet.multiplesDecimalOdds),
            oddsEW     = $scope.bet.EWmultiplesDecimalOdds ? parseFloat($scope.bet.EWmultiplesDecimalOdds) : 0,
            isRequired = bets[id].isRequired;

        if (stake === null || !angular.isDefined(stake) || !stake) {
            stake = 0;
        } else {
            stake = parseFloat(stake);
        }

        if ($scope.bet.hasOddsAndReturns) {

            if (isSingle()) {

                if ($scope.bet.model.userInputs.ew) {

                    // CALCULATE COMPLICATED RETURN :)
                    $scope.bet.returns = Betslip.calculateFinalReturn(stake, ewFraction, $scope.bet.model.userInputs.ew, odds);

                } else {
                    if (!$scope.bet.model.userInputs.oddsSelected) {
                        // SP is selected
                        $scope.bet.returns = 0;
                    } else {
                        $scope.bet.returns = !stake ? stake : (stake * odds);
                    }
                }
            } else {

                // it's multiples
                if ($scope.bet.model.userInputs.ew) {
                    $scope.bet.returns = !stake ? stake : stake * (odds + oddsEW);
                } else {
                    $scope.bet.returns = !stake ? stake : (stake * odds);
                }
            }
        }

        // store a new object with:
        // isRequired and new stake
        bets[id] = {
            type: $scope.type,
            returns: $scope.bet.returns ? $scope.bet.returns : 0
        };

        // re insert isRequired and returns
        bets[id].currentStake = stake;
        bets[id].isRequired   = isRequired;
    }

    function decreaseTotalStakes(stakesValue, ew) {

        var stakesTotal = $scope.olbgBetslipCtrl.getStakesTotal();

        stakesValue = !angular.isDefined(stakesValue) || !stakesValue ? 0 : parseFloat(stakesValue);

        if (stakesValue) {
            stakesValue = ew ? stakesValue * 2 : stakesValue;
            $scope.olbgBetslipCtrl.setStakesTotal(stakesTotal-stakesValue);
        }

        // remove the bet from parent controller
        delete bets[id];
    }

    function stakeIsRequired(status, stake) {
        bets[id] = angular.isDefined(bets[id]) ? bets[id] : {};
        bets[id].isRequired   = status;
        bets[id].currentStake = stake;
    }

    function isSingle() {
        return $scope.type !== 'multiples';
    }

    function updateSingleFormattedOdds(model) {

        /**
         * updateSingleFormattedOdds
         * @model {obj} the bet model
         *
         * it creates the formattedOdds (the field that is shown to the user).
         * it searches for valid odds in the model. (some bookies might not return some odds formats)
         */

        var validOdds,
            bookiesModel = model[$scope.currentBookie];

        validOdds = bookiesModel.odds || bookiesModel.oddsDecimalValue || bookiesModel.oddsAmericanValue;

        if (!validOdds) {
            $scope.bet.model.formattedOdds = 0;
            return;
        }

        $scope.bet.model.formattedOdds = $scope.bet.model.bookiesModel[$scope.currentBookie][Betslip.parseOdds($scope.olbgBetslipCtrl.getOddsFormat())];

        if (!$scope.bet.model.formattedOdds) {
            // format not available: run manual formatting
            $scope.bet.model.formattedOdds = Utils.formatOdds(validOdds, $scope.olbgBetslipCtrl.getOddsFormat());
        }
    }

    function updateTotalStakes(value) {

        bets = angular.isDefined(bets) ? bets : $scope.olbgBetslipCtrl.getBets();

        var currentStake,
            stakesTotal = $scope.olbgBetslipCtrl.getStakesTotal(),
            multiplesStakesTotal = $scope.olbgBetslipCtrl.getMultiplesStakesTotal();

        // make sure to remove old stake
        if (angular.isDefined(oldStake)) {
            stakesTotal -= oldStake;

            if (!isSingle()) {
                multiplesStakesTotal -= oldStake;
            }
        }

        currentStake = !angular.isDefined(value) || !value ? 0 : value;

        if ($scope.bet.model.userInputs.ew) {
            currentStake *= 2;
        }

        if (!isSingle()) {
            // multiply the stakes by the bet count
            currentStake *= $scope.bet.model.count;
        }

        stakesTotal += currentStake;

        if (!isSingle()) {
            multiplesStakesTotal += currentStake;
        }

        // keep a record of previous stake
        oldStake = currentStake;

        // finally update Parent Controller's Total Stake
        $scope.olbgBetslipCtrl.setStakesTotal(stakesTotal);

        if (!isSingle()) {
            // make sure tu update the Multiples stake value
            $scope.olbgBetslipCtrl.setMultiplesStakesTotal(multiplesStakesTotal);
        }

        // set the required flag:
        stakeIsRequired($scope.type === 'singles', currentStake);
    }

    $scope.currency = Lang.labels.currency;

    // Bet namespace
    $scope.bet = {
        oddsChangedMsg     : Lang.betslip.changedDialog.oddsChanged,
        hcChangedMsg       : Lang.betslip.changedDialog.hcChanged,
        oddsChangedToMsg   : Lang.betslip.changedDialog.oddsChangedTo,
        oddsChangedFromMsg : Lang.betslip.changedDialog.oddsChangedFrom
    };
    $scope.bet.model = $scope.betModel;

    // Generate Unique identifier for this Bet
    id = isSingle() ? $scope.bet.model.outcomeId + $scope.bet.model.eventTime : $scope.bet.model.name;

    // Initialize values
    $scope.bet.returns           = 0;
    $scope.bet.stakePlaceholder  = '0';
    $scope.bet.hasOddsAndReturns = (isSingle() || (!isSingle() && $scope.bet.model.odds)) ? true : false;
    $scope.bet.maxStake          = AppSettings.betslip.maxStake;
    $scope.bet.expiredMsg        = AppSettings.betslip.expiredMsg;
    $scope.bet.nrMsg             = Utils.sanitizeHTML(Lang.betslip.nrMsg);
    $scope.bet.gotNoOddsMsg      = Utils.sanitizeHTML(AppSettings.betslip.validation.noOddsReturned);
    $scope.bet.justSP            = $scope.bet.model.userInputs.sp;
    $scope.bet.showMaxReturns    = false;
    $scope.bet.inputsReady       = false;
    $scope.bet.returnsCopy       = Lang.generic.terminology.returns;


    if (isSingle() && $scope.bet.justSP) {
        $scope.bet.model.userInputs.oddsSelected  = false;
    } else {
        $scope.bet.model.userInputs.oddsSelected  = true;
    }

    // multiples specific
    $scope.bet.multiplesDecimalOdds   = !isSingle() && $scope.bet.model.odds   ? parseFloat($scope.bet.model.oddsDecimalValue) : 0;
    $scope.bet.EWmultiplesDecimalOdds = !isSingle() && $scope.bet.model.oddsEw ? parseFloat($scope.bet.model.oddsDecimalValueEw) : 0;

    // initialize multiple to false
    if (!isSingle()) {
        $scope.bet.model.userInputs.ew = false;
    }

    // Scope methods
    $scope.bet.isSingle = isSingle;

    $scope.bet.switchOdds = function (evt) {

        if ($scope.bet.justSP) {
            return;
        }

        // only switch when clicking on off element
        if (angular.isDefined(evt)) {
            if (angular.element(evt.target).hasClass('bet__controls__switch__item--on')) {
                return;
            }
        }

        $scope.bet.model.userInputs.oddsSelected = !$scope.bet.model.userInputs.oddsSelected;
    };

    $scope.bet.switchEw = function () {
        $scope.bet.model.userInputs.ew = !$scope.bet.model.userInputs.ew;
    };

    $scope.bet.gotOdds = function () {
        return (angular.isDefined($scope.bet.model.gotNewOdds) && !$scope.bet.model.gotNewOdds);
    };

    $scope.removeBet = function (outcomeId, sport, isExpired, sportId) {

        /**
         * it removes the bet from the server and the outcomeIds array
         * using Betslip.remove().
         * it removes the bet from the model in the parent controller (betslip-controller.js)
         * without touching the others to prevent losing the bet data the user might have entered
         * (prevent refreshing the whole betslip page model).
         *
         * Recalculate multiples and returns. While doing so:
         * action buttons (login, register, place bets) should be disabled and
         * the areas that are being recalculated greyed out.
         *
         * if Betslip is expired:
         * 1. don't remove from server
         * 2. don't recalculate multiples and returns.
         *
         * It should also remove ads and attempt to add them back.
         *
         * Finally: We need to see if the Bet Slip is not updating.
         * If it is: wait until it's resolved.
         */

        var deferred = Betslip.getUpdatePromise();

        function remove() {

            isExpired = angular.isDefined(isExpired) && isExpired ? true : false;

            // remove from server
            if (!isExpired) {
                Betslip.remove(outcomeId, sport, sportId);
                decreaseTotalStakes($scope.bet.model.userInputs.stake, $scope.bet.model.userInputs.ew);
            } else {
                // it is expired: remove it just from outcomes id
                Betslip.removeFromOutcomesId(outcomeId, sport);
            }

            // remove from view
            $scope.olbgBetslipCtrl.removeBetFromModel(outcomeId, sport);

            // sync adds
            // $scope.olbgBetslipCtrl.syncAds(); // removeBetFromModel will take care of this

            // show message
            $scope.olbgBetslipCtrl.displayMessage(Lang.betslip.selectionRemoved, 'success');
        }

        if (angular.isDefined(deferred)) {
            // if there's a promise:
            // wait until is resolved (Betslip finished syncing)
            deferred.promise.then(remove);
        } else {
            // remove the bet immediately
            remove();
        }
    };

    // watch the both controler and the bet.model
    $scope.$watchGroup(['olbgBetslipCtrl', 'bet.model'], function (values) {

        // we should start processing all the data once we have the key elements!
        if (angular.isDefined(values[0]) &&
            angular.isDefined(values[1])) {

            $q.all([values[0].betslipReady.promise, Betslip.sync()])

            .then(function () {

                // we have both the parent controller and the bet mode
                var format = $scope.olbgBetslipCtrl.getOddsFormat();

                $scope.bet.oddsFormat = Betslip.parseOdds(format);

                if (isSingle() && angular.isDefined(values[1].bookiesModel)) {
                    updateSingleFormattedOdds(values[1].bookiesModel);

                    // wait until inputs are ready
                    // $scope.$watch('bet.inputsReady', function (ready) {
                    //     if (ready) {
                    //         if (angular.isDefined(values[1].changeSet)) {

                    //             // check if expired
                    //             // make sure it happens only once!
                    //             if (!expired) {
                    //                 if (angular.isDefined(values[1].changeSet.expired) &&
                    //                     values[1].changeSet.expired) {
                    //                     decreaseTotalStakes($scope.bet.model.userInputs.stake);
                    //                     expired = true;
                    //                 }
                    //             }

                    //             // if odds changed: make sure to recalculate return values[1]s!
                    //             if (angular.isDefined(values[1].changeSet.odds) &&
                    //                 angular.isDefined(values[1].changeSet.odds[$scope.currentBookie]) &&
                    //                 values[1].changeSet.odds[$scope.currentBookie]) {
                    //                 calculateReturns(values[1].userInputs.stake);
                    //                 updateSingleFormattedOdds(values[1].bookiesModel);
                    //             }
                    //         }
                    //     }
                    // });

                } else {
                    /**
                     * for multiples: just show odds for multiple.count === 1
                     */
                    values[1].formattedOdds   = values[1].count > 1 ? '-' : values[1][Betslip.parseOdds(format)];
                    $scope.bet.showMaxReturns = values[1].count > 1 ? true : false;
                }


                // start watching everything else once we have
                // both the parent controller and the bet model is available
                // watch user inputs

                // $scope.$watchCollection('bet.model.userInputs', function (value) {

                //     if (isSingle()) {
                //         Betslip.storeBetInput($scope.bet.model);
                //     }
                //     // else {
                //     //     Betslip.storeMultipleInput($scope.bet.model.name, value.stake);
                //     // }

                //     // if value exceeds max stake: reset it to 0
                //     if (value.stake > $scope.bet.maxStake) {
                //         $scope.bet.model.userInputs.stake = previousValidStake;
                //     } else {
                //         previousValidStake = $scope.bet.model.userInputs.stake;
                //     }

                //     // calculate total stakes
                //     updateTotalStakes(parseFloat(value.stake, 10));

                //     // calculate returns
                //     calculateReturns($scope.bet.model.userInputs.stake);

                //     // use parent controller to decide whether to show total returns or not
                //     $scope.olbgBetslipCtrl.shouldShowTotalReturns();

                //     if (angular.isDefined(value) && value) {
                //         if (!$scope.bet.inputsReady) {
                //             $scope.bet.inputsReady = true; // change only once
                //         }
                //     }
                // });

                // watch the currency
                // $scope.$watch('currency', function (value) {
                //     // set the stake placeholder value accordingly
                //     $scope.bet.stakePlaceholder = angular.isDefined(value) ? '0 ' : '0';
                // });
            });
        }
    });

    $scope.$on('$destroy', function () {
        // if is multiples: remove from currentBets
        if (!isSingle()) {
            delete bets[id];
        }
    });
}]);