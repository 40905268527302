angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard
/**
 * Bookie account selector directive
 * by Adrian Radu adrianr@olbg.com
 */

.directive('olbgBookieAccountSelector', ['AppSettings', function olbgBookieAccountSelector(AppSettings) {
    return {
        scope: {
            bookie : '='
        },
        restrict: 'E',
        controller: ['$scope', function ($scope) {
            $scope.toggleBookieState = function (state) {
                $scope.bookie.hasAccount = state;
                $scope.bookie.touched    = true;
            };
        }],
        templateUrl: AppSettings.templates['bookie-account-selector']
    };
}]);