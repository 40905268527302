angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBookieItem', ['$rootScope', 'AppSettings', function olbgBookieItemDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        scope: {
            bookie:   '=',
            type:     '@',
            modifier: '@' // possible values: contained, expands
        },
        link: function (scope, element, attrs) {

            var bookieClass = '';

            function prepareModifier(s) {
                return 'bookie-logo--' + s.toLowerCase().replace(' ', '-');
            }

            scope.$watch('bookie', function (value) {
                if (angular.isDefined(value) && value) {
                    element.addClass('bookie-logo--betslip--' + scope.bookie.toLowerCase().replace(' ', '-'));
                }
            });

            // add type modifier
            if (angular.isDefined(scope.type) && scope.type) {
                element.addClass(prepareModifier(scope.type));
            }

            // add another modifier
            if (angular.isDefined(scope.modifier) && scope.modifier) {
                element.addClass(prepareModifier(scope.modifier));
            }            
        },
        template: '<div class="bookie-logo">Bookie: {{bookie}}</div>',
        replace: true
    };
}]);