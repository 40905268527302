angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgAd', function olbgAdDirective(AppSettings, $compile, Pub, Tracker, Utils, $rootScope) {
    return {
        restrict: 'E',
        scope: {
            ad: '='
        },
        controller: ['Tracker', '$scope', 'AppSettings', 'md5', 'Lang', function (Tracker, $scope, AppSettings, md5, Lang) {

            /**
             * if no html_content provided:
             * attempt to render the template
             * the best we can
             * @return {void}
             */
            // function renderFallbackTemplate() {
            //     Tracker.get().then(function (info) {

            //         /**
            //          * creates the image URL in accordance with the guidelines here:
            //          * http://wiki.invendium.co.uk/index.php/Ads_API#IV._Hi-res_images
            //          *
            //          * @param  {string} url the url
            //          * @param  {int}    w   the width of the image
            //          * @param  {int}    h   the height of the image
            //          * @return {string}     the final prepared url
            //          */
            //         function prepareImgURL(url,w,h) {

            //             var imageId = url.split('?')[1].split('=')[1],
            //                 hash    = md5.createHash(imageId + w + h + AppSettings.ads.salt);

            //             return url + (w ? ('&w=' + w) : '') + (h ? ('&h=' + h) : '') + '&hash=' + hash;
            //         }

            //         var res             = info.res.replace('px', '').split('x');
            //             screenMaxWidth  = res[0] > res[1] ? res[0] : res[1],
            //             backgroundImage = $scope.ad.bgimage ? prepareImgURL($scope.ad.bgimage, screenMaxWidth, 0) : '';

            //         $scope.adButtonCopy = Lang.ads.claimLabel; // expose the label for an ad

            //         /*----------  prepare button  ----------*/
            //         if ($scope.ad.button_image) {
            //             $scope.buttonImageUrl = prepareImgURL($scope.ad.button_image, 0, 0);
            //         }

            //         /*----------  Define ad container styles  ----------*/
            //         $scope.adContainerStyles = {
            //             'min-height': $scope.ad.ad_height + 'px',
            //             // 'background-color':  ($scope.ad.bgcolor && backgroundImage) ? $scope.ad.bgcolor : 'inherit',
            //             'background-image':  'url(' + backgroundImage + ')',
            //             'background-size':   'cover',
            //             'background-repeat': 'no-repeat',
            //             'background-position': 'center center'
            //         };
            //     });
            // }

            // if (!$scope.ad.html_content) { renderFallbackTemplate(); }
        }],
        templateUrl: AppSettings.templates.ad,
        link: function (scope, element, attrs) {

            // possible types: fullscreen / list
            var styles,
                adContainer = element.parent(),
                decodedHtml = scope.ad.html_content ? decodeURIComponent(scope.ad.html_content.replace(/\+/g, '%20')) : '';

            if (decodedHtml) {

                Pub.addAdClass(scope.ad.type); // make sure to add helpful classes to the body tag

                element.remove(); // remove old template
                if (scope.ad.type) {
                    styles = decodeURIComponent(Pub.getStyles(scope.ad.type).replace(/\+/g, '%20'));
                }
                if (styles) { adContainer.append($compile('<style scoped>' + styles + '</style>')(scope)); }
                adContainer.append($compile(decodedHtml)(scope));

                // trigger event
                window.dispatchEvent(new CustomEvent(AppSettings.ads.interactive.insertedName, {
                  detail: { id: scope.ad.id }
                }));
            }

            var type = attrs.type;
            element.addClass('ad--' + type);

            // send GA event
            adContainer.find('.mobile-btn-offer-link').on('click', function () {
                var screen      = Utils.olbgMainApi.getCurrentStateName(),
                    eventInfo   = angular.copy(screen === 'hotTips' ? AppSettings.tracking.ga.events.hotTipsAd : AppSettings.tracking.ga.events.otherAd),
                    gaEventInfo = angular.copy(eventInfo.ga);

                // count user property > clicked any ad
                Tracker.setUserProperty({ property: AppSettings.tracking.ga.userProperties.clickedAd.name });

                // set bookie
                eventInfo.params.bookie = scope.ad.bookie;
                gaEventInfo.action      = gaEventInfo.action.replace('${BOOKIE}', scope.ad.bookie);

                // set screen
                eventInfo.params.screen = Utils.olbgMainApi.getCurrentStateName();
                gaEventInfo.label       = 'Screen: ' + eventInfo.params.screen;
                Tracker.sendEvent(eventInfo, gaEventInfo);

                // send AppsFlyer event
                Tracker.trackOfferAppsflyer(scope.ad.id, scope.ad.url, 'tips_ad');
            });
        },
        replace: true
    };
});