angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgOffersLink', ['$rootScope', 'AppSettings', 'Offers', '$timeout', function olbgOffersLinkDirective($rootScope, AppSettings, Offers, $timeout) {


    function ctrl($scope, Navigation, $state) {

        // properties
        this.deactivated = false;
        this.offers = Offers.getOffersModel();
        this.newOffersNumber = Offers.getNewOffersCount();
        this.link = AppSettings.offers.link;

        this.goToOffers = function () {

            if (this.deactivated) {
                return;
            }

            $state.go(AppSettings.states.offers);
        };

        // $timeout(function () {
        //     Offers.init().then(function () {
        //         Offers.updateOffers();
        //     });
        // }, AppSettings.delays.offers); // delay a bit
    }

    return {
        restrict: 'E',
        controller: ['$scope', 'Navigation', '$state', ctrl],
        controllerAs: 'Offers',
        templateUrl: AppSettings.templates['olbg-offers-link']
    };
}]);