angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgOffersStyle', ['$rootScope', function olbgOfferDirective($rootScope) {

    return {
        restrict: 'AE',
        scope: {
            stylecontent: '=' // this will get the value passed via attribute.
        },
        link: function (scope, element) {

            offersStyleEl = element.find('.offersStyles');

            if (angular.isDefined(scope.stylecontent)) {
                decodedHtml = decodeURIComponent(scope.stylecontent.replace(/\+/g, '%20'));

                offersStyleEl.append(decodedHtml);
            }
        },
        template: '<style class="offersStyles" type="text/css" scoped></style>'
    };
}]);