/**
 * IMPORTANT NOTICE:
 * removed support for offers in this page
 */


angular.module('olbg-web-app')

.controller('bookiesSelectorCtrl', [

    '$rootScope',
    '$scope',
    'AppSettings',
    'Betslip',
    '$q',
    'Bookies',
    '$timeout',
    '$state',
    'Utils',
    'ngDialog',
    'Tracker',
    'Lang',
    'NewFeatures',

    function bookiesSelectorCtrl($rootScope, $scope, AppSettings, Betslip, $q, Bookies, $timeout, $state, Utils, ngDialog, Tracker, Lang, NewFeatures) {

        // get the list of bookies
        var availableBookies = angular.copy($scope.chooseBookies.availableBookies),
            bookiesInfo      = $scope.chooseBookies.allBookies.bookies,
            pref             = $scope.chooseBookies.preferences && $scope.chooseBookies.preferences.length ? $scope.chooseBookies.preferences : [],
            listeners        = [],
            sportsList;

        /**
         * method to use when refreshing the view
         * @return {void}
         */
        function updateLists(newBookiesModel) {
            $scope.chooseBookies.show             = false;
            $scope.bookiesSelector.showUpdateNote = false;
            generateLists();
            $timeout(function () { $scope.chooseBookies.show = true; }, 1000);
        }

        function handleBookiesAvailabilty(newBookies) {

            /**
             * handleBookiesAvailabilty
             * @newBookies {array}
             *
             * this function disables actions for unavailable bookies
             * if all bets expired or bets is empty: disable buttons as well
             */
            allExpired = true;
            angular.forEach($scope.chooseBookies.betsModel.selections, function (bet) {
                if (!angular.isDefined(bet.changeSet) || !angular.isDefined(bet.changeSet.expired) || !bet.changeSet.expired) {
                    // at least one bet is still active
                    allExpired = false;
                }
            });

            angular.forEach(availableBookies, function (bookie) {

                bookie.meta.actionEnabled = false;

                if (!allExpired) {
                    angular.forEach(newBookies, function (newBookie) {
                        if (newBookie.bookieId === bookie.bookieId) {
                            bookie.meta.actionEnabled = true;
                        }
                    });
                }
            });
        }


        // generates best, my bookies and other bookies lists
        function generateLists() {

            $scope.bookiesSelector.ready = false;

            // extend data first...
            extendBookiesInformation();

            // get the best bookie
            var prefBookies = pref.length ? availableBookies.filter(function (e,i) { return pref.indexOf(e.bookieId + '') > -1; })   : [],
                other       = pref.length ? availableBookies.filter(function (e,i) { return pref.indexOf(e.bookieId + '') === -1; }) : availableBookies,
                removeBest  = [];

            var bestOdds   = availableBookies[0].sortOdds,
                bestBookie = [];

            // 1. attempt to get the best bookie from pref
            bestBookie = prefBookies.filter(function (b) { return b.sortOdds == bestOdds});

            // 2. decide the list we should remove the bookie from
            removeBest = bestBookie.length ? prefBookies : other;

            // 3. if no best bookie: get it from other
            try { bestBookie = (bestBookie.length ? bestBookie : other.filter(function (b) { return b.sortOdds == bestOdds}))[0];
            } catch(e) {
                console.error(e);
            }

            // remove the best bet
            if (bestBookie) { removeBest.shift(); }

            // get the best: from preferred bookies if any
            $scope.bookiesSelector.best         = [bestBookie];
            $scope.bookiesSelector.myBookies    = prefBookies;
            $scope.bookiesSelector.otherBookies = other;

            $scope.bookiesSelector.ready = true;
        }

        /**
         * createWarnings
         * @bookie {object} the available bookie
         *
         * this function should be executed only if there's a valid
         * Betslip model.
         *
         * it checks if the bookie has missing bets or different handicap values
         */

        function createWarnings(bookie) {

            var missingBets = false,
                differentHC = false,
                bets = $scope.chooseBookies.betsModel && $scope.chooseBookies.betsModel.selections ? $scope.chooseBookies.betsModel.selections : [];

            if (!bets.length) { return; }

            // set model flags
            bookie.meta.availableBets = [];
            bookie.meta.missingBets   = [];
            bookie.meta.showInfoUI    = false;

            // iterate current /acca selections
            angular.forEach(bets, function (selection) {

                var betsObj,
                    selectionBookie,
                    currentHandicap = null,
                    id        = selection.outcomeId + selection.sport.toLowerCase(),
                    defaultHC = angular.isDefined($scope.chooseBookies.storedBets) &&
                                angular.isDefined($scope.chooseBookies.storedBets[id]) ? $scope.chooseBookies.storedBets[id].handicap : null;

                selectionBookie = selection.bookies.filter(function (currentBookie) {
                    return bookie.bookieId === parseInt(currentBookie.bookieId);
                });

                // populate available and missing bets for each bookie
                if (!selectionBookie.length) {
                    missingBets = true;
                    bookie.meta.missingBets.push({
                        name: selection.outcomeName,
                        handicap: defaultHC ? ((parseFloat(defaultHC) >= 0 && selection.marketName === 'Asian Hcap' ? '+' : '') + defaultHC) : null
                    });
                } else {

                    // check different handicap value

                    if (defaultHC) {
                        // standardize 0 values
                        selectionBookie[0].handicap = selectionBookie[0].handicap && selectionBookie[0].handicap == '0.0' ? '0.00' : selectionBookie[0].handicap;
                        if (selectionBookie[0].handicap && selectionBookie[0].handicap !== defaultHC) {
                            currentHandicap = selectionBookie[0].handicap;
                            differentHC     = true;
                        } else {
                            currentHandicap = defaultHC;
                        }
                    }

                    // finally available bet
                    bookie.meta.availableBets.push({
                        name:        selection.outcomeName,
                        handicap:    currentHandicap ? ((parseFloat(currentHandicap) >= 0  && selection.marketName === 'Asian Hcap' ? '+' : '') + currentHandicap) : null,
                        differentHC: currentHandicap !== defaultHC,
                        defaultHC:   defaultHC
                    });
                }
            });

            bookie.meta.showInfoUI  = differentHC || bookie.meta.missingBets.length;

            // generate sports list
            sportsList = Tracker.generateSportsList(bets);
        }

        function extendBookiesInformation() {

            angular.forEach(availableBookies, function (availableBookie) {

                availableBookie.meta = {};

                angular.forEach(bookiesInfo, function (bookie) {
                    var bookieId = parseInt(bookie.bookieId);
                    if (availableBookie.bookieId === bookieId) {
                        availableBookie.meta.bookieName = bookie.bookieName;           // get bookie name
                        availableBookie.meta.APIenabled = bookie.bookieType === 'api'; // see if it's API or URL
                    }
                });

                availableBookie.meta.isLoggedIn    = false;
                availableBookie.meta.actionEnabled = true;

                // if (availableBookie.meta.APIenabled) {

                //     Betslip.isLoggedIn(availableBookie.bookieId).then(function (response) {

                //         availableBookie.meta.isLoggedIn = response;

                //         if (response) {

                //             Betslip.getBalanceForBookie(availableBookie.bookieId)
                //                 .then(function (balanceInfo) {
                //                     availableBookie.meta.balanceInfo = balanceInfo;
                //                 })
                //                 .catch(function (err) {
                //                     if (err === 'login') { availableBookie.meta.isLoggedIn = false; }
                //                 })
                //                 .finally(function () {
                //                     availableBookie.meta.loadedBalance = true;
                //                 });
                //         } else {
                //             // no balance required. hide loader immediately
                //             availableBookie.meta.loadedBalance = true;
                //         }
                //     });
                // }

                // finally: process handicap events
                createWarnings(availableBookie);
            });
        }

        function showAllExpiredDialogBox() {
            var dialog,
                contents = {
                    title:   Utils.sanitizeHTML(Lang.bookiesSelector.betsExpiredMessage.title),
                    message: Utils.sanitizeHTML(Lang.bookiesSelector.betsExpiredMessage.message),
                    actions: [Utils.sanitizeHTML(Lang.bookiesSelector.betsExpiredMessage.action)]
                };

            dialog = ngDialog.openConfirm({
                template:        AppSettings.modals.genericDialog,
                closeByEscape:   false,
                closeByDocument: false,
                showClose:       false,
                scope:           $scope,
                controller:      ['$scope', function ($scope) {
                    $scope.genericDialog = {};
                    $scope.genericDialog.contents             = contents;
                    $scope.genericDialog.contents.centered    = true; // center content
                    $scope.genericDialog.contents.buttonTypes = ['positive'];

                    $scope.genericDialog.executeButton = function (index) {
                        $state.go(AppSettings.states.hotTips);
                        ngDialog.closeAll();
                    };
                }]
            });

            // should stop automatic syncing and purge local betslip
            Betslip.stopAutomaticSyncing();
            Betslip.resetModels();
        }

        function trackOutbound(url, bookie) {

            var name         = bookie && bookie.meta && bookie.meta.bookieName ? bookie.meta.bookieName : '',
                outboundInfo = angular.copy(AppSettings.tracking.ga.events.clicksBetslip),
                gaEventInfo  = angular.copy(AppSettings.tracking.ga.events.clicksBetslip.ga);

            Tracker.appsFlyerTrackEvent(AppSettings.tracking.appsFlyer.events.outboundBetslipClicks, { af_betslip_url: url });

            // append bookie
            outboundInfo.params.bookie = name;
            gaEventInfo.action = gaEventInfo.action.replace('${BOOKIE}', name);

            // append sports
            outboundInfo.params.sports = sportsList;
            gaEventInfo.label = sportsList;

            // track google events
            Tracker.sendEvent(outboundInfo, gaEventInfo);
        }

        // set the controller space
        $scope.bookiesSelector = {
            ready:                      false,
            showUpdateNote:             false,
            updateMessage:              AppSettings.bookiesSelector.updateMessage,
            updateBtnLabel:             AppSettings.bookiesSelector.updateBtnLabel,
            updateMyBookiesABtnLabel:   AppSettings.bookiesSelector.updateMyBookiesABtnLabel,
            specialLabel:               Utils.sanitizeHTML(AppSettings.bookiesSelector.bestOddsCopy),
            balanceLabel:               Lang.generic.terminology.balance + ':',
            betslipBtnLabel:            Lang.betslip.betslipBtnLabel,
            myBookiesTitle:             Lang.bookiesSelector.myBookiesTitle,
            otherBookiesTitle:          Lang.bookiesSelector.otherBookiesTitle,
            editLabel:                  Lang.generic.buttons.edit,
            updateView:                 updateLists,
            APIBetting:                 false
        };

        // show hcap changes
        $scope.bookiesSelector.showInfo = function (meta) {

            if ($rootScope.blockAlerts) {
                return;
            }

            $scope.bookiesSelector.handicapList   = meta.availableBets;
            $scope.bookiesSelector.missingBets    = meta.missingBets;
            $scope.bookiesSelector.handicapTitle  = Lang.bookiesSelector.handicapTitle;
            $scope.bookiesSelector.defaultHCLabel = Lang.generic.terminology.default;

            var dialog = ngDialog.open({
                template: AppSettings.modals.handicapList,
                scope: $scope
            });
        };

        $scope.bookiesSelector.goToBetslip = function (bookie) {

            if (!bookie.meta.actionEnabled) { return; }

            function openExternalBetslip(bookie) {

                Betslip.setCopyChallengeFlag();

                if (Utils.olbgMainApi.isHybrid) {
                    window.open(bookie.betslipURL, '_system'); // open Betslip URLs in system browser
                } else { window.open(bookie.betslipURL, '_blank'); }

                // track
                Tracker.trackBetslipURL(Betslip.getOutcomes(), bookie.bookieId, bookie.betslipURL);

                trackUserProperty();
                trackOutbound(bookie.betslipURL, bookie); // track click via GA
            }

            function trackUserProperty() {
                // regardless: track user bets
                Tracker.setUserProperty({ property: AppSettings.tracking.ga.userProperties.betsInApp.name });
            }

            var id               = bookie.bookieId,
                isAPI            = bookie.meta.APIenabled;
                // eventsInfo       = angular.copy(AppSettings.tracking.ga.events.clickedAPIBetslip);
                // eventsInfo.label = sportsList;

            // if it's API and is logged in > proceed to in-app implemenation
            if (isAPI && bookie.meta.isLoggedIn) {

                // Tracker.sendEvent(eventsInfo);

                trackUserProperty();
                trackOutbound(bookie.betslipURL, bookie); // track click via GA

                Utils.olbgMainApi.navigateTo(AppSettings.states.betslip, {
                    resetNavigator: false,
                    pushToRightPanel: Utils.olbgMainApi.isiPad, // if iPad > push to right panel
                    params: { bookieId: id }
                });
            } else {
                Betslip.showOddsMightChanged($scope)
                    .then(function () {
                        openExternalBetslip(bookie);
                    });
            }
        };

        $scope.bookiesSelector.goToBookies = function () {
            Utils.olbgMainApi.navigateTo(AppSettings.states.myBookiesAccounts, {
                resetNavigator: false
            });
        };

        /*----------  ADD EVENT LISTENERS  ----------*/
        listeners.push($rootScope.$on('betslipJustLoggedIn', function (evt, args) {
            if (angular.isDefined(args.bookieId)) {
                // user logged in to bookie. set status to logged in and attempt to get balance.
                angular.forEach(availableBookies, function (bookie) {
                    if (bookie.bookieId === args.bookieId) {
                        bookie.isLoggedIn    = true;
                        bookie.loadedBalance = false;

                        // get balance ->
                        Betslip.getBalanceForBookie(bookie.bookieId)
                            .then(function (balanceInfo) {
                                bookie.balanceInfo = balanceInfo;
                            })
                            .catch(function (err) {
                                if (err === 'login') { bookie.isLoggedIn = false; }
                            })
                            .finally(function () {
                                bookie.loadedBalance = true;
                            });
                    }
                });

                // proceed to Bet Slip immediately ->
                Utils.olbgMainApi.navigateTo(AppSettings.states.betslip, {
                   resetNavigator: false,
                   pushToRightPanel: Utils.olbgMainApi.isiPad, // if iPad > push to right panel
                   params: { bookieId: args.bookieId }
               });
            }
        }));

        listeners.push($rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            // if coming from bet slip: make sure that bookie selector is at latest
            if (toState.name   === AppSettings.states.bookiesSelector &&
                fromState.name === AppSettings.states.betslip) {

                $scope.chooseBookies.show = false;
                Bookies.getForCurrentBets()
                    .then(function (response) {
                        // get new bookies information. Generate meta data again.
                        availableBookies = response;
                        $scope.bookiesSelector.updateView(); // update view
                    })
                    .finally(function () {
                        $scope.chooseBookies.show = true;
                    });
            }
        }));

        listeners.push($rootScope.$on('betslip.updated', function (evt, args) {

            /**
             * check if odds changed or events expired.
             * if so: download new bookie selector data
             * and ask the user to refresh the view.
             */

            var bets = $scope.chooseBookies.betsModel;
            if (bets.selections && bets.selections.length) {
                Betslip.checkForChanges(bets).then(function (changed) {

                    if (changed) {

                        // Odds have changed or a bet has expired
                        Bookies.getForCurrentBets({
                            force: true
                        }).then(function (el) { // get new Bookies Selector model
                            latestBookiesSelector = el;
                            // console.log('Bookies Selector: A new instance is ready to be deployed!');
                            handleBookiesAvailabilty(latestBookiesSelector);

                            if (allExpired && !$rootScope.blockAlerts) {

                                showAllExpiredDialogBox();

                            } else {
                                // finally: prompt user to update Bookies Selector
                                $scope.bookiesSelector.showUpdateNote = true;
                            }
                        });
                    }
                });
            }
        }));

        // prepare options to show
        generateLists();

        Utils.deregisterEvents($scope, listeners);
}]);