angular.module('olbg-web-app')

    // Simple date service
    .factory('DateService', function (AppSettings) {
        var settings = AppSettings;
        return {
            getMonths: function () {
                return settings.labels.months;
            },
            getDateInfo: function () {
                var currentDate = new Date();

                return {
                    day:   currentDate.getDate(),
                    month: currentDate.getMonth(),
                    year:  currentDate.getYear()
                };
            }
        };
    })

    // Navigation service
    .factory('Navigation', function ($state, Utils, Environment, $location, $stateParams) {

        var stateStack         = [],
            contentStateStack  = [],
            paramsStack        = [],
            contentParamsStack = [],
            storedItems        = {},
            parentState        = '',
            transitionModifier = {};

        function logPages() {
            // console.log('PAGES:', pagesInStack);
            return;
        }

        return {
            get: function (state, elementLabel) {

                /**
                 * Get an element stored in a state
                 */

                 var item;

                 if (angular.isDefined(storedItems[state]) && angular.isDefined(storedItems[state][elementLabel])) {
                    item = storedItems[state][elementLabel];
                 } else {
                    item = '';
                 }

                return item;
            },
            getAll: function () {
                return storedItems;
            },
            store: function (state, element, elementLabel) {

                /**
                 * Store an element in a particular state
                 */

                if (!angular.isDefined(storedItems[state])) {
                    storedItems[state] = {};
                }

                storedItems[state][elementLabel] = element;

                // console.log('Stored the following element in "' + state + '" state as "' + elementLabel + '"', element);
            },
            forgetItem: function (state) {
                delete storedItems[state];
            },
            resetNavigationMemory: function () {
                storedItems = {};
            },
            resetTransition: function () {
                // console.log('transitions reset!');
                transitionModifier = {};
            },
            setTransition: function (obj) {

                /**
                 * Set options for going from one page
                 * to another.
                 *
                 * Settings can be:
                 *
                 * animation:         slide, lift, none
                 * goingBack:         used when pressing the back button
                 * templateUrl:       html page to render
                 * replacePage:       it replaces the current page in the stack for a new one
                 * noParentLoad:      used to prevent default parent loading
                 * menuSelection:     string used to evaluate selected menu item
                 * resetNavigator:    either push a new page or reset stack
                 * openInRightPanel:  it opens the template in the right panel [iPad only]
                 * pushToRightPanel:  it pushes a state to the right panel [iPad only]
                 * setiOSSelectedTab: override tab selection [iOS only]
                 */

                transitionModifier = angular.extend(transitionModifier, obj);
            },
            getTransition: function (obj) {
                return transitionModifier;
            },
            notFound: function () {
                // show 404
                $state.go('404');
            },
            reloadPage: function () {

                $state.go($state.current, {}, {
                    reload: true
                });
            },
            getPagesNumber: function () {
                return stateStack.length;
            },

            /*============================================
            =            STACKS FUNCTIONALITY            =
            ============================================*/

            /**
             * for iPad we have two separate stacks:
             * 1) the regular stack (in mobile is the whole app and in iPad is the navigation stack)
             * 2) the content stack (always displayed to the right)
             * ----------------------------------------------------
             * by Alvaro Casanova [alvaro@olbg.com]
             */

            getStack: function (opts) {
                opts = angular.extend({isContentPanel: false}, opts);
                return opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack : stateStack;
            },
            getLatestStateInStack: function (opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack : stateStack;

                return stack[stack.length-1];
            },
            getPreviousState: function (opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack : stateStack;

                if (stack.length <= 1) {
                    return false;
                }
                return stack[stack.length-2];
            },
            getPreviousParams: function (opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    params = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                if (stack.length <= 1) {
                    return false;
                }
                return params[params.length-2];
            },
            addState: function (state, params, opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    p = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                stack.push(state);
                p.push(params);
            },
            insertState: function (state, params, opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    p = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                p.unshift(params);
                stack.unshift(state);
            },
            removeState: function (opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    params = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                params.pop();
                return stack.pop();
            },
            replaceState: function (state, params, opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    p = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                p[p.length-1] = params;
                stack[stack.length-1] = state;
            },
            resetStates: function (state, params, opts) {

                opts = angular.extend({isContentPanel: false}, opts);

                var stack  = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentStateStack  : stateStack,
                    p = opts.isContentPanel && Utils.olbgMainApi.isiPad ? contentParamsStack : paramsStack;

                p.splice(0, p.length);
                stack.splice(0, stack.length);
                if (angular.isDefined(state)) {
                    stack.push(state);
                }
                if (angular.isDefined(params)) {
                    p.push(params);
                }
            },

            /**
             * generates the link for the current navigation item
             * @param {Object} data the object with all the necessary data
             * @return {string} the URL
             */
            generateSEOlink: function (data) {

                if (!Utils.olbgMainApi.isWebApp || (!data.events && !data.categories && !data.leagues)) {
                    return;
                }

                var url = '',
                    isEvents = !!data.events,
                    iterableObject = data.events || data.categories || data.leagues;

                url = isEvents ? 'sports/' +
                                  $stateParams.sportId  + '/' +
                                  $stateParams.catId    + '/' +
                                  $stateParams.leagueId + '/' : '';

                angular.forEach(iterableObject, function (el) {
                    el.SEOurl = Utils.URLify(($location.protocol() + Environment.OLBGMobileDomain + url + (isEvents ? el.eventname.trim().replace(/ /g, '_') : el.navigation_url.substr(1) )).split('?')[0] + '/');
                    el.SEOurl += (isEvents ? '?eventId=' + el.vc_event_id : '');
                });

                return ;
            }
        };
    })

    // Tips By Location
    .factory('TBL', function ($timeout, AppSettings) {
        var timer,
            settings = AppSettings;

        return {
            isRunning: function () {
                return angular.isDefined(timer);
            },
            storeTimer: function (returnedTimer) {
                timer = returnedTimer;
                // console.log('TBL: User is going to be asked for Tips By Location in ' + (settings.tipsByLocation.introduceTBLdelay / 60000) + ' minutes');
            }
        };
    })

    // Settings service
    .factory('TipsSettings', function(Utils) {
        return Utils.dbFacade({dbName: 'Settings'});
    })

    // General Settings
    .factory('GeneralSettings', function($q, AppSettings, Utils) {

        /** Implementation notes: if in private mode -> use cookies */

        var service,
            storeProcess,
            valuesArray  = [],
            settingsDB   = Utils.dbFacade({
                dbName: 'Settings'
            });

        service = {
            odds: '',
            test: Utils.testLocalStorage,
            store: function (obj) {

                function storeSettings() {

                    /**
                     * storeSettings
                     *
                     * This function creates or updates the settingsDB douchdb
                     * using valuesArray. If local storage is not available:
                     * default to using cookies
                     */

                    var defer  = $q.defer(),
                        item   = valuesArray.splice(0,1),
                        id     = item[0].name,
                        value  = item[0].value;


                    function put(id, value, rev) {

                        if (!angular.isDefined(rev)) {
                            settingsDB.put({_id: id, name: value}).then(function () {
                                // console.log(id + ' created with: ' + value);
                                defer.resolve();
                            }).catch(function (err) {
                                // couldn't save settings
                                storeProcess.reject();
                            });
                        } else {
                            settingsDB.put({_id: id, name: value, _rev: rev}).then(function () {
                                // console.log(id + ' updated with: ' + value);
                                defer.resolve();
                            }).catch(function (err) {
                                // couldn't save settings
                                storeProcess.reject();
                            });
                        }
                    }

                    settingsDB.get(id)
                    .then(function (response) {
                        // update the record (make sure to use revision)
                        put(id, value, response._rev);
                    })
                    .catch(function (err) {
                        // create the record
                        put(id, value);
                    });

                    defer.promise.then(function () {

                        if (!valuesArray.length) {

                            // finish processing
                            storeProcess.resolve(obj);

                        } else {
                            // call this function again
                            storeSettings();
                        }
                    });
                }

                // reset store promise
                var storeProcess = $q.defer(),
                    valuesArray = [];

                // prepare array of objects
                angular.forEach(obj, function (item, index) {
                    valuesArray.push({
                        name:  index,
                        value: item
                    });
                });

                storeSettings();

                return storeProcess.promise;
            },
            get: function (id, callback, callbackError, callbackFinally) {

                var gotData,
                    deferred = $q.defer();

                settingsDB.get(id).then(function (data) {
                    gotData = data;
                    if (angular.isDefined(callback)) {
                        callback(data);
                    }
                }).catch(function (error) {
                    if (angular.isDefined(callbackError)) {
                        callbackError(error);
                    }
                }).finally(function () {
                    deferred.resolve(gotData);
                    if (angular.isDefined(callbackFinally)) {
                        callbackFinally();
                    }
                });

                return deferred.promise;
            },
            getAll: function (callback, callbackError) {

                var defer = $q.defer();

                settingsDB.allDocs({include_docs: true}).then(function (data) {
                    if (angular.isDefined(callback)) {
                        callback(data);
                    }
                    defer.resolve(data);
                }).catch(function (error) {
                    if (angular.isDefined(callbackError)) {
                        callbackError(error);
                    }
                    defer.reject(error);
                });
                return defer.promise;
            },
            getOdds: function () {

                var odds,
                    deferred = $q.defer();

                if (service.odds) {

                    switch(service.odds) {
                        case 'fractional'      :
                        case 'fractional_odds' : service.odds = 'fractional_odds'; break;
                        case 'american_odds'   :
                        case 'american'        : service.odds = 'decimal_odds'; break;
                        default                : service.odds = 'odds';
                    }
                    deferred.resolve(service.odds);
                    return deferred.promise;
                }

                // get odds
                service.get(AppSettings.labels.oddsFormat, function (response) {
                        odds = response.name;
                }, function () {
                        odds = AppSettings.oddsSettings.oddsSettingsDefault;
                }, function () {
                    service.odds = Utils.figureOutOdds(odds);
                    deferred.resolve(service.odds);
                });

                return deferred.promise;
            },
            delete: function (id) {
                var defer = $q.defer();
                settingsDB.get(id)
                    .then(function (doc) {
                        return settingsDB.remove(doc);
                    })
                    .then(defer.resolve)
                    .catch(defer.reject);

                return defer.promise;
            }
        };

        return service;
    })

    // The Comments service
    .factory('Comments', function (AppSettings, $q, Environment, Tracker, Tipsters, Sports2, Request, NewFeatures, Utils, Lang, $timeout) {

        /**
         * This is the main service used to retrieve comments
         *
         * added the Tracker service
         * Adrian Radu adrianr@olbg.com - 2015.10.21
         */

        var settings             = AppSettings,
            commentsUrl          = Environment.APIurls.comments,
            commentsTranslations = { status: false },
            commentSettingsPromise;

        var service = {

            /**
              * get
              * @id {[string]}
              * @page {[integer]}
              *
              * Get comments for the passed id
              * and page.
              */

            get: function (id, page, sportId) {

                var defer = $q.defer(),
                    data = {},
                    commentsUrl;

                Sports2.getAll().then(function (sports) {

                    // parse URL
                    commentsUrl = Environment.APIurls.comments;
                    commentsUrl = commentsUrl.replace('[tip-hash]', id).replace('[page-num]', page);

                    // Finally get the comments based on the generated URL
                    Request.get(commentsUrl)
                        .then(function (response) {

                            var comments = [];

                            angular.forEach(response.data.comments, function (comment) {
                                comments.push(service.create(comment, sports, sportId ? sportId : ''));
                            });

                            // sort comments by profitx10
                            comments = comments.sort(function (a,b) {
                                return parseInt(b.data.profit_x_10) - parseInt(a.data.profit_x_10);
                            });

                            defer.resolve({
                                metadata: response.data.metadata,
                                comments: comments
                            });
                        })
                        .catch(function (error) {
                            defer.reject(error);
                        });
                })
                .catch(function (error) {
                    defer.reject(error);
                });

                return defer.promise;
            },

            create: function (comment, sports, sportid) {

                comment.vc_sport_id = sportid ? sportid : ''; // try to augment user with sport

                var c = {
                    data: comment,
                    tipster: Tipsters.TipsterFactory(comment, Sports2.parseSports(sports))
                };
                // generate id
                c.data.commentId = c.data.tipid + c.tipster.id;
                return c;
            },

            getTranslationStatus: function () { return commentsTranslations.status; },

            getTranslationsSettings: function () {

                if (commentSettingsPromise) {
                    return commentSettingsPromise.promise;
                } else {
                    commentSettingsPromise = $q.defer();


                    if (NewFeatures.isAvailable(AppSettings.labels.features.commentTranslations)) {

                        Request.get(Environment.APIurls.commentTranslationsAvailability)
                            .then(function (response) {
                                try {
                                    commentsTranslations.status = response.data.tips.has_budget;
                                } catch (e) {
                                    commentsTranslations.status = false;
                                }
                            })
                            .catch(function () {
                                commentsTranslations.status = false;
                            })
                            .finally(function () {
                                commentSettingsPromise.resolve(commentsTranslations);
                            });

                    } else {
                        // if comments translations are disabled: resolve right away
                        commentsTranslations.status = false;
                        commentSettingsPromise.resolve(commentsTranslations);
                    }
                }

                return commentSettingsPromise.promise;
            },

            /**
             * retrieves translation from API
             * @return {promise}
             */
            translate: function (id) {
                var deferred = $q.defer();

                if (!id) {
                    deferred.reject();
                    return deferred.promise;
                }

                Request.get(Environment.APIurls.commentTranslate.replace('{:comment_id}', id))
                    .then(function (response) {
                        try {
                            var translation = response.data.comments_translations.translated;
                            deferred.resolve(translation);
                        } catch(e) {
                            deferred.reject();
                        }
                    })
                    .catch(function () {
                        deferred.reject();
                    });

                return deferred.promise;
            },

            /**
             * disables comments translations for this session
             * @return {void}
             */
            disableTranslations: function () {
                commentsTranslations.status = false;
                Utils.olbgMainApi.displayMessage(Lang.translations.disabled, 'error');
            }
        };

        return service;
    })

    /** Sports service
    * http://v21.api.olbg.com/sports/categories_with_tips
    *
    * Adrian Radu adrianr@olbg.com - 2015.10.21
    */

    .factory('Sports', function ($q, $rootScope, Utils, AppSettings, Environment, Tracker, Request, APITranslate) {

        var sportsTimestamp,
            subsportsTimeStamp,
            gotSports       = false,
            sports          = {},
            subsports       = {},
            markets         = {},
            fetchingSports  = false,
            sportsFetched   = $q.defer(),
            settings        = AppSettings,
            sportsAPIUrl    = Environment.APIurls.sports,
            subsportsAPIUrl = Environment.APIurls.subsports,
            subsportsList   = settings.hotTips.filter.subsports;
            sportsObject    = {};


        sportsObject =  {

            /**
             * returns all possible sports
             * @return {Promise}
             */
            getAll: function () {

                var deferred = Utils.getPromise('allSports');

                if (!deferred) {
                    deferred = Utils.setGetterPromise('allSports');
                    APITranslate.fetch()
                        .then(function () {
                            Request.get(Environment.APIurls.allSports)
                                .then(function (response) {
                                    deferred.resolve(response.data);
                                })
                                .catch(function (err) {
                                    Utils.removeGetterPromise('allSports', err);
                                });
                        });

                }


                return deferred.promise;
            },

            getSports: function (callback) {

                /**
                 * Sports.getSports()
                 *
                 * @callback
                 *
                 * This function takes a callback
                 * that is executed once we get sports
                 */

                var currentDate = new Date().getTime();

                // get new sports list only every certain amount of time
                if (!gotSports || !angular.isDefined(sportsTimestamp) || (currentDate - sportsTimestamp) > settings.sportsThreshold) {

                    // make sure to send HTTP request only once
                    if (!fetchingSports) {

                        fetchingSports = true;
                        sportsFetched  = $q.defer();

                        Request.get(sportsAPIUrl)
                            .then(function (response) {

                                // store timestamp of operation
                                sportsTimestamp = new Date().getTime();

                                // refresh sports object
                                sports = response.data.sports;

                                // resolve promise
                                sportsFetched.resolve(sports);

                                // make operation available again
                                fetchingSports = false;

                                gotSports = true;
                            })
                            .catch(function () {
                                fetchingSports = false;
                                sportsFetched.reject();
                            });
                    }
                } else {
                    // return available sports immediately
                    sportsFetched.resolve(sports);
                }


                // finally execute all callbacks
                // pending on this service
                sportsFetched.promise.then(function (data) {
                    if (angular.isDefined(callback)) {
                        callback(data);
                    }
                });

                return sportsFetched.promise;
            },

            getSubSports: function (sport, callback) {

                /**
                 * Sports.getSubSports()
                 *
                 * @sport [{string}]
                 * @callback [{function}]
                 *
                 * This function keeps an updated list
                 * of subsports for a selected sport.
                 */

                var currentTimeStamp = new Date().getTime();

                function populateArray(sport) {

                    /**
                     * populateArray
                     * @sport [{string}]
                     *
                     * This function gets data from API for a particular sport.
                     */

                    var currentApiUrl   = '',
                        subsportsString = '';

                    currentApiUrl = subsportsAPIUrl.replace('[put-sport-here]', Utils.APIfy(Utils.URLify(sport)));

                    Request.get(currentApiUrl)
                        .then(function (response) {
                            // extend the subsports element
                            subsports[sport] = response.data.sports;
                            callback(subsports[sport]);
                        });
                }

                if (!angular.isDefined(subsports[sport])) {
                    populateArray(sport);
                } else {

                    // sport exist already
                    // check timestamp to see if list should be updated
                    if (angular.isDefined(subsportsTimeStamp) && ((currentTimeStamp - subsportsTimeStamp) > settings.sportsThreshold)) {
                        // time has passed beyond threshold: get a new list
                        populateArray(sport);
                    } else {
                        // return current saved subsports list
                        callback(subsports[sport]);
                    }
                }
            },

            getMarkets: function (sport) {

                var currentTimeStamp = new Date().getTime(),
                    deferred = $q.defer();

                function populateArray(sport) {

                    var currentApiUrl = Environment.APIurls.markets.replace('[put-sport-here]', Utils.APIfy(Utils.URLify(sport)));
                    Request.get(currentApiUrl)
                        .then(function (response) {
                            markets[sport] = response.data.markets;
                            deferred.resolve(markets[sport]);
                        });
                }

                if (!angular.isDefined(markets[sport])) {
                    populateArray(sport);
                } else {

                    // market exist already
                    // check timestamp to see if list should be updated
                    if (angular.isDefined(subsportsTimeStamp) && ((currentTimeStamp - subsportsTimeStamp) > settings.sportsThreshold)) {
                        // time has passed beyond threshold: get a new list
                        populateArray(sport);
                    } else {
                        // return current saved subsports list
                        deferred.resolve(markets[sport]);
                    }
                }

                return deferred.promise;
            },

            getCategories: function (navigation, callback, errorCB) {

                /**
                 * Sports.getCategories()
                 *
                 * @navigation [{string}]
                 * @callback
                 *
                 * Gets a list of navigation items
                 * (leagues, categories, events, etc)
                 */
                var url,
                    navigationUrl,
                    urlParts;
                // process navigation url

                navigation    = navigation.substring(1, navigation.length);
                navigation    = (/\/$/.test(navigation)) ? navigation.substring(0, navigation.length-1) : navigation;
                navigationUrl = Environment.APIurls.categories.replace('[cat-path]', navigation);
                url           = navigationUrl;

                urlParts      = navigationUrl.split('?');
                navigationUrl = urlParts.splice(0,1);

                angular.forEach(urlParts, function (part, index) {
                    navigationUrl += (index === 0 ? '?' : '&') + part;
                });

                Request.get(navigationUrl)
                    .then(function (response) {
                        callback(response.data);
                    })
                    .catch(errorCB);
            },

            setMenuLink: function(sportId, catId) {
                /**
                 * setMenuLink
                 *
                 * @sportId {[string]}
                 * @catId {[string]}
                 *
                 * Function used to select the corresponding
                 * link in the menu for the selected sport view.
                 *
                 * It takes two arguments. The sportId and the catId.
                 * If the sport is US SPORTS, the category is used instead.
                 */

                // define selected sport in the menu!
                if (!angular.isDefined($rootScope.selectedLink)) {
                    if (sportId === 'us-sports') {
                        // special case: us sports
                        sportId = catId;
                    }

                    // check sport is popular
                    angular.forEach(settings.sports, function (value) {
                        if (value.name === sportId) {
                            if (value.popular) {
                                $rootScope.selectedLink = Utils.NORMAfy(sportId).toLowerCase();
                            } else {
                                $rootScope.selectedLink = 'other sports';
                            }
                        }
                    });
                }
            }
        };

        return sportsObject;
    })

    // Google URL Shortener
    .factory('UrlShortener', function ($q, $rootScope, AppSettings, dinoGapiClientLoader, Environment) {

        var settings      = AppSettings,
            gapiKey       = Environment.keys.urlShortener,
            gapi_deferred = $q.defer(),
            gapi_loaded    = gapi_deferred.promise;

        // set client ID

        dinoGapiClientLoader({
            key: gapiKey,
            name: 'urlshortener',
            version: 'v1'
        }).then(function (data) {
            gapi_deferred.resolve('loaded');
        });

        return {
            expand: function (short_url, callback) {
                return gapi_loaded.then(function () {
                    var request;
                    request = gapi.client.urlshortener.url.get({
                        'shortUrl': short_url
                    });
                    return request.execute(function (response) {
                        return callback(response.longUrl);
                    });
                });
            },
            shorten: function (url) {

                /**
                 * don't use this for now
                 */

                var short_url_deffered = $q.defer();
                short_url_deffered.resolve(url);
                // gapi_loaded.then(function () {
                //     var request;
                //     request = gapi.client.urlshortener.url.insert({
                //         'resource': {
                //             'longUrl': url
                //         }
                //     });
                //     return request.execute(function (response) {
                //         return $rootScope.$apply(function () {
                //             return short_url_deffered.resolve(response.id);
                //         });
                //     });
                // });
                return short_url_deffered.promise;
            }
        };
    });
