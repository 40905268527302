angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgRacingForm', ['$rootScope', 'AppSettings', 'Lang', function olbgRacingFormDirective($rootScope, AppSettings, Lang) {
	var controller = function() {
		this.labels = Lang.tipDetail.racingFormLabels;
	};

    return {
        restrict: 'E',
        scope: {
            tip: '='
        },
        controller: ['$scope', controller],
        controllerAs: 'Racing',
        templateUrl: AppSettings.templates['racing-form'],
        replace: true
    };
}]);