angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBookiesSelector', ['$rootScope', 'AppSettings', function olbgBookiesSelectorDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        controller: 'bookiesSelectorCtrl',
        templateUrl: AppSettings.templates['bookies-selector']
    };
}])


.directive('olbgBookiesSelectorBookie', ['AppSettings', function (AppSettings) {
  return {
    restrict: 'AE',
    replace: true,
    templateUrl: AppSettings.templates['bookies-selector-bookie']
  };
}]);