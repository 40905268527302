angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard
.directive('olbgBetslipButton', ['$rootScope', 'AppSettings', 'Betslip', function olbgBetslipButtonDirective($rootScope, AppSettings, Betslip) {
    return {
        restrict: 'AE',
        controller: [
            '$scope',
            'AppSettings',
            'Betslip',
            '$state',
            'Bookies',
            'Navigation',
            function ($scope, AppSettings, Betslip, $state, Bookies, Navigation) {

                var loggedInBookies,
                    // bookies,
                    bets;

                // Betslip.getAvailableBookies().then(function (response) {
                //     bookies = response;
                // });

                $scope.disabled = true;

                Betslip.syncedOnce().then(function () {
                    $scope.disabled = false;
                });

                $scope.goToBets = function (opts) {

                    if ($scope.disabled) {
                        return;
                    }

                    opts = angular.extend({
                        transition: true
                    }, opts);

                    loggedInBookies = 0; // reset logged in bookies

                    bets = Betslip.getBetslipModel();

                    if (!opts.transition) {
                        Navigation.setTransition({
                            animation: 'none'
                        });
                    }

                    // for now: go directly to your bets

                    $state.go(AppSettings.states.betsManager);

                    // if (angular.isDefined(bookies) &&
                    //     bookies.bookies.length &&
                    //     bets.selections.length) {

                    //     // check logged in state:
                    //     Bookies.checkLoggedInBookies().then(function (bookies) {
                    //         if (bookies.length === 1) {
                    //             // exactly one bookie: go to that bookie
                    //             $state.go(AppSettings.states.betslip, {
                    //                 bookieId: bookies[0]
                    //             });
                    //         } else {
                    //             $state.go(AppSettings.states.betsManager);
                    //         }
                    //     });
                    // } else {

                    //     // no information about bookies yet or no bets
                    //     // go to Your Bets:                    
                    //     $state.go(AppSettings.states.betsManager);
                    // }
                };
            }]
    };
}]);