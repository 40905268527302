(function(isNode, isAngular) {
  var LanguagesModule = function() {
    return {

        defaultTitle:       'OLBG Mobile',
        defaultTitlePWA:    'OLBG Desktop',
        defaultDescription: 'Find free betting tips from our free tipsters. What are they tipping to win today?',
        defaultKeywords:    'free tips tipsters online betting bet winners',

        labels: {
            currency: '£',

            months : [
                'Jan',
                'Feb',
                'Mar',
                'Apr',
                'May',
                'Jun',
                'Jul',
                'Aug',
                'Sep',
                'Oct',
                'Nov',
                'Dec'
            ],

            days: {
                today:     'Today',
                tomorrow:  'Tomorrow',
                yesterday: 'Yesterday',
                at:        'at',
                mon:       'mon',
                tue:       'tue',
                wed:       'wed',
                thu:       'thu',
                fri:       'fri',
                sat:       'sat',
                sun:       'sun',
                weekdays:  'weekdays',
                weekend:   'weekend',
                daily:     'daily',
                days:      'days',
                months:    'months'
            },

            horseRacingForm: {
                prevPos:   'Prev Pos'
            },

            // filter settings screen
            sortTipsTitle:  'Sort Tips By:',
            sortOptions : {
                popularity: 'Popularity',
                eventTime:  'Event Time'
            },
            filtersAction:  'Apply Filters',
            allSports:      'All Sports',
            allSubsports:   'All Leagues',
            backButton:     'Back',
            saveButton:     'Save'
        },

        subsports: {
            football:            'Football',
            horseRacing:         'Horse Racing'
        },

        titles: {

            'home'                : 'Home',

            // base app
            'hot-tips'            : 'Hot Tips',
            'betsManager'         : 'Loading Selections',
            'choose-bookie'       : 'Choose Bookie',
            'bets'                : 'My Selections',
            'offers'              : 'Offers',
            'offers-terms'        : 'Terms of Service',
            'not-found'           : '404 Not Found',
            'settings'            : 'Settings',
            'filter-settings'     : 'Ordering and Filtering',
            'odds-settings'       : 'Odds Format',
            'change-homepage'     : 'Change Initial Page',
            'about'               : 'About OLBG',
            'terms'               : 'Terms & Privacy',
            'contact'             : 'Contact Us',

            // my interests
            'my-bookies-accounts' : 'My Bookies Accounts',
            'my-sports'           : 'My Sports',
            'my-leagues'          : 'My Leagues',
            'my-daily-accas'      : 'Daily Accas',
            'favorites'           : 'Favourites',

            // tipsters
            'my-tipsters'         : 'My Tipsters',
            'suggested-tipsters'  : 'Suggested Tipsters',
            'hot-tipsters'        : 'Hot Tipsters',
            'tipster-page'        : 'Loading Tipster',

            // my acca
            'my-acca-settings'    : 'My Acca',
            'my-acca-sports'      : 'Select Sports',
            'my-acca-review'      : 'Acca Review',

            // notifications
            'stream-settings'     : 'Tips Notifications',
            'my-daily-accas-list' : 'Daily Accas',

            // winner acca
            'winner-acca'         : 'Winner Acca',

            'installVerification' : 'Verify Installation',

            'savensync'           : 'Save and Sync'
        },

        sideMenu: {
            hotTips      : 'Hot Tips',
            yourBets     : 'Selections',
            myAcca       : 'My Acca',
            myDailyAccas : 'Daily Accas',
            offers       : 'Offers',
            hotTipsters  : 'Hot Tipsters',
            myTipsters   : 'My Tipsters',
            sports       : 'Tips By Sport',
            settings     : 'My Account'
        },

        generic: {

            all:                 'all',
            new:                 'new',
            and:                 'and',
            whoops:              'Whoops!',
            error:               'Something went wrong.<br />Please try again in a minute!',
            servers:             'Sorry, we can’t reach our servers at the moment. Please try again in a few minutes.',
            name:                'name',
            email:               'email',
            message:             'message',
            other:               'other',
            favourites:          'favourites',
            winner:              'winner',
            completed:           'completed',
            at:                  'at',
            areYouSure:          'Are you sure?',
            warning:             'Warning',

            expiredEvents: {
                label:           'Expired',
                allLabel:        'All Expired',
                notification:    'This event has expired!'
            },
            dialog: {
                sure:            'Sure!',
                gotit:           'Got it!',
                okay:            'Okay!',
                notNow:          'Not Now',
                showMe:          'Show Me'
            },
            betActions: {
                add:             'Add',
                remove:          'Remove'
            },
            settings: {
                save:            'Save Settings',
                saved:           'Settings saved successfully'
            },

            noData: 'N/A',

            terminology: {
                sp:            'SP',
                ew:            'EW',
                ews:           'EWs',
                win:           'WIN',
                nap:           'NAP',
                naps:          'NAPs',
                nr:            'NR',
                place:         'P',
                deadheat:      'DH',
                tip:           'tip',
                tips:          'tips',
                result:        'Result',
                balance:       'Balance',
                stakes:        'Stakes',
                returns:       'Returns',
                handicap:      'hcap',
                won:           'won',
                selection:     'selection',
                selections:    'selections',
                experts:       'experts',
                expert:        'expert',
                for:           'for',
                pro:           'pro',
                against:       'against',
                error:         'error',
                odds:          'odds',
                betting:       'betting',
                default:       'default',
                profit:        'profit',

                // [INSTANT FOLLOWING]
                instant:       'instant',
                consensus:     'consensus',
                follow:        'follow',
                unfollow:      'unfollow',
                following:     'following',
                max:           'max',
                avg:           'avg',
                notifications: 'notifications'
            },

            ellapsedTime: {
              'a few seconds ago'  : 'a few seconds ago',
              'a minute ago'       : '1 minute ago',
              '${X} minutes ago'   : '${X} minutes ago',
              'an hour ago'        : '1 hour ago',
              '${X} hours ago'     : '${X} hours ago',
              'a day ago'          : '1 day ago',
              '${X} days ago'      : '${X} days ago',
              'a month ago'        : '1 month ago',
              '${X} months ago'    : '${X} months ago',
              'a year ago'         : '1 year ago',
              '${X} years ago'     : '${X} years ago'
            },

            buttons: {
              'yes'         : 'Yes',
              'no'          : 'No',
              'cancel'      : 'Cancel',
              'remove'      : 'Remove',
              'delete'      : 'Delete',
              'confirm'     : 'Confirm',
              'ok'          : 'ok',
              'edit'        : 'Edit',
              'add'         : 'Add',
              'proceed'     : 'Proceed',
              'retry'       : 'Retry',
              'getStarted'  : 'Get Started',
              'closeModal'  : 'Close Modal',
              'submit'      : 'Submit',
              'changedMind' : 'Changed my mind'
            },

            actions: {
                reportBug:   'Report a bug',
                suggestFeat: 'Suggest a feature'
            },

            keepReading: 'Keep Reading'
        },

        /*================================
        =            SECTIONS            =
        ================================*/

        /*----------  Introduction  ----------*/
        splashScreen: {
            title:          'OLBG.com: Let’s Beat The Bookies',
            updating:       'Updating... Please wait',
            disclaimer:     'This app facilitates betting via regulated and licensed bookmakers.<br>Users login/register with the licensed bookmaker to place their bets.<br>OLBG does not accept wagers directly.<br>Users must be aged 18+ and located in ${COUNTRY_LABEL}.<br><a class="begambleaware" href="https://www.begambleaware.org/">begambleaware.org/</a>',
            buildNum:       'Build Number',
            gettingVersion: 'Getting version...',

            /*----------  updating  ----------*/
            updateTitle1:   'Update Available',
            updateTitle2:   'Update Downloaded',
            updateBtns1:    'Not for now',
            updateBtns2:    'Update!',
            updateMsg1:     'There’s a new version of OLBG app.${NEW_LINE}Do you want to download it?',
            updateMsg2:     'Restarting the app.${NEW_LINE}Please allow up to 1 minute for app to install',

            /*----------  loader  ----------*/
            loaderReason1:   'Starting app...',
            loaderReason2:   'Checking your location...',
            loaderReason3:   'Updating...'
        },

        shouldBeBetting: {
            title:           'Not in the UK?',
            message:         'This version of the OLBG app is for UK residents. We have detected that you are not in the UK. You can still use the app but due to regulations, offers as well as outgoing links to bookmakers have been disabled. Once you return to the UK close and restart the app and full functionality will be restored',
            continue:        'Continue with basic version',
            report:          'I’m in the UK (report a problem)'
        },

        tips: {
          remainingMinutes: '${X} minute(s) until it starts!',
          aboutToStart:     'Less than a minute before it starts!'
        },

        /*----------  Ads  ----------*/
        // ads: {
        //     claimLabel:  'Claim Now',
        //     gambleaware: '18+, begambleaware.org, T&amp;Cs Apply'
        // },

        gambleaware: { odds: 'Odds are correct at time of publishing but are subject to change.' },

        /*----------  Hot Tips  ----------*/
        hotTips: {
            sectionTitle:    'Hot Tips',
            emptyString:     'There are no tips available at the moment',
            emptyPref:       'There are no tips available for your favorite sports and leagues',
            emptyFilters:    'No tips found for your current preferences or filters.',
            goToFilters:     'Change Filter Settings',
            endOfListString: 'No more tips to show at the moment',
            endPref:         'No more tips to show for your favorite sports and leagues',
            updatingTips:    'Updating Tips...',

            search: {
                searched    : 'Searched: ',
                placeholder : 'Search Sports, Selections, Leagues',
                emptyString : 'We couldn’t find tips for that term.'
            },

            filter: {
                allLeagues  : 'All Leagues',
                allCourses  : 'All Courses',
                allMarkets  : 'All Markets'
            },

            filterMethods: ['None', 'My Sports and My Leagues', 'Advanced Filters'],

            settings: {
                minNumberOfTips : 'Minimum number of tips:',
                confidence      : 'Confidence (Winning Tips / Overall Tips):',
                minOddsValue    : 'Minimum odds value:',
                maxOddsValue    : 'Maximum odds value:'
            },

            preferences:    'Tips filtered by ${SPORTS_LINK_START}your sports${SPORTS_LINK_END} and ${LEAGUES_LINK_START}your leagues${LEAGUES_LINK_END} preferences',
            filtersApplied: 'Filters Applied'
        },

        /*----------  Tip Details  ----------*/
        tipDetail: {

            sectionTitle:             'Loading Selection',

            noTipCopy: {
              title:                  'Selection not found',
              message:                'The selection might be no longer available.'
            },

            expiredEvent: {
                title:                'The event has just expired!',
                message:              'We invite you to check out hot tips for other events!',
                button:               'Check Hot Tips'
            },

            comments: {
                noCommentsString:     'There are no comments for this event.',
                noMoreCommentsString: 'There are no more comments for this event.',
                commentsErrorString:  'Couldn’t load comments. Try again later!'
            },

            racingFormLabels: {
                number:               'Number:',
                weight:               'Weight:',
                draw:                 'Draw:',
                jockey:               'Jockey:',
                age:                  'Age:',
                trainer:              'Trainer:'
            },

            quickFormLabel:           'Quick Form',
            tipsterCommentsLabel:     'Tipster Comments:',
            swipeEnd:                 'No more tips for this market'
        },

        /*----------  Sports By Navigation  ----------*/
        sportsSelect:   { sectionTitle: 'Tips By Sports' },
        leaguesPage:    {
            topLeagues: 'Top Leagues',
            otherLeagues: 'Other Leagues'
        },
        eventsPage:     {
            sectionTitle: 'Loading Events',
            swipeEnd    : 'No more events to show!'
        },

        /*----------  Recommended Tips  ----------*/
        emptyCatPage:   {
            pageTitle: 'No Tips Found',
            content: {
                'events'        : {
                    copy        : 'Send me an <b>email</b> when there are new events.',
                    eventsBtn   : 'Show (${NUM}) Finished Events'
                },
                'event'         : {
                    marketsBtn  : 'Show More Markets'
                },
                'default'       : {
                    copy        : 'Sorry. We couldn’t find tips at the moment.',
                    more        : 'See below for other interesting tips you can bet on!'
                },
                recommendedTips : {
                    title       : 'Recommended for you:'
                },
                recommendedCat  : {
                    copy        : '<b>See More: </b>'
                }
            }
        },

        /*----------  Tips By Market Page  ----------*/
        tipsPage:  {
            // general
            sectionTitle:        'Loading Tips',
            foldButtonContent:   'More Outcomes',
            unFoldButtonContent: 'Less Outcomes'
        },

        /*----------  Tipster  ----------*/
        tipsters: {
            annualProfit:       'Annual Profit',
            sevenDaysProfit:    '7 Days Profit',
            yesterdaysProfit:   'yesterday’s Profit',
            lastTippedLabel:    'Last Tipped:',
            expertise:          'Expert in',
            profitDisclaimer:   '* Profits are calculated to a 10 point stake',
            moreTipsters:       '${X} more tipsters you follow',
            oneMoreTipster:     '1 more tipster you follow',
            xTipsters:          '${X} tipsters you follow',
            backsThisSelection: 'backs this',
            backThisSelection:  'back this'
        },

        tipsterPage: {
          tabs:      ['Tips', 'About'],
          notFound:   'Tipster Not Found',
          follower:   'follower',
          followers:  'followers',
          tipsPerDay: 'tips / day'
        },

        /*----------  My Tipsters  ----------*/
        myTipsters: {
            enableNotifications: 'Turn notifications ON for OLBG app in <b>your mobile settings</b> and start getting updates from the tipsters you follow',
            tabs: {
                tips:     'Tips',
                tipsters: 'Tipsters'
            },
            sort: {
                by: 'By:'
            },
            empty: {
                title: 'Suggested Tipsters',
                copy:  'You can now <b>${FOLLOW_ICON_START}follow${FOLLOW_ICON_END} your favourite tipsters</b> and get <b>notifications</b> on what they are tipping on!',
                button: 'Check Hot Tips now!'
            },
            expectsNewTipsters: 'Tipsters recently added may take a few minutes to show',
            tipstersList: {
                sportsFollowingCopy: 'Following for: ',
                empty:               'No tips by followed tipsters found',
                following:           'You are following ',
                unfollowing:         'You unfollowed ',
                disclaimer:          'This list includes only win tips'
            },
            tipstersFollowing: {
                sportFollowing:      'Following for ',
                statsPartOne:        'This tipster places an average of:',
                statsPartTwoS:       ' tip per day',
                statsPartTwoP:       ' tips per day',

                following:           'Following',
                notFollowing:        'Follow',

                followTypeTitle:     'Follow Options',

                introTitle:          'Instant Following',

                introMessage1:       'There are two ways to follow tipsters',
                introMessage2:       'You will receive a notification when any ${X} followed tipsters are on the same selection',
                // introMessage3:       'Receive a notification for a tipster as soon as they make the tip. You get the message fast but it could mean a lot of notifications',
                introMessage3:       'Receive a notification for a tipster as soon as they make the tip',
                introMessage4:       'You will receive a notification when any previously selected number of followed tipsters are on the same selection',
                instantStatsLabel:   '${TOTAL} notifications in past 30 days with max of ${MAX} on any single day',
                noNotifs:            'No notifications in the past 30 days',
                instantDisclaimer:  [
                    '* Some tipsters might not generate notifications due to their low engagement or activity',
                    '* You can customise your notifications anytime in ${LINK}My Account${/LINK}. Access your settings tapping on <b>My Account</b> icon ',
                    'in the top corner.',
                    'in the side menu.'
                ]
            },
            tipsByTipsters: {
                tipsByLabel:         'Tipped by:',
                moreTipsters:        'and ${NUM} others',
                backing:             'tipsters back this selection',
                paginationEnd:       'No more events'
            },
            suggested: {
                title:               'Suggested for:',
                seeMore:             'Check Hot Tipsters',
                placeholder:         'Loading Sports',
                noSuggestions:       'We couldn’t find tipsters for the selected sport'
            }
        },

        /*----------  Settings Page  ----------*/
        settingsPage: {

            sectionTitle:      'My Account',

            // general
            general: {
                title:         'General'
            },

            notifications: {
                title:         'Notifications',
                waTitle:       'Winning Accas Notifications',
            },

            myInterests: {
                title:         'My OLBG',
                accountsTitle: 'My Bookies',
                tipsTitle:     'Daily Accas',
                sportsTitle:   'My Sports',
                leaguesTitle:  'My Leagues'
            },

            // hot tips settings
            hotTips: {
                title:         'Hot Tips',
                filterTitle:   'Order and Filtering',
                oddsTitle:     'Odds Format',
            },

            // olbg
            olbg: {
                aboutTitle:    'About Us',
                termsTitle:    'Terms & Privacy',
                contactTitle:  'Contact Us',
                iOSAppId:      'App ID',
                verifyInstall: 'Verify App Installation'
            },

            // about the app
            aboutSection: {
                title:        'About this app',
                versionLabel: 'Version'
            }
        },

        /*----------  Offers Page  ----------*/
        offersPage: {
            // general
            sectionTitle: 'Offers',
            empty: {
                title:   'No offers',
                message: 'There are no offers available at the moment.'
            }
        },

        offersTermsPage: { sectionTitle: 'Terms of Service' },

        /*----------  Hot Tips Filtering  ----------*/
        filterSettings: {
            sectionTitle: 'Hot Tips Settings',
            messages: {
                success: 'Filter settings saved!'
            }
        },

        /*----------  Odds Settings  ----------*/
        oddsSettings: {
            sectionTitle: 'Odds Format',
            messages: { success: 'Odds settings saved!' },
            decimalLabel:    'decimal',
            fractionalLabel: 'fractional',
            americanLabel:   'american'
        },

        /*---------- Contact  ----------*/
        contact: {
            sectionTitle:        'Contact Us',
            shareText:           'Share OLBG',
            errorListString:     'Check that ${ERROR_LIST} are valid!',
            errorString:         'Check that ${ERROR} is valid!',
            successMessage:      'Message sent! Thanks for contacting us!'
        },

        /*---------- Verify Installationo  ----------*/
        verifyInstall: {
            placeholder: 'Enter Verification Code',
            verified:    'App Installation Verified!',
            explanation: 'Enter the verification code provided to you by the OLBG team to verify the app installation below.',
            invalidMsg:  'Code seems to be invalid! If you think you are entering the code correctly:'
        },

        /*----------  [QUICK BETTING (tm)]  ----------*/
        quickBetting: {
            title:      'Best odds',
            empty1:     'You have no bookie preferences.',
            empty2:     'Add your bookies',
            empty3:     'and we’ll pick the one with best odds for easy access!',
            noFav:      'We couldn’t find odds for your favourite bookies here’s one you can try:',
            setupBtn:   'Setup Bookies'
        },

        /*----------  Bookies Selector  ----------*/
        bestOdds: {
            nextLabel:       'Next',
            CTAlabel:        'See All Bookies',
            emptyBetsTitle:  'Empty Your Selections',
            emptyBetsMsg:    'Are you sure you want to empty your Selections?',
            emptyBetsAction: 'Empty bets!'
        },

        chooseBookies: {
            noBookies: {
                title:    'No bookies available!',
                message:  'There are no bookies available for your current selections.',
                btnLabel: 'Manage your selections'
            }
        },

        bookiesSelector: {
            myBookiesTitle:    'My Bookies',
            otherBookiesTitle: 'Other Bookies',
            betsExpiredMessage: {
                title:       'All events expired!',
                message:     'We invite you to check out hot tips for other events!',
                action:      'Check Hot Tips'
            },
            updateMessage:              'Odds have changed or an event expired.',
            updateBtnLabel:             'Update Bookies!',
            updateMyBookiesABtnLabel:   'Update my Bookies Account',
            bestOddsCopy:               'Best odds!',
            oddsChangedMessage: {
                title:       'Some of the odds might have changed.',
                message:     'Please check them again on the bookie website, before placing the bet!',
                action:      'Continue to Site'
            },
            handicapTitle:   'Available selections for this bookie',
        },

        /*----------  Bet Slip  ----------*/
        betslip: {

            selectionRemoved:       'Selection removed successfully',

            expiredMsg:             'Selection no longer available!',
            nrMsg:                  'Please remove non-runner to continue',

            addSuccess:             'Added to Selections!',
            betslipBtnLabel:        'Go to Site',

            link: {
                label:              'Selections',
            },

            empty: {
                sectionTitle:       'No Selections',
                title:              'You have no selections!',
                message:            'Take a look at hot tips and place your selections!',
                buttonLabel:        'What’s hot?'
            },

            addError:               'Could not add selection. Try again later.'
        },

        /*----------  Bookies Accounts  ----------*/
        bookiesAccounts: {
            copy: {
                showMoreBookies :        'Other Bookies',
                betslipSettings: {
                    title:               'Bookies Accounts',
                    main1:               'Setup your bookies!',
                    main2:               'Tell us which bookies you have an account with and we can check who has the best odds and link you directly to that bookie’s betslip with all your tips added.',
                    btn:                 'Done!'
                },
                settings: {
                    btn:                 'Save Changes',
                    saved:               'Bookies updated successfully!'
                }
            }
        },

        /*----------  Offers  ----------*/

        offers: {
            linkLabel:              'Offers',
            termsButtonLabel:       'Terms'
        },

        /*----------  My Interests  ----------*/
        myInterests: {
            optionalBookiesTitle :  'Other Bookies'
        },

        myBookiesAccounts: {
            title:                  'My Bookies',
            noBookies: {
                title:              'No Bookies Available',
                msg1:               'Whoops! We couldn’t find any bookies for your country right now.',
                msg2:               'In the meantime, <b>improve your experience</b> by telling us your favourite sports!',
                btn:                'Set My Sports'
            },
            copy: {
                searchCopy:         'Search Bookie'
            }
        },

        mySports: {
            title:                  'My Sports',
            copy: {
                main:               '<b>Customise your hot tips</b> by choosing your favourite sports in this page!',
                saved:              'Sports updated successfully!',
                searchCopy:         'Search Sport'
            },
            sportsPage: {
                emptyPreferences:   'Tell us ${LINK_START}your sports${LINK_END} and we’ll show them first at the top for easy access!',
                notInterestedTitle: 'Other Sports'
            },
        },

        myLeagues: {
            copy: {
                main1:              'Customise your hot tips',
                main2:              'by choosing your <b>football</b> favourite leagues in this page!',
                saved:              'Leagues updated successfully!',
                searchCopy:         'Search League'
            }
        },

        myDailyAccasList: {
            tryMyAcca: {
                msg:                'Create your own ${ICON_START}My Acca${ICON_END} from the tips with the most experts right now!',
                btn:                'Try it now!'
            },
            noPrefTitle:            'Best Daily Selections',
            noPrefCopy:             'Setup your daily acca preferences and get our best daily selections!',
            noPrefBtn:              'Setup Daily Accas',
            prefHeader:             '${LINK_START}Setup your acca preferences${LINK_END} and get our best daily selections!',
            available:              'Available:',
            sorry:                  'Sorry only ',
            noTipsToday:            'No recommendations for today',
            somethingWrong:         'Temporarily disabled. Try again in a few minutes.',
            viewBtn1:               'View Bet',
            viewBtn2:               'View Double',
            viewBtn3:               'View Treble',
            viewBtn4:               'View Acca',
            footnote:               'Advised at <b>${TIME}</b> at odds of <b>${ODDS}</b>'
        },

        /*----------  No Connection  ----------*/
        // no internet detected
        noInternet: {
          title:                    'No Connection!',
          body:                     'Check that <b>WIFI</b> or <b>mobile data</b> is turned on.',
        },

        /*----------  Loader  ----------*/
        loader: {
            title:                  'Loading...'
        },

        /*----------  Notifications  ----------*/
        stream: {
            followDialog: {
                title:                  'Never miss a winning tip!',
                msg:                    'OLBG can send you notifications such as when there are new tips from tipsters you follow or winning daily accas to ensure you don’t miss out.',
                msgiOS:                 ' Make sure to accept notifications when prompted',
                footer:                 '* You can setup notifications in Settings menu'
            },
            disabled: {
                title:                  'Notifications disabled',
                message:                'Please turn notifications ON for OLBG app in <b>your mobile settings</b>',
                btn:                    'Go to settings'
            },
            settings: {
                accepts: {
                    off:                'Enable Notifications',
                    multiple:           'Notify popular tips separately',
                    summary:            'Daily summary of tipsters tips'
                },
                minimum:                'Send for:',
                tips:                   '+ tips',
                saved:                  'Notifications settings saved!',
                general:                'General',
                notifications:          'Notifications',
                noSettings:             'No settings available at the moment',
                notificationsType:      'Notification type',
                disclaimer:             '<b>Setup notifications per sport.</b> If you want to stop receiving notifications from one tipster in specific, make sure to <b>unfollow</b> him instead.',

                examples: {
                    multiple:           '<b>John Doe</b>, <b>Jane Doe</b> and <b>2</b> others tipped on <b>The Doe Horse</b>',
                    multipleSummary:    'You have <b>21</b> tips from <b>7</b> tipsters'
                }
            },
            muted: {
                message:                'You haven’t clicked on notifications for a while so we muted them. <b>Do you want to start getting notifications again?</b>',
                button:                 'Re-enable notifications!',
                feedback:               'Notifications are back!'
            },
            subscriptions: {
                success: 'Notifications preferences stored successfully'
            },
            notSupported: {
                title: 'Notifications not supported',
                msg:   'Push Notifications are not supported in iOS devices or in some browsers.<br/>Unless you are in iOS, you can try getting <b>Google Chrome</b>. Don’t miss out!',
                // msg:   'This browser doesn’t support push notifications. We recommend you use the latest version of <b>Google Chrome</b> to get full support for notifications. <br> Don’t miss out!',
                cta:   'Get Google Chrome'

            }
        },

        /*----------  Hot Tipsters  ----------*/
        hotTipsters: {
            sectionTitle:           'Hot Tipsters',
            tabs: {
              sevenDaysProfit:      '7 Days Profit',
              annualProfit:         'Annual Profit',
              followers:            'Followers'
            },
            copy: {
                subtitle:           'Most successful tipsters for',
                mySports:           'My Sports',
                loadingSports:      'Loading Sports',
                empty:              'We couldn’t find Hot Tipsters for this sport.',
                emptySports:        'We couldn’t find sports with Hot Tipsters.',
                withTips:           'only show tipsters with tips',
                changeSport:        'Change Sport'
            },
            search: {
                placeholder:        'Search All Tipsters',
                empty:              'We couldn’t find Hot Tipsters for this term.'
            }
        },

        tipstersTips: {
            copy: {
                empty:              '<b>${TIPSTER_NAME}</b> has no tips at the moment'
            }
        },

        tipstersAbout: {
            titles: {
                profit:             'Profits',
                twelveMonthsProfit: '12 Months Profit Trend',
                dailyProfits:       'Daily Profit',
                dailyProfitsSub:    'Last ${X} days',
                achievements:       'Achievements',
                recentWinners:      'Recent Winners',
                otherTipsters:      'Other tipsters you might like'
            },
            highestProfit:          'Highest Profit',
            lowestProfit:           'Lowest Profit'
        },

        /*----------  My Acca  ----------*/
        // my acca
        myAcca: {

            linkLabel:                  'My Acca',

            littleIntro: {
                title:                  'Create Your Acca',
                copy:                   'Tell us what you like and we will find the tips our most profitable tipsters recommend!'
            },

            settings: {
                ctaLabel:               'Build My Acca!',
                ctaNewLabel:            'Build New Acca!',
                emptyMsg:               'We couldn’t find tips. Try with different settings!',
                fewerSelections:        'Found only ${TIPS_NUM} of ${REQUESTED_NUM} requested selections.',
                creating:               'Creating Acca...',
                labels: {
                    chooseSports:       'Choose Sports',
                    selectedSports:     'Selected Sports',
                    selNumber:          'Selections Number',
                    when:               'When',
                    today:              'Today',
                    tomorrow:           'Today and Tomorrow',
                    latest:             'Latest Acca Review'
                }
            },

            review: {
                inBetslipMsg:           'This is already in your Selections',
                CTAlabel1:              'Replace & Choose Bookie',
                CTAlabel2:              'Merge & Review Your Selections',
                CTAlabel3:              'Next: Choose Bookie',
                CTAlabel4:              'Add to Selections & Choose Bookie',
                header: {
                    title:              'Your Acca has been created!',
                    msg:                'Review Selections, Choose Bookie, Bet!'
                },
                expirationMsg: {
                    title:              'Odds might have changed',
                    msg:                'It’s been a while since you built the acca. Make sure to review your selections as odds might have changed.',
                    button:             'Got it!'
                },
                rebuiltMsg:             'Your Acca has been re-built successfully!',
                removedMsg:             'A selection has been removed from Your Acca',
                lastRemovedMsg:         'No more selections in Your Acca!',
                mergeCopy: {
                    title:              'You have items in your Selections!',
                    subtitle:           'Keep items already in Selections',
                    body:               'We will merge your current selections with the ones shown here.'
                },
                proceedMsg: {
                    add:                'Adding to Selections...',
                    merge:              'Merging with Selections...',
                    replace:            'Replacing Selections...',
                    added:              'Selections added successfully',
                    merged:             'Merged successfully. Please review your Selections',
                    replaced:           'Selections replaced successfully',
                    mergedWithErrors:   'Merged with errors. Some selections are missing!',
                    replacedWithErrors: 'Replaced with errors. Some selections are missing!'
                },
                lspExplanation1:        '<b>Combined Profit</b> is calculated by adding the profits of each tipster for a selection and deducting the profits of each tipster against it. Each tipster’s profit is based on a stake of 10 units on every tip they made in the previous six months.',
                lspExplanation2:        '<b>Experts</b> are OLBG tipsters with high strike rates, current month or 6 month profitability for a particular sport.',
                combinedLSPLabel:       'Combined Expert Profit',
                showTopComment:         'Show Top Comment',
                asAdvised:              'as advised'
            }
        },

        winnerAcca: {
            cta: 'See and follow today’s accas',
            subscribe: 'Get winning acca notifications'
        },

        /*----------  Favorites  ----------*/
        favorites: {
            new:                          'Add to Favourites for easy access',
            add:                          'Add to Favourites',
            remove:                       'Remove from Favourites',
            added:                        '${LEAGUE_NAME} added to Favourites!',
            emptyTitle:                   'Add your favourite leagues!',
            emptyMessage:                 'You can now add your favourite leagues / courses and <b>access all of them conveniently in one place</b>. Navigate to <b>Tips By Sport</b>, look for your favorite league and make sure to tap the heart button!',
            emptyBtnCopy:                 'Go to Tips By Sports',
            empty:                        '(empty)',
            deleteTitle:                  'Remove from Favourites?',
            deleteMsg:                    'More events might become available in the future. Are you sure you want to remove it from favourites?'
        },

        iOS: {
            titles: {
                feedback:               'App Feedback'
            },
            tabbar: {

                // BETTING
                tips: {
                    label: 'Tips',
                    submenu: {
                        'hot-tips'       : 'Hot Tips',
                        'tips-by-sports' : 'Tips By Sports',
                        'my-acca'        : 'My Acca',
                        'my-daily-accas' : 'Daily Accas',
                        'favorites'      : 'Favourites'
                    }
                },
                bets: {
                    label: 'Selections'
                },
                tipsters: {
                  label: 'Tipsters',
                  submenu: {
                    'hot-tipsters'   : 'Hot Tipsters',
                    'my-tipsters'    : 'My Tipsters'
                  }
                },
                offers: {
                    label: 'Offers',
                    submenu: {}
                },
                following: {
                    label: 'Following',
                    submenu: {}
                },
                settings: {
                    label: 'My Account',
                    submenu: {}
                },

                // NON BETTING
                hotTips:     { label: 'Hot Tips' },
                horseRacing: { label: 'Racing' },
                football:    { label: 'Football' },
                otherSports: { label: 'Other' }
            },
            spotlightIndexing: {
              description: 'Most popular selection is ${SELECTION} at ${ODDS}. Check out all our experts tips.'
            }
        },

        /*----------  Consent  ----------*/
        consent: {
            title: 'Important',
            msg:   'We use anonymous device identifiers to personalise content and ads, and to analyse our traffic.\nWe also share such anonymous identifiers and other anonymous information from your device with our analytics partners who may combine it with other information you\'ve provided to them or they\'ve collected from your use of their services.'
        },

        // PWA
        pwa: {
            installBtn:       'Install app',
            popup: {
                title:        'Install as Desktop App',
                msg:          'Easily access your favorite tips and tipsters',
                msgOS:        ' from an icon in your OS Launchpad or Spotlight',
                msgWin:       ' from an icon in your desktop',
                msgEnd:       ' and setup notifications for experts tips.',
                btn:          'Install',
                footer:       '<small>* You can also install the app from the side menu at anytime.</small>'
            }
        },

        // TRANSLATIONS
        translations: {
            seeTranslation:    'See Translation',
            seeOriginal:       'See Original',
            translating:       'Translating',
            disabled:          'Translations no longer available for this session',
            settingsTitle:     'Translations',
            automaticSettings: 'Auto Comment Translations',
            automaticSaved:    'Translations preferences updated!'
        },

        // SWIPE
        swipe: {
            endMessage: 'No more pages to show!',
            intro: {
                title: 'Swipe events',
                message: 'You can now swipe left or right to navigate events in the same league or course!'
            }
        },

        // HOME
        home: {
            intro: {
                title: 'Introducing Home!',
                desc:  [
                    'Check Featured Tipsters, Hot Tips and Featured Events for your favourite leagues and courses at a glance in the new <b>OLBG Home</b> page.',
                    'Access it anytime tapping on: <br>${HOME_ICON} in the',
                    'side menu.',
                    'menu down below.'
                ]
            },
            modules: {
                titles: {
                    olbgSetup: 'Explore OLBG!',
                    tipsters:  'Featured Tipsters',
                    tips:      'Featured Tips & Events'
                },

                // feat tipsters
                featuredTipsters: {
                    actionButtons: [
                        'Check My Tipsters',
                        'Check Hot Tipsters'
                    ],
                    noTipsters: 'No tipsters found. Try again later.',
                    sections: {
                        tipsterConsensus: {
                            title:               'My Tipster Consensus',
                            noConsensus:         'No consensus tip found. Make sure you follow at least <strong>${X} tipsters per sport.</strong>',
                            followPerSportTitle: 'Number of tipsters you follow'
                        },
                        profitableTipsters: {
                            title: 'Most Profitable Tipsters',
                        },
                        winners: {
                            title: 'Consistent Tipsters',
                            options: ['Daily Profit Streak',  'Monthly Profit Streak']
                        }
                    }
                },

                featuredTips: {
                    actionButtons: [
                        'Check more Hot Tips',
                        'Check your Favourites'
                    ],
                    noTips: 'No Events found.  Configure your favourites to ensure you don’t miss any important events',
                    sections: {
                        hotTips: {
                            title: 'Hot Tips'
                        },
                        favEvents: {
                            title: 'My Favourite Events'
                        }
                    }
                }
            },
            settings: {
                explanation: 'Change Initial Page to'
            }
        },

        // OLBG SETUP
        olbgSetup: {

            progress: {
                title:         'Have you seen all OLBG has to offer?',
                completed:     'You became master of OLBG! You rule!',
                showCompleted: 'Show Completed'
            },

            lock: {
                message:     'Keep exploring to unlock more!',
                almostThere: 'You are almost there!',
                unlocked:    'New challenges unlocked!'
            },

            template: {
                checkVideo:  '1) Watch the video to see how',
                yourTurn:    '2) Now it’s your turn'
            },

            // THE STEPS
            step1: {
                title: 'Set your favourite sports',
                desc:  'Customise the type of content you get based in your favourite sports',
                templateParts: [
                    'Like football and basketball?',
                    'Get the best tips and tipsters across the app and in the brand new <b>OLBG HOME</b> specially for these sports!',
                    'Set your Sports'
                ]
            },

            step2: {
                title: 'Follow a Tipster',
                desc:  'Get notifications from your favourite tipsters',
                templateParts: [
                    'Start following tipsters to get <b>notifications</b> whenever many of them back the same selection (consensus) or for each tip they make in the app (instant)',
                    'You can follow them anywhere in the app but ${SECTION} is a good place to start',
                    'Go to <b>Hot Tipsters</b> and <b>follow</b> any tipster in the list'
                ]
            },

            step3: {
                title: 'Filter Hot Tips using an Advanced Filter',
                desc:  'Get just the Hot Tips you want!',
                templateParts: [
                    'Fine tune Hot Tips even more with our advanced filters. Filter selections by sport, league or course, markets, the number of tips, confidence and more!',
                    'Go to <b>Hot Tips</b>, tap on the <b>Filter</b> button, choose <b>Advanced Filters</b>, enter your preferences and tap <b>Save</b> button'
                ]
            },

            step4: {
                title: 'Set your preferred Odds Format',
                desc:  'Change your settings at My Account',
                templateParts: [
                    'Navigate to <b>My Account</b> and <b>select your preferred odds format</b>. While you are there, make sure you take a look at other things you can customise to improve your OLBG experience even further!',
                    'Tap on <b>My Account</b> icon in the top bar',
                    'Find <b>My Account</b> button in the side menu!'
                ]
            },

            step5: {
                title: 'Browse Tips by Sports and Create Favourites',
                desc:  'Favourite a sport, league or course',
                templateParts: [
                    'If you find yourself visiting the same sport, league or course over and over again through <b>Tips By Sports</b>, tap on the heart icon to make it a favourite and check it directly from <b>Favourites</b> next time',
                    'Go to <b>Tips By Sports</b>, navigate to a sport, league or course and <b>tap the heart button</b> to create a favourite. You can now navigate directly to your favourites by tapping on the <b>Favourites</b> button in the Tips submenu.',
                ]
            },

            step6: {
                title: 'Follow a Daily Acca',
                desc:  'Check Daily Accas and follow one',
                templateParts: [
                    'We publish accas throughout the day based on the selections with the most tipsters and experts. Make sure to follow them so not miss out on getting on at a good price',
                    'Go to <b>Daily Accas</b>, browse the accas of the day and turn <b>"Notify me when published" ON</b>'
                ]
            },

            step7: {
                title: 'Set your Favourite Leagues',
                desc:  'Customise the type of content you get based on your favourite league',
                templateParts: [
                    'Get the best tips across the app and in the brand new <b>OLBG HOME</b> for your favourite leagues!',
                    'Set your Leagues'
                ]
            },

            step8: {
                title: 'Create My Acca',
                desc:  'Create an acca tailored to your needs',
                templateParts: [
                    'If our Daily Accas are not enough and you want more customisation, we have the perfect solution. Check <b>My Acca</b>, tell us the sports you want in there, the number of selections and we’ll build the best acca for you!',
                    'Go to <b>My Acca</b>, enter your acca preferences and tap <b>Build My Acca!</b> button'
                ]
            },

            step9: {
                title: 'Set the Bookies you use',
                desc:  'Customise your Bookies Selector by setting the bookies you have an account with',
                templateParts: [
                    'When viewing selections we will show you which of your bookies has best odds for <b>Quick Betting</b>',
                    'Set your Bookies'
                ]
            },

            step10: {
                title: 'Create a list of Selections',
                desc:  'Add as many selections as you want to your list',
                templateParts: [
                    'With OLBG not only you can check what our expert tipsters are backing but also <b>add these selections to a list you can easily copy</b> and use in your favourite Bookie site',
                    'Go to <b>Hot Tips</b> and <b>Add a Selection</b> to create your list. Add as many selections as you want!',
                    'Go to Hot Tips'
                ]
            },

            step11: {
                title: 'Copy Selections to your Bookie',
                desc:  'Use our Bookies Selector to easily copy your selections to your favourite bookie',
                templateParts: [
                    'Go to <b>Bookies Selector</b>, pick the bookie of choice and go to their website with a <b>pre filled Betslip</b> using our app. Quick and Easy!',
                    'Go to <b>Selections</b>, then <b>Bookies Selector</b> and tap <b>Go To Site</b> on your any bookie to copy your list to their Betslip',
                    'Go to Selections'
                ]
            },

            step12: {
                title: 'Filter My Tipsters by Instant Following',
                desc:  'Check the tipsters you get instant notifications for',
                templateParts: [
                    'You may find you are getting too many notifications from one or two tipsters and want to stop them without stopping all OLBG notifications',
                    'Go to <b>My Tipsters</b>, select the <b>Tipsters</b> tab and select <b>Instant</b> from the dropdown to show only those you are <b>Instant Following</b>'
                ]
            }
        },

        // SAVE AND SYNC
        savensync: {
            desc:                 'Associate your settings such as followed tipsters, notification settings, bookies, sports, and favourites with an <strong>email address</strong>.',
            desc2:                'If you change phones or lose your settings somehow you can recover them with that email.',
            desc3:                'If you use the OLBG app on more than one device you can keep the settings in sync across your devices',
            desc4:                'By associating your settings with an email you will be able to reload them into other devices should you change phone or want to use OLBG on a second device.',
            enterEmail:           'Enter your Email',
            sendEmail:            'Send Email',
            disclaimer:           'Your email is stored in encrypted form and only used to verify you when authenticating a new device',
            pendingTitle:         'Not yet Saved/Synced',
            pending:              'A confirmation email has been sent to <b>${EMAIL}</b>. Please click the link in the email and then return here to click the button below.',
            reloadBtn:            'I confirmed',
            cancelMsg:            'This device won’t be linked to your email.',
            unlinkMsg:            'This will unlink this device from your email. Any changes in settings won’t be updated in the cloud.',
            unlinked:             'Deviced unlinked successfully',
            unlinkBtn:            'Unlink this device',
            noEmail:              'Did not get the email? Make sure you check your SPAM folder or ${LINK}try again${/LINK}',
            allDoneTitle:         'All Saved/Synced',
            allDoneMessage:       'This device and your settings has been linked to <b>${EMAIL}</b> successfully. Any changes you make will be synced to any other current or future device you also link to this email.',
            deviceFound:          'This email is already linked to another device(s). Do you want to use their settings or override them with your current settings?',
            syncBtn:              'Replace settings on this device with saved settings',
            overrideBtn:          'Replace saved settings with settings on this device',
            syncFeedback:         '${TYPE}... please allow a few minutes!',
            syncDisclaimer:       'Some data like tipsters you follow on other devices might require restarting the app to take effect.',
            justSyncedDisclaimer: 'Any changes on any linked device will be saved and synced to other devices <b>within 10 minutes</b>.',
            syncing:              'Syncing',
            overriding:           'Overriding',

            introTitle:           'Introducing Save and Sync',
            introDesc:            'You can now <b>save your settings to the cloud</b> such as followed tipsters, notifications settings, bookies, sports and favourites and get them across several devices.',
            introIllustration:    '<div class="olbg-alert__illustration olbg-alert__illustration--big-icon olbg-alert__illustration--big-icon--centered"><i class="olbg-alert__illustration__icon olbg-alert__illustration__icon--big"><span class="olbg-alert__illustration__icon__the-icon ion-upload"></span></i></div>'
        }
    };
  };

  if (isAngular) {
    angular.module('olbg-web-app')
      .factory('LangModule', LanguagesModule);
  } else if (isNode) {
    exports.langObj = new LanguagesModule();
  }
})(typeof module !== 'undefined' && module.exports, typeof angular !== 'undefined');