angular.module('olbg-web-app')

// Share service
/**
 * Adrian Radu adrianr@olbg.com - 2015.10.22
 */

.factory('Share', function ($q, GeneralSettings, AppSettings, Environment, Tracker, Utils, NewFeatures, Request) {

    /**
     * NOT USING ANYMORE
     */


    // /**
    //  * Persist share information between screens
    //  */

    // var shareStrings,
    //     share = {},
    //     shareService = {},
    //     settings    = AppSettings,
    //     shareAPIurl = Environment.APIurls.share;

    // function resetShare() {
    //     share = {
    //         id               : '',
    //         name             : Environment.og.title,
    //         picture          : Environment.og.image,
    //         caption          : Environment.og.caption,
    //         description      : Environment.og.description,
    //         link             : Environment.og.url,
    //         completeLink     : Environment.og.url,
    //         twitterHashTags  : settings.twitterHashtags
    //     };
    // }

    // resetShare();

    // shareService = {
    //     getStrings: function () {

    //         /**
    //          * getStrings
    //          *
    //          * A function that returns share strings
    //          * from either API or Local Storage
    //          */

    //         var settingsDB,
    //             stringsDelay = settings.tipDetail.getNewShareStringsDelay,
    //             shareStrings = {},
    //             stringsReady = $q.defer(),
    //             date = new Date().getTime();


    //         function getFromAPI() {

    //             var defer = $q.defer();


    //             // get strings from API and store them locally
    //             Request.get(shareAPIurl)
    //                 .then(function (response) {

    //                     // got Share Strings
    //                     shareStrings.strings   = response.data.data.settings;
    //                     shareStrings.fetchDate = date;
    //                     // store Share strings in General Settings
    //                     GeneralSettings.store({
    //                         shareStrings: angular.toJson(shareStrings)
    //                     }).then(function () {
    //                         // strings have been stored. Resolve Service.
    //                         stringsReady.resolve(shareStrings.strings);
    //                         defer.resolve();
    //                     }).catch(function () {
    //                         // strings couldn't be stored
    //                         defer.reject();
    //                     });
    //                 })
    //                 .catch(function () {
    //                     defer.reject();
    //                 });
    //             return defer.promise;
    //         }


    //         // attempt to retrieve Share strings from localStorage;
    //         GeneralSettings.get('shareStrings', function (data) {

    //             // get Share strings
    //             shareStrings = angular.fromJson(data.name);

    //             // check if time enough time has passed
    //             if (date - shareStrings.fetchDate > stringsDelay) {
    //                 getFromAPI().catch(function () {
    //                     // couldn't get from API. Use old ones.
    //                     stringsReady.resolve(shareStrings);
    //                 });
    //             } else {
    //                 // got Share strings from API
    //                 stringsReady.resolve(shareStrings.strings);
    //             }
    //         }, function () {
    //             // no stored strings. get from API
    //             getFromAPI().catch(function () {
    //                 // no strings available at the moment.
    //                 // Reject Share String promise
    //                 stringsReady.reject();
    //             });
    //         });

    //         return stringsReady.promise;
    //     },
    //     get: function () {
    //         return share;
    //     },
    //     set: function (object) {
    //         if (!object || !object.id) {
    //             throw 'Please provide an identifier of what needs to be shared. i.e: a tip';
    //         }
    //         share = angular.extend(share, object);
    //     },
    //     reset: function () {
    //         resetShare();
    //     },

    //     /**
    //      * prepares share contents for sharing app
    //      * @return {[promise]}
    //      */
    //     shareApp: function () {

    //         var deferred = $q.defer();

    //         if (!NewFeatures.isAvailable(AppSettings.labels.features.sharing)) {
    //             deferred.resolve();
    //             return deferred.promise;
    //         }

    //         // Get the strings
    //         shareService.getStrings().then(function (response) {

    //             var hashTags,
    //                 twitterDesc,
    //                 hashTagsObj,
    //                 facebookTitle,
    //                 facebookBody,
    //                 mailSubject,
    //                 mailBody,
    //                 twitterString,
    //                 link;

    //             // loop through response and fill variables
    //             angular.forEach(response, function (el) {
    //                 switch (el.key) {
    //                     case 'fb_app_sharing_text':
    //                         facebookBody = el.value;
    //                         break;
    //                     case 'tw_app_sharing_text':
    //                         twitterDesc = el.value;
    //                         break;
    //                     case 'email_app_sharing_subject':
    //                         emailSubject  = el.value;
    //                         // use email subject for Facebook title as well
    //                         facebookTitle = el.value;
    //                         break;
    //                     case 'email_app_sharing_body':
    //                         emailBody = el.value;
    //                         break;
    //                 }
    //             });

    //             // set up share links
    //             link = Environment.appShareLink;

    //             // Use API values if available. If not use Hard Coded Data.
    //             twitterString  = twitterDesc  || AppSettings.contact.share.twitterString;
    //             facebookBody   = facebookBody || AppSettings.contact.share.facebookBody;
    //             mailBody       = mailBody     || AppSettings.contact.share.emailBody;

    //             // replace app store URL
    //             twitterString  = twitterString.replace('${app_store_url}', link);
    //             facebookBody   = facebookBody.replace('${app_store_url}', link);
    //             mailBody       = mailBody.replace('${app_store_url}', link);

    //             // get hashtags
    //             hashTagsObj    = Utils.processHashTags(twitterString);
    //             twitterString  = hashTagsObj.modifiedString;
    //             hashTags       = hashTagsObj.tags;

    //             // reset share information
    //             shareService.reset();

    //             shareService.set({
    //                 id               : 'the app',
    //                 name             : facebookTitle || AppSettings.contact.share.facebookTitle,
    //                 description      : facebookBody,
    //                 mailSubject      : mailSubject || AppSettings.contact.share.emailSubject,
    //                 mailBody         : mailBody,
    //                 twitterDesc      : twitterString,
    //                 link             : link,
    //                 completeLink     : link,
    //                 twitterHashTags  : hashTags || AppSettings.contact.share.twitterHashtags,
    //             });

    //             // all done: resolve!
    //             deferred.resolve();
    //         });

    //         return deferred.promise;
    //     }
    // };

    // return shareService;

    return {};
});