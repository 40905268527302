angular.module('olbg-web-app')

.controller('offersTermsCtrl', [

    '$scope',
    'AppSettings',
    'GeneralSettings',
    'Navigation',
    'Offers',
    '$state',

    function offersTermsCtrl($scope, AppSettings, GeneralSettings, Navigation, Offers, state) {

        /**
         * offer terms page controller         
         */
        
        var that = this;

        this.terms              = decodeURIComponent(Navigation.get(AppSettings.states.offersTerms, "terms"));
        this.show               = true;
        this.emptyMessage       = AppSettings.offersTermsPage.emptyString;
        $scope.sectionTitle     = AppSettings.offersTermsPage.sectionTitle;

        if (!this.terms) {
            state.go(AppSettings.states.offers);
        }

    }
]);