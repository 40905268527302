angular.module('olbg-web-app')

// OFFERS

.factory('Offers', [
    'AppSettings',
    '$q',
    '$filter',
    'Utils',
    'Environment',
    'GeneralSettings',
    'Tracker',
    '$timeout',
    'Scodes',
    'Request',

    function OffersService(AppSettings, $q, $filter, Utils, Environment, GeneralSettings, Tracker, $timeout, Scodes, Request) {

    var offersPromise,
        updatingPromise,
        initPromise,
        currentOffersTime,
        latestCount,
        section               = 'in-offers-tab',
        refreshPromise        = $q.defer(),
        initUrl               = Environment.APIurls.appSettings,
        offersValidity        = AppSettings.offers.offersValidity,
        offersSettingsRefresh = AppSettings.offers.offersSettingsRefresh,
        offersSettingsTime    = 0,
        viewedOffers          = [],
        cachedOffers          = {},
        service               = {},
        refreshingOffers      = false,
        initializing          = false,
        updating              = false,
        gettingOffers         = false,
        reportedOffers        = [],
        visibleOffers         = [],
        impressionsRequests   = [],
        impressionsUrl        = '',
        newOffersCount        = {'count': 0},
        offersURL             = Tracker.appendGeo(Environment.APIurls.offers),
        offerModel            = {'offerId': 0, 'offerViewed': null, 'offerDate': null};

    /**
     * supportsSVG a simple function that checks for SVG support
     * @return void
     */
    function supportsSVG () {
        return document.implementation.hasFeature("http://www.w3.org/TR/SVG11/feature#Image", "1.1");
    }

    service.init = function () {

        if (initializing) {
            return initPromise.promise;
        }

        initPromise = $q.defer();
        initializing = true;

        // if web app > we don't need offers
        if (Utils.olbgMainApi.isWebApp) {
            initPromise.reject();
            return initPromise.promise;
        }

        service.getLocalSettings().then(function (loadedLocal) {

            if (loadedLocal && offersSettingsTime &&
              (new Date().getTime() - offersSettingsTime) < offersSettingsRefresh) {

                // console.log('Serving cached offers settings');
                initPromise.resolve(true);
                initializing = false;

            } else {

                service.getSettingsFromApi().then(function () {
                    initPromise.resolve(true);
                }, function (reason) {
                    initPromise.reject(reason);
                }).finally(function() {
                    initializing = false;
                });
            }
        }, function () {
            service.getSettingsFromApi().then(function () {
                initPromise.resolve(true);
            }, function (reason) {
                initPromise.reject(reason);
            }).finally(function() {
                // initializing = false;
            });
        });

        // initialize offers tracking URL
        Tracker.getInitPromise().then(function () {
            impressionsUrl = Tracker.prepareTrackingURL(Environment.APIurls.adsTracking.replace('{type}', section));
        });

        return initPromise.promise;
    };

    service.getSettingsFromApi = function (err) {

        var ready = $q.defer();

        Tracker.get().then(function (response) {
            var url = Utils.prepareOffersUrl({
                url:      initUrl,
                pageRef:  AppSettings.offers.pageRef,
                memberId: response.userId
            });

            Request.get(url)
                .then(function (response) {

                    // console.log('Offers settings: got them: ', response);

                    offersSettingsTime = new Date().getTime();

                    service.saveLocalSettings(response.data).then(function() {
                        ready.resolve(true);
                    });

                }).catch(function (err) {
                    ready.reject(err);
                    // console.log('ERROR: Get offers settings service failed;');
                });
        });

        return ready.promise;
    };

    service.getLocalSettings = function () {

        var ready = $q.defer();

        GeneralSettings.get(AppSettings.labels.offersSettings)
        .then(function (data) {

            if (angular.isDefined(data) && angular.isDefined(data.name)) {
                data = angular.fromJson(data.name);

                if (data.length) {
                    angular.forEach(data, function(value, key) {
                        if (angular.isDefined(value.name)&&angular.isDefined(value.value)) {
                            if (value.name == 'offersSettingsRefresh') {
                                offersSettingsRefresh = value.value;
                            }
                            if (value.name == 'offersSettingsTime') {
                                offersSettingsTime = value.value;
                            }
                            if (value.name == 'offersValidity') {
                                offersValidity = value.value;
                            }
                        }
                    });
                }
            }

            // console.log ('Finished offers settings refresh: next refresh: ' + offersSettingsRefresh + '; last refresh: ' + offersSettingsTime + '; offers are new for: ' + offersValidity);
            ready.resolve(true);

        }, function(reason){
            // console.log ('Offers settings refresh failed ', reason);
            ready.resolve(false);
        });

        return ready.promise;
    };

    service.saveLocalSettings = function (data) {

        var ready       = $q.defer(),
            doc         = {},
            settings    = [];

        if (angular.isDefined(data.settings)) {
            if (data.settings.length) {
                for (i = 0; i < data.settings.length; i++) {
                    switch (data.settings[i].key) {
                        case 'data_refresh_frequency':
                            // save value in ms (received in days)
                            offersSettingsRefresh = data.settings[i].value * (AppSettings.offers.offersValidity * 2);
                            break;
                        case 'timeframe_for_new_offers':
                            // save value in ms (received in days)
                            offersValidity = data.settings[i].value * (AppSettings.offers.offersValidity * 2);
                            break;
                    }
                }
            }
        }

        settings.push({name: 'offersSettingsRefresh', value: offersSettingsRefresh});
        settings.push({name: 'offersSettingsTime',    value: offersSettingsTime});
        settings.push({name: 'offersValidity',        value: offersValidity});

        doc[AppSettings.labels.offersSettings] = angular.toJson(settings);
        GeneralSettings.store(doc).then(function (response) {
            // console.log('Saved offers settings in local storage.');
            ready.resolve(response);
        }).catch(function(reason){
            // console.log('Failed to save offers settings in local storage: ' + reason);
            ready.resolve(reason);
        });

        return ready.promise;
    };

    service.getOffers = function (opts) {

        if (gettingOffers) {
            return offersPromise.promise;
        }

        opts = angular.extend({
            getCached: true
        }, opts);

        offersPromise = $q.defer();
        gettingOffers = true;

        // wait until [S-CODES] is done!
        Scodes.init().then(function () {
            if (angular.isDefined(currentOffersTime) &&
              (new Date().getTime() - currentOffersTime) < AppSettings.offers.getNewDataEvery &&
              opts.getCached &&
              angular.isDefined(cachedOffers) &&
              cachedOffers) {

                offersPromise.resolve(cachedOffers);
                gettingOffers = false;
            } else {

                // get the offers
                Tracker.get().then(function (response) {

                    var url = Utils.prepareOffersUrl({
                        url: offersURL,
                        pageRef: 'offersPage',
                        useSVG: supportsSVG(),
                        memberId: response.userId
                    });

                    Request.get(url)
                        .then(function (response) {
                            // console.log('Offers: got them', response.data);
                            currentOffersTime = new Date().getTime();
                            offersPromise.resolve(response.data);

                            // show tab if offers and Google Play
                            try {
                                if (Utils.olbgMainApi.isGooglePlay && response.data[section] && response.data[section].length) {
                                    Utils.olbgMainApi.showTab('offers');
                                }
                            } catch(e) {}

                        }).catch(function (err) {
                            offersPromise.reject(err);
                            // console.log('ERROR: Offers service failed;');
                        }).finally(function () {
                            gettingOffers = false;
                        });
                });
            }

        });

        return offersPromise.promise;
    };

    service.getOffersModel = function () {

        return cachedOffers;
    };

    service.updateOffers = function () {

        if (updating) {
            return updatingPromise.promise;
        }

        updating = true;
        updatingPromise = $q.defer();

        this.getOffers()
            .then(function (serviceOffers) {
                angular.extend(cachedOffers, serviceOffers);
                service.refreshViewedOffers().then(function (data) {
                    service.sendSeenOffersIds();
                    service.countNewOffers();
                    service.sortOffersList();
                    updatingPromise.resolve();
                });
            })
            .catch(updatingPromise.reject)
            .finally(function () {
                updating = false;
            });

        return updatingPromise.promise;
    };

    service.sortOffersList = function() {

        // only sort if there are new bets!
        if (cachedOffers[section].length && latestCount) {
            var newOrder = cachedOffers[section].filter(function (offer) {
                                return viewedOffers.indexOf(offer.id) < 0;
                            }).concat(cachedOffers[section].filter(function (offer) {
                                return viewedOffers.indexOf(offer.id) >= 0;
                            }));
            cachedOffers[section].splice(0, cachedOffers[section].length);

            angular.extend(cachedOffers[section], newOrder);
        }
    };

    service.countNewOffers = function() {

        var newCount = cachedOffers[section].filter(function (offer) {
            return viewedOffers.indexOf(offer.id) < 0;
        }).length;

        // update badge!
        if (latestCount != newCount) {
            latestCount = newCount;
            angular.extend(newOffersCount, {'count': newCount});
            document.addEventListener('deviceready', function () {
                if (window.FirebasePlugin) {
                    window.FirebasePlugin.setBadgeNumber(newCount);
                }
            });
        }
    };

    service.refreshViewedOffers = function () {

        if (refreshingOffers) {
            return refreshPromise.promise;
        }

        refreshingOffers = true;

        GeneralSettings.get(AppSettings.labels.offersDB)
        .then(function (data) {

            if (angular.isDefined(data) && angular.isDefined(data.name)) {
                data = angular.fromJson(data.name);
                viewedOffers = Utils.uniqueArray(angular.extend(viewedOffers, data));
            }
            // console.log ('Finished offers refresh', viewedOffers);
            refreshPromise.resolve(true);
        })
        .catch(function(reason){
            // console.log ('Offers refresh failed ', reason);
            refreshPromise.resolve(false);
        })
        .finally(function () {
            refreshingOffers = false;
        });

        return refreshPromise.promise;
    };

    service.addViewedOffers = function (offersList) {

        var ready = $q.defer(),
            doc = {};

        viewedOffers = Utils.uniqueArray(viewedOffers.concat(offersList));

        doc[AppSettings.labels.offersDB] = angular.toJson(viewedOffers);
        GeneralSettings.store(doc).then(function (response) {
            console.log('Saved offers in local storage.', viewedOffers);
            ready.resolve(viewedOffers);
        }, function(reason){
            // console.log('Failed tp save offers in local storage: ' + re
            ready.reject(reason);
        });

        return ready.promise;
    };

    service.getNewOffersCount = function () {
        return newOffersCount;
    };

    /**
     * send the impressions to the server in line up.
     * @param  {array} offerIds an array with the offers ids
     * @return {promise}
     */
    service.sendImpressions = function (offerIds) {

        var deferred = $q.defer(),
            len      = impressionsRequests.length,
            uniqueIDs;

        function sendRequest(deferred) {

            var url;

            // send unique requests
            if (offerIds.length <= reportedOffers.length) {
                return;
            }

            uniqueIds = angular.copy(offerIds);

            uniqueIds.splice(0, reportedOffers.length);

            reportedOffers = offerIds;

            // report ->
            url = impressionsUrl + '&ad_id=' + angular.toJson(uniqueIds);

            Request.get(url)
                .finally(deferred.resolve);

            // send the seem offer ids
            service.sendSeenOffersIds();
        }

        // see if there's a previous operation
        if (len) {
            impressionsRequests[len-1].promise.finally(function () {
                sendRequest(deferred);
            });
        } else {
            sendRequest(deferred);
        }

        // add this operation ->
        impressionsRequests.push(deferred);

        return deferred.promise;
    };

    service.sendSeenOffersIds = function () {
        var reportIds,
            url = Environment.APIurls.seenOffers;
        if (viewedOffers.length) {
            reportIds = service.getOffersModel()[section].filter(function (offer) {
                return viewedOffers.indexOf(offer.id) >= 0;
            }).map(function (offer) { return offer.id; });
            Request.post({
                url: url,
                obj: {
                    offers_id: reportIds
                }
            })
                .then(function (response) {
                    console.log('Offers: seen', response);
                });
        }
    };

    // make it public
    return service;
}]);