var mod;

mod = angular.module('infinite-scroll', []);

mod.value('THROTTLE_MILLISECONDS', null);

mod.directive('infiniteScroll', [
  '$rootScope', '$window', '$interval', 'THROTTLE_MILLISECONDS', '$compile', '$timeout', function($rootScope, $window, $interval, THROTTLE_MILLISECONDS, $compile, $timeout) {
    return {
      scope: {
        infiniteScroll: '&',
        infiniteScrollContainer: '=',
        infiniteScrollDistance: '=',
        infiniteScrollDisabled: '=',
        infiniteScrollUseDocumentBottom: '=',

        // added by Alvaro Casanova [alvaro@olbg.com]
        infiniteScrollParent: '=',
        infiniteScrollNgRepeat: '=',
        infiniteScrollPullEnabled: '=',
        infiniteScrollPull: '&',
        infiniteScrollPullLoader: '=',
        infiniteScrollPullRefreshPage: '=?'
      },
      link: function(scope, elem, attrs) {

        var changeContainer, checkWhenEnabled, container, handleInfiniteScrollContainer, handleInfiniteScrollDisabled, handleInfiniteScrollDistance, handleInfiniteScrollUseDocumentBottom, handler, height, immediateCheck, offsetTop, pageYOffset, scrollDistance, scrollEnabled, throttle, useDocumentBottom, windowElement, fillTimer, newHeight,

            // [PULL TO REFRESH]
            pullHandler,
            pullToRefresh,
            shouldPull,
            resetPull,
            pullLoader,
            pullLoaderEl,
            pullTempDisabled    = false,
            pullMargin          = 58, // required height to do the update
            pullIsActive        = scope.infiniteScrollPullEnabled,
            touchStartY         = 0,
            lastScrollTop       = 0,
            isAtTop             = false,
            pullToRefreshActive = false,
            refreshPage         = angular.isDefined(scope.infiniteScrollPullRefreshPage) ? scope.infiniteScrollPullRefreshPage : false;

        windowElement = angular.element($window);
        scrollDistance = null;
        scrollEnabled = null;
        checkWhenEnabled = null;
        container = null;
        immediateCheck = true;
        useDocumentBottom = false;
        height = function(elem) {
          elem = elem[0] || elem;
          if (isNaN(elem.offsetHeight)) {
            return elem.document.documentElement.clientHeight;
          } else {
            return elem.offsetHeight;
          }
        };
        offsetTop = function(elem) {
          if (!elem[0].getBoundingClientRect || elem.css('none')) {
            return;
          }
          return elem[0].getBoundingClientRect().top + pageYOffset(elem);
        };
        pageYOffset = function(elem) {
          elem = elem[0] || elem;
          if (isNaN(window.pageYOffset)) {
            return elem.document.documentElement.scrollTop;
          } else {
            return elem.ownerDocument.defaultView.pageYOffset;
          }
        };
        handler = function() {
          var containerBottom, containerTopOffset, elementBottom, remaining, shouldScroll;

          if (container === windowElement) {
            containerBottom = height(container) + pageYOffset(container[0].document.documentElement);
            elementBottom = offsetTop(elem) + height(elem);
          } else {
            containerBottom = height(container);
            containerTopOffset = 0;
            if (offsetTop(container) !== void 0) {
              containerTopOffset = offsetTop(container);
            }
            elementBottom = offsetTop(elem) - containerTopOffset + height(elem);
          }
          if (useDocumentBottom) {
            elementBottom = height((elem[0].ownerDocument || elem[0].document).documentElement);
          }

          remaining = elementBottom - containerBottom;

          // ADDED: by Alvaro Casanova (yeahstyle@gmail.com)
          if (remaining >= 0 && angular.isDefined(fillTimer) && fillTimer.$$state.status === 0) {
            // console.log('Infinite Scrolling: screen has been filled. Timer Killed.');
            $interval.cancel(fillTimer);
          }

          shouldScroll = remaining <= height(container) * scrollDistance + 1;
          if (shouldScroll) {
            checkWhenEnabled = true;
            if (scrollEnabled) {
              if (scope.$$phase || $rootScope.$$phase) {
                return scope.infiniteScroll();
              } else {
                return scope.$apply(scope.infiniteScroll);
              }
            }
          } else {
            return checkWhenEnabled = false;
          }
        };

        // [PULL TO REFRESH]
        pullHandler = function (event) {

          if (pullToRefreshActive || !isAtTop || pullTempDisabled || !pullLoaderEl) {
            return;
          }

          var te            = event.originalEvent.changedTouches[0].clientY,
              down          = touchStartY < te, // see if scrolling down
              elementScroll = container[0].scrollTop;

          if (!down) {
            // pullTempDisabled = true; // only allow pulling if user is at top and starts pulling down
            return;
          }

          if (elementScroll <= 0 && lastScrollTop <= 0 && down) {

            event.preventDefault(); // prevent scrolling further!

            // remove transition temporarily
            pullLoaderEl.addClass('olbg-pull-loader--transitions-disabled');

            pullLoaderEl.css({
              height: (te - touchStartY) + 'px',
              opacity: (te - touchStartY) / 100
              // opacity: .3
            });
          }

          lastScrollTop = elementScroll;
        };

        recordPullPos = function (event) {
          touchStartY = event.originalEvent.touches[0].clientY;
          if (container[0].scrollTop <= 0) {
            isAtTop = true;
          } else {
            isAtTop = false;
          }
        };

        shouldPull = function () {

          if (!pullLoaderEl) {
            return;
          }

          pullTempDisabled = false;

          // make sure to enable transitions again
          pullLoaderEl.removeClass('olbg-pull-loader--transitions-disabled');

          // check the height of the loader
          if (pullLoaderEl.height() > pullMargin) {
            pullToRefresh();
          } else {
            resetPull();
          }
        };

        pullToRefresh = function () {

          if (angular.isDefined(attrs.infiniteScrollPull)) {

            if (!pullToRefreshActive) {

              pullToRefreshActive = true;

              if (pullLoader) { pullLoader.addClass('olbg-pull-loader--visible'); }

              // if loading involves refreshing the page
              if (refreshPage) {
                resetPull(function () {
                  $timeout(function () { // force digest
                    scope.infiniteScrollPull();
                  }, 0);
                });
              } else {
                $timeout(function () { // force digest
                  scope.infiniteScrollPull().finally(function () {
                    resetPull();
                  });
                }, 0);
              }
              scope.$digest();
            }
          }
        };

        resetPull = function(cb) {
          if (pullLoader) { pullLoader.removeClass('olbg-pull-loader--visible'); }
          pullLoaderEl.removeAttr('style');
          pullLoaderEl.one('transitionend', cb);
          pullToRefreshActive = false;
        }

        throttle = function(func, wait) {
          var later, previous, timeout;
          timeout = null;
          previous = 0;
          later = function() {
            var context;
            previous = new Date().getTime();
            $interval.cancel(timeout);
            timeout = null;
            func.call();
            return context = null;
          };
          return function() {
            var now, remaining;
            now = new Date().getTime();
            remaining = wait - (now - previous);
            if (remaining <= 0) {
              clearTimeout(timeout);
              $interval.cancel(timeout);
              timeout = null;
              previous = now;
              return func.call();
            } else {
              if (!timeout) {
                return timeout = $interval(later, remaining, 1);
              }
            }
          };
        };
        if (THROTTLE_MILLISECONDS != null) {
          handler = throttle(handler, THROTTLE_MILLISECONDS);
        }
        scope.$on('$destroy', function() {

          // [PULL TO REFRESH]
          if (pullIsActive) {
            // container.unbind('scroll', pullHandler);
            container.unbind('touchstart', recordPullPos);
            container.unbind('touchmove', pullHandler);
            container.unbind('touchend', shouldPull);
          }

          return container.unbind('scroll', handler);
        });
        handleInfiniteScrollDistance = function(v) {
          return scrollDistance = parseFloat(v) || 0;
        };
        scope.$watch('infiniteScrollDistance', handleInfiniteScrollDistance);
        handleInfiniteScrollDistance(scope.infiniteScrollDistance);
        handleInfiniteScrollDisabled = function(v) {
          scrollEnabled = !v;
          if (scrollEnabled && checkWhenEnabled) {
            checkWhenEnabled = false;
            return handler();
          }
        };
        scope.$watch('infiniteScrollDisabled', handleInfiniteScrollDisabled);
        handleInfiniteScrollDisabled(scope.infiniteScrollDisabled);
        handleInfiniteScrollUseDocumentBottom = function(v) {
          return useDocumentBottom = v;
        };
        scope.$watch('infiniteScrollUseDocumentBottom', handleInfiniteScrollUseDocumentBottom);
        handleInfiniteScrollUseDocumentBottom(scope.infiniteScrollUseDocumentBottom);

        changeContainer = function(newContainer) {
          if (container != null) {
            container.unbind('scroll', handler);

            // [PULL TO REFRESH]
            if (pullIsActive) {
              // container.unbind('scroll', pullHandler);
              container.unbind('touchstart', recordPullPos);
              container.unbind('touchmove', pullHandler);
              container.unbind('touchend', shouldPull);
            }
          }

          container = newContainer;

          if (newContainer != null) {

            // [PULL TO REFRESH]
            if (pullIsActive) {

              if (scope.infiniteScrollPullLoader !== false) {

                pullLoader = elem.find('olbg-pull-loader');

                // if no pull loader UI > create it
                if (!pullLoader.length) {
                  pullLoader = $compile('<olbg-pull-loader></olbg-pull-loader>')(scope);
                  elem.prepend(pullLoader);
                }

                pullLoaderEl = pullLoader.find('.olbg-pull-loader');
              } else {
                // remove pullLoader if present >
                elem.find('olbg-pull-loader').remove();
              }

              // container.bind('scroll', pullHandler);
              container.bind('touchstart', recordPullPos);
              container.bind('touchmove', pullHandler);
              container.bind('touchend', shouldPull);

            } else {
              // remove pullLoader if present >
              elem.find('olbg-pull-loader').remove();
            }

            return container.bind('scroll', handler);
          }
        };

        changeContainer(windowElement);

        handleInfiniteScrollContainer = function(newContainer) {

          if ((newContainer == null) || newContainer.length === 0) {
            return;
          }
          if (newContainer instanceof HTMLElement) {
            newContainer = angular.element(newContainer);
          } else if (typeof newContainer.append === 'function') {
            newContainer = angular.element(newContainer[newContainer.length - 1]);
          } else if (typeof newContainer === 'string') {
            newContainer = angular.element(document.querySelector(newContainer));
          }
          if (newContainer != null) {
            return changeContainer(newContainer);
          } else {
            throw new Exception("invalid infinite-scroll-container attribute.");
          }
        };

        scope.$watch('infiniteScrollContainer', handleInfiniteScrollContainer);

        handleInfiniteScrollContainer(scope.infiniteScrollContainer || []);

        // CHANGED: by Alvaro Casanova (yeahstyle@gmail.com)
        if (scope.infiniteScrollParent != null && scope.infiniteScrollParent) {
          changeContainer(angular.element(elem.parent()));
        }

        // ADDED: by Alvaro Casanova (yeahstyle@gmail.com)
        if (angular.isDefined(scope.infiniteScrollNgRepeat) && scope.infiniteScrollNgRepeat) {

          // get initial height
          newHeight = height(elem);

          fillTimer = $interval(function () {

            var currentHeight = height(elem);
            // 1. check that element height has changed    // done by handler
            // 2. check that it surpases container height  // done by handler
            if (currentHeight !== newHeight) {
              newHeight = currentHeight;
              handler();
            }
          }, 50);
        }

        if (attrs.infiniteScrollImmediateCheck != null) {
          immediateCheck = scope.$eval(attrs.infiniteScrollImmediateCheck);
        }
        return $interval((function() {
          if (immediateCheck) {
            return handler();
          }
        }), 0, 1);
      }
    };
  }
]);

mod.directive('olbgPullLoader', [function () {
  return {
    restrict: 'E',
    scope: {
      spinner: '=?',
      pullToRefreshText: '=',
      refreshingText: '='
    },
    link: function (scope) {
      // by default: use spinner
      scope.spinner = angular.isDefined(scope.spinner) ? scope.spinner : true;
    },
    template: '<div class="olbg-pull-loader"><div class="olbg-pull-loader__content">' +
              '<div ng-class="{\'olbg__spinner--centered\' : !pullToRefreshText, \'olbg__spinner--static\' : !spinner}" class="olbg__spinner"></div>' +
              '<p class="olbg-pull-loader__text"><span class="olbg-pull-loader__text--refresh">{{::pullToRefreshText}}</span><span class="olbg-pull-loader__text--updating">{{::refreshingText}}</span></p></div></div>'
  }
}]);