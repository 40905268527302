angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetStatus', ['$rootScope', 'AppSettings', function olbgBetStatusDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        scope: {
            status: '='
        },
        templateUrl: AppSettings.templates['bet-status'],
        replace: true
    };
}]);