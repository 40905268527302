angular.module('olbg-web-app')

    .directive('improvedNumberInput', ['$timeout', function ($timeout) {

        return {
            require: '?ngModel',
            link: function(scope, element, attrs, ngModelCtrl) {

                element.attr('lang', 'en');

                var maxValue = attrs.improvedMax,
                    lastValidValue = '';

                if(!ngModelCtrl) {
                    return;
                }


                ngModelCtrl.$parsers.push(function (val) {

                    // how values are saved to the model
                    var clean, decimalCheck;

                    // first: convert value to string
                    val = angular.isUndefined(val) || val === null ? '' : val + '';
                    clean = val.replace(/[^0-9\.]/g, '');
                    decimalCheck = clean.split('.');

                    if(!angular.isUndefined(decimalCheck[1])) {
                        decimalCheck[1] = decimalCheck[1].slice(0,2);
                        clean = decimalCheck[0] + '.' + decimalCheck[1];
                    }

                    if (val !== clean) {
                        ngModelCtrl.$setViewValue(clean);
                        ngModelCtrl.$render();
                    }
                    return '' + clean;
                });

                ngModelCtrl.$formatters.push(function (val) {
                    // how values appear in the view!
                    return parseFloat(val, 10);
                });

                element.bind('keypress', function (event) {

                    var hasComma = element.val().indexOf('.') > 0 || element.val().indexOf(',') > 0;

                    // accept only numbers! (and only one comma: either period or comma)
                    if ((event.keyCode < 48 ||
                         event.keyCode > 57) && event.keyCode !== 46 && event.keyCode !== 44 ||
                         (hasComma && (event.keyCode === 46 || event.keyCode === 44))) {
                        event.preventDefault();
                    }
                });

                element.bind('keyup', function (event) {

                    var value;

                    // if user enters a period first -> prepend a cero
                    // if (!element.val() && (element.val() !== 0) && (event.keyCode === 190 || event.keyCode === 188)) {
                    //     // element.val('0.');
                    // }

                    value = parseFloat(element.val(), 10);

                    if (angular.isDefined(maxValue) && value > maxValue) {
                        element.val(lastValidValue);
                    } else {
                        lastValidValue = value;
                    }
                });

                // prevent scrolling behavior
                element.bind('focus.disableScroll', function () {

                    var el = angular.element(this);

                    if (Modernizr.touch) {
                        return;
                    }

                    el.bind('mousewheel.disableScroll', function () {

                        el.blur();

                        $timeout(function () {
                            el.focus();
                        },10);
                    });
                });

                element.bind('blur', function () {
                    angular.element(this).off('mousewheel.disableScroll');
                });
            }
        };
    }]);