(function (appInfo) {

    angular.module('olbg-web-app')

    // BOOKIES

    .factory('Bookies', [
        'Betslip',
        'AppSettings',
        '$q',
        'Utils',
        'Tracker',
        'Environment',
        'GeneralSettings',
        'Preferences',
        '$filter',
        'Request',

        function BookiesService(Betslip, AppSettings, $q, Utils, Tracker, Environment, GeneralSettings, Preferences, $filter, Request) {

        var cachedOffers,
            offersPromise,
            bookiesWithAds,
            availableBookiesPromise,
            views                 = AppSettings.bookiesSelector.pageViews, // the number of page views until we show bookies account
            viewsDB               = AppSettings.labels.bookieAccountsPageViewsDB,
            gotBookieSelector     = false,
            offersURL             = Environment.APIurls.ads.replace('{type}', 'in-bookie-selector'),
            gettingOffers         = false,
            gettingBookieSelector = false,
            service = {
                getAll: Betslip.getAvailableBookies, // for now just use Betslip.getAvailableBookies
            },
            currentAvailableBookies = [],
            currentBookiesTime,
            setBookies = false;

        function addSortOdds(model) {
            angular.forEach(model, function (bookie) {
                if (angular.isDefined(bookie.oddsDecimalValue)) {
                    bookie.sortOdds = bookie.oddsDecimalValue === 'SP' ? 0 : parseFloat(bookie.oddsDecimalValue.replace(',', ''));
                }
            });
        }

        function addBookiesAccountView() {

            /**
             * we should show Bookies Account only
             * after the number of app uses defined in:
             * AppSettings.bookiesSelector.pageViews
             */
            var obj = {};

            GeneralSettings.get(viewsDB).then(function (data) {
                if (!data) {

                    obj[viewsDB] = 1;

                    // this data doesn't exists yet
                    GeneralSettings.store(obj);

                } else {
                    obj[viewsDB] = parseInt(data.name);
                    obj[viewsDB]++;
                    GeneralSettings.store(obj);
                    // console.log('Bookies Account: page views ->', obj[viewsDB]);
                }
            });
        }

        service.getForCurrentBets = function (opts) {

            /**
             * Get current available bookies for the selections
             * in ACCA currently in server.
             */

            opts = angular.extend({
                force: false
            }, opts);

            if (gettingBookieSelector ||
                gotBookieSelector &&
                angular.isDefined(currentBookiesTime) &&
                (new Date().getTime() - currentBookiesTime) < AppSettings.bookiesSelector.getNewDataEvery &&
                !opts.force &&
                !Betslip.shouldGetNewBookiesSelector()) {
                return availableBookiesPromise.promise;
            }
            availableBookiesPromise = $q.defer();
            gettingBookieSelector   = true;

            // wait until Betslip and Tracker are initialized

            $q.all([Betslip.init(), Tracker.getInitPromise(), Betslip.processPendingOperations()]).then(function () {
                Betslip.getHeaders().then(function (headers) {

                    Tracker.get().then(function (trackerData) {

                        // extend headers with app id
                        headers.trackingAppId = trackerData.appId;

                        var url = Utils.prepareBookiesLink({
                            memberId: trackerData.userId,
                            url:      Environment.APIurls.bookiesSelector,
                            pageRef:  AppSettings.bookiesSelector.registerData.pageRef
                        });

                        Request.get(url, {headers: headers})
                            .then(function (response) {

                                var bookies = response.data;

                                gotBookieSelector = true;

                                // console.log('Bookies Selector: got the available bookies object.', bookies);

                                // Remove from model bookies with no selections
                                bookies.bookieSelector = bookies.bookieSelector.filter(function (bookie) {
                                    return bookie.validSelections > 0;
                                });

                                currentAvailableBookies.splice(0, currentAvailableBookies.length);
                                angular.extend(currentAvailableBookies, bookies.bookieSelector);
                                addSortOdds(currentAvailableBookies);
                                availableBookiesPromise.resolve(currentAvailableBookies);
                                currentBookiesTime = new Date().getTime();
                                Betslip.setBookiesSelectorFlag(false); // we already got a new bookies selector object


                            })
                            .catch(function (err) {
                                availableBookiesPromise.reject(err);
                            })
                            .finally(function () {
                                gettingBookieSelector = false;
                            });
                    });

                }).catch(function () {
                    gettingBookieSelector = false;
                });
            }).catch(function () {
                gettingBookieSelector = false;
            });

            return availableBookiesPromise.promise;
        };

        // service.checkLoggedInBookies = function () {
        //     /**
        //      * checkLoggedInBookies
        //      * returns number of bookies user is logged in to
        //      */

        //     var defer = $q.defer(),
        //         loggedInBookies = [],
        //         loggedInCheckPromises = [];

        //     Betslip.getAvailableBookies().then(function (response) {
        //         angular.forEach(response.bookies, function (bookie) {
        //             var promise = Betslip.isLoggedIn(bookie.bookieId);
        //             promise.then(function (response) {
        //                 if (response) {
        //                     loggedInBookies.push(bookie.bookieId);
        //                 }
        //             });
        //             loggedInCheckPromises.push(promise);
        //         });

        //         $q.all(loggedInCheckPromises).then(function () {
        //             defer.resolve(loggedInBookies);
        //         });
        //     });

        //     // when all Login promises have been resolved:
        //     return defer.promise;
        // };

        service.getBookieAccounts = function () {

            var bookieAccounts = [],
                ready = $q.defer();

            Preferences.get(['bookies'])
                .then(function (response) {
                    bookieAccounts = response.bookies;
                    setBookies = true;
                    ready.resolve(bookieAccounts);
                });

            return ready.promise;
        };

        service.getBookieAccountsViews = function () {
            return GeneralSettings.get(viewsDB);
        };

        service.storeBookiesAccounts = function (data) {
            var deferred    = $q.defer(),
                accountsArr = data.ids;

            service.getBookiesWithAds()
                .then(function(response) {

                    var bookiesInfo = response.bookies.concat(response.optional_bookies);

                    Preferences.save({
                        listName: 'bookies',
                        list:     accountsArr
                    })
                    .then(function () {

                        var bookiesNames = [];

                        // console.log('Bookies Accounts: Stored bookies.');

                        // track ->
                        Tracker.userBookiesTracking(accountsArr);

                        // send event (for at least one bookie)
                        if (accountsArr.length) {
                            angular.forEach(bookiesInfo, function (b) {
                                if (accountsArr.indexOf(b.bookieId + '') >= 0) {
                                    bookiesNames.push($filter('capitalize')(b.bookieName));
                                }
                            });
                            // eventsInfo.action += data.from ? ' - via ' + data.from : '';
                            // eventsInfo.label   = Tracker.joinGALabels(bookiesNames.sort());
                            // Tracker.sendEvent(eventsInfo);

                            Tracker.setUserProperty({ property: AppSettings.tracking.ga.userProperties.setBookies.name, value: true });
                        }

                        Utils.olbgMainApi.dispatch({
                            name: AppSettings.bookiesAccounts.savedEventName
                        });

                        deferred.resolve();
                    });

                });

            return deferred.promise;
        };

        service.userSetBookies = function () {
            return setBookies;
        };

        service.isGettingBookiesSelector = function () {
            return gettingBookieSelector;
        };

        service.getAllBookies = function () {

            // var code       = appInfo.userGeoCode || '',
            var code       = appInfo.userGeoCode || 'ES', // DEBUG
                deviceType = appInfo.pwa ? 'desktop' : 'mobile',
                url        = Environment.APIurls.getAllBookies
                                .replace('{:DEVICE}', deviceType)
                                .replace('{:CODE}', code);

            return Request.post({
                url: url,
                obj: {},
                headers: { 'clientId' : 'bcmsapi' }
            });
        };

        /*----------  My Bookies Accounts  ----------*/
        service.getBookiesWithAds = function () {

            /**
             * IMPORTANT
             * bookies with ads doesn't use ads at all
             * but don't want to introduce any bugs at this time
             * changing the name of this method
             */


            if (angular.isUndefined(bookiesWithAds)) {

                bookiesWithAds = $q.defer();

                Request.get(Environment.APIurls.bookiesWithAds)
                    .then(function (response) {
                        bookiesWithAds.resolve(response.data);
                    })
                    .catch(function () {
                        bookiesWithAds.reject();
                        bookiesWithAds = undefined;
                    });
            }

            return bookiesWithAds.promise;
        };

        // put in pub service
        service.getOffers = function (opts) {

            if (gettingOffers) {
                return offersPromise.promise;
            }

            opts = angular.extend({
                getCached: false
            }, opts);

            offersPromise = $q.defer();
            gettingOffers = true;

            if (opts.getCached &&
                angular.isDefined(cachedOffers) &&
                cachedOffers) {
                // we have chached offers
                offersPromise.resolve(cachedOffers);
                gettingOffers = false;
            } else {
                Request.get(offersURL.replace('{:pid}', 'in-bookie-selector'))
                    .then(function (response) {
                        // console.log('Bookies Selector: got offers', response);
                        cachedOffers = response.data;
                        offersPromise.resolve(response.data);
                    }).catch(function (err) {
                        offersPromise.reject(err);
                    }).finally(function () {
                        gettingOffers = false;
                    });
            }

            return offersPromise.promise;
        };

        addBookiesAccountView(); // each time the Bookies service is started

        // make it public
        return service;
    }]);

})(window.OLBG_APP_META);