angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgOffer', ['$rootScope', 'AppSettings', 'Offers', '$sce', '$compile', 'Navigation', '$state', 'Utils', '$stateParams', 'Tracker', function olbgOfferDirective($rootScope, AppSettings, Offers, $sce, $compile, Navigation, $state, Utils, $stateParams, Tracker) {

    function ctrl($scope, offer) {

        var thisOffer = this;

        // get the offer itself from the scope
        this.offerContent = $scope.offer;

        this.openTerms = function () {
            Navigation.store(AppSettings.states.offersTerms, this.offerContent.terms.replace(/\+/g, '%20'), 'terms');
            $state.transitionTo(AppSettings.states.offersTerms, $stateParams, { reload: true, inherit: false, notify: true });
        };
    }

    return {
        restrict: 'AE',
        scope: {
            offer: '=' // this will get the value passed via attribute.
        },
        controller: ['$scope', ctrl],
        controllerAs: 'Offer',
        link: function (scope, element) {
            var decodedHtml,
                termsButtonTemplate = '<button class="button--large--cta olbg__button olbg__button--neutral offer__terms-button" ' +
                                      'ng-click="Offer.openTerms()" aria-label="' +
                                      AppSettings.offers.termsButtonLabel +
                                      '">' + AppSettings.offers.termsButtonLabel + '</button>',
                offerListEl = element.find('.offer');

            if (angular.isDefined(scope.offer.html_content)) {
                decodedHtml = decodeURIComponent(scope.offer.html_content.replace(/\+/g, '%20'));

                // add terms button
                if (angular.isDefined(scope.offer.terms) && scope.offer.terms) {
                    decodedHtml = decodedHtml.replace(new RegExp(AppSettings.offers.termsPlaceholder, 'g'), termsButtonTemplate);
                }

                offerListEl.append($compile(decodedHtml)(scope));
            }

            // send GA event
            element.on('click', '.mobile-btn-offer-link', function () {                
                var eventInfo   = angular.copy(AppSettings.tracking.ga.events.freeBetsAd),
                    gaEventInfo = angular.copy(eventInfo.ga);
                eventInfo.params.bookie = scope.offer.bookie;
                gaEventInfo.action = gaEventInfo.action.replace('${BOOKIE}', eventInfo.params.bookie);
                Tracker.sendEvent(eventInfo, gaEventInfo);

                // count user property > clicked free bet
                Tracker.setUserProperty({ property: AppSettings.tracking.ga.userProperties.clickedFreeBet.name });

                // track appsflyer
                Tracker.trackOfferAppsflyer(scope.offer.id, scope.offer.url, 'offers_page');
            });
        },
        template: '<article class="offer"></article>'
    };
}]);