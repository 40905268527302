angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgLoader', [function olbgLoaderDirective() {
    return {
        restrict: 'A',
        scope: {
            olbgLoader: '=',
            olbgLoaderFade: '=?'
        },
        link: function (scope, element, attrs) {

            var el,
                hideIt = false;

            function toggleDisplay(el) {
                if (hideIt) {
                    el[0].style.display = "none";
                }
            }

            if (scope.olbgLoaderFade) {
                element.addClass('olbg-loader--fade');
            }

            element.addClass('olbg-loader--contains-loader');
            element.prepend('<div class="olbg-loader"><span class="load-module__spinner"></span></div>');

            el = element.find('.olbg-loader');

            scope.$watch('olbgLoader', function (value) {
                if (!value) {
                    el.addClass('olbg-loader--show');
                    el[0].style.display = "block";
                    hideIt = false;

                } else {

                    el.removeClass('olbg-loader--show');
                    hideIt = true;

                }
            });

            // IDEALLY: NORMALIZE
            // http://stackoverflow.com/questions/24347393/how-can-angular-listen-event-for-css-transition-end-where-ng-style-triggers-the
            // listen to transition end!
            el.on('webkitTransitionEnd', function () {
                toggleDisplay(el);
            });
            el.on('oTransitionEnd', function () {
                toggleDisplay(el);
            });
            el.on('transitionend', function () {
                toggleDisplay(el);
            });
        }
    };
}])


.directive('olbgDotsLoader', [function olbgDotsLoaderDirective() {
    return {
        restrict: 'E',
        scope: {
            loading: '='
        },
        template: '<span class="olbg-dots-loader" ng-show="loading"><i class="olbg-dots-loader__dot"></i><i class="olbg-dots-loader__dot"></i><i class="olbg-dots-loader__dot"></i></span>'
    };
}]);