angular.module('olbg-web-app')

    .controller('shareUrlCtrl', function (AppSettings, $rootScope, $scope, $location, Share, UrlShortener) {

        /**
         * NOT USING ANYMORE
         */


        // var shortUrl,
        //     settings = AppSettings,
        //     currentUrl  = $location.absUrl(),
        //     sharingData = Share.get(),
        //     facebookMsg = settings.tipDetail.share.facebookShared;

        // // set namespace
        // $scope.share = {};

        // // wait until short URL is ready to show screen
        // $scope.share.shareReady = false;

        // // set title
        // $scope.sectionTitle = settings.shareUrl.sectionTitle;

        // UrlShortener.shorten(currentUrl).then(function (response) {

        //     // URL Shortened!
        //     var shortUrl = response;

        //     // iterate through sharing data and replace instances
        //     // of current link
        //     angular.forEach(sharingData, function (stringEl, index) {
        //         if (index !== 'completeLink') {
        //             if (angular.isDefined(stringEl)) {
        //                 sharingData[index] = stringEl.replace(currentUrl, shortUrl);
        //             }
        //         }
        //     });

        //     // set public sharing data
        //     $scope.shareData = sharingData;

        //     // finally show sharing options
        //     $scope.share.shareReady = true;
        // }).catch($scope.closeModal);

        // // show feedback
        // $scope.share.facebookFeedback = function () {
        //     $scope.displayMessage(facebookMsg, 'success');
        //     // $rootScope.displayMessage(facebookMsg, 'success');
        // };
    });

