(function (olbgMeta, momentJS) {

    /**
     * IMPORTANT:
     * currently introducing save n sync
     */

    // get a reference from document
    var doc                    = angular.element(document),
        AUTOMATIC_TRANSLATIONS = 'automaticTranslations',
        CURRENT_HOME           = localStorage ? localStorage.getItem('homePage') : '',
        INTRODUCED_SAVENSYNC   = 'savensync_introduced';

    angular.module('olbg-web-app')

    /*==========  controller for Settings Screen  ==========*/

    .controller('settingsCtrl', function ($rootScope, AppSettings, $scope, $q, Environment, Utils, $injector, ngDialog, Betslip, $state, Lang, Bookies, NewFeatures, Pub, olbgDialog, Preferences, $filter, Notifications, OLBGSetup, $timeout) {

        var settings = AppSettings,
            autoTranslationsStatus;

        $scope.sectionTitle = Lang.settingsPage.sectionTitle;
        $scope.generic      = Lang.generic;

        function introduceSaveNSync() {

            if (!NewFeatures.isNew(AppSettings.labels.features.savensync)) { return; }

            $timeout(function () {

                $scope.olbgMain.setBlockAlerts(true);

                var intro = olbgDialog.open({
                    type:      'confirm',
                    className: 'savensync',
                    contents: {
                        title:        Lang.savensync.introTitle,
                        message:      Lang.savensync.introDesc,
                        illustration: Lang.savensync.introIllustration,
                        actions: [$scope.generic.dialog.showMe + '!'],
                        buttonTypes: ['positive'],
                        centered: true
                    },
                    actions: [function () {
                        // has shown!
                        Preferences.save({
                            listName: INTRODUCED_SAVENSYNC,
                            list: [true],
                            local: true
                        });

                        $scope.olbgMain.setBlockAlerts(false);

                        // navigate to save n sync
                        $timeout(function () {
                            Utils.olbgMainApi.navigateTo(AppSettings.states.savensync, {
                                resetNavigator: false
                            });
                        }, 800);
                    }]
                });
            }, 500);
        }

        // expose sections
        $scope.settingsPage = {
            show: false,
            goTo: function (state) {
                Utils.olbgMainApi.currentSelectedItem = state;

                Utils.olbgMainApi.navigateTo(state, {
                    resetNavigator: false
                });

                // make sure to open the panel again >
                if (Utils.olbgMainApi.isiPad && $state.$current.self.name === state && !Utils.olbgMainApi.isContentPaneOpen()) {
                    Utils.olbgMainApi.openContentPane();
                }
            },
            generalSection: {
                title:           settings.settingsPage.general.title,
                changeHome:      Lang.titles['change-homepage'],
                oddsTitle:       settings.settingsPage.hotTips.oddsTitle,
                changeHomeState: settings.states.changeHomepage,
                savensync:       Lang.titles.savensync
            },
            notifications: {
                title:                  Lang.settingsPage.notifications.title,
                settingsTitle:          Lang.titles['stream-settings'],
                waTitle:                Lang.settingsPage.notifications.waTitle,

                subscribeToWinningAcca: function () {
                    Notifications.subscribe({type: 'winning_acca', remove: !$scope.settingsPage.notifications.subscribedToWinningAcca});
                }
            },
            translationsSection: {
                title:                  Lang.translations.settingsTitle,
                automaticSettings:      Lang.translations.automaticSettings,
                autoEnabled:            false,
                saveAutoTranslationPref: function (opts) {
                    opts = angular.extend({showFeedback: false}, opts);

                    if (opts.showFeedback) {
                        Utils.olbgMainApi.displayMessage(Lang.translations.automaticSaved, 'success');
                    }

                    Preferences.save({
                        listName: AUTOMATIC_TRANSLATIONS,
                        list: [$scope.settingsPage.translationsSection.autoEnabled]
                    });
                }
            },
            myInterestsSection: {
                title:                  Lang.settingsPage.myInterests.title,
                accountsTitle:          Utils.sanitizeHTML(Lang.settingsPage.myInterests.accountsTitle),
                tipsTitle:              Utils.sanitizeHTML(Lang.settingsPage.myInterests.tipsTitle),
                sportsTitle:            Utils.sanitizeHTML(Lang.settingsPage.myInterests.sportsTitle),
                leaguesTitle:           Utils.sanitizeHTML(Lang.settingsPage.myInterests.leaguesTitle),
                favourites:             Utils.sanitizeHTML($filter('capitalize')(Lang.generic.favourites)),

                myBookiesAccountsState: AppSettings.states.myBookiesAccounts,
                mySportsState:          AppSettings.states.mySports,
                myLeaguesState:         AppSettings.states.myLeagues,
                myTipsState:            AppSettings.states.myDailyAccas,
                favouritesState:        AppSettings.states.favorites,

                noBookies : true,
                enabled: true
            },
            hotTipsSection: {
                title:          settings.settingsPage.hotTips.title,
                filterTitle:    settings.settingsPage.hotTips.filterTitle
            },
            olbgSection: {
                title:          settings.settingsPage.olbg.title,
                verifyInstall:  Lang.settingsPage.olbg.verifyInstall,
                contactTitle:   settings.settingsPage.olbg.contactTitle,
                aboutTitle:     settings.settingsPage.olbg.aboutTitle,
                termsTitle:     settings.settingsPage.olbg.termsTitle
            }
        };

        // About Section >
        $scope.settingsPage.aboutSection = {
            title: Lang.settingsPage.aboutSection.title
        };

        // Cordova Specific
        if ($scope.olbgMain.isHybrid) {

            var CordovaSettings = $injector.get('CordovaSettings'); // get cordova specific settings

            if ($scope.olbgMain.isiOS) {
                $scope.settingsPage.aboutSection.iOSAppId      = Environment.keys.iOSAppId;
                $scope.settingsPage.aboutSection.iOSAppIdLabel = Lang.settingsPage.olbg.iOSAppId;
            }
        }

        // DEBUG: REMOVE!
        // $scope.settingsPage.resetOLBGSetup = function () {
        //     OLBGSetup.reset();
        // };

        /**
         * prepare share data and check if bookies available
         */
        $q.all([
            Betslip.getAvailableBookies(),
            Preferences.get([AUTOMATIC_TRANSLATIONS]),
            Notifications.getSubscriptions({type: 'winning_acca'}),
            Preferences.get([INTRODUCED_SAVENSYNC])
        ])
            .then(function (response) {

                $scope.settingsPage.myInterestsSection.noBookies = !response[0].bookies.length;
                autoTranslationsStatus = response[1][AUTOMATIC_TRANSLATIONS];

                // set the model
                $scope.settingsPage.notifications.subscribedToWinningAcca = response[2].has(true);

                if (autoTranslationsStatus.length) {
                    $scope.settingsPage.translationsSection.autoEnabled = autoTranslationsStatus[0];
                } else {
                    // no preferences: set default value!
                    if (NewFeatures.isAvailable(AppSettings.labels.features.commentTranslations)) {
                        $scope.settingsPage.translationsSection.autoEnabled = AppSettings.translations.defaultAutoComments;
                        $scope.settingsPage.translationsSection.saveAutoTranslationPref({ doNotToggle: true });
                    }
                }

                // Preferences.save({
                //     listName: INTRODUCED_SAVENSYNC,
                //     list: [false]
                // });

                // INTRODUCE SAVE N SYNC?
                try {
                    if (Utils.olbgMainApi.getCurrentStateName() != AppSettings.states.settings) { return; }

                    if (!response[3][INTRODUCED_SAVENSYNC][0]) {
                        if (!Utils.olbgMainApi.alertsAreBlocked()) {
                            introduceSaveNSync();
                        }
                    }
                } catch(e) {
                    console.error('Save N Sync: ', e);
                }


            })
            .finally(function () {
                // show form
                $scope.settingsPage.show = true;
            });
    });

})(window.OLBG_APP_META, moment);
