angular.module('olbg-web-app')

.controller('bookiePageCtrl', ['$rootScope', '$scope', 'AppSettings', 'Betslip', '$stateParams', '$q', 'GeneralSettings', '$state', function bettingHistoryPageCtrl($rootScope, $scope, AppSettings, Betslip, $stateParams, $q, GeneralSettings, $state) {

    function pageResolve() {
        $scope.bookiePage.contentLoaded = true;
    }

    function finishedLoading() {
        $scope.bookiePage.isLoading = false;
    }

    function initBookie() {

        /**
         * initBookie
         *
         * it sets the bookie id and bookie name to be used across
         * this screen. bookie id can be passed via URL. If no bookie
         * via URL: use the current bookie in the Bet Slip service.
         * If no bookie in Bet Slip: set it to the default one.
         */

        if (angular.isDefined($stateParams.bookieId) && $stateParams.bookieId !== 'true') {
            Betslip.setBookie($stateParams.bookieId);
        } else {
            if (!angular.isDefined(Betslip.getCurrentBookie())) {
                // user has not defined a bookie previously
                Betslip.setBookie(AppSettings.betslip.defaultBookie);
            }
        }

        // bookie is initialized
        $scope.bookiePage.bookieId   = Betslip.getCurrentBookie();
        $scope.bookiePage.bookieName = Betslip.getCurrentBookieName();
    }

    function loadPage() {

        // we need bookies and odds format
        // before showing anything else

        $scope.bookiePage.isLoading = true;

        // get bookies and odds format
        $q.all([Betslip.getAvailableBookies(), GeneralSettings.getOdds()]).then(function (response) {

            $scope.bookiePage.oddsFormat = response[1];

            // set current bookie
            initBookie();

            // show the page
            pageResolve();

        }).finally(finishedLoading);
    }

    // function isLogged() {ç
    //     Betslip.isLoggedIn($scope.bookiePage.bookieId).then(function (response){
    //         $scope.bookiePage.loggedIn = response;
    //     });
    // }

    function figureOutSectionTitle() {

        /**
         * figureOutSectionTitle
         *
         * since the bookie page is used across diferent pages
         * we need to figure out the section title out of the
         * current state.
         */

        var currentState = $state.current.name;

        switch(currentState) {
            case 'settings.bettingHistory':
                $scope.sectionTitle = AppSettings.titles['betting-history'];
                break;

            case 'settings.bookies':
                $scope.sectionTitle = AppSettings.titles['bookies-page'];
                break;

            case 'settings.deposit':
                $scope.sectionTitle = AppSettings.titles['deposit-funds'];
                break;
        }
    }

    $scope.bookiePage = {
        reload:         loadPage,
        isLoading:      true,
        contentLoaded:  false,
        loggedIn:       false
    };

    // watch bookie id
    // $scope.$watch('bookiePage.bookieId', function (value) {

    //     // whenever the bookie id changes: check logged status
    //     if (angular.isDefined(value)) {
    //         isLogged();
    //     }
    // });

    figureOutSectionTitle();

    loadPage();

}]);