angular.module('olbg-web-app')

.controller('bestOddsCtrl', ['$rootScope', '$scope', '$timeout', 'AppSettings', 'Utils', 'Betslip', '$q', 'ngDialog', 'Lang', 'Bookies', '$state',
function bestOddsCtrl($rootScope, $scope, $timeout, AppSettings, Utils, Betslip, $q, ngDialog, Lang, Bookies, $state) {

    var timer,
        time,
        formatReady = $q.defer();

    function calculateBestOdds(bet) {

        var bestOdds = 0,
            bestBookie = '';

        // calculate best odds
        bet.bestOdds = angular.isUndefined(bet.bestOdds) ? {} : bet.bestOdds;

        if (bet.bookies.length > 1) {
            angular.forEach(bet.bookies, function (bookie) {
                if (bookie.oddsDecimalValue > bestOdds) {
                    bestOdds = bookie.oddsDecimalValue;
                    bestBookie = bookie.bookieName;
                }
            });
            bet.bestOdds = bet.bookiesModel[bestBookie][$scope.bestOdds.oddsFormat];
        } else {
            // go with the only bookie we have
            bet.bestOdds = bet.bookies[0][$scope.bestOdds.oddsFormat];
        }
    }

    // set the Best Odds controller namespace
    $scope.bestOdds = {
        CTAlabel:            Utils.sanitizeHTML(AppSettings.bestOdds.CTAlabel + ' &#187;'),
        olbgMainApi:         Utils.olbgMainApi, // pass the API for opening ads, among other things
        expiredMsg:          AppSettings.betslip.expiredMsg,
        nrMsg:               Utils.sanitizeHTML(Lang.betslip.nrMsg),
        enabled:             true,
        showProceedBtn:      !$scope.isiPad
    };

    // methods
    $scope.bestOdds.removeBet = function (bet) {

        if (!$scope.bestOdds.removeIsEnabled()) {
            return;
        }

        var deferred  = Betslip.getUpdatePromise(),
            outcomeId = bet.outcomeId,
            sport     = bet.sport;
            sportId   = bet.sportId;

        function remove() {

            if (!angular.isDefined(bet.changeSet) ||
                (angular.isDefined(bet.changeSet) &&
                 angular.isDefined(bet.changeSet.expired) &&
                 !bet.changeSet.expired)) {

                // bet is not expired -> remove from server
                Betslip.remove(outcomeId, sport, sportId);
            } else {
                // remove from outcomes id
                Betslip.removeFromOutcomesId(outcomeId, sport, sportId);
            }


            // remove bet from view
            Betslip.removeBetFromModel(outcomeId, sport, $scope.bets, AppSettings.ads.types.betslip, {
                section: AppSettings.ads.types.betslip,
                syncAds: true
            });

            // show remove message unless we removed the last bet in the view
            if (Betslip.getOutcomes().length) {
                $scope.bestOdds.olbgMainApi.displayMessage(Lang.betslip.selectionRemoved, 'success');
            }
        }

        if (angular.isDefined(deferred)) {
            deferred.promise.then(remove);
        } else {
            remove();
        }
    };

    $scope.bestOdds.removeIsEnabled = function () {

        if (!Utils.olbgMainApi.isiPad) {
            return true;
        } else {
            // if iPad: disable buttons when loading Bookies Selector
            return !Bookies.isGettingBookiesSelector();
        }
    };

    $scope.bestOdds.proceed = function () {

        if (!$scope.bestOdds.enabled) {
            return;
        }

        $scope.bestOdds.olbgMainApi.navigateTo(AppSettings.states.bookiesSelector, {
            resetNavigator: false
        });
    };

    $scope.emptyBets = function (allExpired) {

        if (!$scope.bestOdds.removeIsEnabled()) {
            return;
        }

        if (allExpired) {
            Betslip.purgeBets({ serverSide: false });
        } else {

            ngDialog.open({
                template:        AppSettings.modals.genericDialog,

                scope:           $scope,
                controller:      ['$scope', function($scope) {
                    $scope.genericDialog = {};
                    $scope.genericDialog.contents = {
                        title:       Utils.sanitizeHTML(Lang.bestOdds.emptyBetsTitle),
                        message:     Utils.sanitizeHTML(Lang.bestOdds.emptyBetsMsg),
                        actions:     [Utils.sanitizeHTML(Lang.bestOdds.emptyBetsAction), Utils.sanitizeHTML(Lang.generic.buttons.cancel)],
                        buttonTypes: ['positive', 'neutral'],
                        centered:    true
                    };
                    $scope.genericDialog.executeButton = function (index) {

                        if (index === 0) {
                            Betslip.purgeBets();
                        }

                        ngDialog.closeAll();
                    };
                }]
            });
        }
    };

    $scope.$watch('oddsFormat', function (format) {
        // get the correct odds format label
        format = angular.isDefined(format) ? format : AppSettings.oddsSettings.oddsSettingsDefault;
        $scope.bestOdds.oddsFormat = Betslip.parseOdds(AppSettings.labels[format + 'Odds']);
        formatReady.resolve();
    });

    /*----------  iPad exclusive  ----------*/
    if (Utils.olbgMainApi.isiPad) {
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

            /**
             * iPad exclusive
             * -------------------------------------------------
             * if at any point the user leaves the betslip
             * state (navigates to a tip or a tipster)
             * show a way of navigating back to bookies selector
             */

            var states = $state.$current.self.name.split('.');
            if (states.length && states[0] !== AppSettings.states.betsManager) {
                $scope.bestOdds.showProceedBtn = true;
            }
        });
    }

    if (Utils.olbgMainApi.isiPad && $state.$current.self.name === AppSettings.states.betsManager) {
        /**
         * for iPad > proceed immediately
         */
        $scope.bestOdds.proceed();
    }

    var bets = $scope.bets;

    $scope.bestOdds.enabled = false;

    // process the bets
    angular.forEach(bets, function (bet) {
        if (!bet.isAd) {

            formatReady.promise.then(function () {

                // first calculate best odds >
                calculateBestOdds(bet);

                // then decide if button is enabled or disabled >
                if (bet.bestOdds !== 'NR' &&
                    (!angular.isDefined(bet.changeSet) ||
                     !angular.isDefined(bet.changeSet.expired) ||
                     !bet.changeSet.expired)) {
                    $scope.bestOdds.enabled = true;
                } else {
                    $scope.bestOdds.enabled = false;
                }
            });
        }
    });
}]);