(function (momentJS) {

    /*======================================
    =            olbgDateAtTime            =
    ======================================*/

    // Takes a time stamp and returns a Date at Time string

    angular.module('olbg-web-app')

    .filter('olbgDateAtTime', ['DateService', 'Utils', function olbgDateAtTimeFilter(DateService, Utils) {
        return function(input, format) {

            /**
             * formatDate
             *
             * This filter formats a unix timestamp and returns
             * a string in the following format:
             * [Today | Tomorrow | Month] at [Day Number]
             *
             */

            // process date

            var formattedDate,
                months       = DateService.getMonths(),
                dateInfo     = DateService.getDateInfo();

            if (angular.isDefined(format) && format === 'string') {

                input = momentJS(input).unix();
            }

            // format date
            formattedDate = Utils.formatDate(input, dateInfo, months, true, false);

            return formattedDate;
        };
    }]);






})(moment);