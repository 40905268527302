angular.module('olbg-web-app')

// TRANSLATED MARKETS

.factory('Markets', [
    'AppSettings',
    '$q',
    '$filter',
    'Utils',
    'Environment',
    'GeneralSettings',
    'Tracker',
    '$timeout',

    function MarketsService(AppSettings, $q, $filter, Utils, Environment, GeneralSettings, Tracker, $timeout) {

    var marketsPromise,
        updatingPromise,
        initPromise,
        currentMarketsTime,
        refreshPromise        = $q.defer(),
        cachedMarkets         = {},
        service               = {},
        refreshingMarkets     = false,
        initializing          = false,
        updating              = false,
        gettingMarkets        = false,
        marketsURL            = Environment.APIurls.marketsDetails,
        marketModel           = {"marketid":null, "vcmarketid":null, "name":null, "sport":null, "rank":null};

    service.getMarkets = function (opts) {

        if (gettingMarkets) {
            return marketsPromise.promise;
        }

        opts = angular.extend({
            getCached: true
        }, opts);

        marketsPromise = $q.defer();
        gettingMarkets = true;

       if (angular.isDefined(currentMarketsTime) &&
          (new Date().getTime() - currentMarketsTime) < AppSettings.markets.getNewDataEvery &&
          opts.getCached &&
          angular.isDefined(cachedMarkets) &&
          cachedMarkets) {

            marketsPromise.resolve(cachedMarkets);
            gettingMarkets = false;

        } else {

            // get fresh markets from tips API
            Request.get(marketsURL)
                .then(function (response) {
                    // console.log('Markets: got them', response);
                    currentMarketsTime = new Date().getTime();
                    angular.extend(cachedMarkets, response.data);
                    marketsPromise.resolve(cachedMarkets);
                })
                .catch(function (err) {
                    marketsPromise.reject(err);
                    // console.log('ERROR: Markets service failed;');
                })
                .finally(function () {
                    gettingMarkets = false;
                });
        }

        return marketsPromise.promise;
    };

    service.getMarketName = function (marketId, marketName) {
        var ready = $q.defer();
        this.getMarkets()
            .then(function (translatedMarkets) {
                translatedMarket = angular.extend({}, marketModel);
                if (angular.isDefined(translatedMarkets.markets)) {
                    market = $filter('filter')(translatedMarkets.markets, function (d) {return d.marketid === marketId;})[0];
                    angular.extend(translatedMarket, market);
                }
                if (!translatedMarket.name) {
                    // console.log('Failed to translate market ' + marketName + '(' + marketId + '), revert to original name.');
                    translatedMarket.name = marketName;
                }
                ready.resolve(translatedMarket.name);
            },
            function(reason){
                // console.log('Failed to get markets: ' + reason);
                ready.resolve(marketName);
            });

            return ready.promise;
    };

    // make it public
    return service;
}]);