(function (adsTrackId, appInfo, $) {

    var adClasses = [],
        CONTAINS_ADS_CLASS = 'app--contains-ads';

    angular.module('olbg-web-app')

    .factory('Pub', [
        '$q',
        '$timeout',
        'AppSettings',
        'Tracker',
        'Environment',
        'Utils',
        '$filter',
        'Scodes',
        '$rootScope',
        'Request',

        function AdsService($q, $timeout, AppSettings, Tracker, Environment, Utils, $filter, Scodes, $rootScope, Request) {

        var trackerInfo,
            insertCount          = {},
            ads                  = {},
            viewedAds            = {},
            service              = {},
            FEAT                 = 'ADS',
            FEAT_API_TRANS       = AppSettings.labels.features.apiTranslations,
            adsEnabled           = true,
            interactivePromise   = $q.defer(),
            interactiveEnabled   = false,

            ADMOB_ACTIONS        = AppSettings.admob.actions;

        function prepareUrl(url, path, trackerInfo, section) {

            var pageRef;

            // prepare the standard ti param
            url = Tracker.prepareTrackingURL(url);

            // ad specific data
            if (angular.isDefined(section)) {
                url = url.replace('{type}', section);

                // request by Jess Attree [jess@olbg.com]
                // pass the pageRef
                pageRef = AppSettings.ads.pageRefs[section];
                url = url.replace('{:pid}', pageRef ? pageRef : '');

            } else {
                url = url.replace('&type={type}', '');
            }

            if (angular.isDefined(path)) {
                url = url.replace('{path}', encodeURIComponent(path));
            } else {
                url = url.replace('&path={path}', '');
            }

            if (adsTrackId) {
                url += ('&external_ref=' + 'ref-' + adsTrackId);
            }

            return url;
        }

        // update ad insert counter for each section
        angular.forEach(AppSettings.ads.types, function (value) {
            insertCount[value] = {
                general: 0
            };
        });

        service.getAds = function (section, opts) {

            /**
             * getAds
             *
             * returns ads from API.
             *
             * @section [{string}] this is the section we want to get the ads for
             * @url     [{string}] this is the API URL we are using to get the section's data
             * @opts    [{object}]
             */
            var cachedAds,
                tempOpts,
                requestUrl = Tracker.appendGeo(Environment.APIurls.ads),
                deferred = $q.defer();

            // use lowercase for url
            opts.url = opts.url ? opts.url.toLowerCase() : '';

            if (!adsEnabled) {
                // Tracker.customLog(FEAT, 'disabled!');
                deferred.resolve([]);
                return deferred.promise;
            }

            /**
             * wait for:
             * S codes
             * and interactive ads
             */

            // $q.all([Scodes.init, interactivePromise.promise]).then(function () {
            $q.all([Scodes.init(), interactivePromise.promise]).then(function () {
                // create basic options
                opts = angular.extend({
                    url:              '',
                    useUrl:           false,
                    useCached:        false,
                    eventId:          '',
                    customParameters: []
                }, opts);

                // create object and counter for section:
                ads[section] = angular.isDefined(ads[section]) ? ads[section] : {};
                insertCount[section] = angular.isDefined(insertCount[section]) ? insertCount[section] : {};

                // reset inserted ads counter for this section (and optionally url)
                if (opts.useUrl) {
                    insertCount[section][opts.url] = 0;
                } else {
                    insertCount[section].general = 0; // reset the inserted ads counter for this section
                }

                Tracker.get().then(function (response) {

                    var prepUrl,
                        adsList;

                    trackerInfo = response;
                    prepUrl     = prepareUrl(requestUrl, opts.url, trackerInfo, section);
                    adsList     = opts.useUrl ? ads[section][opts.url] : ads[section].general; // the ads we are going to use

                    if (opts.outcomeId) { prepUrl += '&vc_outcome_id=' + opts.outcomeId; }

                    angular.forEach(opts.customParameters, function (param) {
                        prepUrl += ('&' + param.label + '=' + param.value);
                    });

                    if (section === 'in-events' && opts.eventId) {
                        // append event ID ->
                        prepUrl += '&vc_event_id=' + opts.eventId;
                    }

                    if (opts.useCached && adsList && adsList.length) {

                        // attempt to return cached tips
                        // Tracker.customLog(FEAT, 'returning cached ads');
                        deferred.resolve(adsList);

                    } else {

                        // prepare URL

                        prepUrl += ('&interactiveAds=' + interactiveEnabled);

                        // for country debugging:
                        prepUrl += Utils.olbgMainApi.debugCountry ? ('&forceGeo=' + Utils.olbgMainApi.debugCountry.toLowerCase()) : '';

                        Request.get(prepUrl)
                            .then(function(response) {
                                try {
                                    var adsResponse = response.data;

                                    // adsResponse[section] = [{
                                    //     id: 1,
                                    //     position: 1,
                                    //     isAd: true
                                    // },
                                    // {
                                    //     id: 2,
                                    //     position: 3,
                                    //     isAd: true
                                    // },
                                    // {
                                    //     id: 3,
                                    //     position: 5,
                                    //     isAd: true
                                    // }
                                    // ]; // DEBUG
                                    // Tracker.customLog(FEAT, 'Attempted to fetch ads for \'' + section + '\' section.', prepareUrl(requestUrl, opts.url, trackerInfo, section)); // DEBUG

                                    var adsArray  = adsResponse[section],
                                        styles    = adsResponse.metadata.styles,
                                        positions = [],
                                        finalArray;

                                    if (adsArray.length) {

                                        /**
                                         * Alvaro Casanova (alvaro@olbg.com)
                                         * as a request by Josh:
                                         * should have only one ad per position
                                         */

                                        finalArray = adsArray.filter(function (value) {

                                            var returnIt = true;

                                            value.isAd     = true;
                                            value.isViewed = false;

                                            if (positions.indexOf(value.position) >= 0) {
                                                // position already exists
                                                returnIt = false;
                                            } else {
                                                positions.push(value.position);
                                            }

                                            return returnIt;
                                        });

                                        // extend the right list
                                        if (opts.useUrl) {
                                            ads[section][opts.url] = ads[section][opts.url] ? ads[section][opts.url] : [];
                                            ads[section][opts.url].splice(0, ads[section][opts.url].length);
                                            angular.extend(ads[section][opts.url],finalArray);
                                        } else {
                                            ads[section].general = ads[section].general ? ads[section].general : [];
                                            ads[section].general.splice(0, ads[section].general.length);
                                            angular.extend(ads[section].general, finalArray);
                                        }

                                        ads[section].styles = styles;
                                        deferred.resolve(opts.useUrl ? ads[section][opts.url] : ads[section].general);

                                    } else {
                                        if (opts.useUrl) {
                                            ads[section][opts.url] = [];
                                        } else {
                                            ads[section].general = [];
                                        }
                                        deferred.resolve([]);
                                    }
                                } catch(e) {
                                    Tracker.customLog(FEAT_API_TRANS, e);
                                }
                            })
                            .catch(function () {
                                // could not retrieve ads: send back empty array
                                deferred.resolve([]);
                            });
                    }
                });
            });

            return deferred.promise;
        };

        service.insertAds = function (model, adsArray, section, opts) {

            /**
             * insertAds
             * {array}   list model where the ads are inserted
             * @adsArray
             * @model        {array}   list of ads to be inserted
             * @section      {string}  a string representing the section
             *                         where ads are being manipulated
             * @opts         [{bool}]  contains several options
             */

            opts = angular.isDefined(opts) ? opts : {};

            opts = angular.extend({
                reinsertion:    false,
                forceTrack:     false,
                preventTrack:   false,
                url:            '',
                isTipsByMarket: false,
                isEvents:       false
            }, opts);

            // use lowercase for url
            opts.url = opts.url ? opts.url.toLowerCase() : '';

            var modelLen,
                insertCountNum,
                insertedNewAd = false;

            function insertIt(model, pos, ad, opts, section) {

                model.splice(pos, 0, ad);
                ad.isInserted = true;

                if (opts.url) {
                    insertCount[section][opts.url]++;
                } else {
                    insertCount[section].general++;
                }

                if (!ad.isViewed) {
                    insertedNewAd = true;
                }

                // finally: set the isViewed flag!
                if ((service.sectionIsVisible(section) || opts.forceTrack) && !opts.preventTrack) {
                    ad.isViewed = true; // this is a unique flag and it's reset when the user reloads the application
                }
            }

            function insertAds() {

                /**
                 * insertAds
                 *
                 */
                var adsHash = [];

                angular.forEach(adsArray, function (ad, index) {

                    var label,
                        tipsNum = 0,
                        adProcessed = false,
                        isInserted  = angular.isDefined(ad.isInserted) ? ad.isInserted : false;
                        pos = ad.position;

                    // insert it if there are enough elements in the list
                    if (!opts.isTipsByMarket && !opts.isEvents && pos < modelLen) {
                        if (!isInserted || opts.reinsertion) {
                            insertIt(model, pos, ad, opts, section);
                        }
                    } else {

                        // for Tips By Market the logic is slighly different
                        if (opts.isTipsByMarket || opts.isEvents) {

                            label = opts.isTipsByMarket ? 'tipsArray' : 'eventsArray';

                            // attempt to insert ad in first group
                            angular.forEach(model, function (el) {

                                var tipsLen,
                                    maxOutcome,
                                    tipsSoFar  = 0,
                                    inArrayPos = 0;

                                // [EXPIRED EVENTS] > if all events expired move to the next day
                                if (!adProcessed && !el.allExpired) {

                                    tipsLen = el[label].length;
                                    maxOutcome = el.maxOutcomes ? parseInt(el.maxOutcomes) : false;

                                    // only count visible selections
                                    tipsSoFar = tipsNum;
                                    tipsNum += (maxOutcome && tipsLen >= maxOutcome ? maxOutcome : tipsLen);
                                    if (pos <= tipsNum) {
                                        inArrayPos = pos - tipsSoFar;
                                        adsHash.push({
                                            model:   el[label],
                                            pos:     inArrayPos - 1,
                                            ad:      ad,
                                            opts:    opts,
                                            section: section
                                        });
                                        adProcessed = true;
                                    }
                                }
                            });
                        }
                    }
                });

                if (opts.isTipsByMarket || opts.isEvents && adsHash.length) {
                    angular.forEach(adsHash, function (ad) {
                        // insert all the ads
                        insertIt(ad.model, ad.pos, ad.ad, ad.opts, ad.section);
                    });
                }
            }

            insertCountNum = opts.url ? insertCount[section][opts.url] : insertCount[section].general;

            modelLen = !opts.reinsertion ? (model.length - insertCountNum) : model.length;

            // reset counter for reinsertions
            if (opts.reinsertion) {
                if (opts.url) {
                    insertCount[section][opts.url] = 0;
                } else {
                    insertCount[section].general = 0;
                }
            }

            insertAds();

            //Tracker.customLog(FEAT, 'Inserted ' + service.getInsertedAdsCount(section) + ' ads in ' + section + ' section.'); // DEBUG

            if (insertedNewAd && (service.sectionIsVisible(section) || opts.forceTrack)) {
                if (!opts.preventTrack) {
                    service.trackVisibleAds(adsArray, section);
                }
            }
        };

        service.removeAds = function (model, section, url, opts) {

            /**
             * removeAds
             *
             * @model    {array}    list of objects
             * @section  {string}   a string representing the section
             *                      where ads are being manipulated
             * @url      [{string}] optional: define url for more precise ads
             */

            var noAdsModel = [];

            url = url ? url.toLowerCase() : '';

            function filterAds(el) {
                var isNotAd = true;
                if (angular.isDefined(el.isAd) && el.isAd) {
                    el.isInserted = false;
                    if (angular.isDefined(url)) {
                        insertCount[section][url]--;
                    } else {
                        insertCount[section].general--;
                    }
                    isNotAd = false;
                }
                return isNotAd;
            }

            opts = angular.extend({
                isTipsByMarket: false
            }, opts);

            if (opts.isTipsByMarket) {

                // for tips by market logic is a bit different
                angular.forEach(model, function (market) {

                    // remove ads on a market basis
                    var filteredTips = market.tipsArray.filter(function (el) {
                        return filterAds(el);
                    });

                    market.tipsArray.splice(0, market.tipsArray.length);
                    angular.extend(market.tipsArray, filteredTips);
                });
            } else {
                noAdsModel = model.filter(function (el) {
                    return filterAds(el);
                });
                model.splice(0, model.length);     // empty the model with ads
                angular.extend(model, noAdsModel); // extend it with the new filtered model
            }
        };

        service.clearAds = function (opts) {
            if (!opts.section) {
                return;
            }

            var section = opts.section,
                url     = opts.url ? opts.url.toLowerCase(): '';

            if (url) {
                delete ads[section][url];
            } else {
                delete ads[section];
            }
        };

        service.getInsertedAdsCount = function (section, url) {
            url = url ? url.toLowerCase() : '';
            if (angular.isDefined(url)) {
                return insertCount[section][url];
            } else {
                return insertCount[section].general;
            }
        };

        service.resetInsert = function (opts) {

            if (!opts.section || (opts.useUrl && !opts.url)) { return; }

            // use lowercase for url
            opts.url = opts.url ? opts.url.toLowerCase() : '';

            var adsList,
                section = opts.section,
                url     = opts.url,
                useUrl  = url && opts.useUrl;

            if (!ads || !ads[section]) { return; }

            adsList = useUrl ? ads[section][url] : ads[section].general;

            angular.forEach(adsList, function (ad) { ad.isInserted = false; });

            // reset insert counter
            if (useUrl) {
                insertCount[section][opts.url] = 0;
            } else {
                insertCount[section].general = 0; // reset the inserted ads counter for this section
            }
        };

        service.sectionIsVisible = function (section) {

            /**
             * checks the current state and if it matches
             * the passed section, it returns true!
             */

            function parseStateName(stateName) {
                var nameToReturn;
                switch (stateName) {
                    case 'hotTips':
                        nameToReturn = 'hot-tips';
                        break;
                    case 'sports.categories':
                        nameToReturn = 'categories';
                        break;
                    case 'sports.categories.leagues':
                        nameToReturn = 'leagues';
                        break;
                    case 'sports.categories.leagues.events':
                        nameToReturn = 'events';
                        break;
                    case 'sports.categories.leagues.events.tips':
                        nameToReturn = 'tips';
                        break;
                    case 'sports.categories.leagues.events.tips.tip':
                        nameToReturn = 'comments'; // right now we show comments only in Tip Details screen
                        break;
                    case 'betslip':
                        nameToReturn = 'betslip';
                        break;
                }
                return nameToReturn;
            }

            var currentState = parseStateName(Utils.olbgMainApi.getCurrentStateName());

            return AppSettings.ads.types[currentState] === section;
        };

        service.trackVisibleAds = function (ads, section) {

            var insertedAds = [];
            angular.forEach(ads, function (ad) {
                if (ad.isInserted && ad.isViewed && !viewedAds[ad.id]) {
                    insertedAds.push(ad.id);
                }
            });

            if (insertedAds.length) {
                service.trackAds({
                    ads: insertedAds,
                    section: section
                });
            }
        };

        service.trackAds = function (opts) {

            var ads     = opts.ads,
                section = opts.section,
                url     = prepareUrl(Environment.APIurls.adsTracking, undefined, trackerInfo, section);

            ads = ads.filter(function (id) { return !viewedAds[id]; });

            angular.forEach(ads, function (id) {
                viewedAds[id] = true; // make sure not to track this ad id again
            });

            if (ads.length) {
                Request.get(url + '&ad_id=' + angular.toJson(ads))
                    .then(function () {
                        Tracker.customLog(FEAT_API_TRANS, 'ads tracked!');
                    });
            }
        };

        service.isAd = function (el) { return el.isAd; };

        /**
         * returns the styles to inject for each zone
         * @param  {string} section the zone
         * @return {string}         the styles
         */
        service.getStyles = function (section) {
            return ads[section].styles;
        };

        service.disableAds = function () {
            adsEnabled = false;
        };

        service.enableInteractiveAds = function () {

            function enable(ev) {
                interactiveEnabled = true;     // 1. enable interactive ads
                interactivePromise.resolve();  // 2. load ads
                window.removeEventListener(AppSettings.ads.interactive.eventName, enable); // 3. don't call twice
            }

            Tracker.getInitPromise().then(function() {

                // inject JS
                $('head').append('<script src="' + Tracker.appendTI(Environment.APIurls.interactiveAds) + '" type="text/javascript"></script>');

                // listen to event once
                window.addEventListener(AppSettings.ads.interactive.eventName, enable, false);

                // if after some time interactive ads are not ready: load anyways
                $timeout(function () {
                    if (!interactiveEnabled) { // still no response
                        interactivePromise.resolve();
                    }
                }, AppSettings.ads.interactive.timeout);
            });
        };

        service.addAdClass = function (id) {
            var addClass = CONTAINS_ADS_CLASS + '_' + id;
            $('body').addClass(CONTAINS_ADS_CLASS + ' ' + addClass);
            adClasses.push(addClass);
        };

        service.removeAllClasses = function () {
            $('body').removeClass(CONTAINS_ADS_CLASS + ' ' + Utils.uniqueArray(adClasses).join(' '));
            adClasses.splice(0, adClasses.length);
        };

        service.initAdmob = function () {

            var FEAT = 'ADMOB';

            // admob
            try {
                if (!NightSteed.Ad.AdService) {
                    throw "Admob plugin not present in the project";
                }

                // set up admob ids
                NightSteed.Ad.configure({
                    android: {
                        appId:            appInfo.admob.appId,
                        banner:           appInfo.admob.bannerId,
                        interstitial:     appInfo.admob.interstitialAdId,
                        rewardedVideo:    appInfo.admob.rewardedVideoId,
                        personalizedAdsConsent: false,

                        testDeviceId:    '5BDF65861034F62836A6A273A0BD2925',
                        isTest: true
                    }

                    // FOR iOS: add IDS to ios-overwrites/flag.js

                    // ,
                    // ios: {
                    //     appId: "ca-app-pub-3940256099942544~3347511713",
                    //     banner: "ca-app-pub-3940256099942544/6300978111",
                    //     interstitial: "ca-app-pub-3940256099942544/1033173712",
                    //     rewardedVideo: "ca-app-pub-3940256099942544/5224354917",
                    //     personalizedAdsConsent: false,
                    // }
                });

                // interstitial();

                // rewardedVideo();

            } catch(e) { console.error(FEAT + ': ' + e); }

        };


        // add admob functionality

        /**
         * loads an ad
         * @param  {Object} type the type of ad we want to load
         * @return {Prmise}
         */
        service.loadAdmob = function (type) {
            if (!NightSteed || !NightSteed.Ad) { return; }
            var deferred = $q.defer(),
                ad;

            switch (type) {
                case 'reward':
                    ad = NightSteed.Ad.createRewardedVideo();
                    break;
                case 'interstitial':
                    ad = NightSteed.Ad.createInterstitial();
                    break;
                default:
                    ad = NightSteed.Ad.createBanner();
                    ad.setLayout("TOP_CENTER"); // Set banner position
                    break;
            }

            ad.load();

            ad.on('load', function () {
                deferred.resolve(ad); // pass the ad when resolving
            });

            ad.on('fail', deferred.reject);

            return deferred.promise;
        };

        /**
         * shows an ad
         * @param  {Object} type the type of ad we want to show
         * @param  {Ad} ad the loaded ad
         * @return {Prmise}
         */
        service.showAdmob = function (type, ad) {
            if (!NightSteed || !NightSteed.Ad || !ad) { return; }
            var deferred = $q.defer();

            ad.show(); // we show it

            // set the events
            ad.on('fail', function (err) { deferred.reject(err ? JSON.stringify(err) : ''); });
            ad.on('dismiss', function () {
                deferred.resolve({action: ADMOB_ACTIONS.dismissed});
            });
            ad.on('click', function () {
                deferred.resolve({action: ADMOB_ACTIONS.clicked});
            });

            if (type == 'reward') {
                ad.on('reward', function (reward, error) {
                    if (!error) {
                        deferred.resolve({action: ADMOB_ACTIONS.completed, details: reward});
                      // console.log('amount: ' + reward.amount);
                      // console.log('currency: ' + reward.currency);
                      // console.log('itemKey: ' + reward.itemKey);
                    } else {
                      deferred.reject(error ? JSON.stringify(error) : '');
                    }
                });
            }

            return deferred.promise;
        };


        // service.createAdmobBanner = function () {

        //     var banner = NightSteed.Ad.createBanner(),
        //         deferred = $q.defer();

        //     banner.on('load', function () {
        //         banner.show();
        //     });

        //     banner.on('fail', deferred.reject);

        //     // banner.on('show', function () {});

        //     banner.on('dismiss', function () {
        //        deferred.resolve({action: ADMOB_ACTIONS.dismissed});
        //     });

        //     banner.on('click', function () {
        //        deferred.resolve({action: ADMOB_ACTIONS.clicked});
        //     });

        //     banner.load();

        //     return deferred.promise;
        // };

        // service.createAdmobInterstitial = function () {
        //     var interstitial = NightSteed.Ad.createInterstitial(),
        //         deferred     = $q.defer();

        //     interstitial.on('load', function () {
        //         interstitial.show();
        //     });

        //     interstitial.on('fail', function (error) {
        //         deferred.reject(JSON.stringify(error));
        //     });

        //     interstitial.on('show', function () {});

        //     interstitial.on('dismiss', function () {
        //         deferred.resolve({action: ADMOB_ACTIONS.dismissed});
        //     });

        //     interstitial.on('click', function () {
        //        deferred.resolve({action: ADMOB_ACTIONS.clicked});
        //     });


        //     return deferred.promise;
        // };

        // service.createAdmobRewarded = function () {


        //     var rewardedVideo = NightSteed.Ad.createRewardedVideo(),
        //         deferred      = $q.defer();

        //     rewardedVideo.on('load', function () { rewardedVideo.show(); });

        //     rewardedVideo.on('reward', function (reward, error) {
        //         if (!error) {
        //             deferred.resolve({action: ADMOB_ACTIONS.completed}, details: reward);
        //           // console.log('amount: ' + reward.amount);
        //           // console.log('currency: ' + reward.currency);
        //           // console.log('itemKey: ' + reward.itemKey);
        //         } else {
        //           deferred.reject(JSON.stringify(error));
        //         }
        //     });

        //     rewardedVideo.on('fail', function (error) {
        //         deferred.reject(JSON.stringify(error));
        //     });

        //     rewardedVideo.on('show', function () {});

        //     rewardedVideo.on('dismiss', function () {
        //         deferred.resolve({action: ADMOB_ACTIONS.dismissed});
        //     });

        //     rewardedVideo.on('click', function () {
        //        deferred.resolve({action: ADMOB_ACTIONS.clicked});
        //     });

        //     rewardedVideo.load();

        //     return deferred.promise;
        // };

        // function interstitial() {
        //     var interstitial = NightSteed.Ad.createInterstitial();

        //     interstitial.on('load', function () {
        //         console.log('interstitial loaded');
        //         interstitial.show();
        //     });

        //     interstitial.on('fail', function (error) {
        //         console.log('Interstitial failed: ' + JSON.stringify(error));
        //     });

        //     interstitial.on('show', function () {
        //         console.log('Interstitial shown');
        //     });

        //     interstitial.on('dismiss', function () {
        //         console.log('Interstitial dismissed');
        //     });

        //     interstitial.on('click', function () {
        //        console.log('Interstitial clicked');
        //     });

        //     interstitial.load();
        // }

        // function rewardedVideo() {
        //     var rewardedVideo = NightSteed.Ad.createRewardedVideo();

        //     rewardedVideo.on("load", function(){
        //         console.log("Rewarded video loaded");
        //         rewardedVideo.show();
        //     });

        //     rewardedVideo.on("reward", function(reward, error){
        //         if (!error) {
        //           console.log("amount: " + reward.amount);
        //           console.log("currency: " + reward.currency);
        //           console.log("itemKey: " + reward.itemKey);
        //         } else {
        //           console.log("error: " + JSON.stringify(error));
        //         }
        //     });

        //     rewardedVideo.on("fail", function(error){
        //         console.log("Rewarded video failed: " + JSON.stringify(error));
        //     });

        //     rewardedVideo.on("show", function(){
        //         console.log("Rewarded video shown");
        //     });

        //     rewardedVideo.on("dismiss", function(){
        //         console.log("Rewarded video dismissed");
        //     });

        //     rewardedVideo.on("click", function(){
        //        console.log("Rewarded video clicked");
        //     });

        //     rewardedVideo.load();
        // }


        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            service.removeAllClasses();
        });

        return service;
    }]);

})(typeof TRACK_ID !== 'undefined' ? TRACK_ID : '', window.OLBG_APP_META, jQuery);