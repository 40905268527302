angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetslipLink', ['$rootScope', 'AppSettings', 'Betslip', function olbgBetslipLinkDirective($rootScope, AppSettings, Betslip) {
    return {
        restrict: 'E',
        controller: function ($scope) {

            $scope.link = AppSettings.betslip.link;

            $scope.$watch(Betslip.getCount, function (value) {
                $scope.counter = value;
            });

        },
        templateUrl: AppSettings.templates['betslip-link']
    };
}]);