angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetButton', ['AppSettings', '$timeout', function olbgBetButtonDirective(AppSettings, $timeout) {
    return {
        restrict: 'E',
        scope: {
            odds: '=',
            onlyIcon: '=',
            tip: '=',
            betslip: '=',
            showFeedback: '&',
            bettingEnabled: '=',
            expiration: '=?', // [EXPIRED EVENT]: optional flag
            expirationLabel: '@?' // [EXPIRED EVENT]: optional expiration copy
        },
        controller: 'betButtonCtrl',
        templateUrl: AppSettings.templates['bet-button'],
        replace: true
    };
}]);