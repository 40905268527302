(function (momentJS) {

    // get a reference from document
    var doc = angular.element(document);

    var TIPS_CACHE     = new Map(),
        COMMENTS_CACHE = new Map();

    angular.module('olbg-web-app')

    /*==========  controller for Tip Detail Screen  ==========*/

    .controller('tipDetailCtrl', function (AppSettings, $rootScope, $scope, $timeout, $q, $state, $stateParams, $location, Navigation, Comments, Tips, Share, GeneralSettings, Utils, Sports, DateService, Betslip, Pub, Environment, Lang, Swipe, Request, Tipsters) {

        var tipId,
            sportId,
            currentState,
            tipLoaded,
            currentPage,
            commentsLoaded,
            adsUrl          = Environment.APIurls.categories,
            settings        = AppSettings,
            section         = settings.ads.types.comments,
            individualState = 'tip',
            hasMoreComments = true,
            months          = DateService.getMonths(),
            dateInfo        = DateService.getDateInfo(),
            commentID       = $stateParams.commentId ? $stateParams.commentId : '';

        // do once
        $scope.noTipsCopy           = { title: Lang.tipDetail.noTipCopy.title, body:  Lang.tipDetail.noTipCopy.message };
        currentState                = $scope.getCurrentState(individualState);
        $scope.scrollContainer      = '#comment-scroll';
        $scope.landing              = Utils.olbgMainApi.landed;
        $scope.expiredNotification  = Utils.sanitizeHTML(Lang.generic.expiredEvents.notification);
        $scope.expiredLabel         = Utils.sanitizeHTML(Lang.generic.expiredEvents.label);
        $scope.sectionTitle         = settings.tipDetail.sectionTitle;
        $scope.numberOfTipsCopy     = [Lang.generic.terminology.tip, Lang.generic.terminology.tips];
        $scope.quickFormLabel       = Lang.tipDetail.quickFormLabel;
        $scope.tipsterCommentsLabel = Lang.tipDetail.tipsterCommentsLabel;
        $scope.eventsEndMessage     = Lang.tipDetail.swipeEnd;
        $scope.tip                  = Navigation.get(currentState, 'tip');
        $scope.swipeReady           = false;


        $scope.tipDetails = { connected: true };

        // get staate for this controller
        // regardless if this page is being loaded in the background

        // used for showing cached stuff
        function delayShowing(cb, delay) {
            $timeout(cb, angular.isDefined(delay) ? delay : 250); // at least a sec
        }

        function getTipFromServer(id) {

            // 1. get it from cache
            var tip = TIPS_CACHE.get(id);

            // 2. if there's a tip content and not enough time to update has passed:
            if (tip && tip.content && (Date.now() - tip.tmp <= AppSettings.tipDetail.updateEvery)) {
                $scope.tip       = tip.content;

                delayShowing(function () {
                    $scope.tipDetails.connected = true;
                    tipLoaded.resolve();
                });
            } else {
                // 3. no cached tip: get it from server
                Tips.getOneTip(id, function (data) {
                    if (!data) {
                        tipLoaded.reject();
                        $scope.tipDetails.connected = false;
                        return;
                    }

                    $scope.tip = data && data.tips ? data.tips[0] : '';

                    if ($scope.tip) {

                        GeneralSettings.get(settings.labels.oddsFormat, function (response) {
                            // settings stored: send tip format along
                            prepareTip($scope.tip, response.name);
                            tipLoaded.resolve();
                        }, function () {
                            // no settings stored
                            prepareTip($scope.tip);
                            tipLoaded.resolve();
                        });
                    } else {
                        $scope.tipDetails.connected = false;
                    }
                });
            }
        }

        function redirect() {
            Swipe.clear(); // clear swipe data just in case
            Utils.olbgMainApi.navigateHome();
        }

        function isEmpty () {
            $scope.isFetching       = false;
            $scope.endOfList        = true;
            $scope.noCommentsString = settings.tipDetail.comments.noCommentsString;
        }

        function isEndOfPage() {
            $scope.isFetching       = false;
            $scope.commentsLoading  = false;
            $scope.endOfList        = true;
            $scope.noCommentsString = settings.tipDetail.comments.noMoreCommentsString;
        }

        function commentsError() {
            // whoops! error!
            isEmpty();
            $scope.noticeIsError = true;
            $scope.noCommentsString = settings.tipDetail.comments.commentsErrorString;
            commentsLoaded.resolve();
        }

        function getComments(options) {

            var ads;

            var comments = COMMENTS_CACHE.get(tipId);

            $scope.backing = '';

            Tipsters.getTipsterProfiles()
                .then(function () {

                    var f = Tipsters.following;

                    // if cached: use them!
                    if (comments && comments.list.length && (Date.now() - comments.tmp) <= AppSettings.tipDetail.updateEvery) {
                        $scope.comments = comments.list;

                        $scope.backing = Tipsters.generateBackingMessage({
                            followed: f,
                            comments: $scope.comments
                        });

                        delayShowing(function () {
                            $scope.isFetching = false;
                            commentsLoaded.resolve();
                        });

                    } else {
                        // get round of comments
                        Comments.get(tipId, currentPage, options.sportId).then(function (data) {

                            if (!data.comments) {
                                // no comments returned!
                                commentsError();
                                return;
                            }

                            var adsOpt,
                                reinserting = !options.loadingPage;

                            adsOpt = {
                                url: adsUrl,
                                useUrl: true,
                                useCached: false,
                                outcomeId: $scope.tip.vc_outcome_id
                            };

                            if (!reinserting) {
                                adsOpt.useCached = true;
                            }

                            // Store comments
                            if (data.comments.length) {

                                // there are comments to show > expose them
                                $scope.comments = $scope.comments.concat(data.comments);

                                $scope.backing = Tipsters.generateBackingMessage({
                                    followed: f,
                                    comments: $scope.comments
                                });

                                // get ads:
                                Pub.getAds(section, adsOpt).then(function (adsResponse) {
                                    ads = adsResponse;
                                }).finally(function () {
                                    // insert or re-insert ads
                                    if (angular.isDefined(ads) && ads.length) {
                                        Pub.insertAds($scope.comments, ads, section, {
                                            reinsertion: reinserting,
                                            url: adsUrl
                                        });
                                    }

                                    if (commentID) {scrollToComment(commentID); }

                                    COMMENTS_CACHE.set(tipId, {
                                        list: $scope.comments,
                                        tmp:  Date.now()
                                    }); // cache comments
                                });

                                // is end of page?
                                if (data.metadata.has_more_comments === 'NO') {
                                    isEndOfPage();
                                }
                            } else {
                                if (currentPage === 1) {
                                    // it's first page so it's empty
                                    isEmpty();
                                }
                            }

                            // Show comments
                            $scope.isFetching = false;
                            commentsLoaded.resolve();
                            hasMoreComments = data.metadata.has_more_comments === 'YES' ? true : false;

                        }).catch(function (error) {
                            commentsError();
                        });
                    }
                });

        }

        function prepareTip(tip, formatChoice) {

            var oddsFormat   = '';

            formatChoice = angular.isDefined(formatChoice) ? formatChoice : settings.oddsSettings.oddsSettingsDefault;

            oddsFormat = Utils.figureOutOdds(formatChoice);

            // format tip
            Tips.formatTip(tip, oddsFormat, {figureOutOdds: false});

            // replace tip
            Navigation.store(currentState, tip, 'tip');

            // add tip to cache
            TIPS_CACHE.set(tip.tip_hash, {content: tip, tmp: Date.now()});
        }

        function tipLoadedHandler() {

            var tip = $scope.tip;

            // generate stack ID!
            $scope.stackID = tip.vc_event_id + tip.marketid;

            $scope.breadcrumbData = {
                sportId:  tip.vc_sport_id,
                sport:    tip.sport_alias,
                category: tip.menu_cat,
                league:   tip.menu_league,
                event:    tip.event_name_alias,
                params:   tip.params
            };

            // set the active link
            if (!angular.isDefined($rootScope.selectedLink)) {
                var sportId = Utils.URLify($scope.tip.sport),
                    catId   = Utils.URLify($scope.tip.menu_cat);

                Sports.setMenuLink(sportId, catId);
            }

            // get section title from tip
            $scope.sectionTitle  = $scope.tip && $scope.tip.selection ? $scope.tip.selection : 'Tip Detail';

            // initialize comments
            tipId             = $scope.tip.tip_hash;
            $scope.isFetching = true;
            $scope.endOfList  = false;
            $scope.comments   = [];

            // set total number of comments for this event
            $scope.commentsNumber = $scope.tip ? $scope.tip.comments_count : 0;

            // check there are comments
            $scope.noComments = $scope.tip && ($scope.tip.has_comments === 'YES') ? false : true;

            // prepare URL for ads
            adsUrl = 'sports/' + tip.params.sportId + '/' +
                                  tip.params.catId.replace('-', '_') + '/' +
                                  (tip.params.league  ? tip.params.league  : tip.params.leagueId) + '/' +
                                  (tip.params.eventId ? tip.params.eventId : tip.params.eventName);

            // get initial comments
            if (!$scope.noComments) {
                getComments({
                    sportId: tip.vc_sport_id,
                    loadingPage: false
                });
            } else {
                commentsLoaded.resolve();
                isEmpty();
            }

            Betslip.sync(); // force a betslip sync!

            // Proceed once Share Strings, Comments and the Betslip are ready
            $q.all([commentsLoaded.promise, generateStack(tip)])
                .then(function () {

                    $scope.swipeReady = true;

                    // finally show interface!
                    $scope.detailsShow = true;

                    // force comments repaint to prevent
                    // iOS bug (cutting comments in half inside scroll element)
                    $timeout(function () {
                        $scope.forceRepaint = true;
                    }, 50);
                });
        }

        function generateStack(tip) {

            var deferred = $q.defer();

            var stack = Swipe.getStack($scope.stackID);
            if (stack && stack.length) {
                setIndex(tip.tip_hash, stack);
                deferred.resolve(); // there's a stack!
            } else {
                var vc_event_id = tip.vc_event_id,
                    marketid    = tip.marketid,
                    url         = Environment.APIurls.tipsByMarket
                                    .replace('{:event_id}', vc_event_id)
                                    .replace('{:market_id}', marketid);

                // attempt to create the stack for this market and event
                Request.get(url)
                    .then(function (r) {
                        var response  = r.data,
                            tipsArray = response && response.tips ? response.tips.map(function (tip) {
                                    return tip.tip_hash;
                                }) : [],
                            index = tipsArray.indexOf(tip.tip_hash);

                        if (tipsArray.length) {
                            Swipe.setStack({
                                id: $scope.stackID,
                                stack: tipsArray
                            });

                            setIndex(tip.tip_hash, tipsArray);
                        }

                        // all done!
                        deferred.resolve();
                    })
                    .catch(deferred.resolve);

            }

            return deferred.promise;
        }

        function setIndex(tipHash, stack) {
            var index = stack.indexOf(tipHash);
            Swipe.setIndex({
                id: $scope.stackID,
                index: index
            });
        }

        function loadPage(opts) {

            opts = angular.extend({
                isSwiping: false
            }, opts);

            // set flags
            currentPage                 = 1;
            commentsLoaded              = $q.defer();
            tipLoaded                   = $q.defer();
            $scope.forceRepaint         = false;
            $scope.detailsShow          = false;
            $scope.noticeIsError        = false;
            $scope.tipDetails.connected = true;
            $scope.expired              = false;
            $scope.showRecommendations  = false;
            $scope.expiration           = { tipExpired : false };
            $scope.isAboutToExpire      = Tips.isAboutToExpire;
            $scope.betslip              = Betslip.getOutcomes();
            $scope.isFetching           = true;

            // check if tip exists
            if (opts.isSwiping || !$scope.tip || ($scope.tip.tip_hash !== $state.params.tipId)) {

                // there's no tip stored (user probably got here using permalink)
                // get tip from database
                getTipFromServer(opts.isSwiping ? opts.id : $state.params.tipId);

            } else {

                $scope.tipDetails.connected = true;

                // there's a tip already!
                if ((!angular.isDefined($scope.tip.isFormatted) || !$scope.tip.isFormatted)) {

                    // but it's unformatted!
                    // user might be coming from Tips By Market page
                    GeneralSettings.get(settings.labels.oddsFormat, function (response) {
                        prepareTip($scope.tip, response.name);
                        tipLoaded.resolve();
                    }, function () {
                        prepareTip($scope.tip);
                        tipLoaded.resolve();
                    });
                } else {
                    // it's ready!
                    tipLoaded.resolve();
                }
            }

            // proceed to load everything once we have the tip
            tipLoaded.promise.then(tipLoadedHandler)
                .catch(function () {
                    // show interface anyways
                    $scope.swipeReady  = true;
                    $scope.detailsShow = true;
                });

            return tipLoaded.promise;
        }

        function scrollToComment(id) {
            $scope.scrollToComment = id;
        }

        $scope.loadMoreComments = function () {

            // if still fetching return
            if ($scope.isFetching || $scope.endOfList || !$scope.forceRepaint) {
                return;
            }

            // start loading!
            $scope.isFetching      = true;
            $scope.commentsLoading = true;

            // there are no more comments!
            if (!hasMoreComments) {

                $timeout(function () {

                    isEndOfPage();

                }, settings.loadingDelay);

                if(!$scope.$$phase) {
                    $scope.$apply();
                }
                return;
            }

            // console.log('Loading more comments');

            // increase page
            currentPage++;

            getComments({
                sportId: sportId ? sportId : $scope.tip.vc_sport_id,
                loadingPage: true
            });

            if(!$scope.$$phase) {
                $scope.$apply();
            }
        };

        $scope.goToTips = function () {

            Navigation.setTransition({
                resetNavigator: true
            });

            $state.go('sports.categories.leagues.events.tips', $stateParams);
        };

        $scope.goToTipster = function (tip, comment) {

            isOlbgXX = Utils.isOlbgXX();

            if (!isOlbgXX) {

                Utils.olbgMainApi.navigateTo(AppSettings.states.hotTipster, {

                    resetNavigator: false,

                    params: {
                        tipsterId   : comment.tipsterid,
                        sportId     : tip.vc_sport_id,
                        sportName   : tip.sport_alias.replace(' ', '_'),
                        tipsterName : comment.user.replace(' ', '_')
                    }
                });
            }
        };

        $scope.reloadView = function () {
            // attempt to get tip again
            $scope.detailsShow = false;
            $scope.tipDetails.connected = true;
            $timeout(loadPage, 50);
        };

        $scope.swipeTip = function (opts) {

            var deferred = $q.defer(),
                animationPromise = opts.animationPromise;

            $scope.sectionTitle = Lang.loader.title;

            animationPromise.then(function () {

                Swipe.resetAnimation($scope.stackID);

                // load the page again
                loadPage({
                    isSwiping: true,
                    id: opts.stackEl
                })
                    .finally(deferred.resolve);

            });

            return deferred.promise;
        };

        loadPage();

        // clear the cache when navigating away
        $scope.$on('$destroy', function () {
            // clear the cache!
            TIPS_CACHE.clear();
            COMMENTS_CACHE.clear();
        });
    })


    // the comment scroll functionality
    .directive('tipDetailsCommentScroll', ['$timeout', function ($timeout) {
        return {
            scope: {
                tipDetailsCommentScroll: '=',
                comment: '='
            },
            link: function (scope, el, attrs) {
                scope.$watch('tipDetailsCommentScroll', function (v) {
                    if (v && v == scope.comment.tipid) {

                        $timeout(function () {
                            var scroller = el.closest('#comment-scroll'),
                                pos      = el[0].offsetTop;

                            scroller.scrollTop(pos);
                        }, 300);
                    }
                });
            }
        };
    }]);

})(moment);