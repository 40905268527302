angular.module('olbg-web-app')

.factory('olbgDialog', ['$rootScope', 'ngDialog', 'AppSettings', '$timeout', '$q', 'Utils', function ($rootScope, ngDialog, AppSettings, $timeout, $q, Utils) {

    /**
     * IDEALLY:
     * + this service could be improved in many ways in the future
     *   to allow replacing alert box contents for when the user
     *   opens a new alert box for example.
     *
     * + add support for at least one more button.
     *   change the template generic-dialog-modal.html accordingly
     *   to hold one more button.
     */
    var currentDialog,
        service;


    service = {
        getDefaultOptions: function () {
            return {

                /**
                 * some values need to be an array. remember than a dialog box can have
                 * more than one button. (maybe two)
                 */

                type: 'confirm', // if it's anything else than confirm: normal dialog is open
                contents: {
                    title:       'Generic Title',
                    message:     'Generic Message',
                    actions:     ['Generic Button'],
                    buttonTypes: ['neutral'],
                    centered:    false
                },
                scope:            $rootScope,
                closeByEscape:    true,
                closeByDocument:  false,
                showClose:        true,
                hideButtons:      false,
                closeWhenConfirm: [true],
                actions: [],
                closeHandler:   function () {
                    // console.log('Generic Dialog: The dialog has been dismissed');
                },
                confirmHandler: function () {
                    // console.log('Generic Dialog: The dialog has been confirmed!');
                }
            };
        },

        open: function (opts) {

            /**
             * olbgDialog.open
             * @opts {object} The options for the standard dialog box
             *
             * This method opens a standard dialog box comprised of
             * a title, text and one or two buttons. It can be a simple
             * dialog box or a confirm box (specified in the opts object)
             *
             * IDEALLY:
             * in the future we should add support for loaders
             * and even pass different templates or states for the dialog.
             * And maybe support handlers for when the dialog box is closed.
             *
             */

            var deferred = $q.defer(),
                delay    = angular.isDefined(opts.delay) ? opts.delay : 0,
                defaultOptions = service.getDefaultOptions();

            $timeout(function () {

                var contents          = angular.copy(defaultOptions.contents),
                    finalOptions      = angular.extend(defaultOptions, opts);
                finalOptions.contents = angular.extend(contents, opts.contents);

                function olbgDialogCtrl($scope) {

                    /**
                     * olbgDialogCtrl
                     * generic controller
                     */

                    // prepare data to be used
                    $scope.genericDialog = {};
                    $scope.genericDialog.contents    = finalOptions.contents;
                    $scope.genericDialog.actions     = finalOptions.actions;
                    $scope.genericDialog.options     = finalOptions;

                    $scope.genericDialog.executeButton = function (index) {

                        /**
                         * executeButton
                         *
                         * it runs the actions in the array passed to opts.
                         */

                        if (finalOptions.actions.length) {
                            // run the attached action
                            if (angular.isDefined(finalOptions.actions[index])) {
                                finalOptions.actions[index]();
                            }
                        }

                        // close dialog box if it's in the options
                        if (angular.isDefined(finalOptions.closeWhenConfirm[index]) && !finalOptions.closeWhenConfirm[index]) {
                            return;
                        }

                        // finally close the dialog box
                        ngDialog.closeAll();
                    };
                }

                // sanitize stuff
                defaultOptions.contents.title         = Utils.sanitizeHTML(defaultOptions.contents.title);
                defaultOptions.contents.message       = Utils.sanitizeHTML(defaultOptions.contents.message);
                defaultOptions.contents.actions       = defaultOptions.contents.actions.map(function (o) { return Utils.sanitizeHTML(o); });
                defaultOptions.contents.footer        = defaultOptions.contents.footer       ? Utils.sanitizeHTML(defaultOptions.contents.footer)       : '';
                defaultOptions.contents.illustration  = defaultOptions.contents.illustration ? Utils.sanitizeHTML(defaultOptions.contents.illustration) : '';

                finalOptions.className                = 'ngdialog-theme-default ' + (defaultOptions.className ? defaultOptions.className : '');
                finalOptions.template                 = AppSettings.modals.genericDialog;
                finalOptions.controller               = ['$scope', olbgDialogCtrl];
                finalOptions.scope                    = defaultOptions.scope;
                finalOptions.closeByEscape            = defaultOptions.closeByEscape;
                finalOptions.closeByDocument          = defaultOptions.closeByDocument;
                finalOptions.showClose                = defaultOptions.type === 'confirm' ? false : defaultOptions.showClose;

                currentDialog = defaultOptions.type === 'confirm' ? ngDialog.openConfirm(finalOptions) : ngDialog.open(finalOptions);

                deferred.resolve(currentDialog);

                // CLOSE EVENT: handlers are optional
                if (finalOptions.type === 'confirm') {
                    currentDialog.then(finalOptions.confirmHandler).catch(finalOptions.confirmHandler);
                } else {
                    currentDialog.closePromise.then(finalOptions.closeHandler).catch(finalOptions.closeHandler);
                }
            }, delay);

            return deferred.promise;
        }
    };

    return service;
}]);