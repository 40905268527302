(function () {

    angular.module('olbg-web-app')

    .controller('offersCtrl', [

        '$scope',
        'AppSettings',
        'GeneralSettings',
        'Offers',
        'Utils',
        '$timeout',
        'matchmedia',
        'Tracker',

        function offersCtrl($scope, AppSettings, GeneralSettings, Offers, Utils, $timeout, matchmedia, Tracker) {

            /**
             * offers page controller
             *
             * by: Adrian Radu (adrianr@olbg.com)
             */

            function init() {
                that.connected = true;
                Offers.init().then(function () {
                    // update offers ->
                    Offers.updateOffers().then(function (data) {
                        that.show = true;
                        // that.triggerViewed();
                    })
                    .catch(function () { Utils.olbgMainApi.disconnect(that); });
                });
            }

            var that            = this,
                viewedOffers    = [],
                numToReport     = 0;

            this.offersList     = {};
            this.show           = false;
            this.noOffers       = true;
            this.connected      = true;
            this.reload         = init;
            $scope.sectionTitle = AppSettings.offersPage.sectionTitle;

            // for impressions -> set element to listen for scroll
            this.scrollParent   = $scope.olbgMain.isWebApp ? 'body' : '.page__content';

            // required by the empty-message directive
            $scope.emptyMessage = {
                title:   Utils.sanitizeHTML(AppSettings.offersPage.empty.title),
                message: Utils.sanitizeHTML(AppSettings.offersPage.empty.message)
            };

            this.isTablet   =  matchmedia.is(AppSettings.labels.mq.ipadLandscape);

            this.offersList = Offers.getOffersModel();


            /**
             * this is passed to olbg-impressions
             * as a callback. it's triggered at least
             * once if there are offers on screen and then
             * whenever a new offer enters the screen.
             * @param  {int} num the number of offers viewed
             * @return void
             */
            this.reportViewedOffers = function (num) {

                var offersArray = [],
                    throttle    = num ? 500 : 0;

                numToReport = num;

                $timeout(function () {
                    if (numToReport === num) {

                        var viewed = that.offersList['in-offers-tab']
                            .slice(0, num)
                            .map(function (offer) {
                                return offer.id;
                            });

                        if (viewed && viewed.length) {
                            Offers.addViewedOffers(viewed).then(Offers.countNewOffers);
                            Offers.sendImpressions(viewed);
                        }
                    }
                }, throttle); // add a bit of throttle
            };

            // init offers
            init();
        }
    ]);
})();
