/*==========  Routing Configuration for OLBG  ==========*/

// define possible ROUTES for home page
var HOME_ROUTES = {};

(function (olbgMeta) {

angular.module('olbg-web-app')

    .config(function ($stateProvider, $urlRouterProvider, $locationProvider, AppSettingsProvider, EnvironmentProvider, UtilsProvider, $injector, LangProvider, PlatformSettingsProvider) {

        /**
         * Here we control the different states for the application.
         * Each state has a template URL associated and optionally
         * an action, a navigator label and an animation.
         * If the action is navigate, then navigator (nav) is required.
         *
         * Possible actions: navigate. Not passing an action
         * will default to the main navigation mode.
         */


        // get settings
        var lang             = LangProvider.$get(),
            settings         = AppSettingsProvider.$get(),
            environment      = EnvironmentProvider.$get(),
            basePath         = environment.info.basePath || '/',
            isHybrid         = olbgMeta.type === 'hybrid',
            isWebApp         = olbgMeta.type === 'web-app',
            isCore           = !isHybrid && !isWebApp,
            isiOS            = olbgMeta.type === 'hybrid' && olbgMeta.platform === 'ios',
            isGooglePlay     = olbgMeta.isGooglePlay,
            isBetting        = angular.isDefined(olbgMeta.bettingVersion) ? olbgMeta.bettingVersion : true,
            platformSettings = PlatformSettingsProvider.$get(),
            HOME_PAGE        = localStorage ? localStorage.getItem('homePage') : '';
            // isBetting   = angular.isDefined(olbgMeta.bettingVersion) ? olbgMeta.bettingVersion : true; // DEBUG

            HOME_ROUTES.home        = { label: lang.titles.home,            value: 'home',                  state: settings.states.home,        tabbar: 'home'     };
            HOME_ROUTES.hotTips     = { label: lang.titles['hot-tips'],     value: 'hot-tips',              state: settings.states.hotTips,     tabbar: 'tips'     };
            HOME_ROUTES.hotTipsters = { label: lang.titles['hot-tipsters'], value: 'hot-tipsters/All/All',  state: settings.states.hotTipsters, tabbar: 'tipsters' };
            // HOME_ROUTES.myTipsters  = { label: lang.titles['my-tipsters'],  value: 'my-tipsters',           state: settings.states.myTipsters,  tabbar: 'tipsters' };
            HOME_ROUTES.favorites   = { label: lang.titles.favorites,       value: 'settings/favourites/',  state: settings.states.favorites,   tabbar: 'tips'     };

            HOME_PAGE = HOME_ROUTES[(HOME_PAGE ? HOME_PAGE : 'home')].value;

            //Default routes, get translated routes from host
            localRoutes      = {
                'hot-tips'      : 'hot-tips',
                'sports'        : 'sports',
                'your-bets'     : 'your-bets',
                'offers'        : 'offers',
                'offersTerms'   : 'offersTerms'
            };
            if (typeof appRoutes != "undefined") {
                // we have translated routes from host
                // console.log('TRANSLATED ROUTES: appRoutes exists');
                angular.forEach(localRoutes, function(value, key) {
                    if (angular.isDefined(appRoutes[key])) {
                        this[key] = appRoutes[key];
                    }
                }, localRoutes);
            }

        $stateProvider

            /*----------  Home  ----------*/
            .state(settings.states.home, {
                url: basePath + 'home',
                options: {
                    templateUrl:      settings.pages.home,
                    menuSelection:    'home',
                    iOSMenuSelection: 'home',
                    iPadTitle:         lang.titles.home
                }
            })

            /*----------  Tips  ----------*/

            // Hot Tips
            .state(settings.states.hotTips, {
                url: basePath + localRoutes['hot-tips'],
                options: {
                    templateUrl:      settings.pages['hot-tips'],
                    menuSelection:    'hot tips',
                    iOSMenuSelection: 'tips',
                    iPadTitle:         lang.hotTips.sectionTitle
                }
            })

            // Tips By Sports
            .state(settings.states.sports, {
                url: basePath + localRoutes.sports + '/',
                options: {
                    templateUrl:      settings.pages['sports-chooser'],
                    pageTitle:        lang.sportsSelect.sectionTitle,
                    menuSelection:    'tips by sport',
                    iOSMenuSelection: 'tips',
                    iPadTitle:        lang.sportsSelect.sectionTitle
                },
            })

            // Categories
            .state(settings.states.categories, {

                /**
                 * event ID might be present at any given time
                 * in the Sports By Navigation tree.
                 */

                 url: '{sportId}/?eventId&league',
                 options: {
                     templateUrl:      settings.pages.categories,
                     noParentLoad:     true,
                     menuSelection:    'tips by sport',
                     iOSMenuSelection: 'tips',
                     iPadTitle:         lang.sportsSelect.sectionTitle
                 }
             })

            // Leagues pages
            .state(settings.states.leagues, {
                url: '{catId}/',
                options: {
                    templateUrl:      settings.pages.leagues,
                    noParentLoad:     true,
                    menuSelection:    'tips by sport',
                    iOSMenuSelection: 'tips',
                    iPadTitle:         lang.sportsSelect.sectionTitle
                }
            })

            // Events
            .state(settings.states.events, {
                url: '{leagueId}/',
                options: {
                    templateUrl:      settings.pages.events,
                    noParentLoad:     true,
                    menuSelection:    'tips by sport',
                    iOSMenuSelection: 'tips',
                    iPadTitle:         lang.sportsSelect.sectionTitle
                }
            })

            // Tips By Market
            .state(settings.states.tips, {
                url: '{eventName}/?breakCache',
                options: {
                    templateUrl:      settings.pages.tips,
                    noParentLoad:     true,
                    menuSelection:    'tips by sport',
                    iOSMenuSelection: 'tips',
                    iPadTitle:         lang.sportsSelect.sectionTitle
                }
            })

            // Tip Detail
            .state(settings.states.tipDetail, {
                url: '{tipId}/?commentId',
                options: {
                    templateUrl:      settings.pages['tip-detail'],
                    menuSelection:    'tips by sport',
                    iOSMenuSelection: 'tips',

                    openInRightPanel: true
                }
            })

            /*----------  Hot Tipsters  ----------*/

            .state(settings.states.hotTipsters, {
                url: basePath + 'hot-tipsters/{sportName}/{sportId}?tab}',
                options: {
                    templateUrl:      settings.pages['hot-tipsters'],
                    pageTitle:        settings.titles['hot-tipsters'],
                    menuSelection:    'hot tipsters',
                    iOSMenuSelection: 'tipsters',

                    iPadTitle:         lang.hotTipsters.sectionTitle
                }
            })

            .state(settings.states.hotTipster, {
                url: '/{tipsterName}/{tipsterId}?tipsterTab',
                options: {
                    templateUrl:      settings.pages.tipster,
                    pageTitle:        settings.titles['tipster-page'],
                    menuSelection:    'hot tipsters',
                    iOSMenuSelection: 'tipsters',

                    openInRightPanel: true
                }
            })

            /*----------  My Acca / Daily Accas  ----------*/

            // My Acca
            .state(settings.states.myAccaSettings, {
                url: basePath + 'my-acca',
                options: {
                    templateUrl:      settings.pages['my-acca-settings'],
                    pageTitle:        settings.titles['my-acca-settings'],
                    menuSelection:    'my acca',
                    iOSMenuSelection: 'tips',

                    iPadTitle:         lang.titles['my-acca-settings']
                }
            })

            // Acca Review
            .state(settings.states.myAccaReview, {
                url: '/review',
                options: {
                    templateUrl:      settings.pages['my-acca-review'],
                    pageTitle:        settings.titles['my-acca-review'],
                    menuSelection:    'my acca',
                    iOSMenuSelection: 'tips',

                    openInRightPanel: true
                }
            })

            // My Daily Accas list
            .state(settings.states.myDailyAccasList, {
                url: basePath + 'my-daily-accas/?acca-id',
                options: {
                    templateUrl:      settings.pages['my-daily-accas-list'],
                    pageTitle:        lang.titles['my-daily-accas-list'],
                    menuSelection:    'daily accas',
                    iOSMenuSelection: 'tips',

                    iPadTitle:        lang.titles['my-daily-accas-list']
                }
            })

            // Winner Acca (accessible via Deep Link)
            .state(settings.states.winnerAcca, {
                url: basePath + 'winner-acca?internal_acca_id',
                options: {
                    templateUrl:      settings.pages['winner-acca'],
                    pageTitle:        lang.titles['winner-acca'],
                    iPadTitle:        lang.titles['winner-acca']
                }
            })

            /*----------  My OLBG  ----------*/

            // My Sports
            .state(settings.states.mySports, {
                url: 'my-sports/',
                options: {
                    templateUrl:      settings.pages['my-sports'],
                    pageTitle:        settings.titles['my-sports'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // My Leagues
            .state(settings.states.myLeagues, {
                url: 'my-leagues/',
                options: {
                    templateUrl:      settings.pages['my-leagues'],
                    pageTitle:        settings.titles['my-leagues'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            /*----------  Settings  ----------*/

            // General Settings
            .state(settings.states.settings, {
                url: basePath + 'settings/',
                options: {
                    templateUrl:      settings.pages.settings,
                    pageTitle:        settings.titles.settings,
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    iPadTitle:         lang.titles.settings
                }
            })

            // Filtering and Order
            .state(settings.states.filterHotTips, {
                url: 'filters/',
                options: {
                    templateUrl:      settings.pages['filter-settings'],
                    pageTitle:        settings.titles['filter-settings'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // Home Page
            .state(settings.states.changeHomepage, {
                url: 'change-homepage/',
                options: {
                    templateUrl:      settings.pages['change-homepage'],
                    pageTitle:        settings.titles['change-homepage'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // Odds
            .state(settings.states.oddsSettings, {
                url: 'odds-settings/',
                options: {
                    templateUrl:      settings.pages['odds-settings'],
                    pageTitle:        settings.titles['odds-settings'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            .state(settings.states.savensync, {
                url: 'save-n-sync',
                options: {
                    templateUrl:      settings.pages.savensync,
                    pageTitle:        settings.titles.savensync,
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // My Daily Accas
            .state(settings.states.myDailyAccas, {
                url: 'my-daily-accas/',
                options: {
                    templateUrl:      settings.pages['my-daily-accas'],
                    pageTitle:        settings.titles['my-daily-accas'],
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // Terms & Conditions
            .state(settings.states.terms, {
                url: 'terms/',
                options: {
                    templateUrl:      settings.pages['content-page'],
                    pageTitle:        settings.titles.terms,
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true,

                    // Pass the content URL from where the content
                    // will be loaded
                    contentURL:  environment.APIurls.terms
                }
            })

            // Contact
            .state(settings.states.contact, {
                url: 'contact/?option&debug',
                options: {
                    templateUrl:      settings.pages.contact,
                    pageTitle:        settings.titles.contact,
                    menuSelection:    'my account',
                    iOSMenuSelection: 'settings',

                    openInRightPanel: true
                }
            })

            // Not Found
            .state(settings.states.notFound, {
                url: basePath + 'notFound',
                options: {
                    templateUrl: settings.pages['not-found'],
                    animation: 'none',
                    pageTitle: settings.titles['not-found']
                }
            });


        /*========================================
        =            BETTING VERSION            =
        ========================================*/

        if (isBetting) {

            $stateProvider

                // Your Bets
                .state(settings.states.betsManager, {
                    url: basePath + localRoutes['your-bets'] + '/' + (true ? '?selections' : ''),
                    options: {
                        templateUrl:      settings.pages['your-bets'],
                        pageTitle:        settings.titles.bets,
                        menuSelection:    'betslip',
                        iOSMenuSelection: 'bets',

                        iPadTitle:         lang.titles.bets
                    }
                })

                // Choose Bookie
                .state(settings.states.bookiesSelector, {
                    url: 'choose-bookie?bookieId&token&expire',
                    options: {
                        templateUrl:      settings.pages['choose-bookie'],
                        pageTitle:        settings.titles['choose-bookie'],
                        menuSelection:    'betslip',
                        iOSMenuSelection: 'bets',

                        openInRightPanel: true
                    }
                })

                // My Bookies Accounts
                .state(settings.states.myBookiesAccounts, {
                    url: 'my-bookies-accounts/',
                    options: {
                        templateUrl:      settings.pages['my-bookies-accounts'],
                        pageTitle:        settings.titles['my-bookies-accounts'],
                        menuSelection:    'my account',
                        iOSMenuSelection: 'settings',

                        openInRightPanel: true
                    }
                });
        }

        // Google Play may show offers tab too
        if (isBetting || isGooglePlay) {

            $stateProvider

            // Offers Page
            .state(settings.states.offers, {
                url: '/' + localRoutes.offers,
                options: {
                    templateUrl:      settings.pages.offers,
                    pageTitle:        settings.titles.offers,
                    menuSelection:    'offers',
                    iOSMenuSelection: 'offers',

                    iPadTitle:         lang.titles.offers
                }
            })

            // Offer Terms Page
            .state(settings.states.offersTerms, {
                url: '/' + localRoutes.offersTerms,
                options: {
                    templateUrl:      settings.pages['offers-terms'],
                    pageTitle:        settings.titles['offers-terms'],
                    menuSelection:    'offers',
                    iOSMenuSelection: 'offers',

                    openInRightPanel: true
                }
            });
        }

        /*============================================================
        =                   HYBRID SPECIFIC PAGES                    =
        ============================================================*/

        if ((isHybrid || isCore)) {

            $stateProvider

                /*----------  1.6.0 Android / 3.3.0 iOS  ----------*/

                // My Tipsters
                .state(settings.states.myTipsters, {
                    url: basePath + 'my-tipsters?tab&outcome',
                    options: {
                        pageTitle:        lang.titles['my-tipsters'],
                        templateUrl:      settings.pages['my-tipsters'],
                        menuSelection:    'my tipsters',
                        iOSMenuSelection: 'tipsters',

                        iPadTitle:         lang.titles['my-tipsters']
                    }
                })

                // Notification settings
                .state(settings.states.streamSettings, {
                    url: 'notifications/',
                    options: {
                        templateUrl:      settings.pages['stream-settings'],
                        pageTitle:        settings.titles['stream-settings'],
                        menuSelection:    'my account',
                        iOSMenuSelection: 'settings',

                        openInRightPanel: true
                    }
                })

                // Favorites [CUSTOMIZATION]
                // it now belongs to settings page
                .state(settings.states.favorites, {
                    url: 'favourites/',
                    options: {
                        templateUrl:      settings.pages.favorites,
                        pageTitle:        settings.titles.favorites,
                        menuSelection:    'favourites',
                        iOSMenuSelection: 'tips',

                        openInRightPanel: true
                    }
                })

                // Contact
                .state(settings.states.installVerification, {
                    url: 'install-verification',
                    options: {
                        templateUrl:      settings.pages.installVerification,
                        pageTitle:        settings.titles.installVerification,
                        menuSelection:    'my account',
                        iOSMenuSelection: 'settings',

                        openInRightPanel: true
                    }
                });
        }

        // set first page to whatever the user
        // has set or default to >>> HOME
        $urlRouterProvider.otherwise(basePath + HOME_PAGE);

        /**
         * For Correct Sharing of links and updating share count you must
         * enable HTML5 Mode True for your application.
         * i.e No # in URL'S .
         */

        $locationProvider.html5Mode({
            enabled: true,
            requireBase: false
        }).hashPrefix('!');
    });


})(window.OLBG_APP_META);
