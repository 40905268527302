angular.module('olbg-web-app')

.controller('olbgIframeContainerCtrl', ['$rootScope', '$scope', 'AppSettings', '$state', '$stateParams', 'Navigation', '$sce', 
    function olbgIframeContainerCtrl($rootScope, $scope, AppSettings, $state, $stateParams, Navigation, $sce) {

    var iframeInfo = Navigation.get($state.$current.self.name, AppSettings.labels.iframeInfo);

    if (!iframeInfo) {
        $scope.sectionTitle = 'Page Invalid';
        $scope.closeModal();
        return;
    }

    $scope.sectionTitle = iframeInfo.sectionTitle;

    $scope.iFrameScope = {
        url: $sce.trustAsResourceUrl(iframeInfo.url)
    };

}]);