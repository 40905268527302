(function (momentJS) {


    angular.module('olbg-web-app')

        // Support Service
        .factory('Support', function ($q, GeneralSettings, AppSettings, Environment, Tracker) {

            var supportReady,
                service = {},
                gettingSupport = false;

            function reject() {
                supportReady.reject();
            }

            function updateObj(oldObj, newObj) {

                /**
                 * updateObj
                 * @old {obj}
                 * @new {obj}
                 *
                 * returns an object with updated values
                 */

                 oldObj.timestamp = newObj.timestamp; // update timestamp

                 angular.forEach(newObj.settings, function (newValue) {

                    var addIt = true;

                    angular.forEach(oldObj.settings, function (oldValue) {
                        if (oldValue.key === newValue.key) {
                            addIt = false;
                            oldValue = newValue;
                        }
                    });

                    if (addIt) {
                        oldObj.settings.push(newValue);
                    }
                 });

                 return oldObj;
            }

            function getNew(currentObj) {

                /**
                 * getNew
                 *
                 * gets new support information from server
                 * @currentObj [{obj}] opt the current object
                 */

                var supportObj  = {},
                    currentDate = new Date(),
                    url = Environment.APIurls.support;

                if (angular.isDefined(currentObj)) {
                    url += '&changed_since=' + moment(currentObj.timestamp).format('YYYY-M-D');
                }

                Request.get(url)
                    .then(function (response) {

                        supportObj.settings = response.data.settings;
                        supportObj.timestamp = new Date().getTime();
                        supportObj = angular.isDefined(currentObj) ? updateObj(currentObj, supportObj) : supportObj;

                        supportReady.resolve(supportObj);
                        isGettingSupport = false;

                        // store object
                        GeneralSettings.store({
                            support: angular.toJson(supportObj)
                        });

                    })
                    .catch(function () {

                        if (angular.isDefined(currentObj)) {
                            supportReady.resolve(currentObj);
                        } else {
                            reject();
                        }

                        isGettingSupport = false;
                    });
            }

            service.get = function () {

                if (gettingSupport) {
                    return supportReady.promise;
                }

                supportReady = $q.defer();
                gettingSupport = true;

                // Get local object
                GeneralSettings.get('support', function (response) {

                    var ellapsed;
                    response = angular.fromJson(response.name);
                    ellapsed = angular.isDefined(response.timestamp) ? Math.round((new Date().getTime() - response.timestamp) / 60000) : false;

                    if (ellapsed !== false) {
                        if (ellapsed <= AppSettings.supportThreshold) {
                            // return stored data
                            supportReady.resolve(response);
                            isGettingSupport = false;
                        } else {
                            getNew(response);
                        }
                    } else {
                        getNew();
                    }
                }, function () {

                    // get new from server
                    getNew();

                });

                return supportReady.promise;
            };

            return service;
        });

})(moment);