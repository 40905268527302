(function (momentJS) {

    // get a reference from document
    var doc = angular.element(document);

    angular.module('olbg-web-app')

    /*==========  controller for the navigation toolbar  ==========*/

    .controller('toolbarCtrl', function (AppSettings, $scope, $state, Navigation) {
        var settings = AppSettings;
        // go back one page in the stack
        $scope.goBack = function () {

            // get the previous page
            var state = Navigation.getParentState();

            if(!state.name) {
                return;
            }

            // go to previous state
            // add going back modifier!
            Navigation.setTransition({
                resetNavigator: false,
                goingBack: true,
                animation: 'slide'
            });

            // change state!
            $state.go(state.name);
        };
    })



    /*==========  controller for the Page Stack Navigator  ==========*/

    .controller('mainNavigatorCtrl', function (AppSettings, $scope, $timeout) {
    })


    /*==========  controller for Sport By Navigation Pages  ==========*/

    .controller('sportsByNavigationCtrl', function (AppSettings, $rootScope, $scope, $q, $state, $stateParams, $attrs, Sports, Navigation, Utils, Tips, $location, Lang) {

        var el,
            navUrl,
            tipsEventListeners,
            settings            = AppSettings,
            currentState        = $state.$current.self.name,
            type                = $attrs.type,
            isiPadFirstLeftPage = false; // [iPad] hacky navigation

        // set initial titles
        function prepareTitle(state) {
            $scope.sectionTitle = Lang.loader.title;
        }

        // private function
        function loadPage() {

            var options = {},
                parts,
                eventId,
                league,
                leagueId,
                currentUrl;

            // update current state
            currentState = $state.$current.self.name;

            // load the page only when it's in the foreground
            // console.log('#####', !Utils.olbgMainApi.isiPad && Navigation.getStack({isContentPanel: true}).length);
            if (angular.isDefined(type) && type !== currentState) {

                // for iPad > load the previous page is content page is displayed
                if (!Utils.olbgMainApi.isiPad || (Utils.olbgMainApi.isiPad && !Navigation.getStack({isContentPanel: true}).length)) {

                    // prepare title for the page behind
                    if (!angular.isDefined($scope.sectionTitle)) {
                        prepareTitle(type);
                    }

                    return;

                }
            }


            // LOAD THE CONTENT!

            // console.log('Category Page Content is being LOADED!');

            leagueId = $stateParams.league ? $stateParams.league : ($stateParams.leagueId ? $stateParams.leagueId : '');
            // leagueId = Utils.olbgMainApi.isWebApp && leagueId ? leagueId.toLowerCase() : leagueId;

            leagueId = leagueId.replace(' ', '_');

            // Load current page content

            /**
             * if iPad should load parent state
             */

            if (Utils.olbgMainApi.isiPad && Navigation.getStack({isContentPanel: true}).length) {
                currentState = Navigation.getLatestStateInStack({isContentPanel: false});
                isiPadFirstLeftPage = true;
            }

            switch (currentState) {

                case 'sports.categories':
                    navUrl = $stateParams.sportId + '/';
                    break;

                case 'sports.categories.leagues':
                    navUrl = $stateParams.sportId + '/' + $stateParams.catId + '/';
                    break;

                case 'sports.categories.leagues.events':

                    navUrl = $stateParams.sportId +
                             '/' + $stateParams.catId +
                             '/' + leagueId + '/';

                    break;

                case 'sports.categories.leagues.events.tips':

                    currentUrl = Utils.olbgMainApi.isWebApp ? window.top.location : window.location;
                    parts      = currentUrl.search.substr(1).split('&');

                    angular.forEach(parts, function (part) {
                        var param = part.split('=');

                        if (param[0] === 'eventId') {
                            eventId = param;
                        }
                    });

                    navUrl = $stateParams.sportId + '/' +  $stateParams.catId + '/' + leagueId + '/' + $stateParams.eventName + '/menu_tips' + ((eventId && eventId[1]) ? ('?vc_event_id=' + eventId[1]) : '');

                    break;
            }

            // pass url to options
            options.url = navUrl;

            // prepare titles
            if (!angular.isDefined($scope.sectionTitle)) {
                prepareTitle(currentState);
            }

            if (currentState === 'sports.categories.leagues.events.tips') {

                options.isTipsByMarket = true;
                // options.usePagination  = true; // [EXPIRED EVENTS] don't use pagination for now

                // check if there are tips stored already!
                el = Tips.getTipsByMarket({
                    id: eventId && eventId.length ? eventId[1] : ''
                });

                if (el && el.tips.length) {
                    options.tips = el;
                    $scope.contentReady.resolve(options);
                } else {
                    // there are no tips. Resolve normally.
                    $scope.contentReady.resolve(options);
                }
            } else {
                $scope.contentReady.resolve(options);
            }
        }

        // public variables
        $scope.contentReady = $q.defer();

        // public functions
        $scope.updateSectionTitle = function (title) {
            $scope.sectionTitle = title;
        };

        $scope.disableBackButton = function () {
            $scope.backButtonDisabled = true;
        };

        $scope.enableBackButton = function () {
            if (!isiPadFirstLeftPage) {
                $scope.backButtonDisabled = false;
            }
        };

        $scope.hideBackButton = function () {
            $scope.backButtonHidden = true;
        };

        $scope.showBackButton = function () {
            $scope.backButtonHidden = false;
        };

        // Show back button by default
        $scope.showBackButton();

        // load page
        loadPage();

        // when user goes back: try to load again the page behind!
        $rootScope.$on('goingBack', function () {
            loadPage();
        });
    })


    /*==========  controller for Filter Settings Page  ==========*/

    .controller('filterSettingsPageCtrl', function (AppSettings, $scope) {

        var settings = AppSettings;

        $scope.sectionTitle = settings.filterSettings.sectionTitle;
    })


    /*==========  controller for Filter Settings form  ==========*/

    .controller('hotTipsSettingsCtrl', function (AppSettings, $rootScope, $scope, $q, $state, TipsSettings, Sports, Navigation, Tips, GeneralSettings, $filter, Utils, Lang, Preferences, APITranslate, OLBGSetup) {

        // private variables

        var settings        = AppSettings,
            pageReady       = $q.defer(),
            sportsReady     = $q.defer(),
            oddsFomatReady  = $q.defer(),
            sportsList      = [],
            subsportsList   = {},
            marketsList     = {},
            settingsService = TipsSettings,
            sportsService   = Sports,
            showSettings    = false,
            maxOddsArray    = AppSettings.hotTips.filter.maxOddsValues,
            maxOddsArrayLen = AppSettings.hotTips.filter.maxOddsValues.length - 1;

            // translations
            translations = {
                minNumberOfTips : Lang.hotTips.settings.minNumberOfTips,
                confidence      : Lang.hotTips.settings.confidence,
                minOddsValue    : Lang.hotTips.settings.minOddsValue,
                maxOddsValue    : Lang.hotTips.settings.maxOddsValue,
                all             : Lang.generic.all
            };

            $scope.translations = translations;


        // private functions

        function storeSetting(id, value) {

            /**
             * storeSetting
             *
             * @id {[string]}
             * @value {[string]}
             *
             * This function creates or updates the TipSettings douchdb
             */


            var defer = $q.defer();

            function put(id, value, rev) {

                if (!angular.isDefined(rev)) {
                    settingsService.put({_id: id, name: value}).then(function () {
                        // console.log(id + ' created with: ' + value);
                        defer.resolve();
                    });
                } else {
                    settingsService.put({_id: id, name: value, _rev: rev}).then(function () {
                        // console.log(id + ' updated with: ' + value);
                        defer.resolve();
                    });
                }
            }

            settingsService.get(id).then(function (response) {

                // update the record (make sure to use revision)
                put(id, value, response._rev);

            }, function (err) {

                // create the record
                put(id, value);

            });

            return defer.promise;
        }

        function confidenceFormat(value) {
            return value + '%';
        }

        function setDefaultValues() {
            // $scope.settingsToSave.order        = settings.hotTips.sort[settings.labels.sortOrder];
            $scope.settingsToSave.sport        = settings.hotTips.filter[settings.labels.sport];
            $scope.settingsToSave.subsport     = settings.hotTips.filter[settings.labels.subsport];
            $scope.settingsToSave.market       = settings.hotTips.filter[settings.labels.market];
            $scope.settingsToSave.tipsNumber   = settings.hotTips.filter.defaultTips;
            $scope.settingsToSave.confidence   = settings.hotTips.filter.defaultConfidence;
            $scope.settingsToSave.minOdds      = settings.hotTips.filter.defaultMinOddsValue;
            $scope.settingsToSave.maxOdds      = settings.hotTips.filter.defaultMaxOddsValue;
        }

        // scope variables (public)
        $scope.view = { connected: true };

        // hide the form until the select inputs are populated
        $scope.showForm                      = false;
        $scope.showSubsport                  = false;
        $scope.showMarkets                   = false;
        $scope.settingsToSave                = {};
        $scope.settings                      = {};
        $scope.minTipsValues                 = settings.hotTips.filter.minTipsValues;
        $scope.minConfidenceValues           = settings.hotTips.filter.minConfidenceValues;
        $scope.minOddsValues                 = settings.hotTips.filter.minOddsValues;
        $scope.maxOddsValues                 = settings.hotTips.filter.maxOddsValues;

        $scope.oddsFilter                    = $filter('olbgOddsFormat');
        $scope.oddsFilterParams              = [];

        // Generate sort order options
        $scope.settings.orderName            = settings.labels.sortOrder;
        $scope.settings.orderValues          = settings.labels.sortOptions;

        $scope.settings.sortTipsTitle        = Lang.labels.sortTipsTitle;
        $scope.settings.filtersAction        = Lang.labels.filtersAction;
        $scope.settings.filtersTitle         = Lang.labels.filtersAction + ':';
        $scope.settings.saveButton           = Lang.labels.saveButton;

        // Preferences
        $scope.settings.usingPref            = AppSettings.hotTips.byPreferencesDef;
        $scope.settings.filterMethods        = AppSettings.hotTips.filter.methods;
        $scope.settings.selectedFilterMethod = 'pref'; // by default: use preferences

        // Scope functions (public)

        $scope.selectOption = function (storeWhere, value) {

            var storeObj,
                elementToStore = $scope;

            function navigateToObject(storeWhere) {

                var elementsArray = storeWhere.split('.'),
                    nextObject    = elementsArray.splice(0,1),
                    len           = elementsArray.length;

                if (len > 1) {
                    elementToStore = elementToStore[nextObject];
                    navigateToObject(elementsArray.join('.'));
                } else {
                    return {
                        object: elementToStore[nextObject],
                        storeString: elementsArray[0]
                    };
                }
            }

            // get object reference
            storeObj = navigateToObject(storeWhere);

            // store actual value
            storeObj.object[storeObj.storeString] = value;
        };

        $scope.changeSubsport = function (sport, selectedSubsport) {

            /**
             * changeSubsport
             *
             * @sport [{string}]
             *
             * Everytime a sport is selected in the sports dropdown
             * we check if sport is football or horse racing.
             * If either of those is selected we show the subsport select input
             * disabled until subsport categories are fetched.
             *
             */

            var subsportListReady = $q.defer(),
                selectedSport     = sport.toLowerCase();

            // refresh the ng-model for sub sports so that All Leagues are selected
            // if no subsport is selected already
            $scope.settingsToSave.subsport = selectedSport === 'football' ? Lang.hotTips.filter.allLeagues : Lang.hotTips.filter.allCourses;

            if (selectedSport !== angular.lowercase(Lang.subsports.football) && selectedSport !== angular.lowercase(Lang.subsports.horseRacing)) {
                $scope.showSubsport = false;
                return;
            }

            // if selected sport doesn't exist create it:
            if (!angular.isDefined(subsportsList[sport])) {
                // create array
                subsportsList[sport] = [];
            }

            // disable subsport select box
            $scope.subsportDisabled = true;

            // first add 'all subsport' option if list is empty
            if (!subsportsList[sport].length) {

                subsportsList[sport][0] = {
                    subsport: selectedSport === 'football' ? Lang.hotTips.filter.allLeagues : Lang.hotTips.filter.allCourses,
                    having:   selectedSport === 'football' ? Lang.hotTips.filter.allLeagues : Lang.hotTips.filter.allCourses
                };
            }

            // if sports contain more than one entry: immediately resolve
            if (subsportsList[sport].length > 1) {

                // there are sports already
                subsportListReady.resolve(selectedSubsport);

            } else {
                // get subsport
                sportsService.getSubSports(sport, function (sportsArray) {
                    // update subsports list
                    angular.forEach(sportsArray, function (value) {
                        subsportsList[sport].push({
                            subsport: value.subsport,
                            having: value.filters.having[0]
                        });
                    });

                    // sports selected
                    subsportListReady.resolve(selectedSubsport);
                });
            }

            // show subsport select box
            $scope.showSubsport = true;

            // expose selected sport subsports list to form
            $scope.settings.subsportsList = subsportsList[sport];

            // subsports are ready
            subsportListReady.promise.then(function (subsport) {

                if (subsport) {
                    $scope.settingsToSave.subsport = subsport; // update selection
                }

                // enable sports select inputs again
                $scope.subsportDisabled = false;
            });
        };

        /**
         * get markets everytime the sport changes
         * @param  {[type]} sport [description]
         * @return {[type]}       [description]
         */
        $scope.changeMarkets = function (sport, selectedMarket) {
            if (sport === Lang.labels.allSports) {

                $scope.showMarkets           = false;
                $scope.settingsToSave.market = Lang.hotTips.filter.allMarkets;


            } else {

                $scope.showMarkets = true;

                var marketsReady  = $q.defer(),
                    selectedSport = sport.toLowerCase();

                // refresh the ng-model for markets so that All Markets are selected
                // if no subsport is selected already
                $scope.settingsToSave.market = Lang.hotTips.filter.allMarkets;

                marketsList[sport] = marketsList[sport] ? marketsList[sport] : [];

                // disable subsport select box
                $scope.marketsDisabled = true;

                // first add 'all subsport' option if list is empty
                if (!marketsList[sport].length) {
                    marketsList[sport][0] = {
                        market:   Lang.hotTips.filter.allMarkets,
                        having:   Lang.hotTips.filter.allMarkets
                    };
                }

                // if markets contain more than one entry: immediately resolve
                if (marketsList[sport].length > 1) {
                    marketsReady.resolve(selectedMarket);
                } else {

                    // get markets
                    sportsService.getMarkets(sport).then(function (marketsArray) {

                        // update subsports list
                        angular.forEach(marketsArray, function (value) {
                            marketsList[sport].push({
                                market: APITranslate.getTranslation(value.name),
                                having: settings.labels.market + value.marketid
                            });
                        });

                        // sports selected
                        marketsReady.resolve(selectedMarket);
                    });
                }

                // expose selected marketlist to form
                $scope.settings.marketsList = marketsList[sport];

                // subsports are ready
                marketsReady.promise.then(function (market) {

                    if (market) {
                        $scope.settingsToSave.market = market; // update selection
                    }

                    // enable sports select inputs again
                    $scope.marketsDisabled = false;

                    $scope.showMarkets = marketsList[sport].length > 1;
                });
            }
        };

        /**
         * being passed to ng-change in the sports input
         * @param  {String} sport the sport
         * @return {void}
         */
        $scope.getRelatedData = function (sport) {
            $scope.changeSubsport(sport);
            $scope.changeMarkets(sport);
        };

        $scope.updateSettings = function (submittedSettings) {

            var filtersSaved,
                prefSaved,
                allSaved  = $q.defer(),
                sortSaved = storeSetting(settings.labels.sortOrder, submittedSettings[settings.labels.sortOrder]);

            function processFilters () {

                var sport,
                    subsport,
                    market,
                    filters = [],
                    hash    = {},
                    defer   = $q.defer(),
                    storeFilters = false;

                /**
                 * processFilers
                 * it creates the filters array and passes it
                 * to the storeSettings function.
                 *
                 * it also creates a hash to be used when updating UI controls
                 * for the filters.
                 *
                 * Returns a promise
                 */

                // Check Filter Status [save it only to hash]
                hash[settings.labels.filterStatus] = $scope.settingsToSave.filterStatus;
                storeFilters = hash[settings.labels.filterStatus];

                if (storeFilters) {

                    // sports
                    sport = ($scope.settingsToSave.sport === 'All Sports') ? '' : $scope.settingsToSave.sport;
                    hash[settings.labels.sport] = sport;
                    if (sport) {
                        filters.push(encodeURIComponent(settings.labels.sport + sport));
                    }

                    // subsports
                    subsport = ($scope.settingsToSave.subsport === Lang.hotTips.filter.allLeagues || $scope.settingsToSave.subsport === Lang.hotTips.filter.allCourses) ? '' : $scope.settingsToSave.subsport;
                    hash[settings.labels.subsport] = subsport;
                    if (subsport) {
                        filters.push(hash[settings.labels.subsport]);
                    }

                    // markets
                    market = ($scope.settingsToSave.market === Lang.hotTips.filter.allMarkets) ? '' : $scope.settingsToSave.market;
                    hash[settings.labels.market] = market;
                    if (market) {
                        filters.push(hash[settings.labels.market]);
                    }

                    // Minimum number of tips
                    hash[settings.labels.tipsNum] = $scope.settingsToSave.tipsNumber;
                    filters.push(settings.labels.tipsNum + hash[settings.labels.tipsNum]);

                    // Confidence (trust)
                    hash[settings.labels.confidence] = $scope.settingsToSave.confidence;
                    filters.push(settings.labels.confidence + hash[settings.labels.confidence]);

                    // Min Odds
                    hash[settings.labels.minOdds] = $scope.settingsToSave.minOdds;
                    filters.push(settings.labels.minOdds + hash[settings.labels.minOdds]);

                    // Max Odds
                    hash[settings.labels.maxOdds] = $scope.settingsToSave.maxOdds;
                    filters.push(settings.labels.maxOdds + hash[settings.labels.maxOdds]);
                }

                //store hash
                storeSetting('hash', angular.toJson(hash)).then(function () {

                    // now store filters
                    if (filters.length) {

                        // make sure to pass them as a stringified array
                        storeSetting(settings.labels.filterQuery, angular.toJson(filters)).then(defer.resolve);

                    } else {
                        // delete filters
                        settingsService.get(settings.labels.filterQuery).then(function (doc) {
                            settingsService.remove(doc).then(function () {
                                // console.log('filters removed completely');
                                defer.resolve();
                            });
                        }).catch(function (err) {
                            defer.resolve();
                        });
                    }
                });

                return defer.promise;
            }

            // Process filters
            filtersSaved = processFilters();

            filtersSaved.then(function () {
                // console.log('all filters have been stored!');
            });

            Utils.olbgMainApi.scrollToTop();

            // all data as been saved
            $q.all([sortSaved, filtersSaved, Preferences.isUsingPreferences($scope.settings.usingPref)]).then(function () {

                var parentState = $state.$current.parent.self.name;

                // alert Tips service that settings have changed (possibly)
                Tips.settingsChanged().then(function () {

                    // if filter settings are a modal in Hot Tips
                    if (Navigation.getLatestStateInStack() === AppSettings.states.hotTips) {

                        $scope.closeModal();

                        // tell other controllers to update the hot tips list
                        $rootScope.$broadcast('updatedFilters');

                        Utils.olbgMainApi.closeContentPane();

                    } else {
                        if (!Utils.olbgMainApi.isiPad) {
                            $scope.navigateToParent();
                        } else {
                            // if iPad >
                            Utils.olbgMainApi.currentSelectedItem = '';
                            Utils.olbgMainApi.closeContentPane();
                        }
                    }
                    var step = OLBGSetup.steps.get('filterHotTips');
                    if (step && step.isAvailable() && $scope.settingsToSave.filterStatus) { // only if step is not completed / locked and user is using advanced settings!
                        OLBGSetup.completeSteps('filterHotTips'); // this will be shown only once
                    } else {
                        $scope.displayMessage(settings.filterSettings.messages.success, 'success'); // show the filter settings success as usual
                    }
                });
            });
        };

        $scope.filterMinMaxOddsFormat = function (input) {
            if (input === AppSettings.hotTips.filter.minOddsValues[0].key  || input === maxOddsArray[maxOddsArrayLen].key) {
                return input;
            } else {
                return Utils.formatOdds(input, $scope.oddsFormat);
            }
        };

        $scope.handleFilterMethod = function () {
            var method = $scope.settings.selectedFilterMethod;
            $scope.settings.usingPref          = method === 'pref';
            $scope.settingsToSave.filterStatus = method === 'adv';
        };

        $scope.confidenceFilter = confidenceFormat;

        // get sports
        sportsService.getSports(function (sports) {
            // create customized sports list
            for (var i = 0, length = sports.length; i < length; i++) {
                sportsList.push({
                    alias:   APITranslate.getTranslation(sports[i].alias),
                    apiName: sports[i].api_sport
                });
            }

            // add one more option: all sports
            // API value -> empty string
            sportsList.unshift({
                alias: settings.labels.allSports,
                apiName: settings.hotTips.filter[settings.labels.sport]
            });

            // make sports list public
            $scope.settings.sportsList = sportsList;

            sportsReady.resolve(); // sports is ready! resolve!
        })
        .catch(function () {
            $scope.view.connected = false;
        })

        // once sports are retrieved we can update controls in this page
        sportsReady.promise
            .then(function () {

                var orderPromise,
                    filterPromise,
                    statusPromise;

                $scope.settingsToSave.filterStatus = false;

                // Get stored settings. If no settings do nothing.
                // sort order
                orderPromise = settingsService.get(settings.labels.sortOrder).then(function (data) {
                    $scope.settingsToSave.order = data.name;
                }).catch(function () {
                    $scope.settingsToSave.order = settings.hotTips.sort[settings.labels.sortOrder];
                });

                // filters
                filtersPromise = $q.defer();

                TipsSettings.get('filterByPreferences')
                    .then(function (data) {
                        $scope.settings.usingPref = data ? data.name : $scope.settings.usingPref;
                    })
                    .finally(function () {

                        settingsService.get('hash').then(function (data) {

                            var parsedHash = angular.fromJson(data.name),
                                subsport,
                                market;

                            // Check if filters are on
                            $scope.settingsToSave.filterStatus   = parsedHash[settings.labels.filterStatus];
                            $scope.settings.selectedFilterMethod = $scope.settings.usingPref ? 'pref' : ($scope.settingsToSave.filterStatus ? 'adv' : 'none');

                            // Get filters if they are on
                            if ($scope.settingsToSave.filterStatus) {

                                subsport = parsedHash[settings.labels.subsport];
                                market   = angular.isDefined(parsedHash[settings.labels.market]) ? parsedHash[settings.labels.market] : '';

                                $scope.settingsToSave.sport        = parsedHash[settings.labels.sport] === '' ? settings.hotTips.filter[settings.labels.sport] : parsedHash[settings.labels.sport];
                                $scope.settingsToSave.subsport     = subsport === '' ? ($scope.settingsToSave.sport === 'Football' ? Lang.hotTips.filter.allLeagues : Lang.hotTips.filter.allCourses) : subsport;
                                $scope.settingsToSave.market       = market   === '' ? Lang.hotTips.filter.allMarkets : market;
                                $scope.settingsToSave.tipsNumber   = parsedHash[settings.labels.tipsNum]    ? parsedHash[settings.labels.tipsNum]    : settings.hotTips.filter.defaultTips;
                                $scope.settingsToSave.confidence   = parsedHash[settings.labels.confidence] ? parsedHash[settings.labels.confidence] : settings.hotTips.filter.defaultConfidence;
                                $scope.settingsToSave.minOdds      = parsedHash[settings.labels.minOdds]    ? parsedHash[settings.labels.minOdds] : settings.hotTips.filter.defaultMinOddsValue;
                                $scope.settingsToSave.maxOdds      = parsedHash[settings.labels.maxOdds]    ? parsedHash[settings.labels.maxOdds] : settings.hotTips.filter.defaultMaxOddsValue;

                                // get subsports list
                                $scope.changeSubsport($scope.settingsToSave.sport, $scope.settingsToSave.subsport);
                                $scope.changeMarkets($scope.settingsToSave.sport,  $scope.settingsToSave.market); // get list of markets

                            } else {
                                // should set default options here
                                // set default values (ng-models)
                                setDefaultValues();
                            }
                        }).catch(function () {
                            setDefaultValues();
                        }).finally(function () {
                            filtersPromise.resolve();
                        });
                    });

                // once all settings have been loaded:
                $q.all([orderPromise, filtersPromise.promise, oddsFomatReady.promise])
                .catch(function () {
                    $scope.view.connected = false;
                })
                .finally(function () {
                    pageReady.resolve();
                });
            })
            .catch(function () {
                $scope.view.connected = false;
            });

        // we need to get the odds format
        GeneralSettings.get(settings.labels.oddsFormat, function (response) {
            $scope.oddsFormat = Utils.figureOutOdds(response.name);
        }, function () {
            $scope.oddsFormat = settings.oddsSettings.oddsSettingsDefault;
        }, function () {
            // finally: resolve anyways
            $scope.oddsFilterParams.push($scope.oddsFormat);
            oddsFomatReady.resolve();
        });

        // page is ready: show it!
        pageReady.promise.then(function () {
            $scope.showForm = true;
        });
    })


    /*==========  controller for Change Home Page  ==========*/

    .controller('changeHomePageCtrl', function (Lang, Utils) {

        // populate inputs
        var that       = this;
        this.title     = Lang.home.settings.explanation;
        this.selection = localStorage ? localStorage.getItem('homePage') : '';
        this.showForm  = true;

        // generate the list of options
        this.list = [];
        // HOME_ROUTES is defined in bootstrap.js
        angular.forEach(Object.keys(HOME_ROUTES), function (value, index) {

            // only in first element (home)
            if (value == 'home') {
                that.selection = that.selection || HOME_ROUTES[value].value; // home it's the default
            }

            that.list.push({
                label: HOME_ROUTES[value].label,
                value: value
            });
        });

        this.save = function () {
            localStorage.setItem('homePage', this.selection);
            Utils.olbgMainApi.updateHomePageState(this.selection);
            Utils.olbgMainApi.displayMessage(Lang.generic.settings.saved, 'success');
        };
    })


    /*==========  controller for Odds Settings form  ==========*/

    .controller('oddsSettingsPageCtrl', function (AppSettings, $scope, $q, GeneralSettings, Tips, Utils, OLBGSetup) {

        var settings = AppSettings;

        // group of settings
        var itemsStored,
            newSettings = {},

            // data promises
            oddsSettingsDefer = $q.defer();

        function populateForm() {

            // get odds settings
            GeneralSettings.get(settings.labels.oddsFormat)
                .then(function (response) {
                    $scope.odds.selected = response && response.name ? response.name : ''; // if not set: show empty
                    oddsSettingsDefer.resolve();
                });
        }

        $scope.sectionTitle = settings.oddsSettings.sectionTitle;
        $scope.showForm     = false;

        // expose settings
        $scope.settings = settings;

        $scope.odds = {
            values: settings.oddsSettings.oddsSettingsValues
        };

        $scope.updateSettings = function () {

            // update newSettings
            newSettings[settings.labels.oddsFormat] = $scope.odds.selected;
            GeneralSettings.odds = $scope.odds.selected;

            // store elements into GeneralSettings service
            itemsStored = GeneralSettings.store(newSettings);

            itemsStored.then(function () {

                var step = OLBGSetup.steps.get('changeOddsFormat');

                // show feedback
                if (step && step.isAvailable()) {
                    OLBGSetup.completeSteps('changeOddsFormat'); // complete the step
                } else {
                    // show success message as usual
                    $scope.displayMessage(settings.oddsSettings.messages.success, 'success');
                }

                // alert Tips service that settings have changed (possibly)
                Tips.settingsChanged();

                if (!Utils.olbgMainApi.isiPad) {
                    $scope.goBack();
                }
            });
        };

        $scope.selectOdd = function (oddName) {
            $scope.odds.selected = oddName;
            $scope.updateSettings();
        };

        $q.all([oddsSettingsDefer.promise]).then(function () {
            // all data loaded and fetched.
            // show form

            $scope.showForm = true;
        });

        // populate form!
        populateForm();
    })


    /*==========  controller for Content Pages  ==========*/

    .controller('contentPageCtrl', function (AppSettings, $rootScope, $scope, $state, Utils, Request) {

        var settings     = AppSettings,
            contentState = $state.$current.self,
            contentUrl   = contentState.options.contentURL,
            contentTitle = contentState.options.pageTitle;

        function init() {

            $scope.view.connected = true;

            // finally get content
            Request.get(contentUrl).then(function (response) {

                $scope.contentPages.showContent = true;
                $scope.contentPages.pageToLoad = response.data;

            }).catch(function () {
                Utils.olbgMainApi.disconnect($scope.view);
                // console.log(status);
            });
        }

        $scope.view = {
            connected: true,
            reload: init
        };

        // prevent content from showing
        $scope.contentPages = {
            showContent: false
        };

        // set tilte of the section
        $scope.sectionTitle = contentTitle;

        init();
    })


    /*==========  controller for Not Found Page (404)  ==========*/

    .controller('notFoundCtrl', function (AppSettings, $scope, $state, $timeout) {

        var settings = AppSettings,
            myTimeout;

        $scope.redirectingCount = settings.notFound.redirectTime / 1000;
        $scope.onTimeout = function () {
            $scope.redirectingCount--;
            myTimeout = $timeout($scope.onTimeout, 1000);
        };

        myTimeout = $scope.onTimeout();

        // redirect:
        $timeout(function () {
            // stop counter
            $timeout.cancel(myTimeout);

            // redirect
            $state.go('hotTips');
        },
        settings.notFound.redirectTime);
    });
})(moment);