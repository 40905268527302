(function () {

    angular.module('olbg-web-app')

        // Simple date service
        .factory('ModelUpdater', ['AppSettings',
                'Tips',
                'GeneralSettings',
                'Utils',
                '$q',
                'Pub',
                '$timeout',                
                'Tracker',
                'Request',
            function ModelUpdaterService(AppSettings, Tips, GeneralSettings, Utils, $q, Pub, $timeout, Tracker, Request) {

            /**
             * ModelUpdater
             *
             * a service that takes a model and updates it
             * every certain ammount of time especified in
             * the passed options.
             *
             * If the model contains tips: it process them.
             * If the model contains ads:  it strip the ads and
             * places them back when updating.
             *
             * @return {obj} [the model to be updated]
             */

            var EXPIRED_TIME = 200;

            function formatTips(model, oddsFormat) {

                if (!model || !model.length || !oddsFormat) {
                    return;
                }

                angular.forEach(model, function (tip) {

                    Tips.formatTip(tip, oddsFormat);

                    Tips.createTipParams(tip);

                });
            }

            function update(opts) {

                var processAds = false;

                // check that processing is enabled:
                if (!opts.process || !opts.updateUrl) {
                    return;
                }

                if (opts.updating) {
                    // still updating -> call again
                    opts.timers[0] = $timeout(function () {
                        update(opts);
                    }, opts.updateDelay);
                    return;
                }

                opts.updating = true;

                // get the new model
                Request.get(opts.updateUrl)
                    .then(function (response) {

                        var newModel = response.data;

                        if (opts.levels.length) {
                            angular.forEach(opts.levels, function (level) {
                                if (angular.isDefined(newModel[level])) {
                                    newModel = newModel[level];
                                }
                            });
                        }

                        if (opts.isTips && newModel.length, opts.oddsFormat) {
                            formatTips(newModel, opts.oddsFormat); // format new model
                        }

                        // look for ads in the current model <-
                        if (opts.ads) {
                            processAds = !!opts.model.filter(function (el) {
                                return el.isAd;
                            }).length;
                        }

                        if (processAds) {

                            if (!opts.section || !opts.adsUrl) {
                                throw 'Model Updater: make sure you are passing section and adsURl';
                            }

                            // Pub.removeAds(opts.model, opts.section, opts.updateUrl); // 1.

                            // angular.extend(opts.model, newModel); // 2.

                            Pub.getAds(opts.section, {
                                url: opts.adsUrl,
                                useUrl: true,
                                useCached: true
                            }).then(function (ads) {

                                if (ads && ads.length) {

                                    Pub.insertAds(newModel, ads, opts.section, {
                                        reinsertion: true
                                    });
                                }

                                opts.model.splice(0, opts.model.length);
                                angular.extend(opts.model, newModel); //

                            }).finally(function () {
                                // finally
                                opts.updating = false;
                            });

                        } else {

                            angular.extend(opts.model, newModel);

                            // finally:
                            opts.updating = false;
                        }
                    })
                    .catch(function (err) {
                        // console.log('Model Updater: could not update.', err);
                        opts.updating = false;
                    });

                opts.timers[0] = $timeout(function () {
                    update(opts);
                }, opts.updateDelay);
            }

            function checkExpired(opts) {

                var expired     = false,
                    currentTime = new Date().getTime();

                if (!opts.isTips || !opts.checkExpired || !opts.process) {
                    return;
                }

                if (opts.updating) {

                    // an update is in progress: skip checking
                    opts.timers[1] = $timeout(function () {
                        checkExpired(opts);
                    }, EXPIRED_TIME);

                    return;
                }

                angular.forEach(opts.model, function (tip) {
                    if (!expired) { // if no event expired keep searching
                        if (parseInt(tip[opts.checkExpired]) - Math.round(currentTime / 1000) <= 0) {
                            expired = true; // stop looking
                            // console.log('Model Updater: event expired. Forcing update', tip);
                            // update(opts);   // force updates
                        }
                    }
                });

                // call again:
                opts.timers[1] = $timeout(function () {
                    checkExpired(opts);
                }, EXPIRED_TIME);
            }

            return {
                update: function (opts) {

                    if (!opts.model.length) {
                        return;
                    }

                    var oddsFormat,
                        allDone = $q.defer();

                    // define the options here
                    opts = angular.extend({
                        section:         '',
                        scope:           '',
                        updateUrl:       '',
                        checkExpired:    '',
                        model:           [],
                        timers:          [],
                        updating:        false,
                        process:         true,
                        isTips:          false,
                        ads:             false,
                        pagination:      false,
                        checkingExpired: false,
                        oddsFormat:      '',
                        levels:          [],                         // access a particular part of the returned model
                        updateDelay:     AppSettings.tipsUpdateDelay // use this time by default
                    }, opts);


                    if (opts.updateUrl) {

                        // start update timer
                        opts.timers.push($timeout(function () {
                            update(opts);
                        }, opts.updateDelay));
                    }

                    if (opts.isTips) {

                        // process the tips model ->
                        GeneralSettings.get(AppSettings.labels.oddsFormat, function (response) {
                            opts.oddsFormat = opts.oddsFormat ? opts.oddsFormat : Utils.figureOutOdds(response.name);
                        }, function () {
                            opts.oddsFormat = opts.oddsFormat ? opts.oddsFormat : AppSettings.oddsSettings.oddsSettingsDefault;
                        }, function () {

                            // format tips ->
                            formatTips(opts.model, opts.oddsFormat);

                            // should check for expired events
                            if (opts.checkExpired) {
                                // should check for expiration date in the passed
                                // attribute string
                                opts.timers.push($timeout(function () {
                                    checkExpired(opts);
                                }, EXPIRED_TIME));
                            }
                            allDone.resolve(opts.model);
                        });
                    } else {
                        allDone.resolve(opts.model); // no processing required: don't wait
                    }

                    // cancel all timers when the scope is destroyed ->
                    if (opts.scope) {
                        opts.scope.$on('$destroy', function () {
                            if (opts.timers.length) {
                                // destroy timers
                                angular.forEach(opts.timers, function (timer) {
                                    $timeout.cancel(timer);
                                });

                                // make sure not to process anything else:
                                opts.process = false;
                            }

                            // finally empty opts object
                            opts = {};
                        });
                    }

                    return allDone.promise;
                }
            };
        }]);
})();
