angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetslipIcon', ['AppSettings', 'Betslip', function olbgBetButtonDirective(AppSettings, Betslip) {
    return {
        restrict: 'E',
        controller: function ($scope) {
            // $scope.counter = Betslip.getOutcomes();
            $scope.$watch(Betslip.getCount, function (value) {
                $scope.counter = value;
            });
        },
        templateUrl: AppSettings.templates['betslip-icon'],
        replace: true
    };
}]);