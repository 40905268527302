(function (olbgMeta, global) {

    // get a reference from document
    var doc = angular.element(document),
    initialHref = window.location.href;

    // define cordova
    window.cordova = (typeof window.cordova != 'undefined') ? window.cordova : '';

    // define cordova
    window.cordova = (typeof window.cordova != 'undefined') ? window.cordova : '';

    angular.module('olbg-web-app')

    .controller('olbgAppCtrl', function (
        AppSettings,
        $injector,
        $rootScope,
        $scope,
        $state,
        $stateParams,
        $timeout,
        $sce,
        Navigation,
        GeneralSettings,
        Sports,
        Utils,
        $q,
        Betslip,
        Offers,
        Tracker,
        ngDialog,
        Environment,
        Tips,
        Lang,
        matchmedia,
        $location,
        Notifications,
        InAppReview,
        Pub,
        Favorites,
        NewFeatures,
        Preferences,
        PWAService,
        Swipe,
        OLBGSetup
        ) {


        /**
         * This is the Main Navigation controller.
         * It uses the Navigation service to remember objects across
         * the different pages and the $state to keep track of Routing and History
         */

        // by default navigation will reset pages stack
        var pos,
            noticePromise,
            loginLoader,
            startingPosition    = 1,
            loaderTiming        = 1000,
            attemptingLogin     = false,
            once                = true,
            connected           = true,
            modalOpen           = false,
            olbgMain            = {},
            popularSports       = [],
            settings            = AppSettings,
            defaultTitle        = settings.defaultTitle,
            defaultDescription  = settings.defaultDescription,
            defaultKeywords     = settings.defaultKeywords,
            navigationOptions   = {
                templateUrl:    settings.pages['hot-tips'],
                resetNavigator: true,
                animation:      'slide',
                goingBack:      false,
                noParentLoad:   false,
                replacePage:    false
            },

            // platform specific services
            APKservice,
            PlatformSettings,
            iOSSettings,
            Deeplinking,
            TabBar,

            // request by [dragos@olbg.com]
            SEOtitle   = global.pageTitle,
            landingURL = $location.path().

            // ads
            forceAds   = false,

            defaultSelectedTabbar,
            defaultHomePageState;

        // set default title
        $rootScope.pageTitle = defaultTitle;

        // set default description
        $rootScope.pageDescription = defaultDescription;

        // set default keywords
        $rootScope.pageKeywords = defaultKeywords;

        // App loaded successfully: enable notifications
        $rootScope.enableNotifications = true;

        // Alert Boxes
        $rootScope.blockAlerts = false;

        // set default settings
        function initGeneralSettings() {

            function initBetslip() {
                // initialize Betslip unless Hot Tips (want to call it after the tips)
                if ($state.$current.self.name !== AppSettings.states.hotTips) {
                    Betslip.init();
                }
            }

            function initOffers() {
                // initialize Offers
                $timeout(function () {
                    if ($state.$current.self.name !== AppSettings.states.offers) {
                        Offers.init().then(function () {
                            Offers.updateOffers();
                        });
                    }
                }, $scope.olbgMain.isGooglePlay ? 0 : AppSettings.delays.offers); // delay a bit
            }

            var allSettings = {};

            // initialize tracking information
            Tracker.init();

            initBetslip();      // initialize Betslip

            initOffers();       // initialize Offers

            Preferences.init(); // init preferences

            // [ DEBUG BUTTON ]
            Utils.olbgMainApi.showDebugButton = false;

            // odds value
            return GeneralSettings.getAll(function (data) {

                var settingsOptions = data.rows,
                    savedOptions    = {};

                for (var i = 0, len = settingsOptions.length; i < len; i++) {
                    // create new settings object
                    savedOptions[settingsOptions[i].id] = settingsOptions[i].doc.name;
                }

                // deal with odds
                if (!(settings.labels.oddsFormat in savedOptions)) {
                    allSettings[settings.labels.oddsFormat] = settings.oddsSettings.oddsSettingsDefault;
                }

                // finally store settings
                if (!angular.equals({}, allSettings)) {
                    GeneralSettings.store(allSettings);
                }
            });
        }

        function setPlatformFlags() {

            /**
             * setPlatformFlags
             *
             * it sets the platform flags for platform
             * specific code both CSS and JS
             */

            // set default flags:
            $scope.olbgMain.isCore               = false;
            $scope.olbgMain.isPWA                = false;
            $scope.olbgMain.isHybrid             = false;
            $scope.olbgMain.isAndroid            = false;
            $scope.olbgMain.isiOS                = false;
            $scope.olbgMain.isiPad               = false; // we have a slightly different app structure for iPad
            $scope.olbgMain.inBrowser            = false;
            $scope.olbgMain.country              = olbgMeta.code ? olbgMeta.code : undefined;
            $scope.olbgMain.lang                 = olbgMeta.lang ? olbgMeta.lang : 'EN';
            $scope.olbgMain.cssModifier          = 'is-core';
            $scope.olbgMain.cssCountryModifier   = 'is-uk';
            $scope.olbgMain.cssCountryXXModifier = 'olbg--gb';
            $scope.olbgMain.patchVersion         = angular.isDefined(olbgMeta.patchVersion) ? olbgMeta.patchVersion : '';
            $scope.olbgMain.isGooglePlay         = olbgMeta.isGooglePlay;
            $scope.olbgMain.noGeo                = olbgMeta.noGeo;
            $scope.olbgMain.noGeoError           = olbgMeta.noGeoError;
            $scope.olbgMain.isLandscape          = matchmedia.isLandscape();
            $scope.olbgMain.showOnboarding       = olbgMeta.showOnboarding;
            $scope.olbgMain.debugCountry         = olbgMeta.fakeCode ? olbgMeta.fakeCode : '';
            $scope.olbgMain.hasTranslations      = olbgMeta.translations;

            switch (olbgMeta.type) {
                // case 'web-app':
                //     $scope.olbgMain.isWebApp      = true;
                //     $scope.olbgMain.isOperaMini   = olbgMeta.isOperaMini;
                //     $scope.olbgMain.cssModifier   = 'is-web-app';
                //     $scope.olbgMain.platformLabel = 'web-app';
                //     $scope.olbgMain.cssCountryModifier = 'is-web-app--' + olbgMeta.code.toLowerCase();
                //     $scope.olbgMain.cssCountryXXModifier = 'olbg--' + olbgMeta.code.toLowerCase();
                //     break;
                case 'hybrid':
                    $scope.olbgMain.isHybrid = true;

                    /**
                     * we can now serve the app
                     * in browser for development!
                     */

                    $scope.olbgMain.inBrowser = olbgMeta.inBrowser;

                    if (angular.isDefined(olbgMeta.platform)) {
                        // define platform
                        switch (olbgMeta.platform) {
                            case 'android':
                                $scope.olbgMain.isAndroid     = true;
                                $scope.olbgMain.cssModifier   = 'is-android';
                                $scope.olbgMain.platformLabel = 'android';
                                break;
                            case 'ios':
                                $scope.olbgMain.isiOS              = true;
                                $scope.olbgMain.cssModifier        = 'is-iOS';
                                $scope.olbgMain.platformLabel      = 'iOS';
                                $scope.olbgMain.showiPadContent    = false; // by default the main container is hidden
                                $scope.olbgMain.isiPad             = olbgMeta.isiPad;
                                break;
                        }
                    }

                    if (!$scope.olbgMain.inBrowser) {
                        $scope.olbgMain.cssModifier += ' app--is-running-in-device';
                    }

                    break;
                default:
                    $scope.olbgMain.isCore         = true;
                    $scope.olbgMain.isPWA          = olbgMeta.pwa;
                    $scope.olbgMain.platformLabel  = 'core';
                    $scope.olbgMain.showOnboarding = true; // show onboarding for core app
                    break;
            }

            // Betting Version flag
            $scope.olbgMain.isBetting    = angular.isDefined(olbgMeta.bettingVersion) ? olbgMeta.bettingVersion : true;
            forceAds                     = angular.isDefined(olbgMeta.showAdsAnyways) ? olbgMeta.showAdsAnyways : false;

            dealWithAds({force: forceAds});

            console.log('OLBG application running on:', {
                'original settings': olbgMeta,
                'betting enabled'  : $scope.olbgMain.isBetting,
                'country code'     : $scope.olbgMain.country,
                'language'         : $scope.olbgMain.lang
            });

            if ($scope.olbgMain.isiPad) {
                console.log('OLBG application is currently running on [iPad]');
            }
        }

        // load new pages
        function pushPage(options, toState, fromState) {

            /**
             * pushPage
             * @options {[object]}
             *
             * Pushes a page into the Navigator Stack or
             * replaces the whole stack with a new page (if options.resetNavigator === true)
             * If goingBack is set to true: the previous page is loaded behind the current one.
             * Then the current page is popped.
             */

            // wait till DOM has loaded to reference the navigator!

            var parent, currentState, pages, params, delay;

            doc.ready(function () {

                var nav,
                    swipeDuration = AppSettings.swipe && AppSettings.swipe.duration ? AppSettings.swipe.duration : 2;

                options.animation = 'none'; // disabling animations until we find a proper responsive way

                // [SWIPE] re-enable swiping transition
                options.animation = options.swipeAnimation && !$scope.olbgMain.isiPad ? 'swipe' : 'none';

                currentState = $state.current.name;

                params = angular.copy($stateParams);

                if (!options.resetNavigator && !options.goingBack) {

                    if (options.replacePage) {

                        // iPad: disabled for iPad
                        if ($scope.olbgMain.isiPad) {
                            throw 'Navigation: Replace Page is disabled for iPad navigation for now';
                        }

                        // console.log('Replacing current page for: ', currentState);

                        // delay the destroy event a bit

                        $timeout(function () {
                            // removedElement[0].destroy();
                            // get the page out of the stack immediately
                            pages = mainNavigator.getPages();
                            removedElement = pages.splice(pages.length-2, 1);
                            removedElement[0].destroy();
                        }, options.swipeAnimation ? (swipeDuration + 0.5) * 1000 : AppSettings.transitionsDelay);

                        // remove state from stack
                        Navigation.removeState();
                    }

                    // a new page is added to the stack
                    // push the page to the top of the stack

                    /*----------  iPad > right pane  ----------*/

                    if ($scope.olbgMain.isiPad && options.openInRightPanel) {

                        // load page first
                        if (options.pushToRightPanel) {
                            iPadContentNavigator.pushPage(options.templateUrl, {
                              animation: options.animation
                            });

                            Navigation.addState(currentState, params, {isContentPanel: true});

                        } else {

                            // content stack is reset >
                            iPadContentNavigator.resetToPage(options.templateUrl, {
                              animation: options.animation
                            });

                            Navigation.resetStates(currentState, params, {isContentPanel: true});
                        }

                        // then open drawer
                        if (!$scope.olbgMain.isContentPaneOpen()) {
                            $scope.olbgMain.openContentPane();
                        }
                    } else {

                        mainNavigator.pushPage(options.templateUrl, {
                           animation: options.animation,
                           direction: options.swipeAnimation ? options.swipeAnimationDirection : '',
                           duration:  options.swipeAnimation ? swipeDuration : 0 // pass the duration for the swipe animation
                        });

                        // A page has been pushed. Add it to states stack.
                        Navigation.addState(currentState, params);
                    }

                } else {

                    // reset stack all stacks
                    if (options.resetNavigator) {

                        // clean Swipe stacks
                        Swipe.clear();

                        if ($scope.olbgMain.isiPad) {

                            // reset stacks for iPad
                            pages = iPadContentNavigator.getPages();

                            if (pages.length && pages[0].name) {
                                removedElement = pages.splice(0, 1);
                                removedElement[0].destroy();
                            }

                            // if not opening a new page in right panel > close the panel
                            if (!options.openInRightPanel) {
                                $scope.olbgMain.currentSelectedItem = ''; // clean current selected item
                                $scope.olbgMain.closeContentPane();
                            } else {
                                $scope.olbgMain.openContentPane();
                            }

                            // finally reset states and params >
                            Navigation.resetStates(options.openInRightPanel ? currentState : undefined, options.openInRightPanel ? params : undefined, {isContentPanel: true});
                        }

                        nav = $scope.olbgMain.isiPad && options.openInRightPanel ? iPadContentNavigator : mainNavigator;

                        // replace the whole stack with a new page!
                        // console.log('Stack reset! Loading current page: ', currentState);

                        // load the required state
                        nav.resetToPage(options.templateUrl, {
                            animation: options.animation
                        });

                        // Reset State history and add the current loaded state
                        if (nav === mainNavigator) {
                            Navigation.resetStates(currentState, params);
                        } else {

                            /**
                             * for iPad push previous page in left panel
                             */

                            Navigation.addState(options.loadParent.name, params, {isContentPanel: false});
                            mainNavigator.resetToPage(options.loadParent.options.templateUrl);
                        }

                    } else {
                        if (options.goingBack) {

                            nav = options.isContentPane ? iPadContentNavigator :  mainNavigator;

                            // go back! (go back button has been pressed)
                            nav.popPage({
                                animation: 'none'
                            });

                            // forget associated elements
                            // with this state
                            Navigation.forgetItem(fromState.name);

                            // update page counter
                            Navigation.removeState({
                                isContentPanel: options.isContentPane
                            });

                            // emit event when going back!
                            $rootScope.$broadcast('goingBack');
                        }
                    }

                    // get current stack and parent
                    parent = options.loadParent;

                    // add previous state to the stack: UNLESS iPAD FOR NOW!

                    if (angular.isDefined(parent.options) && !$scope.olbgMain.isiPad) {

                        if (options.goingBack && Navigation.getPagesNumber() === 1 || options.resetNavigator) {

                            // load parent page if there's one in the stack
                            // console.log('loading parent page: ' + parent.name);

                            // load previous state
                            mainNavigator.insertPage(0, parent.options.templateUrl, {
                                animation: options.animation
                            });

                            // update pages counter
                           Navigation.insertState(parent.name, params);
                        }
                    }
                }

                // reset navigation settings to default always.
                Navigation.resetTransition();
            });
        }

        // side menu related
        function closeMenu() {
            /**
             * it simply closes the side menu
             */
            if ($scope.olbgMain.isWebApp) {
                return;
            }
            slidingMenu.closeMenu();
        }

        function dealWithMenus() {

            // side menu available on:
            // + Core

            // get stream events reference
            $scope.olbgMain.stream     = Notifications.getStream();
            $scope.olbgMain.sideMenuOn = $scope.olbgMain.isCore || $scope.olbgMain.isPWA;
            $scope.olbgMain.tabBarOn   = $scope.olbgMain.isHybrid;

            // build side menu for core:
            if ($scope.olbgMain.sideMenuOn) {

                // handle install link
                $scope.installAppCopy = Lang.pwa.installBtn;

                // if feature exist: remove if disabled
                angular.extend($scope.menuLinks, angular.copy(settings.sideMenu.links).filter(function (link) {
                    var isAvailable = !NewFeatures.exists(link.featName) || NewFeatures.exists(link.featName) && NewFeatures.isAvailable(link.featName);
                    return (isAvailable && $scope.olbgMain.isBetting || !$scope.olbgMain.isBetting && !link.betOnly);
                }));
            }

            // build tab-bar menu for iOS and Android:
            if ($scope.olbgMain.tabBarOn) {
                // remove bets button from tab bar if the top button is available
                var menu = NewFeatures.isAvailable(AppSettings.labels.features.topBetslipButton) ? AppSettings.tabbar.bettingMenu.filter(function (t) { return t.id != 'bets';}) : AppSettings.tabbar.bettingMenu;
                $scope.olbgMain.selectedTab = defaultSelectedTabbar;
                $scope.olbgMain.tabbar      = menu; // [TRANSLATIONS]

                // filter out betting items from non betting
                if (!$scope.olbgMain.isBetting) {
                    $scope.olbgMain.tabbar.forEach(function(el) {
                        el.hidden = el.betting;
                        if (el.subMenu && el.subMenu.length) {
                            el.subMenu.forEach(function (s) { s.hidden = s.betting;});
                        }
                    });
                }
            }
        }

        function dealWithAds(opts) {
            opts = angular.extend({
                force: false
            }, opts);

            // Disable ads if necessary
            // unless force ads is enabled (ads are shown regardless if is betting or non betting)
            if (!opts.force && (!NewFeatures.isAvailable(AppSettings.labels.features.ads) || !$scope.olbgMain.isBetting)) {
                Pub.disableAds();
            } else {
                // enable Interactive Ads
                Pub.enableInteractiveAds();
            }

            // if it's Google Play > init admob
            if ($scope.olbgMain.isGooglePlay) {

                // make sure device is ready
                document.addEventListener('deviceready', function () {

                    // [ ADMOB DEBUGGING ]

                    // Pub.initAdmob();

                    // $timeout(function () {
                    //     Pub.loadAdmob('banner')
                    //         .then(function (ad) {
                    //             if (ad) {
                    //                 console.log('@@@ DEBUG: loaded banner');
                    //                 Pub.showAdmob('banner', ad)
                    //                     .then(function (opts) {
                    //                         if (opts.action) {
                    //                             console.log('@@@ DEBUG: user ', opts.action);
                    //                         }
                    //                     });
                    //             }
                    //         });
                    // }, 5000);


                    // Pub.loadAdmob('interstitial')
                    //     .then(function (ad) {
                    //         if (ad) {
                    //             console.log('@@@ DEBUG: loaded banner');
                    //             Pub.showAdmob('banner', ad)
                    //                 .then(function (opts) {
                    //                     if (opts.action) {
                    //                         console.log('@@@ DEBUG: user ', opts.action);
                    //                     }
                    //                 });
                    //         }
                    //     })
                    //     .catch(function (err) {
                    //         console.log('@@@ did not load ad');
                    //     });


                    // // load the reward too
                    // olbgMeta.reward = Pub.loadAdmob('reward');
                });
            }
        }

        // handle SEO title for web app
        function handleSEOTitle() {
            if (!$scope.olbgMain.isWebApp) {
                return;
            }
            if ($location.path() === landingURL) {
                $scope.olbgMainApi.SEOtitle = SEOtitle ;
            } else {
                $timeout(function () {
                    $scope.olbgMainApi.SEOtitle = '';
                }, 100);
            }
        }

        // pass available pages, templates and modals
        $scope.pwaShowInstallBtn   = true;
        $scope.menuLinks           = [];
        $scope.olbgMain            = {};
        $scope.pages               = settings.pages;
        $scope.modals              = settings.modals;
        $scope.templates           = settings.templates;
        $scope.olbgMain.sideMenuOn = false;
        $scope.olbgMain.tabBarOpen = false;
        $scope.settingsAvailable   = undefined;
        $scope.earlyAccessEmail    = olbgMeta.translatorsDebug ? Environment.earlyAccessEmail : '';
        // $scope.landing = true;

        // set platform flags
        setPlatformFlags();

        /*==================================================================
        =            PLATFORM TAGS CAN BE USED FROM THIS POINT:            =
        ==================================================================*/

        // import platform specific services
        if ($scope.olbgMain.isHybrid) {
            PlatformSettings = $injector.get('PlatformSettings'); // conditionally inject PlatformSettings service
            Deeplinking      = $injector.get('Deeplinking');
            Deeplinking.init();
            window.open = typeof cordova !== 'undefined' && cordova.InAppBrowser ? cordova.InAppBrowser.open : window.open;
        }

        // [Pull & Refresh]
        // unavailable for m.olbg.com for now
        $scope.olbgMain.pullAndRefreshEnabled = NewFeatures.isAvailable(AppSettings.labels.features.pullAndRefresh);

        // some promises
        // $scope.olbgMain.betslipReady = $q.defer();
        $scope.olbgMain.hotTipsReady = $q.defer();

        // by default: load list display
        $scope.isCardsLayout = settings.hotTips.isCardsLayout;

        // hide all notices by default
        $scope.notice = {
            hide: true
        };

        // by default show backbutton
        $scope.hideBackButton = false;

        $scope.olbgMain.getCurrentStateName = function () {
            return $state.$current.self.name;
        };

        $scope.olbgMain.isCurrentState = function (state) {

            /**
             * isCurrentState
             *
             * @state [{string}]
             *
             * A function that checks if current state is the passed
             * one to the parameter
             */

            return $state.$current.self.name === state;
        };

        // $scope.olbgMain.setBetslipReady = function () {
        //     $scope.olbgMain.betslipReady.resolve();
        // };

        $scope.olbgMain.scrollToTop = function (opts) {
            opts = angular.extend({
                el: ''
            }, opts);

            if ($scope.olbgMain.isWebApp) {
                window.scrollTo(0,-100); // -100 to prevent hidden smart banner in iOS
            } else {
                if (opts.el) { $(opts.el).scrollTop(0); }
            }
        };

        $scope.setSettingsAvailable = function (flag) {
            $scope.settingsAvailable = flag;
            if (angular.isDefined($scope.olbgMain)) {
                $scope.olbgMain.settingsAvailable = flag;
            }
        };

        /**
         * inserts a page behind the current one
         * @param  {string} state       the state to load
         * @param  {object} newOptions  the options to pass to insertPage method
         * @param  {object} stateParams we might want to override the parameters completely
         * @return {void}
         */
        $scope.manuallyLoadParent = function (state, newOptions, stateParams) {

            doc.ready(function () {

                var params   = stateParams ? stateParams : angular.copy($stateParams),
                    template = $state.get(state).options.templateUrl,
                    options  = {
                        animation: 'slide'
                    };

                // extend options
                angular.extend(options, newOptions);

                mainNavigator.insertPage(0, template, options);

                // console.log(state, 'has been loaded behind!');

                // make sure to update the Page counter in Navigation
                Navigation.insertState(state, params);

            });
        };

        $scope.resetNavigationMemory = function () {
            Navigation.resetNavigationMemory();
        };

        $scope.openModal = function (page, opts) {
            modalOpen = true;
            mainNavigator.pushPage(page, {
                animation: 'lift'
            });
        };

        $scope.closeModal = function (opts) {
            opts = angular.extend({animation: true}, opts);
            if (modalOpen) {
                modalOpen = false;
                mainNavigator.popPage({animation: opts.animation ? opts.animation : 'none'});
                $rootScope.$emit('modalClose');
            }
        };

        $scope.goBack = function (opts) {

            // get previous state
            var containsParams = opts && !!opts.parameters,
                currentState = $state.$current.self.name,
                state = Navigation.getPreviousState({
                    isContentPanel: opts && opts.isContentPane && $scope.olbgMainApi.isiPad
                }),
                menuSelection  = angular.isDefined($rootScope.selectedLink) ? $rootScope.selectedLink : 'undefined',
                previousParams = Navigation.getPreviousParams({
                    isContentPanel: opts && opts.isContentPane && $scope.olbgMainApi.isiPad
                });

            // extend the options
            opts = angular.extend({
                disabled: false,
                isContentPane: false,
                parameters: {}
            }, opts);

            if (opts.disabled) {
                return;
            }

            /**
             * Goes back one page in the navigator stack
             */

            Navigation.setTransition({
                goingBack: true,
                isContentPane: opts.isContentPane && $scope.olbgMainApi.isiPad,
                resetNavigator: false,
                animation: 'none',
                menuSelection: menuSelection
            });

            // go to previous state
            if (state) {

                if (currentState === state) {

                    /**
                     *
                     * when going back in the stack and staying in the same state:
                     * refresh parameters
                     */
                    $state.go(state, previousParams ? previousParams : {}); // make sure to pass previous parameters

                } else {
                    $state.go(state, containsParams ? opts.parameters : (previousParams ? previousParams : {}));
                }

            } else {
                // console.log('There is no previous state to load!');
                return;
            }
        };

        $scope.getCurrentState = function (state) {

            /**
             * getCurrentState
             * @state        {[string]}
             *
             * For some controllers we need to get the current state.
             * This is useful for storing object for that particular state
             * in the Navigation service.
             *
             * The problem is that sometimes, the controller is being loaded
             * in the background, which means that the current state doesn't match
             * that of the controller. In that case we need to go one level down
             * the state chain. For that, we use this function.
             *
             */

            var individualState,
                currentState = $state.$current.self.name,
                regex = /\.([A-Za-z0-9]+)$/;

            // get last individual state
            individualState = regex.exec(currentState)[1];

            // check if it matches the controller's state
            if (individualState === state) {
                // if it does return the state unchanged!
                return currentState;
            } else {
                // if it doesn't, the page is being loaded in the background
                // strip the last state
                return currentState.replace(regex, '');
            }
        };

        $scope.displayMessage = function (message, type, timeout, opts) {

            /**
             * displayMessage
             *
             * @message {[string]}
             * @type    {[string]}
             *
             * This function shows feedback
             * once an action has been performed!
             */

            opts = angular.extend({
                noticeDelay: settings.noticeDelay,
                data: {}
            }, opts);

            timeout = parseInt(timeout);

            // cancel promise
            $timeout.cancel(noticePromise);

            $timeout(function () {

                $scope.notice.hide    = false;
                $scope.notice.message = $sce.trustAsHtml(message);
                $scope.notice.type    = type;
                $scope.notice.data    = opts.data;

                // create new promise
                noticePromise = $timeout(function () {
                    $scope.notice.hide = true;
                }, opts.noticeDelay);

                if(!$scope.$$phase) {
                    $scope.$apply();
                }

            }, (timeout && timeout > 0) ? timeout : 0);
        };

        $scope.navigateToParent = function () {
            $scope.goBack();
        };

        $scope.navigateTo = function (state, opts) {

            opts = angular.extend({
                resetNavigator: true,
                reload:    false
            }, opts);

            $scope.closeModal({animation: true}); // attempt to close any modals if open

            Navigation.setTransition(opts);

            if (opts.reload) {
                $state.reload();
            } else {
                $state.go(state, opts.params);
            }

            $scope.olbgMain.scrollToTop(); // scroll to top in Web App
        };

        // navigates to default home page
        $scope.olbgMain.navigateHome = function (opts) {
            if (opts) {
                if (opts.err) { $scope.displayMessage(opts.err, 'error'); }
                if (Sentry && opts.reportSentry) { Sentry.captureMessage(opts.sentryMessage ? opts.sentryMessage : 'A page returned to home: check API requests'); }
            }

            Navigation.setTransition({
                resetNavigator: true
            });
            $state.go(defaultHomePageState);
        };

        $scope.olbgMain.updateHomePageState = function (homepage) {
            defaultHomePageState = HOME_ROUTES[homepage].state;
        };

        $scope.olbgMain.openIframe = function (url, navTitle) {

            // store a reference to the URL
            Navigation.store($state.$current.self.name, {
                url: url,
                sectionTitle: navTitle
            }, AppSettings.labels.iframeInfo);

            $scope.openModal(AppSettings.modals.iframe);
        };

        $scope.olbgMain.setBlockAlerts = function (status) {
            $rootScope.blockAlerts = status;
        };

        $scope.olbgMain.alertsAreBlocked = function () {
            return $rootScope.blockAlerts;
        };

        $scope.olbgMain.showLoaderModal = function (title) {

            if ($rootScope.blockAlerts) {
                return;
            }

            $rootScope.blockAlerts = true;

            $rootScope.simpleLoader = {
                title: title || 'Loading...',
                show:  false
            };

            return ngDialog.openConfirm({
                template: AppSettings.modals.loader,
                scope: $rootScope,
                closeByEscape: false,
                showClose: false
            });
        };

        $scope.olbgMain.closeLoaderModal = function (dialog) {
            $timeout(ngDialog.closeAll, 500);
        };

        $scope.olbgMain.dispatch = function (opts) {

            if (!opts.name) {
                return;
            }

            $rootScope.$broadcast(opts.name, opts.data ? opts.data : {});
        };

        $scope.olbgMain.listen = function (name, cb) {
            $rootScope.$on(name, function (event, data) {
                cb(event, data);
            });
        };

        // handle connection!
        $scope.olbgMain.disconnect = function (el, error) {
            $timeout(function () {
                el.connected = false;
            }, 200);


            // /**
            //  * use a little timer to ensure reconnection
            //  */

            // var page = angular.copy(el);

            // page.error = error ? error : '';

            // if (Sentry) { Sentry.captureMessage('Disconnected: ' + angular.toJson(page)); }


        };

        $scope.olbgMain.isConnected = function () { return connected; };
        $scope.olbgMain.setConnectionStatus = function (status) { connected = status; };

        // side menu functions

        $scope.openSideMenuLink = function (state, params, item) {

            var theMenu = (olbgMeta.type === 'web-app' ? undefined : slidingMenu),
                opts = { resetNavigator: true, reload: false };


            if (angular.isDefined(params)) { opts.params = params; }

            $scope.olbgMain.navigateTo(state, opts);

            // make transition faster upon click
            $rootScope.selectedLink = item.toLowerCase();
        };

        $scope.toggleMenu = function (navigator, evt) {
            /**
             * toggleMenu
             *
             * @navigator {[ onsen navigator object ]}
             *
             * This function simply reveals the side menu.
             * It takes the Onsen navigator object that controls
             * the side menu.
             */


            if (!angular.isDefined(navigator)) {
                return;
            }
            if (angular.isDefined(evt)) {
                evt.stopPropagation();
            }
            navigator.toggleMenu();
        };

        $scope.olbgMain.isMenuOpen = function (navigator) {
            return navigator.isMenuOpened();
        };

        $scope.olbgMain.closeSlidingMenu = function (navigator, evt) {

            /**
             * closeSlidingMenu
             *
             * @evt [{event object}]
             * @navigator [{object}]
             *
             * This function checks if the menu is open.
             * If menu is open: this function closes it.
             */

            // if the target is the open menu button
            // or the menu is closed: do nothing
            if (!navigator.isMenuOpened()) {
                return;
            }

            // prevent click event from bubbling
            evt.stopPropagation();
            $scope.toggleMenu(navigator, evt);
        };

        // tab bar > iOS
        $scope.olbgMain.isTabBarOpen = function () {
            return $scope.olbgMain.tabBarOpen;
        };

        $scope.olbgMain.showTab = function (id) {
            var offersTab = $scope.olbgMain.tabbar.filter(function (tab) {
                return tab.id === id;
            });
            offersTab[0].hidden = false;
        };

        /**
         * method to open the main menu of the app
         * regardless of platform
         * @param  {Object} opts some options might be required depending on platform
         * @return {void}
         */
        $scope.olbgMain.openMainMenu = function (opts) {

            if ($scope.olbgMain.isWebApp) {
                return; // m.olbg.com doesn't have a menu
            }

            switch($scope.olbgMain.platformLabel) {
                case 'iOS':

                    TabBar = $injector.get('TabBar');

                    if (!TabBar.checkIfOpen() && opts.tab) {
                        TabBar.openiOSTab(opts.tab);
                    }

                    break;
                default:
                    if (!$scope.olbgMain.isMenuOpen(slidingMenu)) {
                        $scope.toggleMenu(slidingMenu);
                    }
                    break;
            }
        };

        // TEMP: bet365 logout button in side menu
        $scope.olbgMain.bet365Logout = function () {
            Betslip.logout(AppSettings.APIBookies[0], 'bet365');
        };

        // display options
        $scope.toggleCardsView = function (on) {
            $scope.isCardsLayout = on;
        };

        // application reload
        $scope.olbgMain.reloadApp = function () {
            if ($scope.olbgMain.isHybrid) {
                if ($scope.olbgMain.isAndroid) {
                    navigator.app.loadUrl(Environment.androidIndexFile);
                } else if ($scope.olbgMain.isiOS) {
                    window.location = initialHref;
                }
            } else { location.reload(); }
        };

        // ads
        $scope.olbgMain.openAd = function (url) {
            window.open(url, '_blank');
        };

        // Your Bets button
        $scope.olbgMain.showBetslipButton = function () {

            // show the top betslip button only if available
            return NewFeatures.isAvailable(AppSettings.labels.features.topBetslipButton) &&
                   !$scope.olbgMain.isCurrentState('betsManager') &&
                   !$scope.olbgMain.isCurrentState('betsManager.chooseBookie') &&
                   !$scope.olbgMain.isCurrentState('betsManager.chooseBookie.betslip') &&
                   !$scope.olbgMain.isCurrentState('betsPlaced');
        };

        // Load a new state in the app
        $scope.olbgMain.openState = function (event, toState, toParams, fromState, fromParams) {

            var options = {},
                parentState,
                currentState = $state.current.name;

            // expose some useful variables
            $scope.olbgMainApi.stateInfo = {
                toState:    toState,
                toParams:   toParams,
                fromState:  fromState,
                fromParams: fromParams
            };

            // is first load >
            $scope.olbgMainApi.landed = $scope.olbgMainApi.isWebApp && once; // landed only for the web app!
            once = false;

            // extend navigation options
            angular.extend(options, navigationOptions);
            angular.extend(options, Navigation.getTransition());
            angular.extend(options, toState.options);

            $rootScope.pageTitle = defaultTitle;

            // set title
            if (options.pageTitle) {
                $rootScope.pageTitle = options.pageTitle + ' - ' + $rootScope.pageTitle;
            }

            // set the selected link
            $rootScope.selectedLink = options.menuSelection ? options.menuSelection : undefined;


            /*----------  Selected tab for mobile app  ----------*/

            if ($scope.olbgMain.isHybrid) {
                // set selected tab
                $scope.olbgMain.selectedTab = options.iOSMenuSelection;
            }

            // by default parent state is not loaded
            options.loadParent = false;

            // check there's parent state
            parentState = $state.$current.parent.self;

            // go to default previous state
            if (angular.isDefined(parentState) && parentState) {
                // page has a parent. Load it behind.
                options.loadParent = parentState;
            }

            // or don't show parent at all if specified!
            if (angular.isDefined(options.noParentLoad) && options.noParentLoad) {
                options.loadParent = false;
            }

            /*==================================
            =            Page Views            =
            ==================================*/

            Tracker.sendSeenEvent(currentState);

            /*===============================
            =              PWA              =
            ===============================*/

            if ($scope.olbgMain.isPWA) {
                $scope.pwaPrompt = function () {
                    Tracker.sendEvent(AppSettings.tracking.ga.events.pwaSideMenuInstallBtn);
                    PWAService.show({
                        delay: 0
                    });
                };

                // listen for PWA installed event
                $scope.olbgMain.listen('installedPWA', function () {
                    $timeout(function () {
                        $scope.pwaShowInstallBtn = false; // hide the button
                    }, 0);
                });
            }

            /*============================
            =            iPad            =
            ============================*/
            if ($scope.olbgMain.isiPad) {

                /**
                 * set the main section title
                 */

                if (options.iPadTitle) {
                    $scope.iPadSectionTitle = options.iPadTitle;
                }
            }

            // maybe close modals?
            $scope.closeModal({animation: false});

            // show new state!
            pushPage(options, toState, fromState);
        };

        /*==========================================
        =            iPad Functionality            =
        ==========================================*/

        $scope.olbgMain.currentSelectedItem = '';
        $scope.olbgMain.toggleContentPane = function () {
            $scope.olbgMain.showiPadContent = !$scope.olbgMain.showiPadContent;
        };

        $scope.olbgMain.isContentPaneOpen = function () { return $scope.olbgMain.showiPadContent;  };
        $scope.olbgMain.openContentPane   = function () { $scope.olbgMain.showiPadContent = true;  };
        $scope.olbgMain.closeContentPane  = function () { $scope.olbgMain.showiPadContent = false; };

        // expose functions to API and Utils service
        $scope.olbgMain.titles            = AppSettings.titles;
        $scope.olbgMain.menuLinks         = $scope.menuLinks;
        $scope.olbgMain.settingsAvailable = $scope.settingsAvailable;
        $scope.olbgMain.navigateTo        = $scope.navigateTo;
        $scope.olbgMain.navigateToParent  = $scope.navigateToParent;
        // $scope.olbgMain.navigateToChild   = $scope.navigateToChild;
        $scope.olbgMain.displayMessage    = $scope.displayMessage;
        $scope.olbgMain.goBack            = $scope.goBack;
        $scope.olbgMain.openModal         = $scope.openModal;
        $scope.olbgMain.closeModal        = $scope.closeModal;
        $scope.olbgMain.openSideMenuLink  = $scope.openSideMenuLink;
        $scope.olbgMainApi                = $scope.olbgMain;
        Utils.olbgMainApi                 = $scope.olbgMainApi;

        // EVENTS

        $scope.olbgMain.hotTipsReady.promise.then(function () {
            if ($scope.olbgMain.isHybrid) {
                // for Hybrid applications: check for new APK
                APKservice = $injector.get('APKUpdate'); // conditionally inject APKUpdate service
                APKservice.checkUpdates();
            }
        });

        // check for a state change
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            // by default: is connected
            connected = true;
            $scope.olbgMain.openState(event, toState, toParams, fromState, fromParams);
            handleSEOTitle();
        });

        // dialog boxes event
        $rootScope.$on('ngDialog.opened', function (e, $dialog) {
            $rootScope.blockAlerts = true;

            if ($scope.olbgMain.isWebApp) {
                scroll(0,0);
            }
        });

        $rootScope.$on('ngDialog.closed', function (e, $dialog) {
            $rootScope.blockAlerts = false;
        });

        // OLBG SETUP: navigate to My Account
        $rootScope.$on('OLBGMenu.toggle-hightlight-my-account', function (ev, args) {
            var btnClass  = '.menu-link__icon.settings-icon, .menu__open-button',
                className = 'olbgSetup-target',
                el        = $(document).find(btnClass);

            if (args.state) {
                el.addClass(className);
            } else {
                el.removeClass(className);
            }

            el.one('click', function () {
                $(this).removeClass(className); // make sure to remove highlight when clicked on
            });
        });

        // navigate to a certain state
        global.addEventListener('olbg.app.navigateTo', function (e) {

            if (!e.detail || !e.detail.state) {
                return;
            }

            $scope.olbgMain.navigateTo(e.detail.state);
        }, false);

        // navigate to URL
        global.addEventListener('olbg.app.navigateToUrl', function (e) {

            if (!e.detail || !e.detail.url) { return; }

            var url = e.detail.url;
            $location.url(url);
        });


        // Get default HOME PAGE and tabbar
        // HOME_ROUTES is defined in bootstrap.js
        defaultHomePageState  = localStorage && localStorage.getItem('homePage') ? localStorage.getItem('homePage') : 'home';
        defaultSelectedTabbar = HOME_ROUTES[defaultHomePageState].tabbar;
        defaultHomePageState  = HOME_ROUTES[defaultHomePageState].state;

        // deal with menus
        dealWithMenus();

        // FIRST CHECK IF SETTINGS PERSISTENCE IS AVAILABLE
        GeneralSettings.test()
            .then(function () {
                // Settings Persistence not available
                $scope.setSettingsAvailable(true);
            })
            .catch(function () {
            // Settings Persistence not available
            $scope.setSettingsAvailable(false);
        }).finally(function () {

            /**
             * DROPPING SUPPORT FOR 4.3 DEVICES
             * change made by Alvaro Casanova [07/18/2019]
             */

            // ANDROID SPECIFIC -----------------------------------------------------------------------------------------------------//
            // if (!$scope.olbgMain.inBrowser) {
            //     $scope.olbgMain.androidIsOld = ($scope.olbgMain.isAndroid  &&
            //                                     angular.isDefined(device)  &&
            //                                     angular.isDefined(device.version) &&
            //                                     (parseFloat(device.version) <= 4.3)); // set specific CSS class for versions older than 4.4
            // }
            // ANDROID SPECIFIC -----------------------------------------------------------------------------------------------------//

            // start with a clean slate
            $scope.resetNavigationMemory();

            // initialize settings
            initGeneralSettings()
                .then(function (settings) {

                    function showConsentOnboarding() {
                        Tracker.getCookiesConsent()
                            .then(function () {

                                // anything we wanna do after consensus dialog box

                            });
                    }

                    if ($scope.olbgMain.isPWA) {
                        PWAService.init(); // initialize the PWA service
                        $scope.olbgMain.listen('installedPWA', function () {

                            // anything we wanna do after the app is instaled...

                        });
                    }

                    if (olbgMeta.shouldBeBetting) {

                        // it should be betting: warn the user
                        navigator.notification.confirm(Lang.shouldBeBetting.message,function (index) {
                            if (parseInt(index) === 1) {
                                showConsentOnboarding();
                            } else {
                                $scope.navigateTo(AppSettings.states.contact, {
                                    params: { option: 1, debug: olbgMeta.locationData }
                                });
                            }
                        },Lang.shouldBeBetting.title,[Lang.shouldBeBetting.continue,Lang.shouldBeBetting.report]);
                    } else {
                        // show consent and onboarding right away
                        showConsentOnboarding();
                    }
                 });

            // handle SEO title
            handleSEOTitle();

            // Betslip sync
            if ($state.$current.self.name !== AppSettings.states.hotTips &&
                !($scope.olbgMain.isWebApp && $state.$current.self.name === AppSettings.states.betsManager)) {
                Betslip.sync();
            }

            // insert popular sports
            // get list of sports: exclude popular ones

            // avoid 1 API call to fill the top/side menus - we do not often change menus content
            // added a var into olbg-config.js

                /**
                 *
                 * Regarding: ^
                 *
                 * Sports.getSports retrieves all the sports once. It's a promise.
                 * Then sports are stored for the session. If user navigates to Filter settings
                 * or the sports page these have been preloaded here with this call.
                 *
                 *
                 * by Alvaro (yeahstyle@gmail.com)
                 */

            Sports.getSports(function (sports) {

                angular.forEach(sports, function (value) {

                    var nextSate      = '',
                        link          = {},
                        stateObj      = {},
                        params        = {},
                        stringArrays  = [],
                        sport         = value,
                        addIt         = true,
                        navigationUrl = sport.navigation_url;

                    sport.urlName = sport.api_sport.toLowerCase().replace(' ', '-');

                    angular.forEach(settings.sports, function (sportEl) {
                        if (sport.urlName.toLowerCase() === sportEl.name && !sportEl.popular) {
                            addIt = false;
                        }
                    });

                    // finally add the sport
                    if (addIt) {

                        stateObj = Utils.getStateFromUrl(navigationUrl);

                        // link.label:
                        link.label      = sport.alias;
                        if ($scope.olbgMain.isWebApp) {
                            link.cssLabel  = sport.api_sport.toLowerCase().replace(' ', '');
                            link.urlName   = sport.api_sport.toLowerCase().replace(' ', '-');
                            link.nextState = stateObj.nextState;
                            link.params    = stateObj.params;
                        }
                        link.state      = stateObj.nextState;
                        link.parameters = stateObj.params;
                    }
                });
            });
        });

        doc.ready(function () {

            // [IN APP REVIEW]
            if ($scope.olbgMain.isiOS) {
                document.addEventListener('deviceready', function () {
                    $timeout(function () {
                        if (InAppReview.initialized) { InAppReview.request(); }
                    }, PlatformSettings.inAppReview.threshold);
                });
            }

            // ANDROID SPECIFIC ----------------------------------------------------------------------//
            if ($scope.olbgMain.isAndroid) {
                ons.disableDeviceBackButtonHandler();

                document.addEventListener('backbutton', function () {
                    if (Navigation.getPagesNumber() === 1) {
                        if ($state.current.name !== defaultHomePageState) {
                            $scope.olbgMain.navigateHome();
                        } else {
                            navigator.app.exitApp(); // no back button: exit the app
                        }
                    }
                });
            }
        });
    });
})(window.OLBG_APP_META, window);