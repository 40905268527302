angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgTabs', ['$rootScope', 'AppSettings', function olbgTabsDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        scope: {
            tabsModel: '=',
            active: '=',
            enabled: '='
        },
        link: function (scope, el, attrs, ctrl) {
            ctrl.scroller = el.closest('.olbg-tabs').next();
        },
        controller: ['$scope', function ($scope) {
            this.select = function (tab) {
                if (!this.enabled) { return; }
                this.active = tab.tabId;
                if (this.scroller.length) {
                    this.scroller[0].scrollTo(0,0);
                }
            };
        }],
        controllerAs: 'olbgTabs',
        bindToController: true,
        templateUrl: AppSettings.templates['olbg-tabs'],
        replace: true
    };
}]);