angular.module('olbg-web-app')

.controller('chooseBookieCtrl', [

    '$scope',
    'AppSettings',
    'Betslip',
    '$q',
    'Bookies',
    'Utils',
    'GeneralSettings',
    '$timeout',
    '$state',
    'Preferences',

    function chooseBookieCtrl($scope, AppSettings, Betslip, $q, Bookies, Utils, GeneralSettings, $timeout, $state, Preferences) {

        /**
         * chooseBookie controller
         *
         * This page should be shown if:
         * 1) there are bets
         * 2) there are bookies available for the current bets
         *
         * It assumes Bets Manager is running on the background <-
         *
         * by: Alvaro Casanova (alvaro@olbg.com)
         */

        var oddsFormat,
            shouldCheck = false;

        function isEmpty() {
            $scope.chooseBookies.empty = true;
            $scope.chooseBookies.show  = true;
        }

        function getPage() {

            // set defaults
            $scope.chooseBookies.connected   = true;
            $scope.chooseBookies.show        = false;
            $scope.chooseBookies.empty       = false;
            $scope.chooseBookies.gotSelector = false;
            $scope.chooseBookies.dataReady   = false;

            /**
             * we'll need:
             * 1) odds format
             * 2) a reference to all the Bookies Available server side
             * 3) the bookies the user has account with (from localStorage)
             * 4) the current state of the Bets Basket (to know if it changes)
             * 5) finally: the available bookies for the current Bets Basket
             */
            Preferences.get('bookies')
                .then(function (p) {
                    $scope.chooseBookies.preferences = p.bookies;
                })
                .finally(function () {

                    GeneralSettings.get(AppSettings.labels.oddsFormat).then(function (data) {
                        oddsFormat = angular.isDefined(data) ? data.name : undefined;
                    }).finally(function () {

                        oddsFormat = angular.isDefined(oddsFormat) ? oddsFormat : AppSettings.oddsSettings.oddsSettingsDefault;

                        $scope.chooseBookies.oddsFormat = Betslip.parseOdds(AppSettings.labels[oddsFormat + 'Odds']);

                        $q.all([
                                Bookies.getAll(),
                                Bookies.getBookieAccounts(),
                                Betslip.sync(),
                                Bookies.getForCurrentBets(),
                                Betslip.getStoredBets(),
                            ]).then(function (responses) {

                            var availableBookies = responses[3];

                            $scope.chooseBookies.gotSelector = true;

                            // 1) if no bets -> go back to Your Bets
                            // (which should show empty state)
                            if (!Betslip.getCount()) {
                                isEmpty();
                                return;
                            }

                            // 2) get Bookies Accounts
                            $scope.chooseBookies.bookiesAccounts = responses[1];

                            /**
                             * 3) get reference to Bet Slip (we are updating it constantly...
                             * we might want to recalculate things over Bookies Selector)
                             */

                            $scope.chooseBookies.betsModel = Betslip.getBetslipModel();

                            // 4) get all the bookies
                            $scope.chooseBookies.allBookies = responses[0];

                            // 5) get available bookiesAccounts
                            $scope.chooseBookies.availableBookies = availableBookies;

                            $scope.chooseBookies.dataReady = angular.isDefined(availableBookies) && availableBookies.length;

                            // 6) get stored bets
                            $scope.chooseBookies.storedBets = responses[4];
                        })
                        .catch(function () {
                            $scope.chooseBookies.connected = false;
                        })
                        .finally(function () {
                            shouldCheck = true;
                            $scope.chooseBookies.show = true;
                        });
                    });
                });
        }

        // set initial title for the page:
        $scope.sectionTitle = AppSettings.titles['choose-bookie'];

        $scope.chooseBookies = {
            connected: true,
            show:  false,
            empty: false,
            gotSelector: false,
            dataReady: false,
            outcomes: Betslip.getOutcomes(),
            reload: function () {
                getPage();
            },
            // bookiesAccountConfirmCB: function () {
            //     $scope.chooseBookies.show = false;
            //     $timeout(function () {
            //        getPage(); // delay loading the page a bit
            //     }, 1000);
            // },
            noBookies: {
                title:    Utils.sanitizeHTML(AppSettings.chooseBookies.noBookies.title),
                message:  Utils.sanitizeHTML(AppSettings.chooseBookies.noBookies.message),
                button:   Utils.sanitizeHTML(AppSettings.chooseBookies.noBookies.btnLabel)
            },
            hotTipsState: AppSettings.states.hotTips
        };

        // if (Utils.olbgMainApi.landed) {
        //     Utils.olbgMainApi.navigateToParent();
        //     return;
        // }

        if (!Betslip.getCount() && Betslip.isSynced()) {
            isEmpty();
        } else {

            getPage();

            /**
             * if iPad: check for changes in betslip locally.
             */

            if (Utils.olbgMainApi.isiPad) {

                $scope.$watchCollection('chooseBookies.outcomes', function (value) {

                    if (!shouldCheck) {
                        return;
                    }

                    if (value.length) {
                        getPage();
                    } else {
                        if ($state.$current.self.name === AppSettings.states.bookiesSelector)  {
                            Utils.olbgMainApi.closeContentPane();
                        }
                    }
                });
            }

            Utils.olbgMainApi.listen(AppSettings.bookiesAccounts.savedEventName, getPage);
        }
}]);