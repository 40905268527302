angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBestOdds', ['$rootScope', 'AppSettings', function olbgBestOddsDirective($rootScope, AppSettings) {
    return {
        restrict: 'E',
        scope: {
            bets: '=',
            oddsFormat: '=',
            isiPad: '=isIpad',
            quickBettingEnabled: '='
        },
        controller: 'bestOddsCtrl',
        templateUrl: AppSettings.templates['best-odds']
    };
}]);