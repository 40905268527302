(function (appInfo) {

    if (angular.isUndefined(appInfo)) {
        throw 'OLBG_APP_META is required';
    }

    var environment = angular.isDefined(appInfo.environment) ? appInfo.environment : 'staging';

    // create the angular module
    angular.module('olbg-web-app')

    // put settings in a factory
    .factory('EnvironmentExtension', ['EnvironmentVariables', function EnvironmentExtensionService(EnvironmentVariables) {
        
        return {};

    }]);

})(OLBG_APP_META);
