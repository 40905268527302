angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetslipEmpty', ['AppSettings', 'Utils', function olbgBetslipDirective(AppSettings, Utils) {
    return {
        restrict: 'E',
        scope: {
            action: '&'
        },
        controller: ['$scope', function ($scope) {

            // very simple controller to parse content
            $scope.betslipEmpty = {
                title:   Utils.sanitizeHTML(AppSettings.betslip.empty.title),
                message: Utils.sanitizeHTML(AppSettings.betslip.empty.message),
                button:  Utils.sanitizeHTML(AppSettings.betslip.empty.buttonLabel)
            };            
        }],
        templateUrl: AppSettings.templates['betslip-empty']
    };
}]);