(function (appInfo) {

    if (angular.isUndefined(appInfo)) {
        throw 'OLBG_APP_META is required';
    }

    var environment = angular.isDefined(appInfo.environment) ? appInfo.environment : 'staging';

    // create the angular module
    angular.module('olbg-web-app')

    // put settings in a factory
    .factory('EnvironmentVariables', ['EnvironmentExtensionService', function EnvironmentVariablesService(extendedEnvironment) {

            /**
             * Returns the olbg environment settings.
             */

        var environments  = {},
            stagingAPI    = 'https://api.invendium-dev.com/',
            stagingAdsAPI = 'https://apiads.invendium-dev.com/';

        environments = {

            // territory to be used in international
            territory      : appInfo.international && appInfo.code &&
                                appInfo.code.toLowerCase() != 'int' &&
                                appInfo.code.toLowerCase() != 'us' ?
                                ('_lite_' + appInfo.code.toLowerCase()) : '',

            stagingAPI     : stagingAPI,
            adsAPI         : (environment === 'staging') ? 'http://apiads.invendium-dev.com/' : 'https://a.olbg.info/',
            // adsAPI      : stagingAdsAPI,
            snsAPI         : (environment === 'staging') ? 'http://apiads.invendium-dev.com/' : 'https://sync.olbg.info/',
            trackingAPI    : 'https://tracking.olbg.info/',
            apiKey         : (environment === 'staging') ? '6TLH24FHFMJJ2E5U'                       : 'VBK69W2Z7BNZQUNC',
            premiumKey     : (environment === 'staging') ? 'coreApp'                                : 'XBTuYbV19WkIZfrIjcCyNU0Wfs5o9gXA',
            bettingAPI     : (environment === 'staging') ? stagingAPI + 'newversion/'               : 'https://betting.olbg.info/',
            tipsAPI        : (environment === 'staging') ? 'http://tipsdev.​olbg.​info/'              : 'https://tips.olbg.info/',
            infoAPI        : (environment === 'staging') ? 'http://olbg.info/'                      : 'http://olbg.info/',
            premiumAPI     : (environment === 'staging') ? 'http://premiumdev.olbg.info/'           : 'https://premium.olbg.info/',
            tipstersAPI    : (environment === 'staging') ? 'https://followdev.olbg.info/'           : 'https://follow.olbg.info/',
            wizardsAPI     : (environment === 'staging') ? 'http://helpapi.invendium-dev.com'       : 'https://helpwizards.olbg.info',
            bookiesAPI     : 'http://bookiescms.olbg.info/api/',
            olbgDomain     : 'https://app.olbg.com',
            notifications  : (environment === 'staging') ? 'https://pushn-test.invendium-dev.com/api/' : 'https://pushn.olbg.info/api/',
            members        : (environment === 'staging') ? 'https://newdev.olbg.com/members/api/app/'  : 'https://www.olbg.com/members/api/app/'
            // members        : 'https://newdev.olbg.com/members/api/app/'
        };

        // return the extended environment
        return angular.extend(environments, extendedEnvironment);
    }]);
})(OLBG_APP_META);
