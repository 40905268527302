(function (appInfo) {

    if (angular.isUndefined(appInfo)) {
        throw 'OLBG_APP_META is required';
    }

    var environment = angular.isDefined(appInfo.environment) ? appInfo.environment : 'staging';

    // create the angular module
    angular.module('olbg-web-app')

    // put settings in a factory
    .factory('EnvironmentExtensionService', [function EnvironmentExtensionService() {

        var environments;

        environments = {};

        return environments;

    }]);

})(OLBG_APP_META);
