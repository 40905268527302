angular.module('olbg-web-app')

.controller('hotTipsPageCtrl', function (AppSettings, $rootScope, $scope, $q, $state, $timeout, Tips, TipsSettings, GeneralSettings, Navigation, DateService, ngDialog, Utils, TBL, Betslip, Environment, Lang, Pages, Preferences, Pub, EnvironmentVariables, NewFeatures) {

    var FEAT = AppSettings.labels.features.hotTips,
        fetching = true,
        loadedTips,
        ads,
        section,
        updatedTmp,
        headAd = false;

    // page values
    $scope.sectionTitle = AppSettings.hotTips.sectionTitle;

    function init(opts) {

        updatedTmp = Date.now();

        opts = angular.extend({
            updating: false
        }, opts);

        function proceed() {
            // set initial values
            resetControllerValues();

            // Update filters icon
            updateFilterIcon();

            // get tips
            $scope.getFirstPage({
                initBetslip: true,
                getCached: false
            })

                // [TIP HEADER AD]
                .then(function () {

                    if (!headAd && NewFeatures.isAvailable(AppSettings.labels.features.headerAds)) {

                        headAd = true;

                        // get the ad
                        Pub.getAds(AppSettings.ads.types['hot-tips-header'], {
                            url: Environment.APIurls.tips.replace(EnvironmentVariables.tipsAPI, '')
                        })
                            .then(function (ads) {
                                $timeout(function () {
                                    $scope.hotTips.headerAd = ads[0] ? ads[0] : '';
                                }, 1000);
                            });
                    }
                });
        }

        // hot tips namespace
        $scope.hotTips = {

            list: [],

            // connectivity
            connected : true,
            show      : true,

            /// preferences
            filtersEnabled          : true,
            filterByPreferences     : AppSettings.hotTips.byPreferencesDef, // by default turned on
            preferencesLabel        : Utils.sanitizeHTML(Lang.hotTips.preferences
                                                    .replace('${SPORTS_LINK_START}','<a ng-click="openYourSports()" href="">')
                                                    .replace('${SPORTS_LINK_END}','</a>')
                                                    .replace('${LEAGUES_LINK_START}','<a ng-click="openYourLeagues()" href="">')
                                                    .replace('${LEAGUES_LINK_END}','</a>')),
            filtersApplied          : Lang.hotTips.filtersApplied,
            noTipsWithFilters       : Lang.hotTips.emptyFilters,
            goToFilters             : Lang.hotTips.goToFilters,

            // search related
            searched                : false,
            searching               : false,
            searchBlur              : false,
            searchString            : null,
            searchedValue           : AppSettings.hotTips.search.placeholder,

            // pagination
            page                    : 1,
            requestPage             : 1,
            pageLoading             : false,
            pageEnd                 : false,
            pageSize                : AppSettings.hotTips.filter[AppSettings.labels.tipsPerPage],
            // pageSize                : AppSettings.hotTips.byPreferencesDef ? AppSettings.hotTips.byPreferencesSize : AppSettings.hotTips.filter[AppSettings.labels.tipsPerPage],
            pageEndCopy             : AppSettings.hotTips.endOfListString,
            useInfiniteScrollParent : false, // true by default
            infiniteScrollParent    : '.page--hotTips__content',

            // auto-update
            preventCache            : false
        };

        $rootScope.pageTitle           = AppSettings.pageSeo.hotTips.title  + ' - ' + AppSettings.defaultTitle;
        $rootScope.pageDescription     = AppSettings.pageSeo.hotTips.description;
        $rootScope.pageKeywords        = AppSettings.pageSeo.hotTips.keywords;

        // first get if using preferred sports and leagues
        TipsSettings.get('filterByPreferences')
            .then(function (data) {
                $scope.hotTips.filterByPreferences = data ? data.name : $scope.hotTips.filterByPreferences;
            })
            .finally(function () {

                // if using preferences >>>
                if ($scope.hotTips.filterByPreferences) {
                    Preferences.init()
                        .then(proceed);

                } else { proceed(); }
            });
    }

    function resume() {
        // only re-initialize if enough time has passed
        if (Date.now() - updatedTmp >= AppSettings.hotTips.updateAfter) {
            init();
        }
    }

    function resetControllerValues () {

        fetching = true;

        // search data
        encodedSearch = '';
    }

    function resetPagination (opts) {
        $scope.hotTips.requestPage = opts && opts.getCached ? $scope.hotTips.requestPage : 1;
        $scope.hotTips.page        = 1;
        $scope.hotTips.pageEnd     = false;
        $scope.hotTips.pageEndCopy = opts && opts.getAll ? Lang.hotTips.endPref : Lang.hotTips.endOfListString;
    }

    function updateFilterIcon() {

        /**
         * Updates the filter icon status.
         * If filters are on, the icon shows feedback (color and animation)
         */

        TipsSettings.get('hash').then(function (data) {

            var hash = angular.fromJson(data.name);
            $scope.hotTips.filters = hash[AppSettings.labels.filterStatus];

        }).catch(function (err) {
            $scope.hotTips.filters = false;
        });
    }

    function getTips(opts) {

        // INIT
        if (!opts.getCached) {
            Pages.create(FEAT); // create a stack for Hot Tips
        }

        var promise = $q.defer();

        Pages.initPage(FEAT, 1); // adds a page and its promise

        resetPagination(opts);

        /**
         * [ADS]
         * for now get ads only once!
         */

        Tips.getHotTips({
            page:      1, // get first page
            size:      $scope.hotTips.pageSize,
            getCached: opts && opts.getCached,
            getAll:    opts && opts.getAll,
            cacheAds:  true // for now
        }).then(function (tips) {

            // add the final data to the page
            Pages.addContent({ id: FEAT, data: angular.copy(tips), page: 1 });
            promise.resolve(tips);

        }).catch(promise.reject);

        return promise.promise;
    }

    function renderTips(opts) {

        opts = angular.extend({
            searching: false,
            applyPreferencesFilter: false,
            tips: [],
        }, opts);

        function isEmpty(o) {

            // set message
            $scope.hotTips.emptyMessage = $scope.hotTips.searched ? Utils.sanitizeHTML(AppSettings.hotTips.search.emptyString) : Utils.sanitizeHTML(AppSettings.hotTips.emptyString);
            $scope.hotTips.noEvents       = true;
            $scope.hotTips.pageEnd        = false;
            $scope.hotTips.pageLoading    = false;

            // set message for filtered sports
            if (o && o.filtered) {
                $scope.hotTips.emptyMessage = Utils.sanitizeHTML(Lang.hotTips.emptyPref);
            }

            // clean model
            $scope.hotTips.list = [];
        }

        function proceed(o) {

            function show() {

                // show the list
                $timeout(function () {
                    fetching = false;
                    $scope.hotTips.showList = true;

                    // finally: insert ads again
                    $scope.insertAds();

                }, Tips.areFirstHotTips() ? 0 : AppSettings.loadingDelay);
            }

            // get from stack
            Pages.getPage({id: FEAT, page: 1 })
                .then(function (tips) {

                    if (!tips || !tips.length) {
                        isEmpty(o);
                        show();
                    } else {

                        // there are events
                        $scope.hotTips.noEvents = false;

                        // update scope
                        $scope.hotTips.list = tips;

                        // $scope.hotTips.list.splice(0,7); // DEBUG!

                        // if less tips than tips per page. Show no more events
                        // message
                        if ($scope.hotTips.list.length < $scope.hotTips.pageSize) {

                            // normal list: there are no more tips
                            $scope.hotTips.pageEnd     = true;
                            $scope.hotTips.pageLoading = false;
                            show(); // finally show
                        }

                        show();
                    }

                    // if iPad open at least one tip
                    if (Utils.olbgMainApi.isiPad && !Utils.olbgMainApi.isContentPaneOpen()) {
                        // open the first tip
                        $scope.openTip($scope.hotTips.list[0]);
                    }
                });
        }

        // is searching >>>
        if (opts.searching) {

            if (opts.tips && !opts.tips.length) {
                isEmpty();
            } else {
                $scope.hotTips.list = opts.tips;
            }

            fetching = false;
            $scope.hotTips.showList = true;
        } else {

            // get preferences first:
            Preferences.get(['sports', 'leagues'])
                .then(function (p) {

                    // by preference >
                    if (opts.applyPreferencesFilter &&
                        (p.sports.length || p.leagues.length)) {

                        var stack = Pages.getWholeStack(AppSettings.labels.features.hotTips);

                        Tips.getFilteredList(stack)
                        .then(function (filtered) {

                            // generate stack
                            Tips.createFilteredStack(filtered);
                            proceed({filtered: true});

                        });
                    } else {
                        proceed();
                    }
                });
        }
    }

    /**
     * recursively get next page
     * @return {void}
     */
    function nextPage() {

        $scope.hotTips.requestPage++;

        var p = $scope.hotTips.requestPage,
            d = $q.defer(),
            t = [];

        Pages.initPage(FEAT, p); // init page

        Tips.getHotTips({
            page:     $scope.hotTips.requestPage,
            size:     $scope.hotTips.pageSize,
            cacheAds: true
        })
            .then(function (tips) {
                t = tips;
            })
            .finally(function () {
                Pages.addContent({ id: FEAT, data: angular.copy(t), page: p });
                d.resolve();
            });

        return d.promise;
    }

    // public methods (ran from view)
    $scope.reloadView = Utils.olbgMainApi.reloadApp;

    $scope.getFirstPage = function (opts) {

        var deferred = $q.defer();

        opts = angular.extend({
            initBetslip: false,
            getCached: false,
            updating: false
        }, opts);

        $scope.hotTips.show         = opts.updating;
        $scope.hotTips.connected    = true;
        $scope.hotTips.preventCache = false;
        $scope.hotTips.showList     = opts.updating;

        // Get first page:
        Preferences.get(['sports', 'leagues'])
            .then(function (p) {

                var applyingFilters = !!($scope.hotTips.filterByPreferences && (p.sports.length || p.leagues.length));

                // get all tips if using preferences!
                opts.getAll = applyingFilters;

                getTips(opts).then(function (tips) {

                    if (opts.initBetslip) {
                        $timeout(Betslip.sync, AppSettings.delays.betslip); // delay a bit >>> synchronize betslip after getting tips
                        // got betslip!
                        $scope.betslip = Betslip.getOutcomes();
                    }

                    renderTips({ applyPreferencesFilter: applyingFilters });
                    fetching = false;

                    deferred.resolve();

                    // after a while: prevent any sort of cache
                    $timeout(function () {
                        $scope.hotTips.preventCache = true;
                    }, AppSettings.hotTips.preventCacheTimer);

                })
                .catch(function () {
                    // could not get tips
                    $scope.hotTips.connected = false;
                    $scope.hotTips.show      = true; // stopped getting tips
                    deferred.reject();
                });
            });

        return deferred.promise;
    };

    $scope.openTip = function (tip) {

        if (tip.isAd) {

            if (tip.url) {
                window.open(tip.url, Utils.olbgMainApi.isiOS ? '_system' : '_blank');
            }
            return;
        }

        $scope.selectedTip = tip;

        Tips.openTip(tip);
    };

    $scope.loadNewEvents = function () {

        function appendEvents() {
            Pages.getPage({id: FEAT, page: $scope.hotTips.page})
                .then(function (tips) {
                    if (!tips || !tips.length) {
                        $scope.hotTips.pageEnd = true; // end pagination
                    } else {

                        $scope.hotTips.list = $scope.hotTips.list.concat(tips); // append!
                        $scope.insertAds();

                        // make sure it's the end!
                        if (tips.length < $scope.hotTips.pageSize) {
                            $scope.hotTips.pageEnd = true;
                        }
                    }
                })
                .catch(function () {
                    $scope.hotTips.pageEnd = true;
                })
                .finally(function () {
                    fetching = false;
                    $scope.hotTips.pageLoading = false;
                });
        }

        if ($scope.hotTips.pageEnd || $scope.hotTips.noEvents || fetching || $scope.hotTips.searched) { return; }

        var promise;

        fetching                   = true;
        $scope.hotTips.pageLoading = true;

        $timeout(function () {

            $scope.hotTips.page++; // increase pagination

            if (Pages.isInited(FEAT, $scope.hotTips.page)) {
                appendEvents(); // there's a request in progress: append events
            } else {
                // no request > make a new request
                nextPage()
                    .then(appendEvents);
            }
        }, 0);
    };

    $scope.searchTips = function (value) {

        // Angular trims the value automatically

        var q = value;

        if (!q || fetching) {
            return; // no value to search or fetching in progress
        }

        // make a copy of current tips
        loadedTips = $scope.hotTips.list;

        // set flags
        $scope.hotTips.searchedValue    = Lang.hotTips.search.searched + value;
        // encodedSearch                   = encodeURIComponent(AppSettings.hotTips.search.apiFormat + '\'' + value + '\'');
        $scope.hotTips.searchBlur       = true;

        $rootScope.pageTitle            = Lang.hotTips.search.searched + value  + ' - ' + AppSettings.defaultTitle;
        $rootScope.pageDescription      = AppSettings.pageSeo.hotTips.description;
        $rootScope.pageKeywords         = AppSettings.pageSeo.hotTips.keywords;

        if (!$scope.$$phase) {
            $scope.$apply();
        }

        // user has searched
        $scope.hotTips.searched  = true;

        // start search process
        $scope.hotTips.showList  = false;
        $scope.hotTips.searching = true; // hide current tips

        fetching = true;

        Tips.search(encodeURIComponent(value)).then(function (tips) {

            $scope.hotTips.searching = false; // show current tips

            // show results
            renderTips({searching: true, tips: tips });

            $scope.hotTips.searchBlur = false;

        });
    };

    $scope.resetSearch = function () {

        if (fetching) {
            return;
        }

        // end search process
        $scope.hotTips.searched         = false;
        $scope.hotTips.noEvents         = false;
        $scope.hotTips.searchString     = null;
        $scope.hotTips.searchedValue    = AppSettings.hotTips.search.placeholder;

        $rootScope.pageTitle            = AppSettings.pageSeo.hotTips.title  + ' - ' + AppSettings.defaultTitle;
        $rootScope.pageDescription      = AppSettings.pageSeo.hotTips.description;
        $rootScope.pageKeywords         = AppSettings.pageSeo.hotTips.keywords;

        $scope.getFirstPage({ getCached: !$scope.hotTips.preventCache });

        if (!$scope.$$phase) {
            $scope.$apply();
        }
    };

    $scope.trackHotTips = function (el) {

        if (el.isAd) {
            return 'ad' + el.id;
        }

        return el.tip_hash;
    };

    $scope.openYourSports = function () {
        Utils.olbgMainApi.navigateTo(AppSettings.states.mySports, {
            resetNavigator: false
        });
    };

    $scope.openYourLeagues = function () {
        Utils.olbgMainApi.navigateTo(AppSettings.states.myLeagues, {
            resetNavigator: false
        });
    };

    $scope.saveByPreferences = function () {
        // MAYBE:remove all the filters!
        // if (!$scope.hotTips.filterByPreferences) {}
        Preferences.isUsingPreferences($scope.hotTips.filterByPreferences)
            .finally(init);
    };

    $scope.insertAds = function (opts) {

        opts = angular.extend({ reinsert: false }, opts);

        function insertThem() {
            Pub.insertAds($scope.hotTips.list, ads, section);
        }

        Tips.getHotTipsAdsPromise()
        .then(function (data) {
            if (data && data.hotTipsAds && data.hotTipsAds.length) {
                ads     = data.hotTipsAds;
                section = data.section;
                insertThem();
            }
        });
    };

    $scope.updateTips = function () {
        init();
    };

    // update Hot Tips events
    $rootScope.$on('updatedMySports',  function () { $timeout(init, 500);});
    $rootScope.$on('updatedMyLeagues', function () { $timeout(init, 500);});
    $rootScope.$on('updatedFilters',   function () { $timeout(init, 500);});

    // listen to hotTips.enableLoadingNewEvents event!
    $rootScope.$on('hotTips.enableLoadingNewEvents', function () {

        // enable loading new events as long as number
        // of events is equal to tips per page

        // if ($scope.hotTips.length === AppSettings.hotTips.filter[AppSettings.labels.tipsPerpage]) {
        //     $scope.hotTips.pageLoading = true;
        //     $scope.endOfList = false;
        // }
    });

    // update hot tips on app resume from now on:
    document.addEventListener('deviceready', function () {
        // only update if enough time has passed
        document.addEventListener('resume', resume, false);
    }, false);

    init();

});