(function (olbgInfo) {

    var app, deps = [
            'olbg-config',
            'templates',
            'onsen',
            'ui',
            'ui.router',
            'ngDialog',
            'ngAnimate',
            'ngResource',
            'pouchdb',
            // 'djds4rce.angular-socialshare',
            'matchmedia-ng',
            'infinite-scroll',
            'wu.masonry',
            'ng.deviceDetector',
            'angular-bind-html-compile',
            'pasvaz.bindonce',
            'ngCookies',
            'angular-md5'
            // 'olbg-debugging' // DEBUG
        ];

    // create the olbg-web-app module (main application)
    app = olbgInfo.findOrCreateModule('olbg-web-app', deps);

    app.config(function ($provide, EnvironmentProvider, AppSettingsProvider, PlatformSettingsProvider) {

        olbgInfo.appSettings      = AppSettingsProvider.$get(); // settings now available globally
        olbgInfo.platformSettings = PlatformSettingsProvider.$get();

        // init analytics
        document.addEventListener('deviceready', function () {
            if (typeof window.FirebasePlugin !== 'undefined') {
                window.FirebasePlugin.setAnalyticsCollectionEnabled(true);
            }

            // use GA for now
            if (navigator && navigator.analytics) {
                navigator.analytics.setTrackingId(EnvironmentProvider.$get().keys.GAkey);
            }
        });

        // add exception Handling via Sentry!
        $provide.decorator('$exceptionHandler', function ($delegate) {

            return function (exception, cause) {
                // $delegate(exception, cause);
                console.error(exception);
                if (Sentry) {
                    Sentry.captureException(exception);
                }
            };
        });

    });

    // m.olbg.com
    if (olbgInfo.type === 'web-app') {
        app.config(function ($analyticsProvider) {
            // console.log('GA events: turning off automatic virtual page views for m.olbg.com');
            $analyticsProvider.virtualPageviews(false);
        });
    }

    // when app runs:
    // app.run(function ($FB, Environment) {
    //     $FB.init(Environment.keys.facebook);
    // });

})(OLBG_APP_META);