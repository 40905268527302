angular.module('olbg-web-app')

.directive('olbgSelectFacade', [function olbgSelectFacadeDirective() {
    return {
        restrict: 'A',
        scope: {
            ngModel: '=',
            ngDisabled: '=',
            olbgSelectFacade: '=',
            olbgSelectFacadeLabel: '@',
            olbgSelectFacadeKey: '@',
            olbgSelectFacadeFilter: '=?',         // optional filter function to apply to the shown value
            olbgSelectFacadeFilterParams: '=?',   // optional parameters for the filter function
            olbgSelectFacadePlaceholder:  '=?'    // optional parameter for showing a placeholder
        },
        link: function (scope, element, attrs) {

            var facade,
                facadeText,
                el = element,
                placeholder = scope.olbgSelectFacadePlaceholder,
                parentEl = element.parent(),
                wrapper = angular.element('<div class="select-facade-wrapper"><div class="select-facade"><span class="select-facade__text"></span></div></div>');

            function updateString(value) {

                var selectedString,
                    filterFunc   = scope.olbgSelectFacadeFilter,
                    filterParams = angular.extend([], scope.olbgSelectFacadeFilterParams),
                    model        = scope.olbgSelectFacade,
                    label        = scope.olbgSelectFacadeLabel,
                    key          = scope.olbgSelectFacadeKey;

                // everytime the model changes: select an element from the list
                angular.forEach(model, function (el) {

                    var finalString;

                    if (angular.isDefined(key) && el[key] === value) {
                        // update facade content
                        finalString = el[label];
                    } else {
                        // it's just an array
                        if (value === el) {
                            finalString = el;
                        }
                    }

                    if (angular.isDefined(finalString)) {

                        // check if there's a filter defined:
                        if (angular.isDefined(filterFunc) && angular.isDefined(filterParams)) {

                            // in the filters the first param is always the value
                            filterParams.unshift(finalString);

                            // apply the filter with the passed params
                            finalString = filterFunc.apply(undefined, filterParams);
                        }

                        facadeText.html(finalString);
                    }
                });
            }

            facade     = wrapper.find('.select-facade');
            facadeText = facade.find('.select-facade__text');

            // move select element into wrapper
            parentEl.append(wrapper.append(el));

            // if placeholder -> use it
            if (placeholder) {

                facadeText.html(placeholder);

                scope.$watch('olbgSelectFacadePlaceholder', function (val) {
                    facadeText.html(val);
                });
            }

            // watch possible values
            scope.$watchGroup(['ngModel', 'olbgSelectFacade', 'olbgSelectFacadeFilter'], function (value) {
                updateString(scope.ngModel);
            });

            scope.$watchCollection('olbgSelectFacadeFilterParams', function (value) {
                if (angular.isDefined(value) && value.length) {
                    updateString(scope.ngModel);
                }
            });

            // watch for the disabled event
            scope.$watch('ngDisabled', function (value) {
                if (value) {
                    facade.addClass('select-facade--disabled');
                } else {
                    facade.removeClass('select-facade--disabled');
                }
            });

            // focus / blur events

            el.on('focus', function () {
                facade.addClass('select-facade--focus');
            });

            el.on('blur', function () {
                facade.removeClass('select-facade--focus');
            });
        }
    };
}]);