angular.module('olbg-web-app')

    /*==========  controller for Sports Selection Page  ==========*/
    .controller('sportsChooserCtrl', function (AppSettings, $rootScope, $scope, $q, $state, Sports, Navigation, Utils, Preferences, Lang, $timeout) {

        /**
         * sportsChooserCtrl
         *
         * This is the controller for the sports selection menu.
         * All sports are shown except for the most popular ones
         */


        /**
         * [CUSTOMIZATION]:
         *
         * For now if no preferences: show regular page...
         */


        function init(preferences) {

            var sportsArray = [];

            Preferences.get('sports')
            .then(function (preferences) {

                $scope.view.connected  = true;
                $scope.showSports      = false;

                $scope.preferredSports = [];
                $scope.otherSports     = [];
                $scope.mySportsTitle   = Lang.mySports.title;
                $scope.emptyCopy       = Utils.sanitizeHTML(Lang.mySports.sportsPage.emptyPreferences
                                                                .replace('${LINK_START}','<a ng-click="openYourSports()" href="">')
                                                                .replace('${LINK_END}','</a>'));
                $scope.otherTitle      = Lang.mySports.sportsPage.notInterestedTitle;

                // get list of sports: exclude popular ones
                Sports.getSports(function (sports) {

                    var sortedSports;

                    angular.forEach(sports, function (value) {

                        var stringArrays  = [],
                            nextSate      = '',
                            params        = {},
                            sport         = value,
                            navigationUrl = sport.navigation_url;

                        sport.urlName  = sport.api_sport.toLowerCase().replace(' ', '-');
                        sport.cssLabel = sport.vc_sport_id;

                        /**
                         * as a request by Dragos [dragos@olbg.com]
                         * generate a hidden SEO URL for indexing!
                         */
                        Navigation.generateSEOlink({categories: [sport]});

                        // prepare state for each sport
                        // based on Navigation URL
                        stringArrays = navigationUrl.split('/');
                        stringArrays = stringArrays.slice(2);

                        switch (stringArrays.length) {

                            // one level navigation: categories
                            case 1:
                                nextState = 'sports.categories';
                                params.sportId = stringArrays[0];
                                break;

                            // two level navigation: leagues
                            case 2:
                                nextState = 'sports.categories.leagues';
                                params.sportId = stringArrays[0];
                                params.catId   = stringArrays[1];
                                break;

                            // third level navigation: events
                            case 3:
                                nextState = 'sports.categories.leagues.events';
                                params.sportId  = stringArrays[0];
                                params.catId    = stringArrays[1];
                                params.leagueId = stringArrays[2];
                                break;
                        }

                        sport.nextState = nextState;
                        sport.params    = params;

                        // finally push it!
                        sportsArray.push(sport);
                    });

                    // we got all the sports
                    sortedSports = Utils.sortSportsByPopular(sportsArray, 'urlName');

                    // generate the lists
                    angular.forEach(sortedSports, function (sport) {
                        var list = preferences.sports.indexOf(sport.vc_sport_id) >= 0 ? $scope.preferredSports : $scope.otherSports;
                        // var list = $scope.otherSports; // DEBUG
                        list.push(sport);
                    });

                    $scope.editLabel = $scope.preferredSports.length ? Lang.generic.buttons.edit : Lang.generic.buttons.add;

                    // show them
                    $scope.showSports = true;
                })
                .catch(function () { Utils.olbgMainApi.disconnect($scope.view); });
            });
        }

        $scope.view = {
            connected: true,
            reload: init
        };

        $scope.sectionTitle = AppSettings.sportsSelect.sectionTitle;

        // public functions
        $scope.openSport = function (sport) {
            var sportName = sport.urlName,
                nextState = sport.nextState,
                params    = sport.params;

            Navigation.setTransition({
                resetNavigator: false
            });

            $state.go(nextState, params);
        };

        $scope.openYourSports = function () {
            Utils.olbgMainApi.navigateTo(AppSettings.states.mySports, {
                resetNavigator: false
            });
        };

        init();

        $rootScope.$on('updatedMySports', function () { $scope.showSports = false; $timeout(init, 500);});
    });