angular.module('olbg-web-app')

/*==========  controller for the contact form  ==========*/
/**
 * added  Tracker.appendTI(url) to $http post
 * Adrian Radu adrianr@olbg.com - 2015.10.22
 */

.controller('contactCtrl', ['Environment', 'AppSettings', '$scope', '$state', '$sce', '$http', '$timeout', '$q', 'deviceDetector', 'Share', 'Utils', 'Tracker', 'Lang', '$stateParams', '$filter',
    function (Environment, AppSettings, $scope, $state, $sce, $http, $timeout, $q, deviceDetector, Share, Utils, Tracker, Lang, $stateParams, $filter) {

    var form = $scope.contactForm;

    function resetForm() {

        // reset model
        $scope.contact.model = {};
        $scope.contact.model.subject = AppSettings.contact.subjects[0];

        // reset contact form to Pristine state
        form.$setPristine();
    }

    $scope.$watch('contactForm', function(contactForm) {
      form = contactForm;
    });

    // set page title
    $scope.sectionTitle = AppSettings.contact.sectionTitle;

    // define the model
    $scope.contact = {
        model:    {},
        enabled:  false,
        showForm: true,
        placeholders: {
            name:    Utils.capitalize(Lang.generic.name),
            email:   Utils.capitalize(Lang.generic.email),
            message: Utils.capitalize(Lang.generic.message)
        }
    };

    // form settings
    $scope.contact.submitted = false;
    $scope.shakeInputs       = false;

    // set submit text
    $scope.contact.submitText = $sce.trustAsHtml(Lang.generic.buttons.submit);

    // expose data
    $scope.contact.subjects      = AppSettings.contact.subjects;
    $scope.contact.subjects[2]   = $filter('capitalize')($scope.contact.subjects[2]);
    $scope.contact.model.subject = $scope.contact.subjects[$stateParams.option ? parseInt($stateParams.option) : 0];
    $scope.contact.shareText     = AppSettings.contact.shareText;

    // populate stuff

    // the send function
    $scope.contact.send = function () {

        if (!$scope.contact.enabled) {
            return;
        }

        $scope.contact.enabled = false;

        Tracker.get().then(function (data) {

            var modelJSON,
                debuggingString,
                messageString,
                modelToSend,
                trackingInfo   = data,
                successMsg     = AppSettings.contact.successMessage,
                errorMsg       = AppSettings.contact.errorMessage,
                url            = Environment.APIurls.contact,
                errorsArray    = [],
                errorString    = '';

            $scope.contact.submitted = true;

            // get a hold of the message
            messageString = $scope.contact.model.message;

            if (form.$valid) {

                // SUBMIT FORM

                $scope.contact.showForm = false;
                $scope.contact.enabled  = false;

                // append debugging data!
                debuggingString = 'UserId: '          + trackingInfo.userId          + '\n' +
                                  'Browser: '         + trackingInfo.browser.name    + '\n' +
                                  'Browser-version: ' + trackingInfo.browser.version + '\n' +
                                  'Device: '          + deviceDetector.device        + '\n' +
                                  'OS: '              + trackingInfo.os.family       + '\n' +
                                  'OS-version: '      + trackingInfo.os.version      + '\n' +
                                  'Resolution: '      + trackingInfo.res             + '\n' +
                                  'userIP: '          + trackingInfo.userIp          + '\n';

                if (angular.isDefined(trackingInfo.appVersion)) {
                    debuggingString += ('App-build: ' + trackingInfo.appVersion + '\n');
                }

                if (angular.isDefined(trackingInfo.appName)) {
                    debuggingString += ('App: ' + trackingInfo.appName + trackingInfo.os.family + '\n');
                }

                if ($stateParams.debug) {
                    debuggingString += ('Additional Data: ' + $stateParams.debug);
                }

                // create a copy of the model to send ->
                modelToSend = angular.copy($scope.contact.model);
                modelToSend.message       += '\n' + debuggingString;
                modelToSend.patchVersion  = window.OLBG_APP_META && window.OLBG_APP_META.patchVersion ? window.OLBG_APP_META.patchVersion : '';

                // clear errors
                $scope.contact.error = errorString;

                // send model!
                Tracker.getInitPromise().then(function () {
                    $http({
                        method  : 'POST',
                        url     : Tracker.appendTI(url),
                        data    : $.param(modelToSend),  // pass in data as strings
                        headers : { 'Content-Type': 'application/x-www-form-urlencoded' }  // set the headers so angular passing info as form data (not request payload)
                    }).success(function (response) {
                        // console.log(response);
                        switch(response.contact_request.status) {
                            case 'success':
                                // reset form!
                                resetForm();
                                // dispatch message
                                $scope.displayMessage(successMsg, 'success');
                                break;
                            case 'failed':
                                // dispatch message
                                $scope.displayMessage(errorMsg, 'error');
                                // get rid of debugging
                                $scope.contact.model.message = messageString;
                                break;
                        }

                    }).error(function () {

                        // dispatch message
                        $scope.displayMessage(errorMsg, 'error');

                        // get rid of debugging
                        $scope.contact.model.message = messageString;

                    }).finally(function () {
                        // show form again!
                        $scope.contact.enabled  = true;
                        $scope.contact.showForm = true;
                    });
                });
            } else {

                // REPORT ERRORS!

                // check email
                if (!form.email.$valid || form.email.$isEmpty) {
                    errorsArray.push(form.email.$name);
                    form.email.$setDirty();
                }

                // check message
                if (!form.message.$valid) {
                    errorsArray.push(form.message.$name);
                    form.message.$setDirty();
                }

                // finally create string

                if (errorsArray.length > 1) {
                    errorString = AppSettings.contact.errorListString.replace('${ERROR_LIST}', errorsArray.join(', '));
                } else {
                    errorString = AppSettings.contact.errorString.replace('${ERROR}', errorsArray[0]);
                }

                // Finally emit error message!
                // $scope.displayMessage(errorString, 'error');
                $scope.contact.error = errorString;

                // also: shake inputs!
                $scope.contact.shakeInputs = true;

                $timeout(function () {
                    $scope.contact.shakeInputs = false;
                }, 1000);
            }
        });
    };

    // validate form
    $scope.contact.validate = function () {
        $scope.contact.enabled = form.$valid;
    };
}]);