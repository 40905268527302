angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgReceipt', ['$rootScope', 'AppSettings', '$templateCache', '$compile', function olbgReceiptDirective($rootScope, AppSettings, $templateCache, $compile) {
    return {
        restrict: 'E',
        controller: ['$scope', 'Lang', function ($scope, Lang) {
            $scope.currency = Lang.labels.currency;
        }],
        link: function (scope, element, attrs) {

            // get template
            var template = $templateCache.get(attrs.template);
            element.find('.olbg-receipt').append($compile(template)(scope));

            // set title
            if (angular.isDefined(attrs.title) && attrs.title) {
                element.prepend('<h1 class="olbg-receipt-title">' + attrs.title + '</h1>');
            }
        },
        templateUrl: AppSettings.templates['olbg-receipt'],
        replace: true
    };
}]);