/*==================================
=            Capitalize            =
==================================*/

// Shows any value capitalized

angular.module('olbg-web-app')

.filter('capitalize', function() {
    return function(input, all) {
      return (!!input) ? input.replace(/([^\W_]+[^\s-]*) */g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) : '';
    };
})


/*=============================
=            cssfy            =
=============================*/

// Turns any string in CSS friendly

.filter('cssfy', function() {
    return function(input) {
      return input.toLowerCase().replace(/\s/g, '-');
    };
})

/*=======================================
=            Show 2 decimals            =
=======================================*/

.filter('toDecimal', function () {
    return function (input) {
        return input ? +parseFloat(input).toFixed(2) : '';
    };
});


