angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgTipsByMarketMenu', function (AppSettings) {
    return {
        restrict: 'E',
        controller: 'olbgTipsByMarketMenuCtrl',
        templateUrl: AppSettings.templates['tips-by-market-in-menu'],
        replace: true
    };
});