angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgBetInfo', ['AppSettings', function olbgBetInfoDirective(AppSettings) {
    return {
        restrict: 'E',
        scope: {
            bet: '=',
            isSingle: '=',
            resetNavigator: '=?'
        },
        controller: ['$scope', 'Navigation', '$state', 'AppSettings', 'Utils', 'Markets', function ($scope, Navigation, $state, AppSettings, Utils, Markets) {

            //translate market
            if (Utils.isOlbgXX()) {
                Markets.getMarketName($scope.bet.marketId, $scope.bet.marketName).then(function(translatedMarket){
                    $scope.bet.marketName = translatedMarket;
                });
            }

            $scope.goToTipDetails = function () {

                var params  = $scope.bet.stateParams || $scope.bet.params,
                    expired = (angular.isDefined($scope.bet.changeSet) &&
                               angular.isDefined($scope.bet.changeSet.expired) &&
                               $scope.bet.changeSet.expired);

                if (!params || expired) {
                    return;
                }

                Utils.olbgMainApi.navigateTo(AppSettings.states.tipDetail, {
                    resetNavigator: $scope.resetNavigator && !Utils.olbgMainApi.isiPad,
                    params: params
                });
            };
        }],
        templateUrl: AppSettings.templates['bet-info']
    };
}]);