angular.module('olbg-web-app')

.controller('betButtonCtrl', ['$scope', 'AppSettings', 'Betslip', 'Utils', 'Lang', 'Tips', '$timeout', 'OLBGSetup', 'GeneralSettings', function betCtrl($scope, AppSettings, Betslip, Utils, Lang, Tips, $timeout, OLBGSetup, GeneralSettings) {

    function checkIfToggle() {

        if (angular.isUndefined($scope.betslip) || !$scope.betslip.length || angular.isUndefined($scope.tip) || !$scope.tip) {

            if (!$scope.betslip.length) {
                // The betslip changed and it's empty so:
                $scope.isAdded = false;
            }

            return;
        }

        var found = false;

        angular.forEach($scope.betslip, function (arrayItem) {

            var tipSport, tipSportId;

            // check that the bet-button is in the outcomesId array
            if (arrayItem.vc_outcome_id === $scope.tip.vc_outcome_id) {

                // check that sport is the same
                // We should check sportid instead of sport name because of translations
                tipSport = $scope.tip.sport_alias.toLowerCase().replace(' ', '');
                tipSportId = $scope.tip.vc_sport_id;

                if (arrayItem.vc_sport_id == tipSportId) {
                    $scope.isAdded = true;
                    found = true;
                }
            }
        });

        if (!found) {
            $scope.isAdded = false;
        }
    }

    function disable(value) {
        var isOFF = value && value.toLowerCase() == 'off';
        if (value === 'NR' || value === '' || value === '0.00' || isOFF) {
            $scope.enabled = false;
            $scope.odds = isOFF ? value.toUpperCase() : 'NR';
        }
    }

    function enable(value) {
        var isOFF = value && value.toLowerCase() == 'off';
        if (value === 'NR' || value === '' || value === '0.00' || isOFF) {
            return;
        }

        $scope.enabled = true;
    }

    function expire(value) {

        /**
         * [EXPIRED EVENTS]
         * when bet button expires make sure to
         * attempt to remove bet from Betslip
         */
        $timeout(function () {
            $scope.enabled = false;
            $scope.expired = true;

            if ($scope.isAdded) {
                removeBet(); // attempt to remove it when expired
            }
        }, 200);
    }

    function removeBet() {

        Betslip.remove($scope.tip.vc_outcome_id, $scope.tip.sport_alias, $scope.tip.vc_sport_id);

        // Bet removed succesfully
        $scope.isAdded = false;
    }

    function addBet() {

        // add tip
        Betslip.add($scope.tip.vc_outcome_id, $scope.tip.sport_alias, $scope.tip, $scope.odds, $scope.tip.sport_alias);

        // Bet added succesfully
        $scope.isAdded = true;

        // added enough selections to complete a challenge
        var step = OLBGSetup.steps.get('addSelections');
        if (step && step.isAvailable()) {
            OLBGSetup.completeSteps('addSelections');
        } else {
            Utils.olbgMainApi.displayMessage(Lang.betslip.addSuccess, 'success');
        }
    }

    $scope.formattedOdds = '-';

    // parse the odds
    GeneralSettings.getOdds()
        .then(function (f) {
            $scope.formattedOdds = $scope.tip[f];
        });

    if (angular.isDefined($scope.bettingEnabled) && !$scope.bettingEnabled) {
        return; // proceed only if Betting is enabled!
    }

    var eventDate = $scope.tip && $scope.tip.event_start_unix_timestamp ? $scope.tip.event_start_unix_timestamp : '';

    $scope.enabled      = false;
    $scope.expired      = false;
    $scope.addLabel     = Lang.generic.betActions.add;
    $scope.removeLabel  = Lang.generic.betActions.remove;

    // Scope methods
    $scope.toggleBet = function (evt) {

        // prevent click propagation
        if (angular.isDefined(evt)) {
            evt.stopPropagation();
        }

        if (!$scope.tip || !$scope.enabled || $scope.expired) {
            return; // There's no tip or button is disabled. Do nothing.
        }

        if ($scope.isAdded) { removeBet(); } else { addBet(); }
    };

    // Scope watch
    $scope.$watchCollection('betslip', checkIfToggle);
    $scope.$watch('tip', checkIfToggle);

    // if no odds: disable
    $scope.$watch('odds', function (value) {
        disable(value);
    });

    // activate links unless it's expired
    if (angular.isDefined($scope.expiration) && !$scope.expiration || !angular.isDefined($scope.expiration)) {
        Betslip.syncedOnce().then(function () {

            // attempt to enable
            enable($scope.odds);

            if (angular.isDefined($scope.expiration)) {
                // watch it!
                $scope.$watch('expiration', function (value) {
                    if (value) {
                        // expire the bet!
                        expire($scope.odds);
                    }
                });
            } else {
                if (eventDate) {

                    /**
                     * if no expiration handled from outside:
                     * attempt to do it in the inside
                     */

                    Tips.eventHasExpired({
                        // expiration: new Date().getTime() / 1000, // [EXPIRED EVENTS DEBUG]
                        expiration: eventDate,
                        scope: $scope
                    }).then(function () {
                        expire($scope.odds); // expire the bet!
                    });

                }
            }
        });
    } else {
        expire($scope.odds); // expire the bet
    }
}]);