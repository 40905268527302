angular.module('olbg-web-app')

.controller('betsManagerCtrl', [

    '$rootScope',
    '$scope',
    'AppSettings',
    'Betslip',
    '$q',
    'GeneralSettings',
    '$timeout',
    '$state',
    '$stateParams',
    'Utils',
    'Bookies',
    // 'ngDialog',

    function betsManagerCtrl($rootScope, $scope, AppSettings, Betslip, $q, GeneralSettings, $timeout, $state, $stateParams, Utils, Bookies) {

        /**
         * betsManagerCtrl
         *
         * This is the main controller that decides whether to show
         * the standard Betslip (user is logged in exactly to one Bookie)
         * or show Aggregated odds and the option to Choose a Bookie (user
         * is logged in to 0 or more Bookies).
         *
         * by: Alvaro Casanova (alvaro@olbg.com)
         */

        function extractSelections(selections) {

            if (Utils.olbgMainApi.isWebApp) {
                return angular.fromJson(decodeURIComponent(selections));
            } else {

                /**
                 * double quotes are not supported in deeplinking
                 * so we can't use JSON for hybrid apps
                 */

                return decodeURIComponent(selections) .slice(1, -1) .split(',');
            }
        }

        var bookieId,
            bookiesNum = 0,
            execTime   = new Date().getTime(),
            comingFromBetslip = false,

            // add selections via URL
            selections = $stateParams.selections ? extractSelections($stateParams.selections) : [];
            loadPagePromise = $q.defer(),
            addSelections = !!(selections.length &&
                            (($state.$current.self.name === AppSettings.states.betsManager) ||
                            Utils.olbgMainApi.isiPad && $state.$current.self.name === AppSettings.states.betsManager || $state.$current.self.name === AppSettings.states.bookiesSelector));

        function handleNoConnection() {

            // set title ->
            $scope.sectionTitle = AppSettings.titles.bets;
            Utils.olbgMainApi.disconnect($scope.betsManager);

            // // no internet connection
            // var newTime = new Date().getTime(),
            //     totalTime = newTime - execTime;

            // totalTime = totalTime >= 1000 ? 1000 : (1000 - totalTime);

            // // show loader
            // $scope.betsManager.show = false;

            // $timeout(function () {
            //     $scope.betsManager.show = true; // make sure to show the Betslip page anyways.
            //     $scope.betsManager.gotBetslip = false;
            // }, totalTime);
        }

        function getBetslip(opts) {

            // show loading state
            $scope.betsManager.show      = false;
            $scope.betsManager.connected = true;

            // Get a fresh betslip
            Betslip.sync(opts).then(function () {

                // got Betslip: check if empty
                if (!!Betslip.getCount()) {

                    $scope.sectionTitle = AppSettings.titles.bets;

                    $scope.betsManager.bets = Betslip.getBetslipModel().selections;

                    if ($scope.betsManager.bets.length) {
                        // there are bets:
                        Betslip.startAutomaticSyncing({
                            forceSyncing: false
                        });
                    }

                    // finally:
                    showTemplates();

                } else {
                    isEmpty();
                }
            }).catch(handleNoConnection);
        }

        function showTemplates() {
            $scope.betsManager.show       = true;
            $scope.betsManager.gotBetslip = true;
        }

        function isEmpty() {
            // console.log('Betslip: Is empty!');
            $scope.sectionTitle = AppSettings.betslip.empty.sectionTitle;
            $scope.betsManager.empty = true;
            showTemplates();
        }

        // set initial title for the page:
        $scope.sectionTitle = AppSettings.titles.betsManager;

        Betslip.setIsUpdating(false); // expired events will be removed from view

        $scope.betsManager = {
            connected:        true,
            show:             false,
            empty:            false,
            gotBetslip:       false,
            exactlyOneBookie: false,
            enabled:          true,
            bookiesWithAds:   false,
            bets:             [],
            oddsFormat:       AppSettings.oddsSettings.oddsSettingsDefault,
            hotTipsState:     AppSettings.states.hotTips,
        };

        if (addSelections) { // THERE ARE SELECTIONS

            // wait for both Betslip initing and syncing
            Betslip.init().then(function () {
                    // now sync
                    Betslip.sync().then(function () {
                        // console.log('Acca URL: Adding selections via URL!', selections);

                        Betslip.purgeBets() // 1. PURGE BETS!
                        .then(function () {
                            // 2. ADD!
                            Betslip.addInBulk(selections.map(function (selection) {
                                var selectionParts = selection.split('-');
                                return {
                                    sportid:     selectionParts[0],
                                    vc_sport_id: selectionParts[0],
                                    outcomeId:   selectionParts[1],
                                    formattedOdds: '0.00' // pass this temporarily to comply with Betslip.addLocally()
                                };
                            }))
                            .finally(loadPagePromise.resolve); // handle adding selections errors gracefully: ignore process

                    });
                })
                .catch(loadPagePromise.resolve); // handle adding selections errors gracefully: ignore process
            });
        } else {
            loadPagePromise.resolve();
        }

        // LOAD THE PAGE ->
        loadPagePromise.promise.then(function () {
            // we need to see if there are Bookies for this country
            Bookies.getBookiesWithAds()
                .then(function (response) {
                    $scope.betsManager.bookiesWithAds = response && response.bookies.length;

                    // if Betslip is synced and is empty: show immediately
                    if (!Betslip.getCount() && Betslip.isSynced()) {
                        isEmpty();
                    } else {
                        GeneralSettings.get(AppSettings.labels.oddsFormat, function (response) {
                            if (response.name) {
                                $scope.betsManager.oddsFormat = response.name;
                            }
                        }, function () {}, function () {
                            getBetslip({
                                forceBetslip: addSelections
                            });
                        });
                    }

                })
                .catch(handleNoConnection);
        });

        // betslip page public methods
        $scope.betsManager.reload = function (opts) {
            getBetslip(opts);
        };

        // EVENTS
        $scope.$watchCollection('betsManager.bets', function (betsArray) {
            // watch bets: if no more bets -> show empty state
            if (!betsArray.length && $scope.betsManager.gotBetslip) {
                $scope.betsManager.empty = true;
            } else {
                if ($scope.betsManager.gotBetslip) {
                    $scope.betsManager.empty = false;
                }
            }
        });

        // if betslip has been purged >
        $rootScope.$on('betslipPurged', function () {
            $scope.betsManager.empty = true;
        });

        /*----------  iPad exclusive  ----------*/
        if (Utils.olbgMainApi.isiPad) {
            $rootScope.$on('betslip.addedOperation', function () {
                $scope.betsManager.reload({forceBetslip: true});
            });

            $rootScope.$on('betslip.removedOperation', function (ev, removedBet) {

                // remove bet from view (if still there)
                // console.log(removedBet);
                if ($scope.betsManager.bets.filter(function (bet) {
                    return (bet.sport === removedBet.sport && bet.outcomeId === removedBet.outcomeId);
                }).length) {
                    Betslip.removeBetFromModel(removedBet.outcomeId, removedBet.sport, $scope.betsManager.bets, AppSettings.ads.types.betslip, {
                        section: AppSettings.ads.types.betslip,
                        syncAds: true
                    });
                }
            });
        }
}]);