/*======================================
=               olbgOdds               =
======================================*/

angular.module('olbg-web-app')

.filter('olbgOddsFormat', ['Utils', function olbgOddsFormatFilter(Utils) {
    return function(input, format) {

        /**
        * olbgOdds
        *
        * This filter formats an odds string
        * into the required
        *
        */

        if (angular.isDefined(input) && angular.isDefined(format)) {
            return Utils.formatOdds(input, format);
        }
    };
}]);