angular.module('olbg-web-app')

.controller('bookiesAccountCtrl', [

    '$scope',
    '$filter',
    'AppSettings',
    'Betslip',
    'GeneralSettings',
    'Tracker',
    'Bookies',
    '$q',

    function bookiesAccountCtrl($scope, $filter, AppSettings, Betslip, GeneralSettings, Tracker, Bookies, $q) {

        /**
         * IMPORTANT:
         * this functionality is tightly coupled to
         * My Bookies Accounts new page
         */

        /**
         * bookiesAccount modal controller
         *
         * by: Adrian Radu (adrianr@olbg.com)
         */
        var accountsArr = [],
            finalModel;

        function createBookie(availableBookie, bookieEnabled) {
            return {
                        id          : availableBookie.bookieId,
                        hasAccount  : !!bookieEnabled,
                        bookieName  : availableBookie.bookieName,
                        bookieLogo  : availableBookie.bookieLogo,
                        bookieColor : availableBookie.bookieColor
                    };
        }

        function getAvailableBookies() {

            // get a list of available bookies
            Bookies.getBookiesWithAds().then(function (response) {

                var userBookieAccounts = [],
                    main       = response.bookies && response.bookies.length ? response.bookies : [],
                    optional   = response.optional_bookies && response.optional_bookies.length ? response.optional_bookies : [],
                    allBookies = main;

                if (optional.length) {
                    allBookies = allBookies.concat(optional);
                }

                angular.forEach(allBookies, function (availableBookie) {
                    userBookieAccounts.push(createBookie(availableBookie, $scope.passedScope.bookieAccounts.indexOf(availableBookie.bookieId+'') >= 0));
                });

                // // populate optional bookies
                // $scope.bookieAccounts.optionalBookies = response.optional_bookies;

                // expose stored bookies accounts
                $scope.bookieAccounts.preferences = $scope.passedScope.bookieAccounts;

                // display the bookies in the modal
                angular.extend($scope.bookieAccounts.displayedBookies, userBookieAccounts);

                $scope.bookieAccounts.loading = false;

            }).catch(function () {
                $scope.bookieAccounts.failedGettingBookies = true;
            });
        }

        $scope.bookieAccounts = {
            loading: true,
            formEnabled: false,    // by default -> button is disabled
            failedGettingBookies: false,
            getAvailableBookies: getAvailableBookies,
            displayedBookies: []
        };

        // get list of current bookies
        if (!angular.isDefined($scope.passedScope) ||
            !angular.isDefined($scope.passedScope.bookieAccounts)) {

            Bookies.getBookieAccounts().then(function (response) {

                if (angular.isDefined($scope.passedScope)) {
                    $scope.passedScope.bookieAccounts = response;
                } else {
                    $scope.passedScope = {
                        bookieAccounts: response
                    };
                }
            })
            .finally(getAvailableBookies);
        } else {
            getAvailableBookies();
        }

        // update models whenever the user touches the displayed bookies
        $scope.$watch('bookieAccounts.displayedBookies', function (value) {

            accountsArr = [],
            finalModel  = [];

            if (value.length === value.filter(function (el) { return el.touched; }).length) {
                $scope.bookieAccounts.formEnabled = true;
            } else {
                $scope.bookieAccounts.formEnabled = false;
            }

            angular.forEach(value, function (acc) {

                finalModel.push({
                    bookieId:   acc.id,
                    hasAccount: acc.hasAccount
                });

                if (acc.hasAccount) {
                    accountsArr.push(acc.id);
                }
            });
        }, true);

        /**
         * save bookie accounts and send tracking
         * @param  {Object} opts options
         * @return {[type]}
         */
        $scope.proceed = function (opts) {

            var doc         = {},
                deferred    = $q.defer(),
                finalModel  = [],
                accountsArr = [];

            if (opts.modelToSave && opts.modelToSave.length) {
                angular.forEach(opts.modelToSave, function (bookie) {
                    finalModel.push({
                        bookieId:   parseInt(bookie.id),
                        hasAccount: bookie.hasAccount
                    });
                    accountsArr.push(bookie.id);
                });
            }

            doc[AppSettings.labels.bookieAccountsDB] = angular.toJson(finalModel);

            if (angular.isDefined($scope.confirm)) {
                $scope.confirm(finalModel);
            }
            
            return Bookies.storeBookiesAccounts({ids: accountsArr, from: AppSettings.tracking.via[0]});
        };
    }
]);