angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

.directive('olbgFoldButton', function (AppSettings, $templateCache, $compile) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var settings   = AppSettings,
                market     = scope.market,
                maxOutcome = market.maxOutcomes,
                // maxOutcome = AppSettings.tipsPage.maxOutcomes,
                buttonTemplate = angular.element($templateCache.get(AppSettings.templates['tips-by-market-fold-button']));

            // append the compiled button template at the end of the list
            element.append(angular.element($compile(buttonTemplate)(scope)));

            // public interface
            scope.fold = {}; // create scope namespace
            scope.fold.foldButtonTxt = settings.tipsPage.foldButtonContent;
            scope.fold.showAll = false;
            scope.fold.folded  = true;

            scope.fold.shouldFold = function (index) {

                /**
                 * shouldFold
                 *
                 * @index [{integer}]
                 * @catType [{string}]
                 * @maxOutcome [{integer | null}]
                 *
                 * Function that determines if the current tip should
                 * be shown. It takes the index number provided by ng-repeat,
                 * the category type so this is processed
                 */

                if (!maxOutcome) {
                    return false;
                }

                if (((index + 1) <= maxOutcome) && scope.fold.folded) {
                    return false;
                } else {
                    if (scope.fold.folded) {
                        return true;
                    }
                }
            };

            // check if fold button should be displayed
            scope.fold.hideFoldButton = function () {

                if (!maxOutcome || market.tipsArray.length <= maxOutcome) {
                    return true;
                }
            };

            // unfold list
            scope.fold.unfold = function () {
                scope.fold.folded = !scope.fold.folded;

                // set button content
                if (scope.fold.folded) {
                    scope.fold.foldButtonTxt = settings.tipsPage.foldButtonContent;
                } else {
                    scope.fold.foldButtonTxt = settings.tipsPage.unFoldButtonContent;
                }
            };
        }
    };
});