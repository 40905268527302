angular.module('olbg-web-app')

// prefix all directives with olbg to avoid any collisions
// with future standard

/*===============================
=            General            =
===============================*/


/*==========  Check active states for links in menu  ==========*/

.directive('olbgMenuActive', function ($rootScope) {

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $rootScope.$watch('selectedLink', function (value) {

                var label = attrs.olbgMenuActive.toLowerCase(),
                    selectedLink = value;

                // no selected link
                if (!angular.isDefined(selectedLink)) {
                    element.removeClass('isActive');
                    return;
                }

                selectedLink = value.toLowerCase();

                if (selectedLink === label) {
                    element.addClass('isActive');
                } else {
                    element.removeClass('isActive');
                }
            });
        }
    };
})


/*==========  Update the SEO page Title,Description and Keywords for each state  ==========*/

.directive('olbgUpdateTitle', function ($rootScope, $timeout) {

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $rootScope.$watch('pageTitle', function () {
                element.text($rootScope.pageTitle);
            });
        }
    };
})
.directive('olbgUpdateDescription', function ($rootScope, $timeout) {

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $rootScope.$watch('pageDescription', function () {
                element.attr('content',$rootScope.pageDescription);
            });
        }
    };
})
.directive('olbgUpdateKeywords', function ($rootScope, $timeout) {

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            $rootScope.$watch('pageKeywords', function () {
                element.attr('content',$rootScope.pageKeywords);
            });
        }
    };
})

/*==========  Create Open Graph meta tags  ==========*/

.directive('olbgOp', function ($rootScope, AppSettings, Environment, Utils) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            if (!Utils.isOlbgXX()) {
                element.append(
                    '<meta property="og:title" content="' + Environment.og.title + '" />' +
                    '<meta property="og:type"  content="' + Environment.og.type  + '" />' +
                    '<meta property="og:image" content="' + Environment.og.image + '" />' +
                    '<meta property="og:url"   content="' + Environment.og.url   + '" />' +
                    '<meta property="og:description" content="' + Environment.og.description + '" />'
                    );
            }
        }
    };
})


/*==========  Repaint  ==========*/

/**
 * This directive is a hack for lists embedded into
 * a div with -webkit-overflow-scrolling: touch;
 *
 * This is a fix for iOS
 */

.directive('olbgRepaint', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            element[0].style.display = 'none';

            scope.$watch(attrs.olbgRepaint, function (value) {
                var el = element[0];

                if (value) {
                    element.addClass('olbg-repaint--start');

                    // repaint it
                    $timeout(function () {
                        el.style.display = 'block';
                        $timeout(function () {
                            element.addClass('olbg-repaint--end');
                        }, 10);
                    }, 500);
                }
            });
        }
    };
})



/*===============================
=             Lists             =
===============================*/

/*==========  Hover Scrolling  ==========*/

// .directive('olbgHoverScrolling', function (matchmedia, AppSettings) {

//     /**
//      * This directive disables hovering when the user scrolls
//      * gaining performance.
//      */

//     return {
//         restrict: 'A',
//         link: function (scope, element, attrs) {

//             // var enableTimer = 0,
//             //     timeToRestore = 300;

//             // // enable hover to the element
//             // element.addClass('hover-enabled');

//             // // only execute this if desktop
//             // if (matchmedia.is(AppSettings.labels.mq.desktop)) {
//             //     // bind scroll event to window
//             //     element.bind('scroll', function () {

//             //         clearTimeout(enableTimer);
//             //         element.removeClass('hover-enabled');

//             //         // enable after 1 second
//             //         enableTimer = setTimeout(function () {
//             //             element.addClass('hover-enabled');
//             //         }, timeToRestore);

//             //         // scope.$apply();
//             //     });
//             // }
//         }
//     };
// });


/*===============================
=            Loaders            =
===============================*/

/*==========  Pagination  ==========*/

.directive('olbgPaginationLoader', function () {
    return {
        restrict: 'E',
        template: '<div class="pagination-loader__icon-wrapper"><div class="pagination-loader__icon"></div></div>'
    };
})

/*==========  Module-loading  ==========*/

.directive('olbgModuleLoading', function () {

    var className = 'load-module--show';

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var loaderWrapper = angular.element('<div class="load-module__contents"></div>');

            loaderWrapper.append(element.contents());

            element.append('<div class="load-module__spinner"></div>');
            element.append(loaderWrapper);

            if (angular.isDefined(attrs.olbgModuleIsPage)) {
                element.addClass('load-module--is-page');
            }

            // add load-module class
            element.addClass('load-module load-module--with-loader');

            scope.$watch(attrs.olbgModuleLoading, function (value) {
                if (!value) {
                    element.addClass(className);
                    element.removeClass('load-module--with-loader');
                } else {
                    element.removeClass(className);
                    element.addClass('load-module--with-loader');
                }
            });
        }
    };
})


/*=============================
=            Stats            =
=============================*/

.directive('olbgStat', function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var regex    = /^[-]/;
                olbgStat = attrs.olbgStat;

            // if value starts with underscore: add negative class
            if (regex.test(olbgStat)) {
                element.addClass('stat__number--negative');
            }
        }
    };
})


/*==============================
=            Search            =
==============================*/

/*==========  Search: BlurMe  ==========*/

.directive('olbgBlurMe', function ($timeout, $parse) {

    return {

        link: function (scope, element, attrs) {
            var model = $parse(attrs.olbgBlurMe);

            scope.$watch(model, function (value) {
                if (value) {
                    $timeout(function () {
                        element[0].blur();
                    });
                }
            });

            // element.bind('focus', function () {
            //     scope.$apply(model.assign(scope, false));
            // });
        }
    };
})

/*=======================================
=             Content Pages             =
=======================================*/

.directive('olbgContentPage', function ($rootScope, $state, Utils) {

    return {
        restrict: 'E',
        scope: {
            page: '='
        },
        link: function (scope, element, attrs) {

            if (scope.page) {
                // put the fetched content inside the content page element
                element.html('<div class="content-page is-typeset">' + scope.page + '</div>');
            }
        }
    };
})


/*========================================
=            Tips By Location            =
========================================*/

.directive('olbgTblBrowserWarning', function () {
    return {
        restrict: 'E',
        link: function (scope, element, attrs) {
            element.html('<p class="olbg-alert__body">' + scope.tipsByLocation.browserWarning + '</p>');
        },
    };
})


/*==================================================
=                  Share Directives                =
==================================================*/

.directive('olbgShareFacebook', function ($timeout, Tracker, Share) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.shareFacebook = function () {
                // just click the immediate children
                // element.children().click();
                Tracker.sendShareEvent('Facebook', Share.get());
            };
        }
    };
})

.directive('olbgShareTwitter', function ($timeout, Tracker, Share) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.shareTwitter = function () {
                // just click the immediate children
                // element.find('a')[0].click();
                Tracker.sendShareEvent('Twitter', Share.get());
            };
        }
    };
})

.directive('olbgShareEmail', function ($timeout, Tracker, Share) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            scope.shareEmail = function () {
                // just click the immediate children
                // element.find('a')[0].click();
                Tracker.sendShareEvent('Email', Share.get());
            };
        }
    };
})

/*==============================================
=                  Progress Bar                =
==============================================*/

.directive('olbgProgressBar', function () {
    return {
        restrict: 'E',
        scope: {
            progress: '='
        },
        controller: [function () {}],
        controllerAs: 'OPG',
        bindToController: true,
        link: function () {},
        template: '<div class="olbg-progress-bar"><div style="width: {{OPG.progress}}%" class="olbg-progress-bar__progress"></div></div>'
    };
});

