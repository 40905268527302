angular.module('olbg-web-app')

.controller('betslipWrapperCtrl', ['$rootScope',
    '$scope',
    'AppSettings',
    'Betslip',
    '$timeout',
    '$q',
    'GeneralSettings',
    'Utils',
    '$stateParams',
    'ngDialog',
    'Bookies',
    '$state',
    function betslipPageCtrl($rootScope, $scope, AppSettings, Betslip, $timeout, $q, GeneralSettings, Utils, $stateParams, ngDialog, Bookies, $state) {

    var watchModel    = false,
        execTime      = new Date().getTime(),
        settingsDefer = $q.defer(),
        listeners     = [];

    // function showChangedDialog(options) {

    //     // fill in data for Changes Dialog box

    //     // open the Changes dialog box
    //     $scope.betslipPage.changedDialog.dialog = ngDialog.open({
    //         template: AppSettings.modals.betslipChanged,
    //         scope: $scope
    //     });

    //     // listen to when the user closes the dialog box
    //     $scope.betslipPage.changedDialog.dialog.closePromise.then(function () {

    //         // make sure to scroll to top!
    //         Utils.olbgMainApi.scrollToTop({el: '.betslip__bets-container'});

    //         // when closed make sure to start automatic syncing
    //         if (options.allowAutomaticSync) {
    //             startAutomaticSync();
    //         }

    //         if (options.updateBetslip) {
    //             getFreshBetslip({force: true});
    //         }
    //     });
    // }

    function handleNoConnection() {

        // no internet connection
        var newTime = new Date().getTime(),
            totalTime = newTime - execTime;

        totalTime = totalTime >= 1000 ? 1000 : (1000 - totalTime);

        // show loader
        $scope.betslipPage.show = false;

        $timeout(function () {
            $scope.betslipPage.show = true; // make sure to show the Betslip page anyways.
            $scope.betslipPage.gotBetslip = false;
        }, totalTime);
    }

    function updateMultiples(opt) {

        // disable placing bets when updating
        $scope.betslipPage.canSubmit       = false;
        $scope.betslipPage.multiplesStatus = false;
        $scope.betslipPage.multiplesEmpty  = false;

        function execute() {

            if (Betslip.shouldShowMultiples()) {

                // attempt to update
                Betslip.updateMultiples({
                    logged: opt.logged,
                    force:  opt.force
                }).then(function () {

                    // got multiples. Check if they are empty.
                    if (!$scope.betslipPage.multiples.length) {

                        // multiples are still empty
                        $scope.betslipPage.multiplesEmpty = true;

                    } else {
                        $scope.betslipPage.multiplesEmpty = false;
                    }
                }).catch(function (errorType) {
                    switch (errorType) {
                        case 'login':
                            $scope.betslipPage.logged = false;
                            break;
                        case 'MultiplesNotAvailable':
                            break;
                        default:
                            $scope.betslipPage.multiplesError = true;
                            $scope.betslipPage.multiplesErrorMsg = AppSettings.betslip.multiples.genericError;
                            break;
                    }
                }).finally(function () {

                    // user can submit whether we get multiples or not
                    // but at least wait until we try to get them
                    $scope.betslipPage.multiplesStatus = true;
                    $scope.betslipPage.canSubmit       = true;
                });
            } else {
                // just in case: hide multiples
                $scope.betslipPage.showMultiples = false;
                // make sure to enable can submit again
                $scope.betslipPage.multiplesStatus = true;
                $scope.betslipPage.canSubmit       = true;
            }
        }

        // see if multiples is ready.
        var deferred = Betslip.getMultiplesPromise();

        // reset error flag
        $scope.betslipPage.multiplesError = false;

        if (angular.isDefined(deferred) && angular.isDefined(deferred.promise)) {
            // run a new operation once it's finished
            deferred.promise.finally(execute);
        } else {
            execute();
        }
    }

    function bookieIsAvailable(availableBookies) {

        var d     = $q.defer(),
            id    = parseInt($stateParams.bookieId),
            found = false;

        // check that the Bet Slip is valid:
        angular.forEach(availableBookies, function (bookie) {
            if (bookie.bookieId === id) {
                found = AppSettings.APIBookies.indexOf(id) >= 0; // make sure API is available too
            }
        });

        if (found) {
            d.resolve();
        } else {
            d.reject();
        }
        return d.promise;
    }

    function showPage() {

        var newExecTime   = new Date().getTime(),
            totalExecTime = newExecTime - execTime;

        // show betslip after at least 500ms
        if ((totalExecTime) < 500) {
            $timeout(function () {
               $scope.betslipPage.show = true;
            }, (500 - totalExecTime));
        } else {
            $scope.betslipPage.show = true;
        }
    }

    function getFreshBetslip(opts) {

        /**
         * getFreshBetslip()
         * involves two steps:
         * 1) get the actual Bet Slip
         * 2) get Bookies Selector information
         */

        opts = angular.extend({force: false}, opts);

        // show loading state
        $scope.betslipPage.show = false;

        $q.all([Betslip.sync({forceBetslip: opts.force}), Bookies.getForCurrentBets()])
        .then(function (responses) {

            // set the bookie
            $scope.betslipPage.urlBookie = Betslip.setBookie($stateParams.bookieId);

            // got Betslip!
            $scope.betslipPage.gotBetslip = true;

            // Bookies Selector
            $scope.betslipPage.bookiesSelector = responses[1];

            // Get Bet Slip
            $scope.betslipPage.betslip = Betslip.getBetslipModel();

            if (!$scope.betslipPage.betslip.selections.length) {
                // no bets
                renderBetslip();
            } else {

                // check if bookie is available!
                bookieIsAvailable($scope.betslipPage.bookiesSelector)
                    .then(renderBetslip)
                    .catch(function () {
                        goToBookiesSelector();
                    });
            }
        })
        .catch(handleNoConnection);
    }

    function renderBetslip() {

        // from this moment on: user can submit
        $scope.betslipPage.canSubmit = true;

        // Got Tips: Ready to render the page.
        $q.all([
            settingsDefer.promise,
            Betslip.getAvailableBookies(),
        ])
        .then(function (response) {

            // figure out odds format to be used in betslip
            $scope.betslipPage.oddsFormat = Utils.figureOutOdds(response[0]);

            // resolve Betslip ready in the olbg main controller
            // $scope.olbgMain.setBetslipReady();

            if (!$scope.betslipPage.betslip.selections.length) {
                $scope.betslipPage.empty = true;
                showPage();
            } else {
                // // Betslip is not empty. Check if it changed from last time.
                // Betslip.checkForChanges($scope.betslipPage.betslip, $scope.betslipPage.urlBookie).then(function (response) {

                //     if (!response) {
                //         // no changes: start automatic sync
                //         startAutomaticSync();
                //     } else {
                //         showChangedDialog({
                //             allowAutomaticSync: true
                //         });
                //     }

                //     // Now that the Betslip is not empty. We can check if the user is logged in.
                //     Betslip.isLoggedIn(Betslip.getCurrentBookie()).then(function (response) {

                //         // figure out if there are enough non expired events to show multiples:
                //         var showMultiples = Betslip.shouldShowMultiples();

                //         // set the logged flag!
                //         $scope.betslipPage.logged = response;

                //         if (showMultiples) {
                //             // show the multiples section.
                //             $scope.betslipPage.showMultiples = true;

                //             if ($scope.betslipPage.logged) {

                //                 updateMultiples({
                //                     logged: response,
                //                     force:  true
                //                 });
                //             }
                //         } else {
                //             // multiples are ok!
                //             $scope.betslipPage.multiplesStatus = true;
                //         }

                //         if ($scope.betslipPage.logged) {

                //             // now we can get balance
                //             Betslip.getBalance().then(function (response) {
                //                 $scope.betslipPage.balanceInfo = response;
                //             }).catch(function (errorType) {
                //                 // could not get Balance!
                //                 $scope.betslipPage.balanceInfo = false;

                //                 if (angular.isDefined(errorType) && errorType === 'login') {
                //                     $scope.betslipPage.logged = false;
                //                 }

                //             });

                //             // console.log('LOGGED IN TO:', $scope.betslipPage.urlBookie);
                //         }

                //         // finally: show the page
                //         showPage();
                //     });
                // });
            }
        }).catch(handleNoConnection);
    }

    function startAutomaticSync() {
        Betslip.startAutomaticSyncing({
            forceSyncing: false
        });
    }

    function getModelLength() {
        // watch outcomeIds array length
        return Betslip.getOutcomes().length;
    }

    function goToBookiesSelector() {
        Utils.olbgMainApi.goBack({
            parameters: {
                fromBetslip: true
            }
        });
    }

    function updateBookiesSelector() {

        $scope.betslipPage.betterOddsLoading = true;

        Bookies.getForCurrentBets({force: true}).then(function (el) { // get new Bookies Selector model
            $scope.betslipPage.bookiesSelector = el;
        }).finally(function () {
            $scope.betslipPage.betterOddsLoading = false;
        });
    }

    $scope.sectionTitle = AppSettings.titles.betslip;

    // Betslip page namespace
    $scope.betslipPage = {};

    // init things
    $scope.parameters = {
        fromBetslip: true
    };
    $scope.betslipPage.changedDialog = {};
    $scope.betslipPage.multiples     = Betslip.getMultiples();

    // Set flags
    $scope.betslipPage.show               = false;
    $scope.betslipPage.empty              = false;
    $scope.betslipPage.logged             = false;
    $scope.betslipPage.showMultiples      = false;
    $scope.betslipPage.multiplesError     = false;
    $scope.betslipPage.multiplesEmpty     = false;
    $scope.betslipPage.multiplesStatus    = false;
    $scope.betslipPage.betterOddsLoading  = false;
    $scope.betslipPage.betsInvalidHandler = {};
    Betslip.setIsUpdating(false);         // expired events will be removed from view


    GeneralSettings.get(AppSettings.labels.oddsFormat, function (response) {
        settingsDefer.resolve(response.name);
    });

    // if Betslip is synced and is empty: show immediately
    if (!Betslip.getCount() && Betslip.isSynced()) {
        // console.log('Betslip: Showing Betslip immediately');
        $scope.betslipPage.show  = true;
        $scope.betslipPage.empty = true;
        $scope.betslipPage.gotBetslip = true;
    } else {
        getFreshBetslip();
    }

    // betslip page public methods
    $scope.betslipPage.reload = function () {
        getFreshBetslip();
    };

    // $scope.betslipPage.loadMultiples = function () {
    //     Betslip.isLoggedIn(Betslip.getCurrentBookie()).then(function (response) {
    //         updateMultiples({
    //             logged: true
    //         });
    //     });
    // };

    // watch the length of the model.
    $scope.$watch(getModelLength, function (value) {
        // this should run only the second time the model changes (user deleted an object)
        if (angular.isDefined(value) && watchModel) {

            // console.log('Multiples: Betslip length changed. Attempting to update multiples.');

            // if we still need updating multiples
            if (Betslip.shouldShowMultiples()) {
                updateMultiples({
                    logged: $scope.betslipPage.logged,
                    force: true
                });
            } else {
                // hide multiples section:
                $scope.betslipPage.showMultiples = false;
                Betslip.emptyMultiples();
            }

            if (value) {
                updateBookiesSelector(); // update bookie selector if we still have bets
            }
        }

        // set the flag so that when the model changes from now on we
        // update multiples (if needed to)
        if (angular.isDefined(value)) {
            watchModel = true;
            $scope.betslipPage.empty = !value; // no more bets
        }
    });

    // watch bets invalid handler
    $scope.$watch('betslipPage.betsInvalidHandler', function (value) {

        if (angular.isDefined(value) && angular.isDefined(value.type)) {
            $timeout(function () {
                switch (value.type) {
                    case 'expiredEvents':
                        // show changed dialog
                        showChangedDialog({
                            allowAutomaticSync: true,
                            updateBetslip: true
                        });
                        break;
                    case 'invalidOdds':
                        // if new values aren't returned...
                        if (!value.odds) {

                            // no new odds: show generic error
                            $scope.olbgMainApi.displayMessage(AppSettings.betslip.validation.genericError, 'error');
                            // make sure to scroll to top!
                            Utils.olbgMainApi.scrollToTop({el: '.betslip__bets-container'});
                            getFreshBetslip({force: true});

                        } else {

                            // new odds are returned: we should show new odds
                            showChangedDialog({
                                allowAutomaticSync: false // when odds are invalid: don't update betslip
                            });
                            // prevent updating from now on:
                            Betslip.stopAutomaticSyncing();
                            // update model
                            Betslip.handleInvalidOdds(value.odds); // handle the invalid bets
                            // update multiples
                            updateMultiples({
                                logged: $scope.betslipPage.logged,
                                force: true
                            });
                        }
                        break;
                    case 'insufficientFunds':
                        $scope.olbgMainApi.displayMessage(AppSettings.betslip.validation.balance, 'error');
                        break;
                    default:
                        $scope.olbgMainApi.displayMessage(AppSettings.betslip.validation.genericError, 'error');
                        break;
                }
            }, AppSettings.betslip.validation.wait);
        }
    });

    // EVENTS
    listeners.push($rootScope.$on('multiples.notAvailable', function (event, message) {
        $scope.betslipPage.multiplesError = true;
        $scope.betslipPage.multiplesErrorMsg = message;
    }));

    listeners.push($rootScope.$on('betslip.updated', function () {

        // // check login hasn't expired
        // Betslip.isLoggedIn(Betslip.getCurrentBookie()).then(function (response) {

        //     // set the logged flag!
        //     $scope.betslipPage.logged = response;

        //     $scope.betslipPage.canSubmit = true;

        //     // if should show multiples
        //     if (Betslip.shouldShowMultiples()) {

        //         $scope.betslipPage.showMultiples = true;

        //         updateMultiples({
        //             logged: $scope.betslipPage.logged,
        //             force: false
        //         });

        //     } else {
        //         $scope.betslipPage.showMultiples = false;
        //     }
        // });

        // check for expired events and refresh best bookies
        if (angular.isDefined($scope.betslipPage.betslip) &&
            $scope.betslipPage.betslip.selections.length) {
            Betslip.checkForChanges($scope.betslipPage.betslip).then(function (changed) {
                if (changed) {

                    // Odds have changed or a bet has expired
                    updateBookiesSelector();
                }
            });
        }
    }));

    // deregister events
    Utils.deregisterEvents($scope, listeners);

}]);